import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { PropertyPanelComponent } from "./propertyPanel.component";
import { OkaPulldownModule } from "../okaPulldownModule/okaPulldown.module";
import { PropertyScoreInputModule } from "../propertyScoreInputModule/propertyScoreInput.module";
import { UiSwitchModule } from "ngx-ui-switch";
import { ColorPickerModule } from "../colorPickerModule/color-picker.module";
import { InlineSvgModule } from 'src/app/sharedModule/inlineSvgModule/inlineSvg.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
		TranslateModule,
		FontAwesomeModule,
		OkaPulldownModule,
		PropertyScoreInputModule,
		UiSwitchModule,
		ColorPickerModule,
		InlineSvgModule
    ],
    declarations: [
        PropertyPanelComponent
    ],
    exports: [
        PropertyPanelComponent
    ],
    providers: [],
    bootstrap: []
})
export class PropertyPanelModule { }