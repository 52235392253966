import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { TextFlowUtils } from "./TextFlowUtils";
// import { AllROComponents } from "./AllROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLJSNode } from "./xml/XMLParser";
import { XMLNode } from "./xml/XMLNode";
import { HTMElementTool } from "src/app/common/HTMLElementTool";
import { ROContext } from "./ROContext";
import { Subject } from "rxjs";

@Component({
	template:""
})

export class ROToggleOptionComponent extends ROComponent
{
	public textflowOffset:number = 0;
	public state:any;
	public answerControl;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.canEditInside = true;
		
	}
	public showDefaultAnswer(): void {
		this.state.defaultAnswer = true;
		this.answerControl.showDefaultAnswer();
	}
	public hideDefaultAnswer(): void {
		this.state.defaultAnswer = false;
		this.answerControl.hideDefaultAnswer();
	}
	public canVerify(): boolean {
		return true;
	}
	public verify(_showScoring: boolean) {
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.classList.remove("verify");
		// dom.classList.remove("correct");
		// dom.classList.remove("incorrect");
		if(this.answerControl.hasAnswer())
		{
			if(_showScoring) dom.classList.add("verify");
			
			var o:any = {maxScore:this.getFullScore()}
			var info:any = this.answerControl.verify(_showScoring);
			if(info.total == info.correct)
			{
				o.correct = 2;
				// all correct
			} else if(info.correct > 0)
			{
				o.correct = 1;
			} else {
				o.correct = 0;
			}
			o.score = o.maxScore;
			return o;
		}
		return null;
	}
	reset()
	{
		this.answerControl.setAnswer(null);
		this.answerChanged = true;
	}

	assignData(answer:any):void
	{
		this.answerControl.setAnswer(answer)
	}

	public set data(value:string) {
		this.answerChanged = false;
		if(value)
		{
			try{
				var o:any = JSON.parse(value);
				this.assignData(o);
			} catch(err)
			{
				this.reset();
			}
		} else {
			this.reset();
		}
	}	
	
	public get data():string {
		if(this.answerControl.hasAnswer())
		{
			return JSON.stringify(this.answerControl.getAnswer());
		}
		return null;
	}

	
	public answerEnable(flag: boolean): void {
		super.answerEnable(flag);
		this.state.answerEnable = flag;
	}

	public getTagNames():string []
	{
		return ["ToggleOptions2"];
	}

	protected setDefaultXMLData():void
	{
		
		var defaultText:string = "<TextFlow fontFamily=\"DFHKStdKai\" fontSize=\"32\" lineBreak=\"toFit\" whiteSpaceCollapse=\"preserve\" version=\"3.0.0\" xmlns=\"http://ns.adobe.com/textLayout/2008\"><p><span>一般文字</span></p></TextFlow>";
		this.node = new XMLJSNode().assign({
			"children": [
				{
					"type": "text",
					"text": defaultText
				}
			],
			attributes: {
				"autoHeight": true,
				"coordinateExpression": "UA UK KH X 60 Y 7 D SIZE 1024 768 1024 768 T 7 L 60 H 43 W 190",
				"douid": this.createDouid(),
				"fillingColor": 16117210,
				"fillingUnderLine": false,
				"fillingAlpha": 0,
				"autoWidth": false,
				"wideFilling": false,
				"writing": true,
				"transparency": 100,
				"memo": false,
				"isVAlign": false,
				"locked": false
			},
			tag:"TLFText"
		});
		this.node.createElement();
	}
	
	private removeAnswerSign(dom:HTMLElement):any
	{
		var list = dom.querySelectorAll("dom");
		var len = list.length;
		for(var i = 0;i < len;i++)
		{
			list[i].remove();
		}
	

		
		var elements:NodeListOf<Element> = dom.querySelectorAll(".answer");
		
		elements.forEach((answerNode:Element)=>{
			var text = answerNode.textContent;
			var isCorrect:boolean = text.indexOf("#") == 0;
			if(isCorrect)
			{
				this.removeFirstAnswerSign(answerNode);
			}
			
		});
		
	}
	private removeFirstAnswerSign(dom:ChildNode):void
	{
		if(dom instanceof Text){
			var textNode = <Text> dom;
			if(textNode.textContent == "#")
			{
				textNode.remove();
			} else {
				textNode.splitText(1);
				textNode.remove();
			}
		} else {
			this.removeFirstAnswerSign(dom.firstChild)
		}
	}
	private rebuidAnswerNode(container:HTMLElement):any[]
	{
		var groups = [];
	    var line = null;
	    container.childNodes.forEach((node)=>{
	        if(node.textContent == "/")
	        {
	            line = {type:"/", nodes:[node]};
	            groups.push(line);
	            line = null;
	        } else {
	            if(line== null)
	            {
	                line = {type:"ans", nodes:[]};
	                groups.push(line);
	            }
	            line.nodes.push(node);
	        }
	    })
	    var answerIndex = 0;
	    groups.forEach((line:any, index:number)=>{
	        var span = document.createElement("span");
	        this.tool.copyElementAttribute(<HTMLElement> container, span);
	        if(line.type == "/")
	        {
	            span.classList.add("separator")
	            container.appendChild(span);
	            line.nodes.forEach((node)=>{
	                span.appendChild(node);
	            })
	        } else if(line.type == "ans"){
	            span.classList.add("answer")
	            span.setAttribute("answer-index", "index-"+answerIndex);
	            answerIndex++;
	            container.appendChild(span);
	            line.nodes.forEach((node)=>{
	                span.appendChild(node);
	            })
	        }
	    });

		var answers:any [] = [];
		
		container.querySelectorAll(".answer").forEach((answerNode:HTMLElement, index:number)=>{
			var isCorrect:boolean = answerNode.textContent.indexOf("#") == 0;
			answers.push({
				index:index,
				title:answerNode.textContent.replace(/^#/, ""),
				isCorrect:isCorrect,
				element:answerNode
			});
		});
		this.removeAnswerSign(container);
		return answers;
	}
	tool:HTMElementTool;
	blankOptions:any;
	blanks:any[];
	subject:Subject<any>;
	protected buildContent():void
	{
		this.subject = new Subject();
		this.blankOptions = JSON.parse(this.node.getAttribute("blank"));
		this.state = {
			subject:this.subject
		};
		this.subject.subscribe((data:any)=>{
			this.answerChanged = true;
		})
		this.tool = new HTMElementTool();
		var utils:TextFlowUtils = new TextFlowUtils();
		var blankIndex:number =  0;
		var blanks = [];
		utils.replaceHandler("Blank",{
			createTextFlowDOM:(rootNode:XMLNode, listElement:any, node:XMLNode, withBG:boolean):HTMLElement =>{
				var markup:string = node.getAttribute("markup");
				var div = document.createElement("div");
				var dom:HTMLElement = utils.textFlowToDOM(markup, false); // p
				
				this.tool.splitTextNode(dom, /\[|\#|\/|\]/g);
				// removing [ and ]
				this.tool.removeNode(dom, /\[|\]/g);
				var answers:any[] = this.rebuidAnswerNode(dom);
				dom.classList.add("blank-container")
				blankIndex++;
				blanks.push({
					index:blankIndex,
					dom:dom,
					answers:answers
				});
				
				div.classList.add("inline-block");
				div.setAttribute("blank-index", blankIndex.toString());
				div.append(dom);
				return div;
			}
		})
		this.blanks = blanks;
		try
		{
			var textFlow:string = this.node.children[0].text;
			var dom:HTMLElement = this.elementRef.nativeElement;
			var container:HTMLElement = document.createElement("div");
			dom.appendChild(container);

			var flow:HTMLElement= utils.textFlowToDOM(textFlow, true);
			this.estimateFirstLine(flow);
			container.appendChild(flow);
			var blankContainerIndex2:number = 0;
			
			
			if(this.blankOptions.type == "h")
			{
				dom.classList.add("h");
				this.answerControl = new HToggleControl2(this.context, this.blanks, this.state);
			} else {
				dom.classList.add("v");
				this.answerControl = new VToggleControl2(this.context, this.blanks, this.state);
			}
			dom.appendChild( document.createComment(JSON.stringify(this.node.attributes))  );
		} catch(err)
		{
			debugger;
		}
		
		
///		var flow:HTMLElement= utils.textFlowToDOM(textFlow, false);
///		container.appendChild(flow);

		var transparency:number = this.node.getAttribute("transparency");
		if(transparency != 100)
		{
			dom.style.opacity = `${transparency}%`; 
		}
		if(this.textflowOffset) container.style.transform = `translate(0px, -${this.textflowOffset}px)`;
	}

	public isQuestionComponent():boolean
	{
		return true;
	}


	protected estimateFirstLine(flow:HTMLElement):void
	{
		// var dom:HTMLElement = this.elementRef.nativeElement;
		var tag = this.node.tag;
		var container = document.createElement("div");
		if(tag)
		{
			container.setAttribute("component", tag);
			container.classList.add("ro-component", tag);
		}
		container.style.width = this.w+"px";
		container.style.height = this.h+"px";
		document.body.appendChild(container);
		container.appendChild(flow);
		var topElement:HTMLElement = container.querySelector("dom.t");
		var bottomElement:HTMLElement = container.querySelector("dom.b");
		if(topElement && bottomElement)
		{
			var b = topElement.getBoundingClientRect();
			var d = bottomElement.getBoundingClientRect();
			var lineHeight = StyleUtils.getDeepStyleVariable(topElement, "span-line-height");
			var l:number;
			if(lineHeight)
			{
				l = parseFloat(lineHeight) / 100;
			} else {
				l = 1.2;
			}
			var tempH = (d.bottom - b.top);
			this.firstLineHeight = tempH / l;
			var offset = this.firstLineHeight * (l - 1)/2;
			
			this.textflowOffset = offset;
		}
		container.parentElement.removeChild(container);
		container.removeChild(flow);
	}

	// =======================================
	// edit relative function
	// =======================================
	public set editInStage(value:boolean)
	{
		this._editInStage = value;
		this.elementRef.nativeElement.setAttribute("contenteditable", value);
		this.elementRef.nativeElement.focus();
		if(!value &&  (<HTMLElement>this.elementRef.nativeElement).firstElementChild) {
			// 文字編輯完成
			//this.updateBounding();
			var dom:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild);
			this.h = dom.offsetHeight + this.textflowOffset;
			this.assignLTWH();

			this.getXMLJSON();
		}
	}
	public get editInStage():boolean{
		return this._editInStage;
	}
	
	public resizeWidth(width:number):any {
		// 更新闊度
		this.w = Math.max(40, width);
		var dom:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild);
		this.h = dom.offsetHeight + this.textflowOffset;

		this.assignLTWH();
		
		// 取得新的size
		/*
		div tramsform
			div textflow
				pre
		*/
		return {width:this.w, height:this.h};
	}

	public getSelectorSettings():any {
		return {
			popupSelector:this.getPopupSelector(),
			rotation:false,lockedAspectRatio:false,widthResizer:true,heightResizer:false,resizer:false,move:true
		};
	}

	public getXMLJSON():any {
		var utils:TextFlowUtils = new TextFlowUtils();
		this.node.children[0].text = this.node.element.elements[0].text = utils.domToTextFlow(this.elementRef.nativeElement.firstElementChild.firstElementChild);
		return super.getXMLJSON();
	}
}
class VToggleControl
{
	
	private selectedAnswer:any;
	container: HTMLElement;
	constructor(private context:ROContext, private blank:any, private state:any)
	{
		this.container = blank.dom;
		// private container:HTMLElement, private answers:any[], 
		this.container.querySelectorAll(".separator").forEach((node)=>{
			node.remove();
		})
		this.container.addEventListener("tap", this.onTap.bind(this));
	}
	showDefaultAnswer():void
	{
		this.blank.answers.forEach((answer:any)=>{
			if(answer.isCorrect) answer.element.classList.add("selected");
			else answer.element.classList.remove("selected");
		});
	}
	hideDefaultAnswer():void
	{
		this.blank.answers.forEach((answer:any)=>{
			answer.element.classList.remove("selected");
			if(this.selectedAnswer == answer) answer.element.classList.add("selected");
		});
	}
	verify(show:boolean):boolean
	{
		var isCorrect = this.isCorrect();
		this.blank.dom.classList.remove("correct-answer");
		this.blank.dom.classList.remove("incorrect-answer");
		if(show)
		{
			if(isCorrect)
			{
				this.blank.dom.classList.add("correct-answer");
			} else {
				this.blank.dom.classList.remove("incorrect-answer");
			}
		}
		return isCorrect;
	}
	isCorrect():boolean
	{
		return (this.selectedAnswer && this.selectedAnswer.isCorrect);
	}
	hasAnswer():boolean
	{
		return this.selectedAnswer !== null;
	}
	getAnswer(): any {
		if(this.selectedAnswer)
		{
			return {
				index:this.selectedAnswer.index,
				text:this.selectedAnswer.title
			}
		} else {
			return {
				index:-1,
				text:""
			}
		}
	}

	setAnswer(answer: any) {
		if(
			answer &&
			answer.index >=0 && answer.index <this.blank.answers.length
		)
		{
			var targetAnswer = this.blank.answers[answer.index];
			this.selectAnswer(targetAnswer);
		} else {
			this.selectAnswer(null)
		}
	}
	onTap(event:Event):void
	{
		if(this.state.defaultAnswer) return;
		if(!this.state.answerEnable) return;
		event.stopImmediatePropagation();
		var selections:any = [{title:this.context.translate("ro.book.clear-answer"), type:"clear"}].concat(this.blank.answers);
		this.context.service.whitePopupService.showSelection(this.container, selections).then((answer:any)=>{
			this.blank.dom.classList.remove("correct-answer", "incorrect-answer");
			if(answer.type == "clear")
			{
				this.selectAnswer(null);
				this.state.subject.next(null);
			} else {
				this.selectAnswer(answer);
				this.state.subject.next(null);
			}
		});
	}

	reset():void
	{
		if(this.selectedAnswer)
		{
			this.selectedAnswer.element.classList.remove("selected");
			this.selectedAnswer = null;
		}
	}
	selectAnswer(answer:any)
	{
		/*
		if(this.selectedAnswer != answer)
		{
			if(this.selectedAnswer) this.selectedAnswer.element.classList.remove("selected");
			this.selectedAnswer = answer;
			if(this.selectedAnswer) this.selectedAnswer.element.classList.add("selected");
		}
		*/
		this.selectedAnswer = answer;
		this.blank.answers.forEach((ans:any)=>{
			ans.element.classList.remove("selected");
			if(this.selectedAnswer == ans) ans.element.classList.add("selected");
		});
		if(this.selectedAnswer)
		{
			this.blank.dom.classList.add("has-selection");
		} else {
			this.blank.dom.classList.remove("has-selection");
		}

	}
}
class HToggleControl2{
	items: HToggleControl[];
	constructor(private context:ROContext, private blanks:any[], private state:any)
	{
		this.items = this.blanks.map((blank)=>{
			return new HToggleControl(blank, this.state);
			// new VToggleControl(this.context, blank, this.state);
		})
	}
	showDefaultAnswer():void
	{
		this.items.forEach((item:HToggleControl)=>{
			item.showDefaultAnswer();
		});
	}
	hideDefaultAnswer():void
	{
		this.items.forEach((item:HToggleControl)=>{
			item.hideDefaultAnswer();
		});
	}
	verify(showFlag:boolean):any
	{
		var correctCount:number = 0;
		var total:number = this.items.length;
		this.items.forEach((item:HToggleControl)=>{
			if(item.verify(showFlag))
			{
				correctCount++;
			}
		})
		return {total:total, correct:correctCount};
	}
	hasAnswer():boolean
	{
		return this.items.filter((item:HToggleControl)=>{
			return item.hasAnswer();
		}).length > 0;
	}
	getAnswer():any
	{
		if(this.hasAnswer())
		{
			return {
				data:this.items.map((item:HToggleControl)=>{
					return item.getAnswer();
				})
			};
		}
		return null;
	}
	setAnswer(ans:any)
	{
		if(ans && ans.data && ans.data.length == this.items.length)
		{
			return this.items.forEach((item:HToggleControl, index:number)=>{
				var o = ans.data[index];
				item.setAnswer(o);
			});
		} else {
			return this.items.forEach((item:HToggleControl)=>{
				item.setAnswer(null);
			});
		}
	}
}
class VToggleControl2{
	items: VToggleControl[];
	constructor(private context:ROContext, private blanks:any[], private state)
	{
		this.items = this.blanks.map((blank)=>{
			return new VToggleControl(this.context, blank, this.state);
		})
	}
	showDefaultAnswer():void
	{
		this.items.forEach((item:VToggleControl)=>{
			item.showDefaultAnswer();
		});
	}
	hideDefaultAnswer():void
	{
		this.items.forEach((item:VToggleControl)=>{
			item.hideDefaultAnswer();
		});
	}
	verify(showFlag:boolean):any
	{
		var correctCount:number = 0;
		var total:number = this.items.length;
		this.items.forEach((item:VToggleControl)=>{
			if(item.verify(showFlag))
			{
				correctCount++;
			}
		})
		return {total:total, correct:correctCount};
	}
	hasAnswer():boolean
	{
		return this.items.filter((item:VToggleControl)=>{
			return item.hasAnswer();
		}).length > 0;
	}
	getAnswer():any
	{
		if(this.hasAnswer())
		{	
			return {
				data:this.items.map((item:VToggleControl)=>{
					return item.getAnswer();
				})
			};
			// this.items.getData();
		}

		return null;
	}
	setAnswer(ans:any)
	{
		if(ans && ans.data && ans.data.length == this.items.length)
		{
			return this.items.forEach((item:VToggleControl, index:number)=>{
				var o = ans.data[index];
				item.setAnswer(o);
			});
		} else {
			return this.items.forEach((item:VToggleControl)=>{
				item.setAnswer(null);
			});
		}
	}
}

class HToggleControl
{
	private selectedAnswer:any = null;
	constructor(private blank:any, private state:any)
	{
		var that = this;
		this.blank.answers.forEach((answer:any)=>{
			answer.element.addEventListener("tap", (event:any)=>{
				if(this.state.defaultAnswer) return;
				if(!this.state.answerEnable) return;

				that.selectAnswer(answer === that.selectedAnswer ? null : answer);
				this.state.subject.next(null);
			})
		});
	}
	showDefaultAnswer():void
	{
		
		this.blank.answers.forEach((answer:any)=>{
			if(answer.isCorrect) {
				// answer.element.classList.add("correct-answer");
				answer.element.classList.add("selected");
			} else {
				answer.element.classList.remove("selected");
			}
		});
	}
	
	hideDefaultAnswer():void
	{
		this.blank.answers.forEach((answer:any)=>{
			if(this.selectedAnswer == answer)
			{
				answer.element.classList.add("selected");
			} else {
				answer.element.classList.remove("selected");
			}
		});
	}
	verify(show:boolean):boolean
	{
		this.blank.answers.forEach((answer:any)=>{
			answer.element.classList.remove("correct-answer");
			answer.element.classList.remove("incorrect-answer");
		});
		var isCorrect:boolean = false;
		if(this.selectedAnswer)
		{
			isCorrect = this.selectedAnswer.isCorrect ? true : false;
			if(show)
			{
				if(isCorrect)
				{
					this.selectedAnswer.element.classList.add("correct-answer");
				} else {
					this.selectedAnswer.element.classList.add("incorrect-answer");
				}
			}
		}

		return isCorrect;
	}
	isCorrect():boolean
	{
		return this.selectedAnswer !== null && this.selectedAnswer.isCorrect;
	}
	hasAnswer():boolean
	{
		return this.selectedAnswer !== null;
	}
	getAnswer(): any {
		if(this.selectedAnswer)
		{
			return {
				index:this.selectedAnswer.index,
				text:this.selectedAnswer.title
			}
		} else {
			return {
				index:-1,
				text:""
			}
		}
	}
	setAnswer(answer:any)
	{
		if(
			answer &&
			answer.index >=0 && answer.index <this.blank.answers.length
		)
		{
			var targetAnswer = this.blank.answers[answer.index];
			this.selectAnswer(targetAnswer);
		} else {
			this.selectAnswer(null)
		}
	}
	
	reset():void
	{
		if(this.selectedAnswer)
		{
			this.selectedAnswer.element.classList.remove("selected");
			this.selectedAnswer = null;
		}
	}
	selectAnswer(answer:any)
	{
		/*
		if(this.selectedAnswer != answer)
		{
			if(this.selectedAnswer) this.selectedAnswer.element.classList.remove("selected");
			this.selectedAnswer = answer;
			if(this.selectedAnswer) this.selectedAnswer.element.classList.add("selected");
		}
		*/

		this.selectedAnswer = answer;
		this.blank.answers.forEach((ans:any)=>{
			ans.element.classList.remove("incorrect-answer");
			ans.element.classList.remove("correct-answer");
			ans.element.classList.remove("selected");
			if(this.selectedAnswer == ans) ans.element.classList.add("selected");
		});
	}

}
