import { Component, HostListener, NgZone, ViewChild, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService } from 'src/app/service/common.service';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {OupService} from "../../../../service/oup.service";
declare var Chart;

@Component({
	selector: 'p-chapter-chart',
	template: `
	<div class="card" *ngIf="chartData.scoredComponents.length > 0">
		<div class="panel" [ngClass]="theme.theme$.value + '-theme'">
			<ng-container *ngIf="chartDatasets">
				<div class="item" *ngFor="let ds of chartDatasets;let i = index;">
					<div class="item-box item-{{i}}" [style.backgroundColor]="ds.backgroundColor"></div>
					<div class="item-label">{{ ds.label }}</div>
				</div>
			</ng-container>
		</div>
		<div class="body">
			<div class="chart-wrapper">
				<canvas #canvas></canvas>
			</div>
		</div>
	</div>

	<div class="no-data" *ngIf="chartData.scoredComponents.length == 0">
		<div class="no-data-image"></div>
		<div class="no-data-text">{{ {tc:'未有題目',sc:'未有题目',en:'No question'}[datas.lang] }}</div>
	</div>
  `,
	styleUrls: ['./personal-chapter.chart.scss']
})
export class PersonalChapterChart implements OnInit, AfterViewInit, OnDestroy {
	@Input() parent;
	public chartData:any;
	public chartDatasets:any;
	public chartLabels:any;
	public chartObj:any;
	public chartOptions:any;
	public xMax = 100;
	public stepSize = 1;
	public chapterLabels:any = [];
	@ViewChild('canvas', {static: false}) canvas;
	public onDestroy = new Subject();
	public faCheck = faCheck;
	constructor(public lds: LoadingService, public datas:DataService, public theme: ThemeService, public coms: CommonService, public oup:OupService) {
	}

	ngOnInit() {
		this.chartData = this.parent.chartData;
	}

	ngAfterViewInit(): void {
		this.coms.waitFor(()=> this.chartData.compXmls || this.chartData.bookType != 'pdf-paper', 20, 300).then(()=>{
			this.initChartData();
			this.initChartOptions();
			this.renderChart();
		});
		this.parent.parent.filterChange.pipe(takeUntil(this.onDestroy)).subscribe(event=>{
			this.initChartData();
			this.initChartOptions();
			this.chartObj.data.datasets = this.chartDatasets;
			this.chartObj.update();
		});
	}
	
	ngOnDestroy() {
		if (this.chartObj){
			this.chartObj.destroy();
		}
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	initChartOptions(){
		let axisYLabel = {tc: '章節', sc: '章节', en: 'Chapter'}[this.datas.lang];
		if (this.chartData.bookType == 'pdf-paper'){
			axisYLabel = {tc: '環節', sc: '環节', en: 'Section'}[this.datas.lang];
		}
		const axisXLabel = {tc: '平均分 (%)', sc: '平均分 (%)', en: 'Average (%)'}[this.datas.lang];
		this.chartOptions = {
			maintainAspectRatio: false,
			animation: {
				duration: 300,
			},
			scales: {
				y: {
					display: true,
					grid: {
						display: false,
					},
					border: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					ticks: {
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 14,
							weight: "bold",
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#333',
					},
					title: {
						display: true,
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 18,
							weight: 'bold'
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#666',
						text: axisYLabel,
					},
					stacked: false,
				},
				x: {
					grid: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					border: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					ticks: {
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 14,
							color: "#8e9295",
							weight: "bold",
						},
					},
					beginAtZero: true,
					grace: '%',
					max: this.xMax,
					stepSize: this.stepSize,
					title: {
						display: true,
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 18,
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#666',
						text: axisXLabel,
						padding: 5
					},
					stacked: false,
				},
			},
			responsive: true,
			title: true,
			layout: { padding: { left: 20, right: 20, top: 20, bottom: 20 } },
			plugins: {
				legend: {
					display: false,
					labels: {
						display: false,
					},
				},
			},
			indexAxis: 'y',
		};
	}

	initChartData(){
		this.chartDatasets = [];
		this.chartLabels = [];
		this.xMax = 100;
		const selectedShares = this.chartData.shares;
		selectedShares.forEach((share, index)=>{
			let dataset:any = {
				label: share.tag_fulltitle,
				borderColor: share.color,
				backgroundColor: share.color,
				borderWidth: 1,
				outlierRadius: 3,
				outlierColor: '#999999',
				itemRadius: 0,
				data: []
			};
			let personalDataset:any = {
				label: this.chartData.studentName,
				borderColor: '#FD6585',
				backgroundColor: '#FD6585',
				borderWidth: 1,
				outlierRadius: 3,
				outlierColor: '#999999',
				itemRadius: 0,
				data: []
			}
			if(this.oup.isOupUser) {
				Object.defineProperty(dataset, 'label', {
					get: () => {
						return this.oup.get_class_name(share.tag_fulltitle) || share.tag_fulltitle;
					}
				})
			}

			this.loadChapterData(share, dataset, personalDataset);
			this.chartDatasets.push(dataset);
			this.chartDatasets.push(personalDataset);
			this.chartLabels.push({color: share.color, label: share.tag_fulltitle});
			this.chartLabels.push({color: '#FD6585', label: this.chartData.studentName});
		});
		this.stepSize = 5;
	}
	
	renderChart(){
		if (this.chartData.scoredComponents.length == 0){
			return;
		}
		if (this.chartObj) {
			this.chartObj.destroy();
		}
		this.coms.waitFor(()=> this.canvas && this.canvas.nativeElement, 20, 200).then(()=>{
			const canvasEle = this.canvas.nativeElement;
			const ctx = canvasEle.getContext('2d');
			this.lds.add('renderChart', 5000);
			setTimeout(() => {
				const axisXLabel = {tc: '組別', sc: '組別', en: 'Group'}[this.datas.lang];
				this.chartObj = new Chart(ctx, {
					type: 'bar',
					data: {
						labels: this.chapterLabels,
						datasets: this.chartDatasets,
					},
					options: this.chartOptions,
					plugins: [
						{
							beforeDraw: (chart, args, options)=> {
								this.beforeDraw(chart);
							}
						},
						{
							afterRender: (chart, args, options)=> {
								this.lds.remove('renderChart');
							} 
						}
					]
				});
			}, 500);
		});
	}

	loadChapterData(share, dataset, personalDataset){
		if (this.chartData.bookType == 'pdf-paper'){
			let questionSections:any = [{qs: null, comps: [], maxScore: 0 }];
			this.chapterLabels = [ {tc: '無環節', sc: '无环节', en: 'No section'}[this.datas.lang] ];
			this.chartData.compXmls.forEach(chx => {
				const found = questionSections.find(e=> e.qs == chx.questionSection);
				const compInfo = this.chartData.scoredComponents.find(e=> e.cid == chx.cid);
				if (found){
					found.comps.push(chx.cid);
					found.maxScore += compInfo?compInfo.maxScore:0;
				} else {
					this.chapterLabels.push( chx.questionSection );
					const comp = {qs: chx.questionSection, comps: [chx.cid], maxScore: compInfo?compInfo.maxScore:0};
					questionSections.push(comp);
				}
			});
			if (questionSections.length > 1){
				questionSections = questionSections.filter(e=> e.qs != null);
				this.chapterLabels = this.chapterLabels.filter((e,i)=> i > 0);
			}
			// this.chapterLabels = this.chapterLabels.map((e,i) => (i + 1) +'. ' + e);
			if (questionSections[0].maxScore == 0){
				this.chapterLabels.shift();
				questionSections.shift();
			}
			questionSections.forEach(qs => {
				let sectionTotal = 0;
				let sectionCount = 0;
				let personalTotal = 0;
				share.peoples.forEach(people => {
					let calculatedCid = [];
					let sectionScore = 0;
					let resultSubmited = false;
					const filteredPeopleResult = share.result.filter(e=> e.uid == people.uid && qs.comps.indexOf(e.cid) > -1);
					filteredPeopleResult.forEach(result => {
						if (calculatedCid.indexOf(result.cid) == -1){
							calculatedCid.push(result.cid);
							sectionScore += parseFloat(result.score);
							resultSubmited = true;
						}
					});
					if (resultSubmited){
						sectionTotal += Math.round(sectionScore * 100 * 10/ qs.maxScore) / 10;
						sectionCount++;
						if (people.uid == this.chartData.uid){
							personalTotal += Math.round(sectionScore * 100 * 10/ qs.maxScore) / 10;
						}
					}
				});
				if (sectionCount > 0){
					const sectionAverage = Math.round(sectionTotal * 10 / sectionCount) / 10;
					dataset.data.push(sectionAverage);
					personalDataset.data.push(personalTotal);
				}
			});
			return;
		}
		this.chapterLabels = [];
		this.chartData.chapters.forEach( (chapter, index) => {
			let chapterTotal = 0;
			let chapterCount = 0;
			let personalTotal = 0;
			this.chapterLabels.push(chapter.title);
			share.peoples.forEach(people => {
				let calculatedCid = [];
				let chapterScore = 0;
				let resultSubmited = false;
				chapter.results.forEach(r => {
					if (r.uid == people.uid && calculatedCid.indexOf(r.cid) == -1){
						calculatedCid.push(r.cid);
						chapterScore += parseFloat(r.score);
						resultSubmited = true;
					}
				});
				if (resultSubmited){
					chapterTotal += Math.round(chapterScore * 100 * 10 / chapter.maxScore) / 10;
					chapterCount++;
					if (people.uid == this.chartData.uid){
						personalTotal += Math.round(chapterScore * 100 * 10 / chapter.maxScore) / 10;
					}
				}
			});
			if (chapterCount > 0){
				const chapterAverage = Math.round(chapterTotal * 10 / chapterCount) / 10;
				dataset.data.push(chapterAverage);
				personalDataset.data.push(personalTotal);
			} else {
				dataset.data.push(0);
				personalDataset.data.push(0);
			}
		});
	}

	beforeDraw(chart){
		this.lds.remove('renderChart');
	}


}