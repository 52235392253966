import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
// import { XMLParser } from "fast-xml-parser";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { HammerTransformControl } from "../panZoomModule/HammerTransformControl";
import { CoordinateExpression } from "./CoordinateExpression";
import { ROContext } from "./ROContext";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";
// import SVG from "svg.js"
import { SWFLoader, SWFTool } from "./SWFLoader";

import { AllROComponents } from "./AllROComponent";
import { StyleUtils } from "./StyleUtils";
import { ROQuestionNumber } from "./ROQuestionNumber";
import { ROQuestionScore } from "./ROQuestionScore";
import { ScriptService } from "src/app/service/script.service";
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";

import { ByteArray } from "openfl";
import { Base64 } from 'js-base64';
import  * as Serializer from './infomaniac-amf/amf/serializer.js';
import { AnswerSource } from "./ROBookConfig";
import { js2xml, xml2js, xml2json } from "xml-js";
import { SelectMarkerIcon } from "./SelectMarkerIcon";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { QuestionOrderHelper } from "./QuestionOrderHelper";
import { DOMHelper } from "src/app/common/DOMHelper";
import { XMLJSNode } from "./xml/XMLParser";
import { PromiseUtils } from "src/app/common/PromiseUtils";
import { Subscription, fromEvent } from "rxjs";
import { ScriptLoader } from "src/app/common/ScriptLoader";
import { AMF3Encoder } from "./AMF3";
import { NoteLayer } from "./epen/NoteLayer";
import { ColorTool } from "./color/ColorTool";
import { moveItemInArray } from "@angular/cdk/drag-drop";



declare var html2canvas;

export class ROScoringType
{
	//[0: Partial correct, 1:complete]
	static COMPLETE:number = 1;
	static PARTIAL:number = 0;
	static INSCALE:number = 2;
}
export class ROComponentMap{
	private map:any;
	public unknownDefinition:any;
	constructor()
	{
		this.map = {};
	}
	
	public add(tag:string, definition:any):void
	{
		this.map[tag] = definition;
	}

	public init(map:any, unknown:any):void
	{
		ObjectUtils.copyTo(map, this.map);
		this.unknownDefinition = unknown;
	}

	public getDefinition(tag:string):any
	{
		if(this.map.hasOwnProperty(tag))
		{
			return this.map[tag];
		}
		return this.unknownDefinition;
	}
}

@Component({
	selector:"ro-verify-icon",
	template:`
		<div 
			class="icon"
			[class.correct]="correctState === 2"
			[class.partial_correct]="correctState === 1"
			[class.incorrect]="correctState === 0"
			
			
		></div>
	`,
	styles:[
		`
		:host{
			z-index:var(--verify-z-index);
		}
		.icon{
			position:absolute;
			right: -15px;
			top: -15px;
			width:30px;
			height:30px;
			background-position:center;
			background-size: 30px 30px;
			background-repeat: no-repeat;
		}
		.icon.correct
		{
			background-image:url(./assets/img/component/verify/correct.svg);
		}
		.icon.partial_correct
		{
			background-image:url(./assets/img/component/verify/partial_correct.svg);
		}
		.icon.incorrect{
			background-image:url(./assets/img/component/verify/incorrect.svg);
		}
		`
	]
})
export class ROVerifyIcon 
{
	@Input() public correctState:number;
}

@Component({
	template:`Component`, 
})
export class ROComponent implements  AfterViewInit, OnDestroy
{
	public firstLineHeight:number;
	public canEditInside:boolean = false;
	// @Input() public componentRef:ComponentRef<any>;
	// @ViewChild("vc", {read: ViewContainerRef, static: false}) public vc: ViewContainerRef;
	// @ViewChild("elementRef", {read: ViewContainerRef, static: false}) public elementRef: ElementRef;
	// @ViewChild("vc", {static: false}) public vc: ViewContainerRef;
	// public tagName:string;
	public defaultAnswer:any = null; 
	public visible:boolean = true;
	@Input() public parent:ROComponent;
	@Input() public page:ROComponent;
	@Input() public node:XMLNode;
	@Input() public context:ROContext;
	@Input() public viewMode:string;
	
	public rotation:number = 0;
	public x:number = 0;
	public y:number = 0;
	public w:number = 100;
	public h:number = 100;
	public qObj:ROQuestionNumber;
	public sObj:ROQuestionScore;
	public resultObject:any;
	public answerChanged:boolean = false;
	public componentRef:ComponentRef<any>;
	protected destroyed:boolean = false;

	constructor(protected cdr:ChangeDetectorRef, protected elementRef:ElementRef){
		this.editInStage = false;
		
	}

	public getSubComponentDataArray(isData: boolean, component: any):any []
	{
		return null;
	}


	public hasSubComponent():boolean
	{
		return false;
	}

	public hasAnswer()
	{
		return true;
	}

	public resetComponent()
	{
		if(this.context.showDefaultAnswer)
		{
			this.context.showDefaultAnswer = false;
			this.hideDefaultAnswer();
		}
		if(this.hasAnswer()) this.answerChanged = true;
		this.resetData();
		this.onAnswerSet();
	}
	
	public resetData()
	{
		this.data = null;
		this.resultObject = null;
	}
	
	public set mouseEnabled(input:boolean)
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.pointerEvents = input ? "all": "none";
		StyleUtils.setStyleVariable(dom, "mouse-enabled" ,  input ? 1 : 0);// for debug
	}

	ngOnDestroy(): void {
	}

	public viewerIsStudent():boolean {
		return this.context.service.dataService.userInfo.user_role==2;
	}

	public viewerIsTeacher():boolean {
		return this.context.service.dataService.userInfo.user_role==3;
	}

	public answerEnable(flag:boolean):void
	{
		// 模擬返 flash disable interaction 效果
		// 控制可以進行互動
		var dom:HTMLElement = this.elementRef.nativeElement;
		if(!flag)
			dom.classList.add("answerDisable");
		else
			dom.classList.remove("answerDisable");
	}

	public isLocked():boolean
	{
		return false;
	}
	
	public lockXY():boolean{
		return false;
	}

	public canDelete(target:any = null):boolean {
		if(target == null) {
			if(this.node.attributes.hasOwnProperty("canDelete"))
				return this.node.attributes.canDelete;
			return true;

		} else if(target instanceof ROComponent) {
			return (<ROComponent>target).canDelete();
		}
		return true;
	}

	getFirstLineHeight():number{
		if(isNaN(this.firstLineHeight)) return this.h;
		return this.firstLineHeight;
	}

	public clone():ROComponent{
		var nodeCopy:XMLNode = this.node.deepCopy();;
		var newID:string = this.createDouid();
		nodeCopy.setAttribute("douid", newID);
		var ref:ComponentRef<any> = this.context.createComponentRef(nodeCopy, this.page);
		return ref.instance;
	}
	
	public iterateChildren(fn:Function):void
	{
		fn(this);
	}
	public hasDefaultAnswer():boolean
	{
		return true;
	}

	showComponentScore():void
	{
		if(this.sObj)
		{
			if(this.isQuestionComponent())
			{
				if(this.sObj.getEnabled()) this.sObj.showScore(this.hasDefaultAnswer() ? 2 : -1,this.getFullScore());
			}
		}
	}
	onTransformBegin() {
		
	}
	onTransforming() {
		
	}

	onTransformComplete() {
		
	}
	
	getBoundingDOM():HTMLElement
	{
		return this.elementRef.nativeElement;
	}

	getReferenceDOM():HTMLElement
	{
		return this.elementRef.nativeElement;
	}

	getComponentRectangle():any
	{
		return {x:0,y:0,width:this.w,height:this.h};
	}


	getReferencePosition():DOMRect
	{
		return this.getReferenceDOM().getBoundingClientRect() as DOMRect;
	}
	
	getBoundingClientRect(): DOMRect {
		return this.getBoundingDOM().getBoundingClientRect() as DOMRect;
	}

	remove():void
	{
		if(!this.parent) return;
		this.parent.removeComponent(this);
		this.destroy();
	}

	removeComponent(component: ROComponent) {
		this.node.removeChild(component.node);
		this.removeComponentView(component);
	}

	removeComponentView(component: ROComponent):void
	{
		// component.destroy();
		this.getElementRef().nativeElement.removeChild(component.getElementRef().nativeElement);
	}

	removeChild(obj:any):void {

	}

	public getTitleName():string
	{
		var key:string = `ro.components.name.${this.node.tag}`;
		var lang:string = this.context.service.translateService.instant(key);
		if(key === lang)
			lang = this.node.tag;
		return lang;
	}

	public showDefaultAnswer():void
	{

	}
	public hideDefaultAnswer():void	
	{
		
	}

	public destroy():void
	{
		this.unwatch();
		this.destroyed = true;
		this.componentRef.destroy();
		if(this.qObj)this.qObj.destroy();
		if(this.sObj) this.sObj.destroy();
	}
	
	public reattach():void
	{
		this.watch();
		this.cdr.reattach();
		// this.context.attach(this.componentRef);
		if(this.qObj)this.qObj.reattach();
		if(this.sObj) this.sObj.reattach();
		
	}

	public detach():void
	{
		this.unwatch();
		this.cdr.detach();
		// this.context.detach(this.componentRef);
		if(this.qObj)this.qObj.detach();
		if(this.sObj) this.sObj.detach();
		
	}

	getElementRef():ElementRef
	{
		return this.elementRef;
	}
	@HostListener('click', []) 
	log() {
		// console.log(JSON.stringify(this.node.attributes));
	}

	public getTagNames():string []
	{
		return [];
	}

	public get douid():string {
		if(!(this.node && this.node.attributes && this.node.attributes.douid))
			this.node.attributes.douid = this.createDouid();
		return this.node.attributes.douid;
	}

	public createDouid():string {
		return GUID.create("OKD guid");
	}

	public build():void
	{
		if(!this.node)
			this.setDefaultXMLData();
		this.initVariables();
		this.initCoordinateExpression();
		this.buildQuestionAndScoreObject();
		this.buildContent();
		this.buildChildren();

	}

	runCommand(action:string):void
	{
		
		
	}
	private setLearningObjective(array:any []):void
	{
		if(!array)
		{
			array = [];
		}
		this.node.setAttribute("learningObjective", JSON.stringify(array));
	}

	public setPropertiesThroughPanel(key:string, val:any):boolean
	{
		switch(key) {
			case "q.section":
				if(val) {
					this.node.setAttribute("questionSection", val);
					this.qObj.setQuestionSection(val);
				} else {
					this.node.removeAttribute("questionSection");
					this.qObj.setQuestionSection(null);
				}
				break;
			case "learningObjective":
				this.setLearningObjective(val);
				break;
		}
		return true;
	}

	public getPropertiesThroughPanel(key:string):any
	{
		if(key == "q.show")
		{
			if(this.qObj)
			{
				return true;
			}
			return false;
		} else if(key == "q.section")
		{
			return this.node.getAttribute("questionSection");
		} else if(key == "learningObjective")
		{
			return JSON.parse(this.node.getAttribute("learningObjective", '[]'));
		}
		return null;
	}
	
	protected setDefaultXMLData():void
	{

	}
	
	protected buildChildren():void
	{
		
	}

	protected initVariables():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var tag = this.node.tag;
		if(tag)
		{
			dom.setAttribute("component", tag);
			dom.classList.add("ro-component", tag);
		}
		if(this.node)
		{
			var json:string = JSON.stringify(this.node.attributes);
			var commentBlock:Comment = document.createComment(json);
			dom.appendChild(commentBlock);
		}
	}

	protected buildContent():void
	{

	}

	public _editInStage:boolean;
	public set editInStage(value:boolean)
	{
		if(this._editInStage && !value)
			this.editInStageFinish();
		this._editInStage = value;
	}
	public get editInStage():boolean{
		return this._editInStage;
	}
	protected editInStageFinish():void
	{
		
	}

	assignAnswer(answer:any) 
	{
		if(this.context.showDefaultAnswer)
		{
			this.context.showDefaultAnswer = false;
			this.hideDefaultAnswer();
		}
		if(answer)
		{
			this.data = answer.data
			this.resultObject = answer.result;
		} else {
			this.data = null;
			this.resultObject = null;
		}
		this.onAnswerSet();
	}
	
	protected onAnswerSet() {
		
	}
	

	// 有題目、分數的組件先需要 call
	protected buildQuestionAndScoreObject():void
	{
		if(!this.isQuestionComponent() && !this.isSurveyComponent())
			return;
		
		if(this.node && this.context && this.elementRef && this.page && this.node.attributes) {
			if(this.node.attributes.hasOwnProperty("q")) {
				let xmlNode:XMLNode = new XMLJSNode().assign({attributes:JSON.parse(this.node.attributes.q), tag:"QuestionNumber"});
				this.qObj = <ROQuestionNumber> this.context.createForEF(this.elementRef, xmlNode, this.page, this);
			}
			
			if(this.node.attributes.hasOwnProperty("s")) {
				let xmlNode:XMLNode = new XMLJSNode().assign({attributes:JSON.parse(this.node.attributes.s), tag:"QuestionScore"});
				this.sObj = <ROQuestionScore> this.context.createForEF(this.elementRef, xmlNode, this.page, this);
			}
		}
	}

	protected defaultQNSetting():any {
		return {
			"color": "noColor",
			"freezed": 1,
			"level": 0,
			"type": "auto",
			"show": false,
			"ballSize": 36,
			"x": -60,
			"y": 0
		};
	}

	protected defaultQSSetting():any {
		// 部份有 save show 這個 value
		return {
			"reference": "rt",
			"optional": false,
			"offset": null,
			"enable": false,
			"freezed": 1,
			"x": 0,
			"y": 0
		};
	}

	ngAfterViewInit(): void {
		
	   
	}
	
	public setPosition(px:number, py:number):void
	{
		this.x = px;
		this.y = py;
		this.moveTo(this.x, this.y);

	}

	// 新設定的 size 不在許可內，則傳回修正值
	public resize(width:number, height:number):any
	{
		this.w = width;
		this.h = height;
		this.assignLTWH();
		return null;
	}

	public resizeWidth(width:number):any {
		var ret:any = null;

		// 更新闊度
		if(width<40) {
			this.w = 40;
			ret = {width:40, height:this.h};
		} else {
			this.w = width;
		}
		this.assignLTWH();
		return ret;
	}
	
	public moveTo(px:number, py:number):void
	{
		// console.log("moveTo", px, py);
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.left = px+"px";
		dom.style.top  = py+"px";
	}
	public moveBy(px:number, py:number):void
	{
		this.x += px;
		this.y += py;
		this.updatePosition();
	}

	public rotate(action: 'rotate' | 'antiRotate') {
		// Adjust the rotation based on action
		if (action === 'rotate') {
			this.rotation += 10;
		} else if (action === 'antiRotate') {
			this.rotation -= 10;
		}
	
		let dom: HTMLElement = this.elementRef.nativeElement;
		dom.style.transform = `rotate(${this.rotation}deg)`;
	}

	public updatePosition():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.left = this.x+"px";
		dom.style.top  = this.y+"px";
	}

	protected initCoordinateExpression():void{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var style:CSSStyleDeclaration = dom.style;
		style.position = "absolute";
		
		var ceString:string = this.node.getAttribute("coordinateExpression");
		if(!ceString) {
			// 只用一般座標
			var options:any = this.node.attributes;
			this.x = parseInt(options.x);
			this.y = parseInt(options.y);
			this.w = parseInt(options.w);
			this.h = parseInt(options.h);
			this.assignLTWH();
			return;
		}
		// "A UK X 18 Y 57 D TB 57 20 L 18 W 988"
		
		var ce:CoordinateExpression = this.parseCE(ceString);
		
		if(!ce) return ;
		// var element:HTMLElement = this.vc.element.nativeElement;
		
		// if(ceString == "A UK X 18 Y 57 D TB 57 20 L 18 W 988")
		/*
		if(ceString == "UA UK D T -318.6 L -215.6 H 1276.6 W 1452.717069368667")
		{
			debugger;
		}
		*/

		this.x = ce.getX();
		this.y = ce.getY();
		this.rotation = ce.rotation;
		
		this.w = Math.ceil(ce.getWidth()) + 1; // quick fix //not sure what's the problem
		this.h = Math.ceil(ce.getHeight());
		
		this.assignLTWH();
		
		style.transformOrigin = "left top";
		
		if(ce.rotation)
		{
			style.transform = `rotate(${ce.rotation}deg)`;
		}
	}
	updateRotation() {
		if(this.rotation)
			{
				var dom:HTMLElement = this.elementRef.nativeElement;
				var style:CSSStyleDeclaration = dom.style;
				style.transformOrigin = "left top";
				style.transform = `rotate(${this.rotation}deg)`;
			}
	}
	
	assignLTWH() {
		var dom:HTMLElement = this.elementRef.nativeElement;
		var style:CSSStyleDeclaration = dom.style;
		style.left = this.x + "px";
		style.top = this.y + "px";
		style.width = this.w + "px";
		style.height = this.h + "px";
	}
	assignWH():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var style:CSSStyleDeclaration = dom.style;
		style.width = this.w + "px";
		style.height = this.h + "px";
	}

	protected parseCE(ceString:string):any
	{
		if(!ceString) return null;
		var ce:CoordinateExpression = new CoordinateExpression(this.page.node, this.parent);
		ce.parseExpression(ceString);
		return ce;
	}

	public updateWHAfterSetProperty():void {
		console.log("updateWHAfterSetProperty");
		const element:any = (<HTMLElement>this.elementRef.nativeElement);
		
		if (this.node.getAttribute("autoWidth") === true) {
			var maxWidth = 0;
			element.querySelectorAll('span').forEach((child: HTMLElement) => {
				maxWidth = Math.max(maxWidth, child.offsetWidth);
			});
			element.style.width = `${maxWidth}px`;
			this.resizeWidth(maxWidth);
		} else {
			this.resizeWidth(this.w);
		}
	}

	// =========================
	// update properties function
	// =========================
	public setProperties(propertiesName:string, val:any):boolean {
		switch(propertiesName) {
			case "x":
				this.x = val;
				this.elementRef.nativeElement.style.left = val + "px";
				return true;
			case "y":
				this.y = val;
				this.elementRef.nativeElement.style.top = val + "px";
				return true;
			case "w":
				this.w = val;
				this.elementRef.nativeElement.style.width = val + "px";
				return true;
			case "h":
				this.h = val;
				this.elementRef.nativeElement.style.height = val + "px";
				return true;
		}

		return false;
	}

	public getSelectorSettings():any {
		return {
			popupSelector:this.getPopupSelector(),
			rotation:false,lockedAspectRatio:false,widthResizer:false,heightResizer:false,resizer:false,move:true
		};
	}
	public getPopupSelector():any []
	{
		if(this.editInStage) {
			var ary:any[] = [this.getPSComplete()];

			var i:number = this.context.editLayerManager.editLayers.indexOf(this);
			i = i==-1 ? this.context.editLayerManager.editLayers.length : i;
			if(i>=1)
				ary.push({
					type:"action",
					action:"back",
					target:"editor",
					color:"#FBB226",
					icon:SelectMarkerIcon.BACK_ICON,
					name:this.context.translate("ro.components.common.prev_layer")
				});
			return ary;
		}

		var ary:any[] = [this.getPSName(), this.getPSSound()];
		if(this.canEditInside)
			ary.splice(1, 0, this.getPSCanEdit());
		if(this.canDelete())
			ary.push(this.getPSDel());
		return ary;

///		return [this.getPSName(), this.getPSDel()];
	}

	protected getPSName():any {
		return {
			type:'name',
			color:"#063C38",
			translate:false,
			name:this.getTitleName()
		};
	}

	protected getPSComplete():any {
		return {
			type:"complete",
			action:"complete",
			target:"editor",
			icon:SelectMarkerIcon.COMPLETE_ICON,
			name:this.context.translate("ro.components.common.complete")
		};
	}

	protected getPSCanEdit():any {
		return {
			type:"action",
			action:"editLayer",
			target:"editor",
			color:"#FBB226",
			icon:SelectMarkerIcon.PENCIL_ICON,
			iconStyle:{
				"color": "#FCB327"
			},
			component:this
		};
	}

	protected getPSSound():any {
		return {
			type:"action",
			action:"sound",
			target:"editor",
			color:"#FBB226",
			icon:SelectMarkerIcon.VOLUME_ICON,
			component:this
		};
	}

	protected getPSFontSize():any {
		return {
			type:"number",
			action:"editFontSize",
			target:"editor",
			key:"fontSize",
			color:"#FBB226",
			icon:SelectMarkerIcon.TEXT_ICON,
			component:this
		};
	}

	protected getPSDel():any {
		return {
			type:"action",
			action:"remove",
			target:"editor",
			icon:SelectMarkerIcon.DELETE_ICON
		};
	}

	// =========================
	// data function
	// =========================
	// return score result object
	public verify(_showScoring:boolean):any {
		return null;
	}

	public canVerify():boolean
	{
		return false;
	}

	public hideScore():void
	{
		if(this.sObj) this.sObj.hideScore(); 
	}

	public set data(value:string) {
	}

	public get data():string {
		return null;
	}
	public getAnswerAssets():any [] 
	{
		return null;
	}
	public hasScoring():boolean {
		return String(this.node.attributes.hasScoring).toLowerCase() == "true";
	}

	protected objectToByteArray(obj:any, compress:boolean = true):ByteArray
	{
		var bytes:ByteArray = new ByteArray();
		var serializer = new Serializer(bytes);
		bytes.objectEncoding = 3;
		bytes.position = 0;
		serializer.serialize(obj);
		return bytes;
	}

	protected objectToString2(obj:any, compress:boolean = true):string
	{
		var bytes:ByteArray = new ByteArray();
		var serializer = new Serializer(bytes);
		bytes.objectEncoding = 3;
		bytes.position = 0;
		serializer.serialize(obj);
		bytes.objectEncoding = 3;
		bytes.compress();
		return Base64.fromUint8Array(this.bytesToUint8Array(bytes)); 
	}

	protected objectToString(obj:any):string
	{
		var bytes:ByteArray = new ByteArray();
		bytes.objectEncoding = 3;
		bytes.position = 0;
		bytes.writeObject(obj);
		bytes.compress();

		return Base64.fromUint8Array(this.bytesToUint8Array(bytes)); 
	}

	public decode(str:string):any
	{
		return this.stringToObject(str);
	}
	protected stringToObject(str:string):any
	{
		var bytes:ByteArray = this.uint8ArrayToBytes(Base64.toUint8Array(str));
		bytes.uncompress();
		bytes.position = 0;
		bytes.objectEncoding = 3;
		return bytes.readObject();
	}

	protected uint8ArrayToBytes(u8s:Uint8Array):ByteArray
	{
		var bytes:ByteArray = new ByteArray();
		u8s.forEach(b => bytes.writeByte(b));
		bytes.position = 0;
		return bytes;
	}

	protected bytesToUint8Array(bytes:ByteArray):Uint8Array
	{
		var len = bytes.length;
		var array:any [] = [];
		bytes.position = 0;
		for(var i = 0;i < len;i++)
		{
			var byte:any = bytes.readUnsignedByte();
			array.push(byte);
		}
		
		return  new Uint8Array(array);
	}
	
	// =========================
	// component type
	// =========================
	// 題目組件：有資料、計分/不計分、有/無對錯、出成績表
	public isQuestionComponent():boolean
	{
		return false;
	}

	// 問卷組件：有資料、不計分、無對錯、出成績表
	public isSurveyComponent():boolean
	{
		return false;
	}

	// 用來判斷是否要有資料提交 (如 page 的縮圖、epen、問題組件、問卷組件)
	public isDataComponent():boolean
	{
		return this.isQuestionComponent() || this.isSurveyComponent();
	}


	// =========================
	// for printing use
	// =========================
	isContentReady():Promise<any>
	{
		// return Promise.resolve(1);
		return PromiseUtils.delay(1, 0);
	}
	
	contentReady():boolean
	{
		return true;
	}
		
	dataContentReady():boolean
	{
		return true;
	}

	///////
	public watch():void
	{

	}
	public unwatch():void
	{

	}

	public activate():void
	{

	}

	public deactivate():void
	{

	}
	
	public getFullScore():number
	{
		return this.node.attributes.fullScore;
	}
	public getUnitScore():number
	{
		return this.node.attributes.unitScore;
	}
	
	// =========================
	// edit relative functiuon
	// =========================
	public getXMLJSON():any {
		this.savePosition();
		if(this.qObj)	this.node.setAttribute("q", JSON.stringify(this.qObj.node.attributes));
		if(this.sObj)	this.node.setAttribute("s", JSON.stringify(this.sObj.node.attributes));
		// 其餘參數應已直接改
		return this.node.element;
	}

	public savePosition():void
	{
		this.saveCoordinateExpression();
		["x", "y", "w", "h", "rotation"].forEach((key:string)=>{
			if(this.node.hasAttribute(key))
			{
				this.node.setAttribute(key, this[key]);
			}
		})
	}

	public saveCoordinateExpression()
	{
		if(this.node.hasAttribute("coordinateExpression")) {
			var ce:CoordinateExpression = this.parseCE(this.node.attributes.coordinateExpression);
			ce.setX(this.x);
			ce.setY(this.y);
			ce.setWidth(this.w);
			ce.setHeight(this.h);
			ce.rotation = this.rotation;
			this.node.setAttribute("coordinateExpression", ce.buildExpression());
		}
	}

	public getSupportedComponentPanel():string
	{
		return "none";
	}
};
AllROComponents.push(ROComponent);
//////////


/*
@Component({
	template:`DEMO`, 
})
export class RODemoComponent extends ROComponent
{
	// @Input() public componentRef:ComponentRef<any>;
	// @ViewChild("vc", {read: ViewContainerRef, static: false}) public vc: ViewContainerRef;
	// @ViewChild("elementRef", {read: ViewContainerRef, static: false}) public elementRef: ElementRef;
	// @ViewChild("vc", {static: false}) public vc: ViewContainerRef;
	
	// public tagName:string = "Demo";
	control: HammerTransformControl;
	
	constructor(elementRef:ElementRef)
	{
		super(elementRef);
	}
	
	protected buildContent():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		this.control = new HammerTransformControl(dom, dom);
		dom.addEventListener("tap", this.onTap.bind(this));
		
	}
	private counter:number = 1;
	private onTap(event:any):void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		if(this.counter == 1)
		{
			this.counter = 0;
			dom.style.backgroundColor = "blue";
		} else 
		{
			this.counter = 1;
			dom.style.backgroundColor = "yellow";
		}
	}
	protected initCoordinateExpression():void{
		var element:HTMLElement = this.elementRef.nativeElement;
		element.style.position = "absolute";
		element.style.left = "400px";
		element.style.top = "400px";
		element.style.backgroundColor = "red";
		element.style.padding = "50px";
		
	}

};
AllROComponents.push(RODemoComponent);

*/
//////////
@Component({
	template:"Container"
})
export class ROContainerComponent extends ROComponent
{
	addComponent(com: ROComponent) {
		com.parent = this;
		com.page = this.page;
		var componentRef:ComponentRef<any> = com.componentRef ;
		this.context.service.dcs.appendVFToEF(componentRef.hostView, this.getElementRef());
		com.build();
		var com:ROComponent = <ROComponent>componentRef.instance;
		if(this.page && this.page instanceof ROPageComponent && com)
		{
			this.page.addToComponentsList(com);
		}
		this.children.push(com);
		this.node.addChild(com.node);
	}
	canAddComponent(com: ROComponent):boolean{
		return true;
	}
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	public children:any [] ;

	public iterateChildren(fn:Function):void
	{
		fn(this);
		this.children.forEach((childComponent:ROComponent)=>{
			/*
			// Always check if the current child is a question component
			if (childComponent.isQuestionComponent()) {
				fn(childComponent); 
			}
			*/
			childComponent.iterateChildren(fn);
		});
	}

	protected buildChildren():void
	{
		this.children = [];
		this.node.children.forEach((childNode:XMLNode)=>{
			var child = this.context.createForEF(this.elementRef, childNode, this.page, this);
			this.children.push(
				child
			);
		});
	}

	public showDefaultAnswer():void
	{
		this.defaultAnswer = true;
		this.children.forEach((child:ROComponent)=>{
			if(!child) {
				return;
			}
			child.showDefaultAnswer();
		})
	}
	public hideDefaultAnswer():void	
	{
		this.defaultAnswer = null;
		this.children.forEach((child:ROComponent)=>{
			if(!child) {
				return;
			}
			child.hideDefaultAnswer();
		})
	}

	public getChildComponents():any[] {
		var result = [];
		if(this.children) {
			this.children.forEach(e => {
				if(e instanceof ROComponent) {
					result.push(e);
				}
			});
		}

		return result;
	}

	// =======================================
	// edit relative function
	// =======================================
	public addComponentByTag(componentTag:string, assetSrc:string = null):ROComponent {
		var componentRef:ComponentRef<any>  = this.context.service.dcs.createComponentRef(
			this.context.map.getDefinition(componentTag), 
			{
				parent:this,	// parent component
				page:this.page,	// page component
				context:this.context
			}
		);
		this.context.service.dcs.appendVFToEF(componentRef.hostView, this.getElementRef());
		componentRef.instance.componentRef = componentRef;
		// 加入的 component 一定無 node ，先 create default data ，令 attributes 可以寫入去
		componentRef.instance.setDefaultXMLData();
		if(assetSrc)
			componentRef.instance.setPropertiesThroughPanel("assetSrc", assetSrc);
		componentRef.instance.build();
		var com:ROComponent = <ROComponent>componentRef.instance;
		if(this.page && this.page instanceof ROPageComponent && com)
		{
			this.page.addToComponentsList(com);
		}

		this.node.addChild(com.node); // link the child node
		this.children.push(com);
		return com;
	}

	public removeComponent(com:ROComponent):void {
		this.node.removeChild(com.node); // unlink the child node
		this.children = this.children.filter(e=>e!=com);
		this.getElementRef().nativeElement.removeChild(com.getElementRef().nativeElement);
		com.parent = null;
	}

	public getXMLJSON():any {
		if(this.children) {
			this.node.element.elements = [];
			this.children.forEach(e => {
				if(e instanceof ROComponent) {
					e.getXMLJSON();
					
					this.node.element.elements.push(e.node.element);
				}
			});
		}

		return super.getXMLJSON();
	}

	
	protected editInStageFinish():void
	{
		this.updateBoundaryAndChildPosition();
	}

	protected updateBoundaryAndChildPosition():void
	{
		var left:number = Number.MAX_VALUE;
		var right:number = -Number.MAX_VALUE;
		var top:number = Number.MAX_VALUE;
		var bottom:number = -Number.MAX_VALUE;

		this.children.forEach(child => {
			left = Math.min(left, child.x);
			right = Math.max(right, child.x+child.w);
			top = Math.min(top, child.y);
			bottom = Math.max(bottom, child.y+child.h);
		});

		// 計算移動偏移值
		var offsetx:number = -left;
		var offsety:number = -top;

		// 更新題目佔據範圍
		this.w = right-left;
		this.h = bottom-top;
		this.moveBy( -offsetx, -offsety);
		this.savePosition();
		this.assignLTWH();

		this.adjustChildPositrion(offsetx, offsety);

		// 更新題號位置
		if(this.qObj && !this.qObj.node.attributes.freezed)  // 自定位置時，修定位置
			this.qObj.moveBy( offsetx, offsety);			

		// 更新分數球位置
		if(this.sObj) {
			// 自定位置時，修定位置
			var attr:any = this.sObj.node.attributes;
			if(!attr.freezed && ['page','auto'].indexOf(attr.position)==-1)
				this.sObj.moveBy( offsetx, offsety);	
		} 
	}

	protected adjustChildPositrion(offsetx:number, offsety:number):void
	{
		// 修正所有子控點的位置
		this.children.forEach(child => {
			child.setPosition(child.x+offsetx, child.y+offsety);
		});
	}

	public getSupportedComponentPanel():string
	{
		return "basic";
	}

	public moveComponentLayer(com:ROComponent, option = "top") {
		const index = this.children.indexOf(com);
		if (index === -1) return;
		const nativeElement = this.getElementRef().nativeElement;
		// Remove the component's DOM element first
		nativeElement.removeChild(com.getElementRef().nativeElement);
		
		this.node.reorderChild(com.node, option)

		if (option === "top") {
			// Move the child to the end of the array
			moveItemInArray(this.children, index, this.children.length - 1);
		} else if (option === "up" && index < this.children.length - 1) {
			// Move the child down by one position
			moveItemInArray(this.children, index, index + 1);
		} else if (option === "down" && index > 0) {
			// Move the child up by one position
			moveItemInArray(this.children, index, index - 1);
		} else if (option === 'bottom') {
			moveItemInArray(this.children, index, 0)
		}

		// Re-add the component's DOM element in the new order
		this.children.forEach(child => {
			nativeElement.appendChild(child.getElementRef().nativeElement);
		});

		if (this.page && this.page instanceof ROPageComponent && com) {
			this.page.reorderComponents(com, option);
		}	
	}

}
AllROComponents.push(ROContainerComponent);
//////////
@Component({
	template:``,
	styles:[
		`
		:host{
			position: absolute;
		}
		`
	]
})
export class ROGraphicComponent extends ROComponent
{
	private contentLoadPromise:Promise<any>;
	private assetURL:string;
	flipH: boolean;
	flipV: boolean;
	// public tagName:string = "Graphic";

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["Graphic"];
	}
	protected buildContent(): void {
		var options:any = this.node.attributes;
		var dom:HTMLElement = this.elementRef.nativeElement;
		if(options.alpha != 1) dom.style.opacity = options.alpha;
		this.flipH = options.flipH;
		this.flipV = options.flipV;

		var src:string=  this.node.getAttribute("src");
		if(!src) return;
		this.load(src).then(()=>{
			this.contentLoadPromise = null;
		});
	}
	private load(src:string):Promise<any>
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var style:CSSStyleDeclaration = dom.style;

		style.backgroundSize = "contain";
		
		
		if(src.indexOf("https://") == 0){
			style.backgroundImage = `url(${src})`;
			this.contentLoadPromise = this.loadImage(src);
		} else if(src.indexOf("https://") !== 0){
			
			this.contentLoadPromise = this.context.service.resourceSource.lookupResource(src).then(
				(url:string)=>{
					this.assetURL = url;
				}
			).then((o:any)=>{
				//this.renderSWFAsPNG(this.assetURL);
				this.renderSWFAsSVG(this.assetURL);
				
			});
		}
		return this.contentLoadPromise;
	}
	isContentReady(): Promise<any> {
		if(this.contentLoadPromise)
		{
			return this.contentLoadPromise;
		} else {
			return Promise.reject(null);
		}
	}
	private loadImage(url:string):Promise<any>
	{
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.addEventListener('load', resolve);
			image.addEventListener('error', reject);
			image.src = url;
		});
	}
	/*
	private renderSWFAsPNG(url:string):void
	{
		// https://dev.openknowledge.hk/RainbowOne/index.php/transport/Master/get_file/1?file=resource/2021/01/13/uid(40402)/time(115128)-cs(E38889D4).asset
		var url2:string = url.replace("https://dev.openknowledge.hk/RainbowOne/index.php/transport/Master/get_file/1?file=", "http://localhost:8899/swf_decoder/");
		console.log("render", url2);
		this.renderImage(url2);
		
	}
	*/
	private renderImage(imageURL:string):void
	{
		console.log("render", imageURL);
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.appendChild(document.createComment(imageURL));
		
		var img = document.createElement("img");
		img.setAttribute("src", imageURL);
		dom.appendChild(img);

		var flipH:boolean = this.node.getAttribute("flipH");
		var flipV:boolean = this.node.getAttribute("flipV");
		if(flipH || flipV)
		{
			var xScale:number = flipH ? -1 : 1;
			var yScale:number = flipV ? -1 : 1;
			img.style.transform = `scale(${xScale}, ${yScale} )`;
		}
		
		img.style.width = "100%";
		img.style.height = "100%";
	}
	
	private renderSWFAsSVG(url:string):Promise<any>
	{
		
		var dom:HTMLElement = this.elementRef.nativeElement;
		return SWFLoader.loadURL(this.assetURL).then((stage)=>{
			return stage.toScaleGridSVG();
		}).then((svg:SVGGraphicsElement)=>{
			var image = SWFTool.createImageForSVG(svg);

			var scaleX = this.flipH ? -1 : 1;
			var scaleY = this.flipV ? -1 : 1;
			image.style.transform = `scale(${scaleX}, ${scaleY})`;
			// image.style.border = "solid 1px red";
			var container:HTMLElement = dom;
			container.appendChild(image);
			// container.style.border = "solid 1px red";
			

			
			/*
			var container:HTMLElement = dom;
			container.innerHTML = "";
			document.body.appendChild(svg);
			var box = svg.getBBox();
			container.appendChild(svg);
			var scaleContainer:HTMLElement = svg.querySelector(".scale-container");

			var scaleX = this.w / box.width;
			var scaleY = this.h / box.height;
			var finalScale = Math.min(scaleX, scaleY);
			var svgW:any = box.width * finalScale;
			var svgH:any = box.height *finalScale;
			if(isNaN(svgW))
			{
				console.log(url);
				return ;
			}
			svg.setAttribute("preserveAspectRatio", "none");
			svg.setAttribute("width", svgW);
			svg.setAttribute("height", svgH);
			svg.setAttribute("viewBox", `0 0 ${svgW} ${svgH}`);
			svg.style.width = "100%";
			svg.style.height = "100%";
			
			var scaleX:number = this.flipH ? -finalScale : finalScale;
			var scaleY:number = this.flipV ? -finalScale : finalScale;
			var offset:any = {
				x:this.flipH ? this.w : 0,
				y:this.flipV ? this.h : 0
			};
			scaleContainer.setAttribute("transform", `translate(${offset.x} ${offset.y}) scale(${scaleX}, ${scaleY}) translate(${- box.x} ${- box.y}) scale(0.05)`)
			*/
		});
	}
	private getSVGMaxLineWidth(svg:SVGGraphicsElement):number
	{
		var html:string = svg.innerHTML;
		const regex = /stroke-width=\"(.*?)\"/gm;
		var m:any;
		while ((m = regex.exec(html)) !== null) {
			// This is necessary to avoid infinite loops with zero-width matches
			if (m.index === regex.lastIndex) {
				regex.lastIndex++;
			}
			
			// The result can be accessed through the `m`-variable.
			m.forEach((match, groupIndex) => {
				console.log(`Found match, group ${groupIndex}: ${match}`);
			});
		}
		return 1;
	}
	
}
AllROComponents.push(ROGraphicComponent);
//////////
/*
class VirtualCTX{
	setTransform (m0, m1, m2, m3, m4, m5):void
	{
		console.log("VirtualCTX.setTransform", m0, m1, m2, m3, m4, m5);
	}
	fillRect(px:number, py:number, w:number, h:number):void
	{
		console.log("VirtualCTX.fillRect", px, py, w, h);
	}
	drawImage(canvas:any, px:number, py:number, w:number, h:number):void
	{
		console.log("VirtualCTX.drawImage", canvas, px, py, w, h);
	}
}
*/
///////////////

@Component({
	template:`
	`
})
export class ROImageComponent extends ROComponent
{
	// public tagName:string = "Image";
	protected img:HTMLImageElement;
	protected onReadyResize:boolean = false;
	private isContentPromise:Promise<any>;
	private loadState:string;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	isContentReady(): Promise<any> {
		if(this.isContentReady)
		{
			return this.isContentPromise;
		} else {
			if(this.loadState == "ready")
			{
				return Promise.resolve(this.loadState);
			} else if(this.loadState == "failed"){
				return Promise.reject(this.loadState)
			} else if(this.loadState == "loading")
			{
				// something is wrong
				return Promise.reject(this.loadState);
			} else {
				return Promise.reject("unknown error");
			}
		}
	}
	public getTagNames():string []
	{
		return ["Image"];
	}

	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"coordinateExpression": "UA UK KH X 0 Y 0 D SIZE 1024 768 1024 768 T 0 L 0 W 0 H 0",
				rotation:0,flipH:false,flipV:false,isFloating:false,locked:false,alpha:1,
				"douid": this.createDouid()
			},
			tag:"Image"
		});
		this.node.createElement();
	}

	protected buildContent(): void {
		/**
		
		{"src":"OKG://id/33204/checksum/00000000/len/0/token/8444B712/type/1/server/22/title/女主管在商務會議",
		"coordinateExpression":"A UK KS X 778 Y 539 D T 539 L 778 H 153.77709107829108 W 209.67741846094543",
		"rotation":0,"flipH":false,"flipV":false,
		"isFloating":false,
		"url":"OKG://id/33204/checksum/00000000/len/0/token/8444B712/type/1/server/22/title/女主管在商務會議?size=2",
		"locked":false,"alpha":0.296875,"douid":"961BB002-7143-D371-5C83-555A17478B06"}
		
		*/
		var options:any = this.node.attributes;
		var dom:HTMLElement = this.elementRef.nativeElement;
		var style:CSSStyleDeclaration = dom.style;
		var src:string=  this.node.getAttribute("src");
		var colorCode:string = this.node.getAttribute("color", null);
		style.backgroundSize = "contain";
		if(options.alpha != 1)
			style.opacity = options.alpha;
		if(colorCode)
		{
			var tool:ColorTool = new ColorTool();
			var filter = tool.colorCodeToSVGFilter(colorCode);
			console.log("filter", filter);
			style.filter = filter;
		}

		this.initImage();
		if(!src) return;
		this.load(src).then((o:any)=>{
			this.isContentPromise = null;
		})
	}

	private load(src:string):Promise<any>
	{
		this.isContentPromise = this.context.service.resourceSource.lookupResource(src).then((url:string)=>{
			return this.applyURL(url);
		});
		return this.isContentPromise;
	}

	private initImage():void
	{
		if(!this.img) {
			this.img = document.createElement("img");
			this.img.setAttribute("draggable","false");
			
			this.img.onload = ()=>{
				if(this.onReadyResize || this.w==0 || this.h==0) {
					this.onReadyResize = false;

					var size:any = this.context.service.fileIO.calContainSizeInSquare(this.img.naturalWidth, this.img.naturalHeight, 600);
					this.resize(size.width, size.height);
					
					this.context.subject.next({
						type:"action", 
						action:"componentAutoResized",
						data:this
					});
				}
				
			};
		}
			
	}
	
	private applyURL(url:string):Promise<any>
	{
		return new Promise((resolve, reject)=>{
			var subscription:Subscription = new Subscription(()=>{})
			var dom:HTMLElement = this.elementRef.nativeElement;
			
			subscription.add(fromEvent(this.img, "load").subscribe(
			(event:any)=>{
				this.loadState = "ready";
				resolve("loaded");
				subscription.unsubscribe();
			}));
			subscription.add(fromEvent(this.img, "error").subscribe(
				(event:any)=>{
					this.loadState = "failed";
					reject(event);
					subscription.unsubscribe();
				}));
	

			this.loadState = "loading";
			this.img.src = url;
			this.img.style.width = "100%";
			this.img.style.height= "100%";
			var flipH:boolean = this.node.getAttribute("flipH");
			var flipV:boolean = this.node.getAttribute("flipV");
			if(flipH || flipV)
			{
				var xScale:number = flipH ? -1 : 1;
				var yScale:number = flipV ? -1 : 1;
				this.img.style.transform = `scale(${xScale}, ${yScale}`;
			}
			dom.appendChild(this.img);
		})
		
	}

	public setPropertiesThroughPanel(key:string, val:any):boolean {
		if(key == "src" || key == "assetSrc") {
			if(!val) return false;

			if(key == "assetSrc")
				this.onReadyResize = true;
			this.node.setAttribute("src", val);
			this.context.service.resourceSource.lookupResource(val).then((url:string)=>{
				this.applyURL(url);
			});
			return true;
		}

		return super.setPropertiesThroughPanel(key, val);
	}

	// =======================================
	// edit relative function
	// =======================================
	public getSelectorSettings():any {
		return {
			popupSelector:this.getPopupSelector(),
			rotation:false,lockedAspectRatio:true,widthResizer:false,heightResizer:false,resizer:true,move:true
		};
	}

	public getPopupSelector():any []
	{
		return [this.getPSName(), {
			type:"action",
			action:"changeImage",
			target:"editor",
			color:"#FBB226",
			icon:SelectMarkerIcon.IMAGE_ICON
		}, this.getPSDel()];
	}
}
AllROComponents.push(ROImageComponent);
///////////////

@Component({
	template:``,
	styles:[
		`
		`
	]
})
export class ROShapeBubbleSpeakComponent extends ROContainerComponent
{
	// public tagName:string = "ShapeRectangle";
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["ShapeBubbleSpeak"];
	}
	protected buildContent():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var options:any = this.node.attributes;
		var svg:HTMLElement = document.createElement("svg");
		dom.appendChild(svg);
		var borderAttributes:string []= [];
		if(options.borderWidth)
		{
			borderAttributes.push(`stroke-width="${options.borderWidth}"`);
			borderAttributes.push(`stroke="${options.borderColor}"`);
			if(options.borderStyle == "dashed")
			{
				borderAttributes.push(`stroke-linecap="round"`);
				var onDash:number = options.dashW+3;
				var offDash:number = options.dashS+options.borderWidth;
				var dashLen:number = onDash + offDash; 
				borderAttributes.push(`stroke-dasharray="${onDash} ${offDash}"`);
			} else if(options.borderStyle == "dotted")
			{
				var onDash:number = options.dashW+3;
				var dashLen:number = options.dotS+options.borderWidth;
				borderAttributes.push(`stroke-linecap="round"`);
				borderAttributes.push(`stroke-dasharray="0 ${dashLen}"`);
			}
		}
	
		var borderAttributesString =  borderAttributes.join(" ");
		var fillColor = ROColorUtils.formatColor(options.shapeColor, "none");
		var rx = 0; var ry = 0;
		if(options.bubbleShape == "rectangle")
		{
			rx = ry = options.roundRect/2;
		} else {
			rx = this.w/2;
			ry = this.h/2;
		}
		
		var angle = options.arrowRot;
		var cx:number = this.w / 2;
		var cy:number = this.h / 2;
		var _dist:number = options.arrowDist;
		var delta:number = (cx + cy) / 5;
		var angle1:number = angle  + (Math.PI / 2);
		// var angle2:number = angle  - (Math.PI / 2);
		var cos = Math.cos(angle1);
		var sin = Math.sin(angle1);
		var updown:boolean = Math.abs(cos) > Math.abs(sin);
		
		var points = [
			{x:(_dist * Math.cos(angle )), y:(_dist * Math.sin(angle ))}
		];
		var left:number = cos * delta;
		var right:number = sin * delta;
		if(updown)
		{
			var r = Math.min(this.w/2);
			left = (left > 0) ? Math.min(r, left) : -Math.min(-r, -left);
		} else {
			var r = Math.min(this.h/2);
			right = (right > 0) ? Math.min(r, right) : -Math.min(-r, -right);
		}
		points.push(
			{x:left, y:right},
			{x:-left, y:-right}
		);
		points = points.map((pt)=>{
			return {x:cx + pt.x,y:cy + pt.y};
		});
		
		ScriptLoader.load("assets/js/clipper.js", "ClipperLib").then((ClipperLib:any)=>{
			// points
			var cpr = new ClipperLib.Clipper();
			var path1 = this.createRectPath({
				rx:rx, ry:ry,
				width:this.w, height:this.h
			}).map((pt)=>{
				return {
					X:pt.x,
					Y:pt.y
				}
			});
			var path2 = points.map((pt)=>{
				return {
					X:pt.x,
					Y:pt.y
				}
			});

			cpr.AddPaths([path1], ClipperLib.PolyType.ptSubject, true); 
			cpr.AddPaths([path2], ClipperLib.PolyType.ptClip, true); 

			var solution_paths = new ClipperLib.Paths();
			cpr.Execute(
				ClipperLib.ClipType.ctUnion, 
				solution_paths, 
				ClipperLib.PolyFillType.pftNonZero, 
				ClipperLib.PolyFillType.pftNonZero
			);
			var path:string = this.paths2string(solution_paths);
			
			svg.outerHTML = `
				<svg xmlns="http://www.w3.org/2000/svg" 
					viewBox="0 0 10 10" width="10" height="10" overflow="visible"
					style="position:absolute"
					>
					<path 
						d="${path}" 
						fill="${fillColor}" 
						${borderAttributesString}
					/>
				</svg>
			`;
			this.ready= true;
		})
		if(options.shapeAlpha!= 1) dom.style.opacity = options.shapeAlpha;
	}
	private ready:boolean;

	protected buildChildren():void
	{
		// {"ver":1,"bubbleShape":"circle","arrowDist":230.34693941965017,"arrowRot":0.02018831199533457,"arrowJawLeft":-0.3082862967244102,"arrowJawRight":0.3082862967244102,"roundRect":30,"borderVisible":true,"borderWidth":4,"borderColor":"#f3c75b","borderStyle":"line","shapeAlpha":1,"shapeColor":"#f0535a","rotation":0,"textVisible":true,"textPadding":15,"locked":false,"coordinateExpression":"UA UK X 53 Y 350 D T 350 L 53 H 230.68181818181816 W 321.4285714285715","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"dashW":5,"dashS":5,"dotS":5,"studEditable":false,"douid":"F45B9781-F3FC-3ECE-CD0F-82206DB4582E"}
		if(this.node.getAttribute("textVisible"))
		{
			super.buildChildren();
		} else {
			this.children = [];
		}
	}
	formatNumber(num)
	{
		return num;
	}
	paths2string (paths) {
		var svgpath = "", i, j;
		for(i = 0; i < paths.length; i++) {
			for(j = 0; j < paths[i].length; j++){
				if (!j) svgpath += "M";
				else svgpath += "L";
				svgpath += this.formatNumber(paths[i][j].X ) + ", " + this.formatNumber(paths[i][j].Y );
			}
			svgpath += "Z";
		}
		if (svgpath=="") svgpath = "M0,0";
		return svgpath;
	}
	createRectPath(obj)
	{
		if(!obj.x) obj.x = 0;
		if(!obj.y) obj.y = 0;
		if(!obj.rx) obj.rx = 0;
		if(!obj.ry) obj.ry = 0;
		if(obj.rx * 2 > obj.width) obj.rx = obj.width/2;
		if(obj.ry * 2 > obj.width) obj.ry = obj.height/2;
		var points = [];
		var angle = 0;
		var accuracy = 20;
		var angleStepSize = Math.PI / accuracy / 2;
		var array = [
			{
				// bottom
				pt:{x:obj.width - obj.rx, y:obj.height - obj.ry}, 
				from:{x:obj.width - obj.rx, y:obj.height},
				to:{x:obj.rx, y:obj.height},
			},
			{
				
				pt:{x:obj.rx, y:obj.height - obj.ry}, 
				from:{x:0, y:obj.height-obj.ry},
				to:{x:0, y:obj.ry},
			},
			{
				// top
				pt:{x:obj.rx, y:obj.ry}, 
				from:{x:obj.rx, y:0},
				to:{x:obj.width - obj.rx, y:0},
			}, 
			{
				// right
				pt:{x:obj.width - obj.rx,y:obj.ry},
				/* 
				from:{x:obj.width, y:obj.y},
				to:{x:obj.width, y:obj.height - obj.y},
				*/
			}
		];
		array.forEach((element)=>{
			var pt = element.pt;
			
			if(obj.rx && obj.ry)
			{ // bottom
				for(var i = 0;i <  accuracy;i++)
				{
					if(i > 0)
					{
						points.push({
							x:pt.x + obj.rx * Math.cos(angle),
							y:pt.y + obj.ry * Math.sin(angle)
						});
					}
					angle += angleStepSize;
				}
			}
			
			if(element.from && element.to)
			{
				points.push(element.from);
				points.push(element.to);
			}
			return element;
		});
		return points.map((pt)=> {return {
			x:obj.x+pt.x,
			y:obj.y+pt.y
		}});
	}
	
	dataContentReady(): boolean {
		return this.ready;
	}
}

@Component({
	template:`
	<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" overflow="visible">
	  <ellipse 
	  	[attr.stroke]="borderColor"
		[attr.cx]="cx" [attr.rx]="cx" 
		[attr.cy]="cy" [attr.ry]="cy" 
		[attr.opacity]="opacity" 
		[attr.fill]="fillColor"
		[attr.stroke-dasharray]="dasharray"
		stroke-linecap="round"
		[attr.stroke-width]="borderWidth"
		/>
		<!-- [attr.stroke-dasharray]="10 10" -->
	</svg>
	`
})

export class ROShapeCircleComponent extends ROComponent
{   
	// public tagName:string = "ShapeCircle";
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["ShapeCircle"];
	}
	public fillColor:string;
	public cx:number = 0;
	public cy:number = 0;
	public opacity:number = 1;
	public borderColor:string;
	public borderStyle:string;
	public stroke:string;
	public borderWidth:number;
	public dasharray:string = "";

	protected buildContent():void
	{
		/**
		 * xml.@borderVisible = false;
			xml.@borderWidth = 0;
			xml.@borderColor = "#000000";
			xml.@borderStyle = "line";
			
			xml.@shapeAlpha = 1;
			xml.@shapeColor = "#5BB9D9";
			xml.@rotation = 0;
			xml.@textVisible = false;
			
			xml.@textPadding = 5;*/
		var options:any = this.node.attributes;
		this.fillColor = ROColorUtils.formatColor(options.shapeColor, "none");
		this.cx = this.w/ 2;
		this.cy = this.h/ 2;
		
		var borderWidth:number =  parseInt(options.borderWidth);
		 
		if(borderWidth > 0 )
		{
			this.borderColor = ROColorUtils.formatColor(options.borderColor, "#000000");
			this.borderStyle =  options.borderStyle;
			this.borderWidth = borderWidth;
			if(this.borderStyle == "line") this.borderStyle = "solid";
			if(this.borderStyle == "dashed")
			{
				// dashW="5" dashS="5" 
				// dotS="5" 
				this.dasharray = `${options.dashW + 3} ${options.dashS + options.borderWidth}`;
			} else if(this.borderStyle == "dotted")
			{
				var dashLen = options.dashW + 3 + options.dashS + options.borderWidth;
				this.dasharray = `0 ${dashLen}`;
			}
			// var dashy:DashedLine_2 = new DashedLine_2(borderSprite, dashW+3, dashS+borderWidth);
		} else {
			this.borderColor = "none";
		}
		/*
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.backgroundColor = this.node.getAttribute("shapeColor");
		dom.style.borderRadius = "50%";
		var borderWidth:number =  parseInt(this.node.getAttribute("borderWidth"));
		if(borderWidth > 0 )
		{
			dom.style.borderColor = this.node.getAttribute("borderColor");
			dom.style.borderWidth = borderWidth + "px";
			var borderStyle:string =  this.node.getAttribute("borderStyle")
			if(borderStyle == "line")borderStyle = "solid";
			dom.style.borderStyle = borderStyle;
		}
		dom.style.opacity = this.node.getAttribute("shapeAlpha");
		*/
		this.opacity = options.shapeAlpha ; // this.node.getAttribute("shapeAlpha");
	}
}
AllROComponents.push(ROShapeCircleComponent);
///////////////

@Component({
	template:``
})
export class ROShapeCustomComponent extends ROComponent
{   
	// public tagName:string = "ShapeCustom";
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return [
			"ShapeCustom",
			"ShapeTriangle",
			"ShapeCustomStar",
			"ShapeDiamondStar",
			"ShapeRATriangle",
			"ShapeTrapezia",
			"ShapeHarrier",
			"ShapeIsosTriangle",
			"ShapeBalanceQuad",
			"ShapeDiamond",
			"ShapeRegularPolygon"
		];
	}

	protected buildContent():void
	{
		this.buildContentWithSVG();
		// this.buildContentWithCanvas2SVG();
	}
	/*
	protected buildContentWithCanvas2SVG():void
	{
		ScriptLoader.loadArray(
			[
				{url:"assets/swf2js/canvas2svg.js", name:"C2S"}
			]
		).then((o:any)=>{
			var dom:HTMLElement = this.elementRef.nativeElement;
			var C2S:any = window["C2S"];
			var ctx = new C2S(this.w, this.h);
			ctx.beginPath();
			this.node.children.forEach((node:XMLNode, index:number)=>{
				var px:number = Math.floor(node.getAttribute("x") );
				var py:number = Math.floor(node.getAttribute("y") );
				// pts.push(px, py);
				if(index == 0)
					ctx.moveTo(px, py);
				else
					ctx.lineTo(px, py);
			});
			ctx.fillStyle="#FF000080";
			ctx.fill();
			var svg = ctx.getSvg();
			svg.setAttribute("overflow", "visible");
			dom.appendChild(svg);
		});
	}
	*/
	protected buildContentWithSVG():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var pts:any []  = [];
		var options:any = this.node.attributes;
		this.node.children.forEach((node:XMLNode)=>{
			var px:number = Math.floor(node.getAttribute("x") );
			var py:number = Math.floor(node.getAttribute("y") );
			pts.push(px, py);
		});
		
		// dom.style.border = "solid 1px red";
		var ptString:string = pts.join(",");
		var shapeColor:string = ROColorUtils.formatColor(
			options.shapeColor, "none"
		);
		var borderWidth:number = parseInt(this.node.getAttribute("borderWidth"));
		var polygonStyleArray:string [] = [];
		if(borderWidth)
		{
			/*
			{
				"ver":1.1,"borderWidth":5,"borderColor":"#000000",
				"borderStyle":"dashed",
				"shapeAlpha":1,"shapeColor":"#5bb9d9",
				"rotation":0,"textVisible":false,"textPadding":5,
				"dashW":5,"dashS":5,"dotS":5,"locked":false,"numSPoints":3,"douid":"24B79B96-7EEA-82DC-7CA3-F9C74EEAB922","coordinateExpression":"UA UK X 140 Y 358 D T 358 L 140 H 147.02616970658212 W 212.3062769789621","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"borderVisible":true,"studEditable":false}
			*/
			var borderColor:number = this.node.getAttribute("borderColor");
			polygonStyleArray.push(`stroke="${borderColor}"`);
			polygonStyleArray.push(`stroke-width="${borderWidth}"`);
			if(options.borderStyle == "dashed")
			{
				polygonStyleArray.push(`stroke-dasharray="${options.dashW + 3} ${options.dashS + options.borderWidth}"`);
			} else if(options.borderStyle == "dotted")
			{
				polygonStyleArray.push(`stroke-dasharray="0 ${options.dashS + options.borderWidth}"`);
			}
			polygonStyleArray.push(`stroke-linecap="round"`);
		}
		var shapeAlpha:number = parseFloat(this.node.getAttribute("shapeAlpha"));
		if(shapeAlpha != 1){
			polygonStyleArray.push(`opacity="${shapeAlpha}"`);
		}
		
		var polygonStyle:string = polygonStyleArray.length ? polygonStyleArray.join(" ") : "";
		
		/**
		 * <!-- {"ver":1.1,"borderWidth":10,"borderColor":"#000000","borderStyle":"line","shapeAlpha":0.5,"shapeColor":"#5bb9d9","rotation":0,"textVisible":false,"textPadding":5,"dashW":5,"dashS":5,"dotS":5,"locked":false,"numSPoints":3,"douid":"19516D8A-0937-697B-181A-78BDF15F107A","coordinateExpression":"UA UK X 458 Y 300 D T 300 L 458 H 200 W 200","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"borderVisible":true,"studEditable":false} -->
		 */
		// document.createComment()
		var json:string = JSON.stringify(this.node.attributes);
		dom.innerHTML = `
			<svg 
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 10 10" width="10" height="10" 
				overflow="visible"
				style="position: absolute;"
				>
				<polygon points="${ptString}" 
					fill="${shapeColor}" 
					${polygonStyle}
					/>
			</svg>
			<!-- ${json} -->
		`;
		// shape-rendering="geometricPrecision/crispEdges/optimizeSpeed" />
		//  
	}
	/*
	protected buildContentWithSVGJS():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var pts:any []  = [];
		var maxW:number = 20;
		var maxH:number = 20;
		this.node.children.forEach((node:XMLNode)=>{
			var px:number = Math.floor(node.getAttribute("x") + 20);
			var py:number = Math.floor(node.getAttribute("y") + 20);
			pts.push(px, py);
			if(px > maxW) maxW = px;
			if(py > maxH) maxH = py;
		});
		maxW += 20;
		maxH += 20;
		
		var ptString:string = pts.join(",");
		var shapeColor:string = this.node.getAttribute("shapeColor");
		var borderWidth:number = parseInt(this.node.getAttribute("borderWidth"));
	   
		var shapeAlpha:number = parseFloat(this.node.getAttribute("shapeAlpha"));
		var draw :Svg = SVG().addTo(dom).viewbox(0, 0, maxW, maxH).width(maxW).height(maxH);
		var group:G = draw.group();
		
		var polygon:Polygon = group.polygon(ptString).fill(shapeColor);
		var style:CSSStyleDeclaration = draw.node.style;
		style.left = "-20px";
		style.top = "-20px";
		style.position = "absolute";

		if(borderWidth)
		{
			var borderColor:string = this.node.getAttribute("borderColor");
			polygon.stroke({
				color:borderColor,
				width:borderWidth
			});
		}
		
		if(shapeAlpha != 1){
			polygon.opacity(shapeAlpha);
		}
		var comment = document.createComment(JSON.stringify(this.node.attributes));
		dom.appendChild(comment);


		// shape-rendering="geometricPrecision/crispEdges/optimizeSpeed" />

	}
	*/
}
AllROComponents.push(ROShapeCustomComponent);
///////////////
@Component({
	template:``,
	styles:[
		`
		`
	]
})
export class ROShapeRectangleComponent extends ROComponent
{
	// public tagName:string = "ShapeRectangle";
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["ShapeRectangle"];
	}
	protected buildContent():void
	{
		// <!--{"ver":1.1,"roundRect":0,"borderVisible":true,"borderWidth":3,"borderColor":"#f5edda","borderStyle":"line","shapeAlpha":1,"shapeColor":"#ffffff","rotation":0,"textVisible":false,"textPadding":5,"coordinateExpression":"UA UK KS X 101 Y 350.5 D T 350.5 L 101 H 200 W 200","dashW":5,"dashS":5,"dotS":5,"studEditable":false,"locked":false,"douid":"2A8FA452-D951-873B-F777-EE9FB3390664"}-->
		
		var dom:HTMLElement = this.elementRef.nativeElement;
		var options:any = this.node.attributes;
		var svg:HTMLElement = document.createElement("svg");
		dom.appendChild(svg);
		var opacity = options.shapeAlpha;
		var shapeAttributes = [];
		if(opacity != 1)
		{
			shapeAttributes.push(`opacity="${opacity}"`);
		}
		if(options.borderWidth)
		{
			shapeAttributes.push(`stroke-width="${options.borderWidth}"`);
			shapeAttributes.push(`stroke="${options.borderColor}"`);
			/*
			if (borderStyle == "dashed") {
				var dashy:DashedLine_2 = new DashedLine_2(borderSprite, dashW+3, dashS+borderWidth);
			} else {
				dashy = new DashedLine_2(borderSprite, 1, dotS+borderWidth);
			}
			*/

			if(options.borderStyle == "dashed")
			{
				shapeAttributes.push(`stroke-linecap="round"`);
				// stroke-dasharray="50 150"
				// stroke-width="50"
				var onDash:number = options.dashW+3;
				var offDash:number = options.dashS+options.borderWidth;
				
				var dashLen:number = onDash + offDash; 

				shapeAttributes.push(`stroke-dasharray="${onDash} ${offDash}"`);
			} else if(options.borderStyle == "dotted")
			{
				var onDash:number = options.dashW+3;
				var dashLen:number = options.dotS+options.borderWidth;
								shapeAttributes.push(`stroke-linecap="round"`);
				shapeAttributes.push(`stroke-dasharray="0 ${dashLen}"`);
			}

		}
		if(options.roundRect>0){
			var rx = options.roundRect/2;
			shapeAttributes.push(`rx=${rx}`);
		}
			

		var shapeAttributesString =  shapeAttributes.join(" ");
		var fillColor = ROColorUtils.formatColor(options.shapeColor, "none");
		svg.outerHTML = `
			<svg xmlns="http://www.w3.org/2000/svg" 
				viewBox="0 0 10 10" width="10" height="10" overflow="visible"
				style="position:absolute"
				>
				<rect 

					width="${this.w}" height="${this.h}" 
					fill="${fillColor}" 
					opacity="${options.shapeAlpha}"
					${shapeAttributesString}
					/>
			</svg>
		`;
		/*
		
		dom.style.opacity = options.shapeAlpha;
		if(options.borderWidth)
		{
			dom.style.borderWidth = options.borderWidth + "px";
			dom.style.borderColor = options.borderColor;
			dom.style.borderStyle = this.formatBorderStyle(options.borderStyle);
		}

		if(options.roundRect>0)
			dom.style.borderRadius = options.roundRect/2 + "px";
			*/
	}
}

AllROComponents.push(ROShapeRectangleComponent);
///////////////
@Component({
	template:`
	<ng-template #hello>Hello</ng-template>`
})

export class ROTLFTextComponent extends ROComponent
{
	public textflowOffset:number = 0;
	
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.canEditInside = true;
	}

	public getTagNames():string []
	{
		return ["TLFText"];
	}

	protected setDefaultXMLData():void
	{
		/*
		xml = <TLFText autoHeight="true">
			  <TextFlow 
				fontFamily={RODocumentManager.getBookSetting("fontFamily")}
				fontSize={RODocumentManager.getBookSetting("fontSize")}
				lineBreak="explicit" 
				whiteSpaceCollapse="preserve" version="2.0.0" xmlns="http://ns.adobe.com/textLayout/2008">
				<span>{LANG.instance().getText("LANG_DEFAULT_TEXTFIELD_TEXT")}</span>
			  </TextFlow>
			</TLFText>;
			this.w = 200;
			this.h = 40;
		*/
		var defaultText:string = "<TextFlow fontFamily=\"DFHKStdKai\" fontSize=\"32\" lineBreak=\"toFit\" whiteSpaceCollapse=\"preserve\" version=\"3.0.0\" xmlns=\"http://ns.adobe.com/textLayout/2008\"><p><span>一般文字</span></p></TextFlow>";
		this.node = new XMLJSNode().assign({
			"children": [
				{
					"type": "text",
					"text": defaultText
				}
			],
			attributes: {
				"autoHeight": true,
				"coordinateExpression": "UA UK KH X 60 Y 7 D SIZE 1024 768 1024 768 T 7 L 60 H 43 W 190",
				"douid": this.createDouid(),
				"fillingColor": 16117210,
				"fillingUnderLine": false,
				"fillingAlpha": 0,
				"autoWidth": false,
				"wideFilling": false,
				"writing": true,
				"transparency": 100,
				"memo": false,
				"isVAlign": false,
				"locked": false
			},
			tag:"TLFText"
		});
		this.node.createElement();
	}
	
	protected buildContent():void
	{
		var utils:TextFlowUtils = new TextFlowUtils();
		try
		{
			var textFlow:string = this.node.children[0].text;
			var dom:HTMLElement = this.elementRef.nativeElement;
			var container:HTMLElement = document.createElement("div");
			dom.appendChild(container);

			// textFlow = textFlow.replace(/-textflowFontsize="40pxpx"/g, '');
			var flow:HTMLElement= utils.textFlowToDOM(textFlow, true);
			this.estimateFirstLine(flow);
			container.appendChild(flow);
		} catch(err)
		{
			debugger;
		}
		
		
///		var flow:HTMLElement= utils.textFlowToDOM(textFlow, false);
///		container.appendChild(flow);

		var transparency:number = this.node.getAttribute("transparency");
		if(transparency != 100)
		{
			dom.style.opacity = `${transparency}%`; 
		}
		if(this.textflowOffset) container.style.transform = `translate(0px, -${this.textflowOffset}px)`;
	}


	protected estimateFirstLine(flow:HTMLElement):void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var tag = this.node.tag;
		var container = document.createElement("div");
		if(tag)
		{
			container.setAttribute("component", tag);
			container.classList.add("ro-component", tag);
		}
		container.style.width = this.w+"px";
		container.style.height = this.h+"px";
		document.body.appendChild(container);
		container.appendChild(flow);
		var topElement:HTMLElement = container.querySelector("dom.t");
		var bottomElement:HTMLElement = container.querySelector("dom.b");
		if(topElement && bottomElement)
		{
			var b = topElement.getBoundingClientRect();
			var d = bottomElement.getBoundingClientRect();
			var lineHeight = StyleUtils.getDeepStyleVariable(topElement, "span-line-height");
			var l:number;
			if(lineHeight)
			{
				l = parseFloat(lineHeight) / 100;
			} else {
				l = 1.2;
			}
			var tempH = (d.bottom - b.top);
			this.firstLineHeight = tempH / l;
			var offset = this.firstLineHeight * (l - 1)/2;
			
			this.textflowOffset = offset;
		}
		container.parentElement.removeChild(container);
		container.removeChild(flow);
	}

	// =======================================
	// edit relative function
	// =======================================
	public set editInStage(value:boolean)
	{
		this._editInStage = value;
		this.elementRef.nativeElement.setAttribute("contenteditable", value);
		this.elementRef.nativeElement.focus();
		if(!value &&  (<HTMLElement>this.elementRef.nativeElement).firstElementChild) {
			// 文字編輯完成
			//this.updateBounding();
			var dom:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild);
			this.h = dom.offsetHeight + this.textflowOffset;
			this.assignLTWH();

			this.getXMLJSON();
		}
	}
	public get editInStage():boolean{
		return this._editInStage;
	}

	/*protected updateBounding():void{
		var dom:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild);
		console.log("TLF updateBounding ",dom, dom.offsetWidth, dom.offsetHeight, dom.clientWidth, dom.clientHeight);
		var rect = dom.getBoundingClientRect();
		console.log(rect);
		var pt1:any = DOMHelper.getLocalPoint(dom, {x:rect.x+rect.width, y:rect.y+rect.height});
		console.log(pt1);
		var rect2 = this.elementRef.nativeElement.getBoundingClientRect();
		console.log(rect2);
		pt1 = DOMHelper.getLocalPoint(dom, {x:rect.x+rect2.width, y:rect.y+rect2.height});
		console.log(pt1);
		this.w = dom.offsetWidth;
		this.h = dom.offsetHeight;
		this.assignLTWH();
	}*/

	public resizeWidth(width:number):any {
		// 更新闊度
		this.w = Math.max(40, width);
		// var dom:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild);
		var dom:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild);
		this.h = dom.offsetHeight + this.textflowOffset;
		this.assignLTWH();
		this.updateRotation();
		
		
		// 取得新的size
		/*
		div tramsform
			div textflow
				pre
		*/
		return {width:this.w, height:this.h};
	}

	public getSelectorSettings():any {
		return {
			popupSelector:this.getPopupSelector(),
			rotation:true,lockedAspectRatio:false,widthResizer:!this.node.getAttribute("autoWidth"),heightResizer:false,resizer:false,move:true
		};
	}

	public getPopupSelector():any {

		if(this.editInStage) {
			var ary:any[] = [this.getPSComplete()];

			var i:number = this.context.editLayerManager.editLayers.indexOf(this);
			i = i==-1 ? this.context.editLayerManager.editLayers.length : i;
			if(i>=1)
				ary.push({
					type:"action",
					action:"back",
					target:"editor",
					color:"#FBB226",
					icon:SelectMarkerIcon.BACK_ICON,
					name:this.context.translate("ro.components.common.prev_layer")
				});
			return ary;
		}

		var ary:any[] = [this.getPSName(), this.getPSSound(), this.getPSFontSize()];
		if(this.canEditInside)
			ary.splice(1, 0, this.getPSCanEdit());
		if(this.canDelete())
			ary.push(this.getPSDel());
		return ary;
	}

	public getXMLJSON():any {
		var utils:TextFlowUtils = new TextFlowUtils();
		this.node.children[0].text = this.node.element.elements[0].text = utils.domToTextFlow(this.elementRef.nativeElement.firstElementChild.firstElementChild);
		return super.getXMLJSON();
	}

	public setPropertiesThroughPanel(key:string, val:any = null):boolean
	{
		const element:any = (<HTMLElement>this.elementRef.nativeElement);
		const innerTextHtml:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild.firstElementChild);
		// const innerTextHtml:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild);
		if ((val === null || val === undefined) && !key.startsWith("markup") && !key.startsWith("align")) {
			console.log("setPropertiesThroughPanel ROTLFTextComponent Error", key, val)
			return false;
		}
		switch(key) {
			case "fontFamily":
				element.firstElementChild.firstElementChild.style.fontFamily = val;
				break;
			case "fontSize":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					child.style.fontSize = `${val}px`;
				});
				break;
			case "lineSpacing":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					child.style.lineHeight = `${val}px`;
				});
				break;
			case "letterSpacing":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					child.style.letterSpacing = `${val}px`;
				});
				break;
			case "transparency":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					child.style.opacity = `${val}`;
				});
				break;
			case "markupBold":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					if (child.style.fontWeight !== "bold") {
						child.style.fontWeight = "bold";
					} else {
						child.style.fontWeight = "normal";
					}
				});
				break;
			case "markupItalic":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					if (child.style.fontStyle !== "italic") {
						child.style.fontStyle = "italic";
					} else {
						child.style.fontStyle = "normal";
					}
				});
				break;
			case "markupUnderline":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					if (!child.style.textDecoration.includes("underline")) {
						child.style.textDecoration = child.style.textDecoration ? `${child.style.textDecoration} underline` : "underline";
					} else {
						child.style.textDecoration = child.style.textDecoration.replace("underline", "").trim();
					}
				});
				break;
			case "markupStrikethrough":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					if (!child.style.textDecoration.includes("line-through")) {
						child.style.textDecoration = child.style.textDecoration ? `${child.style.textDecoration} line-through` : "line-through";
					} else {
						child.style.textDecoration = child.style.textDecoration.replace("line-through", "").trim();
					}
				});
				break;
			case "alignLeft":
				element.querySelectorAll('p').forEach((child: HTMLElement) => {
					child.style.textAlign = "left";
				});
				break;
			case "alignCenter":
				element.querySelectorAll('p').forEach((child: HTMLElement) => {
					child.style.textAlign = "center";
				});
				break;
			case "alignRight":
				element.querySelectorAll('p').forEach((child: HTMLElement) => {
					child.style.textAlign = "right";
				});
				break;
			case "alignJustify":
				element.querySelectorAll('p').forEach((child: HTMLElement) => {
					child.style.textAlign = "justify";
				});
				break;
			case "color":
				element.querySelectorAll('span').forEach((child: HTMLElement) => {
					child.style.color = val;
				});
				break;
			case "autoWidth":
				this.node.setAttribute("autoWidth", val);
				if (val === true) {
					element.querySelectorAll('span').forEach((child: HTMLElement) => {
						child.style.whiteSpace = 'nowrap';
					});
				} else {
					element.querySelectorAll('span').forEach((child: HTMLElement) => {
						child.style.whiteSpace = 'normal';
					});
				}
				this.updateWHAfterSetProperty();
				break;
			case "wordDirection":
				innerTextHtml.style.direction = val;
				break;
			case "x":
				this.x = val;
				break;
			case "y":
				this.y = val;
				break;
			case "w":
				element.style.width = `${val}px`;
				this.w = val;
				break;
			case "h":
				element.style.height = `${val}px`;
				this.h = val;
				break;
		}
		this.updateWHAfterSetProperty();
		return true;
	}

	public getPropertiesThroughPanel(key:string):any
	{
		const element:any = (<HTMLElement>this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild.firstElementChild.firstElementChild);
		switch(key) {
			case "fontFamily":
				return getComputedStyle(this.elementRef.nativeElement.firstElementChild.firstElementChild, null).getPropertyValue('font-family');
			case "fontSize":
				const fontSize = parseFloat(getComputedStyle(element, null).getPropertyValue('font-size'));
				return fontSize;
			case "lineSpacing":
				const lineSpacing = parseFloat(getComputedStyle(element, null).getPropertyValue('line-height'));
				return lineSpacing;
			case "letterSpacing":
				// NOTE: the default of letter-spacing is "normal". see more: https://developer.mozilla.org/en-US/docs/Web/CSS/letter-spacing
				const rawLetterSpacing = getComputedStyle(element, null).getPropertyValue('letter-spacing');
				let letterSpacing = 0;
				if (rawLetterSpacing === "normal") {
					letterSpacing = 0;
				} else {
					letterSpacing = parseInt(rawLetterSpacing);
				}
				return letterSpacing;
			case "transparency":
				const transparency = parseFloat(getComputedStyle(element, null).getPropertyValue('opacity'));
				return transparency;
			case "fontFamily":
				return getComputedStyle(element, null).getPropertyValue('font-family');
			case "color":
				return getComputedStyle(element, null).getPropertyValue('color');
			case "markupBold":
				const fontWeight = getComputedStyle(element, null).getPropertyValue('font-weight');
				const isBold = fontWeight === 'bold' || parseInt(fontWeight) >= 700;
				return isBold;
			case "markupItalic":
				const isItalic = getComputedStyle(element, null).getPropertyValue('font-style') === 'italic';
				return isItalic;
			case "markupUnderline":
				const isUnderline = getComputedStyle(element, null).getPropertyValue('text-decoration').includes('underline');
				return isUnderline;
			case "markupStrikethrough":
				const isStrikethrough = getComputedStyle(element, null).getPropertyValue('text-decoration').includes('line-through');
				return isStrikethrough;
			case "align":
				return getComputedStyle(element, null).getPropertyValue('text-align');
			case "autoWidth":
				// console.log('autoWidth : ', getComputedStyle(element, null).getPropertyValue('white-space'))
				return this.node.getAttribute("autoWidth");
			case "wordDirection":
				return getComputedStyle(element, null).getPropertyValue('direction');
			case "x":
				return Math.round(this.x);
			case "y":
				return Math.round(this.y);
			case "w":
				return Math.round(this.w);
			case "h":
				return Math.round(this.h);
		}
		return null;
	}

}

AllROComponents.push(ROTLFTextComponent);
///////////////


///////////////
@Component({
	template:``
})
export class ROSuperTextComponent extends ROTLFTextComponent
{
	// public tagName:string = "SuperText";
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["SuperText"];
	}
}
AllROComponents.push(ROSuperTextComponent);
///////////////
@Component({
	template:`Unknown`,
	styles:[
		`:host{
			border: solid 1px red;
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
		}`
	]
})
export class ROUnknownComponent extends ROComponent
{
	// public tagName:string = "Unknown";
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["Unknown"];
	}

	protected buildContent(): void {
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.innerText = this.node.tag;
		if(this.node)
		{
			var json:string = JSON.stringify(this.node.attributes);
			var commentBlock:Comment = document.createComment(json);
			dom.appendChild(commentBlock);
			// console.log(json);
		}
	}

}

AllROComponents.push(ROUnknownComponent);



///////////////
@Component({
	template:`
		
	`,
	styles:[
		`:host{
			border: solid 1px red;
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
		}`
	]
})
export class ROVideoComponent extends ROComponent
{
	public videoElement:HTMLVideoElement ;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["Video", "Video2"];
	}

	protected buildContent(): void {
		
		var url:string = this.node.getAttribute("videoSrc");
		this.context.service.resourceSource.lookupResource(url).then((trueURL:string)=>{
			this.videoElement  = document.createElement("video");
			this.videoElement.setAttribute("src", trueURL);
			this.videoElement.setAttribute("controls", "");
			this.videoElement.style.width = "100%";
			this.videoElement.style.height = "100%";
			this.elementRef.nativeElement.appendChild(this.videoElement);	
		})
		// videoSrc: 'OKG://id/18414675/checksum/B7DAA9F0/type/1/title/「Living Chinese 生活學中文」app
	}
	public activate():void
	{
		if(this.videoElement)
		{

		}
	}

	public deactivate():void
	{
		if(this.videoElement)
		{
			this.videoElement.pause();
		}
	}
	
}

AllROComponents.push(ROVideoComponent);

///////////////

///////////////

const STATE_NO_ANSWER:number = 0; // 未答過
const STATE_SUBMITTED:number = 1; // 已提交
const STATE_ANSWER_CHANGE:number = 2; // 修改中

@Component({
	template:``,
	styles:[
		`
		:host{
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
		}
		`
	]
	// ,
	// entryComponents:[ROGroupComponent]
})
export class ROPageComponent extends ROContainerComponent implements OnInit, OnDestroy
{
	public source:AnswerSource;
	public dataComponents:any []  = [];
	public components:ROComponent [] = [];
	public chapter: any;
	public state:number = STATE_NO_ANSWER;
	public ref:any;
	public tagName:string = "Page";
	public pageScore:number = 0;
	public questionCount:number = 0;
	public printing:boolean = false;
	private noteLayerElemnt:HTMLElement;
	public noteLayer:NoteLayer;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef, private script: ScriptService, )
	{
		super(cdr, elementRef);
		this.noteLayerElemnt = document.createElement("div");
		this.noteLayerElemnt.classList.add("epen-note-layer");
		this.noteLayer = new NoteLayer(this.noteLayerElemnt);
		
	}
	ngOnDestroy(): void {
		if(this.destroyed) return;
		this.destroy();
	}

	public answerEnable(flag:boolean):void
	{
	}

	getNoteLayer() {
		this.elementRef.nativeElement.appendChild(this.noteLayerElemnt);
		return this.noteLayer;
	}

	public addComponentByTag(componentTag:string, assetSrc:string = null):ROComponent {
		var com:ROComponent 
		
		if(componentTag == "ScoreCom")
		{
			var sections = [];
			var components:ROComponent [] = this.getChildComponents();
			components.forEach((com:ROComponent)=>{
				if(com.node.tag == componentTag)
				{
					var section:string=  com.getPropertiesThroughPanel("q.section");
					if(section) sections.push(section);
				}
			})
			sections.sort((s1, s2)=>{
				if(s1 > s2) return 1;
				if(s1 < s2) return -1;
				return 0;
			})
			com = super.addComponentByTag(componentTag, assetSrc);
			if(sections.length) {
				var section:string = sections.pop();
				com.setPropertiesThroughPanel("q.section", section);
			}
		} else {
			com = super.addComponentByTag(componentTag, assetSrc);
		}
		
		return com;
	}
	
	updateQuestionNumber() {
		var nodes:XMLNode[] = [];
		this.iterateChildren((com:ROComponent)=>{
			if(com.isQuestionComponent()) nodes.push(com.node);
		});
		// var helper:QuestionOrderHelper = new QuestionOrderHelper();
		QuestionOrderHelper.update(this.node, nodes);

		// QuestionOrderHelper.update(this.node, )
		/*
		var start:number = this.node.getAttribute("questionStartIndex");
		var list:any[] = [];
		var map:any = {};
		var components:ROComponent[] = [];
		this.iterateChildren((com:ROComponent)=>{
			if(com.isQuestionComponent()) components.push(com);
		});
		components.forEach(
			(com:ROComponent, componentIndex:number)=> {
				var node:XMLNode = com.node;
				var title:string = node.getAttribute("questionLabelTitle");
				var index:number = node.getAttribute("questionIndex");
				var show:boolean = com.getPropertiesThroughPanel("q.show");
				try
				{
					// com.setPropertiesThroughPanel("q.updateQN", show);
					var info:any = JSON.parse(node.getAttribute("qInfo"));
					if(info == null)
					{
						debugger;
					}
					var id:number = info.id;
					var pid:number = info.pid;
					var obj:any = {
						error:info.error? true : false,
						level:info.level,
						show:show,
						index:index,
						title:title,
						id:id,
						pid:pid,
						com:com,
						children:[],
						info:info
					};
					list.push(obj);
					map[id] = obj;
				} catch (error:any)
				{
					console.log(error);
				}
			}
		);
		
		list.forEach(
			(o:any) =>
			{
				if (o.pid && map.hasOwnProperty(o.pid))
				{
					var p:any = map[o.pid];
					if (p)
					{
						p.children.push(o);
					}
				}
			}
		);
		
		list.sort(this.sortComponentOrder);
		this.findAllQuestionComponents(list);
			
		
		list.forEach(
			(obj:any)=>
			{
				
				var com:ROComponent = obj.com;
				var title:string = obj.title;
				var index:number = obj.index;
				var info:any = obj.info;
				
				var id:number = obj.id;
				var pid:number = obj.pid;
				
				var level:number = obj.level;
				var hasChildren:number = obj.children.length;
				var show:boolean = obj.show;
				
				var error:boolean = info.error;
				if (show)
				{
					var subfix:String = hasChildren && level == 0 ? " a" : "";
					debugger;
					if (title)
					{
						// com.setPropertiesThroughPanel("q.label", title + subfix);
					} else {
						if (error)
						{
							// com.setPropertiesThroughPanel("q.label", "?"+ subfix);
						} else if (level == 0)
						{
							var trueQuestionNumber = index + start + 1;
							// com.setPropertiesThroughPanel("q.label", trueQuestionNumber + subfix);
						} else if (level == 1)
						{
							// com.setPropertiesThroughPanel("q.label", TextStyleCreator.lowerAlphaString(index + 2));
						} else if (level == 2)
						{
							// com.setPropertiesThroughPanel("q.label", TextStyleCreator.lowerRomanString(index + 1));
						} else if (level == 3)
						{
							// com.setPropertiesThroughPanel("q.label", TextStyleCreator.cjkHeavenlyStemString(index + 1));
							// com.setPropertiesThroughPanel("q.label", "x");
						}
					}
					
				}
			}
		);
		*/
		
	}

	private findAllQuestionComponents(list:any[]):void 
	{
		var questionComponentList:any[] = [];
		var list2:any[] = list.filter((element:any):boolean=>
		{
			return element.pid == 0;
		});
		var a:any = {
			com:null,
			children:list2
		};
		iterate(a);
		function iterate(o:any):void
		{
			if (o.com)
			{
				questionComponentList.push(o.com);
			}
			var array:any [] = o.children;
			array.forEach((o2:any)=>{
				iterate(o2);
			});
		}
	}
	
	private sortComponentOrder(a:any, b:any):number
	{
		var aOffset:any = QuestionOrderHelper.getComponentQuestionOffset((<ROComponent> a.com).node);
		var bOffset:any = QuestionOrderHelper.getComponentQuestionOffset((<ROComponent> b.com).node);
		var ay:number = (a.com.y + aOffset.y) * 10 + (a.com.x + aOffset.x);
		var by:number = (b.com.y + bOffset.y)  * 10 + (b.com.x + bOffset.x);
		if (ay > by)
		{
			return 1;
		} else if (ay < by)
		{
			return -1;
		} 
		return 0;
	}
	
	removeComponent(component: ROComponent) {
		ArrayUtils.removeElement(this.children, component);
		ArrayUtils.removeElement(this.components, component);
		this.node.removeChild(component.node);
		// component.destroy();
		this.removeComponentView(component);
		component.parent = null;
	}
	

	ngOnInit(): void {
		this.script.load('js/html2canvas.js');
	}

	public getTagNames():string []
	{
		return ["Page"];
	}
	
	addToComponentsList(d: ROComponent):void
	{
		if(d.isDataComponent())
			this.dataComponents.push(d);
		this.components.push(d);
	}

	removeComponentFromList(d:ROComponent):void
	{
		ArrayUtils.removeElement(this.dataComponents, d);
		ArrayUtils.removeElement(this.components, d);
	}

	reorderComponents(d: ROComponent, option: string): void 
	{
		const index = this.components.indexOf(d);
		if (index === -1) return;

		if (option === "top") {
			// Move the child to the end of the array
			moveItemInArray(this.components, index, this.components.length - 1);
		} else if (option === "up" && index < this.components.length - 1) {
			// Move the child down by one position
			moveItemInArray(this.components, index, index + 1);
		} else if (option === "down" && index > 0) {
			// Move the child up by one position
			moveItemInArray(this.components, index, index - 1);
		} else if (option === 'bottom') {
			moveItemInArray(this.components, index, 0)
		}
	}

/*	addDataComponents(d: ROComponent):void{
		this.dataComponents.push(d);
	}*/
	
	getQuestionCount():number{
		return this.dataComponents.length;
	}
	
	public updatePageInfo():void
	{
		var pageScore:number = 0;
		var questionCount:number = 0;
		this.components.forEach((com:ROComponent)=>{
			if(com instanceof ROUnknownComponent)
			{
				var options:any = com.node.attributes;
				var isQuestion = options.isQuestionComponent;
				if(isQuestion)
				{
					var scoreEnable:boolean = false;
					if(options.s)
					{
						var scoreObject:any = JSON.parse(options.s);
						if(scoreObject.enable) scoreEnable = true;
					} else if(options.hasScoring)
					{
						scoreEnable = true;
					}
					if(scoreEnable)
					{
						questionCount++;
						pageScore += options.fullScore;
					}
				}
			} else if(com.isQuestionComponent())
			{
				if(com.sObj && com.sObj.getEnabled())
				{
					questionCount++;
					pageScore += com.getFullScore();
				}
			}
		});

		this.questionCount = questionCount;
		this.pageScore = pageScore;
	}

	private buildTemplate():void
	{
		var file:string = this.node.getAttribute("file");
		if(file)
		{
			var templateContainerElement:HTMLElement = document.createElement("div");
			var container:HTMLElement = this.elementRef.nativeElement;
			container.appendChild(templateContainerElement);
			this.context.renderTemplate(templateContainerElement, file);
		}
	}
	public build():void
	{
		this.page = this;
		super.build();
		var dom:HTMLElement = this.elementRef.nativeElement;
		var scale:number;
		var scale:number;
		let qSizeIndex:number = (this.context && this.context.config && this.context.config.setting) ? this.context.config.setting.qSize-1 : 2;
		scale = ComponentSettingConstant.numberBallSizeArray[qSizeIndex]/36;
		StyleUtils.setStyleVariable(dom, "question-ball-transform", "scale("+scale+")");

		let scoreSizeIndex:number = (this.context && this.context.config && this.context.config.setting) ? this.context.config.setting.scoreSize - 1 : 2;
		scale = ComponentSettingConstant.numberBallSizeArray[scoreSizeIndex]/36;
		StyleUtils.setStyleVariable(dom, "score-transform", "scale("+scale+")");
	}
	protected buildChildren():void
	{
		this.buildTemplate();
		

		super.buildChildren();
	}

	protected initVariables():void
	{
		super.initVariables();
	}

	protected initCoordinateExpression():void{
		
		// var ce:CoordinateExpression = this.parseCE(this.node.getAttribute("coordinateExpression"));
		this.w = parseInt(this.node.getAttribute("w"));
		this.h = parseInt(this.node.getAttribute("h"));
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.width = this.w+"px";
		dom.style.height = this.h+"px";
		// dom.style.backgroundColor = 
		var slideTitle:string = this.node.getAttribute("slideTitle");
		if(slideTitle) dom.setAttribute("title", slideTitle);
		dom.setAttribute("type", this.tagName);
		dom.setAttribute("questionCount", this.node.getAttribute("questionCount"));
	}
	
	// =========================
	// data function
	// =========================
	public isDataComponent():boolean
	{
		return true;
	}
	
	clearEpen() {
		this.noteLayer.reset();
		this.noteLayer.clear();
		
	}
	undoEPen() {
		this.noteLayer.undo();
	}
	protected moveNoteLayerToTop():void
	{
		this.elementRef.nativeElement.appendChild(this.noteLayerElemnt);
	}

	protected stringToObject(str:string):any
	{ 
		var bytes:ByteArray = this.uint8ArrayToBytes(Base64.toUint8Array(str));
		bytes.uncompress();
		bytes.position = 0;
		bytes.objectEncoding = 3;
		var encoder = new AMF3Encoder();
		var obj = encoder.decodeByteArray(bytes)
		// var obj = bytes.readObject();
		// bytes.writeObject(obj);
		return obj;
	}

	protected objectToString(obj:any):string
	{
		var encoder = new AMF3Encoder();
		var bytes:ByteArray = encoder.encodeToByteArray(obj);
		bytes.compress();
		return Base64.fromUint8Array(this.bytesToUint8Array(bytes)); 
	}
	public reset()
	{
		this.state = STATE_ANSWER_CHANGE;
		this.noteLayer.reset();
	}
	public set data(value:string) 
	{
		this.noteLayer.data = null;
		// clear verify and answer
		this.state = STATE_NO_ANSWER;
		if(value) {
			try {
				let obj:any = this.stringToObject(value);
				
				this.state = obj.state;
				if(obj.thumb && obj.thumb.ref) {
					this.ref = {
						key:this.douid,
						reference:obj.thumb.ref,
						index:0
					};
				}
				if(!obj.note.epen)
				{
					console.log("hi")
					this.noteLayer.data = null;
				} else if (obj.note) {
					// assign note and read should be the same
					// 
					// this.noteLayer.testData(obj.note);
					this.noteLayer.data = obj.note;
					this.moveNoteLayerToTop();
				} else {
					this.noteLayer.data = null;
				}
			} catch(e) {
				console.log("=========  page data error  =========");
				console.log(value);
				console.error(e);
			}
		} else {
			this.noteLayer.data = null;
		}
	}

	public get data():string
	{
		return this.state !== STATE_NO_ANSWER || this.noteLayer.hasSteps() ?  this.objectToString({
			thumb:this.ref ? {
				key:this.ref.key, reference:this.ref.reference, index:this.ref.index
			} : null, 
			note:this.noteLayer.data, 
			state:this.state
		}) : null;
	}

	public capture_full(scale: number = 1, options: { target?: string, onclone?: Function, ignoreElements?:Function, padding?:number } = {}): Promise<File> {
		return new Promise((resolve, reject) => {
			const target: HTMLElement = this.elementRef.nativeElement.closest(options.target || 'ro-page');

			let width = this.node.attributes.w;
			let height = this.node.attributes.h;
			if (options.padding) {
				width += options.padding * 2;
				height += options.padding * 2;
			}

			return html2canvas(target, {
				useCORS: true, allowTaint: true, scale: scale, width, height,
				onclone: (doc) => {
					let page_container = doc.querySelector('.center-page-container');
					page_container.style.transform = "";
					if (options.onclone) {
						options.onclone(doc);
					}
				},
				ignoreElements: (element) => {
					if (options.ignoreElements) {
						return options.ignoreElements(element);
					}
					return false;
				}
			}).then(canvas => {
				canvas.toBlob(blob => {
						let image_file: File = new File([blob], 'page.png', {
							type: 'image/png'
						});

						resolve(image_file);
					},
					'image/png',
					1
				);
			});
		});
	}

	public capture(pageScale:number):Promise<any> {
		return new Promise((resolve, reject) => {
			const target:HTMLElement = this.elementRef.nativeElement.parentNode;

			let w:number = this.node.attributes.w*pageScale;
			let h:number = this.node.attributes.h*pageScale;

			return html2canvas(target,{
				useCORS: true, allowTaint: true, scale:1, width:w, height:h,
				onclone: (doc) => {
					let page_container = doc.querySelector('.center-page-container');
					page_container.style.transform = "";
				},
			}).then(canvas => {
				let context = canvas.getContext('2d');
				let newCanvas:HTMLCanvasElement = document.createElement("canvas");
				newCanvas.width = 160;
				newCanvas.height = 120;
				let newContext = newCanvas.getContext('2d');
				// 只 fit width
				let starty:number = (120 - canvas.height * 160 / canvas.width)/2;
				newContext.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, starty, 160, 120);

				//let div:HTMLDivElement = document.createElement("div");
				//div.appendChild(newCanvas);
				//div.appendChild(canvas);
				//div.style.marginTop = "300px";
				//this.elementRef.nativeElement.appendChild(div);

				try {
					newCanvas.toBlob(blob => {
						let thumnail:File = new File([blob], 'page-thumbnail.png');
						if(this.ref == null) {
							this.ref = {
								key:this.douid,
								reference:this.context.createReference(),
								file:thumnail,
								index:0
							};
						} else {
							this.ref.reference = this.context.createReference();
							this.ref.file = thumnail;
						}
	
						resolve([this.ref]);
					});
				} catch (error) {
					console.log(error);
					reject(error);
				}
			});
		});
	}

	///////
	public activate():void
	{
		this.reattach();
		this.components.forEach((c:ROComponent)=>{
			c.activate();
			c.reattach();
		})
	}

	public deactivate():void
	{
		this.detach();
		this.components.forEach((c:ROComponent)=>{
			c.deactivate();
			c.detach();
		})
	}

	
	public destroy(): void {
		if(this.components)
		{
			this.components.forEach((c:ROComponent)=>{
				c.destroy();
			});
		}
		super.destroy();
	}
	isContentReady():Promise<any>
	{
		return Promise.all(
			this.components.map((component:ROComponent)=>{
				return component.isContentReady().then(()=>{
					// console.log(component.node.tag, "ready");
				}).catch((reason)=>{
					console.log(component.node.tag, "failed:", reason);
				});
			})
		).then((o:any)=>{
			return "ready";
		});
	}

	isDataContentReady(): Promise<any> {
		var notReadyComponents:ROComponent[] = this.components;
		return new Promise((resolve, reject)=>{
			var checkData = ()=>{
				notReadyComponents = notReadyComponents.filter((component:ROComponent)=>{
					return component.dataContentReady() ? false : true;
				})
				if(this.destroyed)
				{
					reject(null);
				} else {
					if(notReadyComponents.length > 0)
					{
						setTimeout(()=>{
							checkData();
						}, 500);
					} else {
						resolve(null);
					}
				}
			}
			checkData();
		});				
	}

}

AllROComponents.push(ROPageComponent);

///////////////

export class ROColorUtils
{
	public static formatColor(dColor:any, defaultValue:string = null):string{
		var valueType = typeof dColor;
		if(valueType== 'string')
		{
			if(dColor === "noColor")
			{
				return defaultValue;
			} else {
				return dColor;
			}
		} else if(typeof dColor == 'number'){
			var code = ( dColor & 0xFFFFFF);
			return '#' + ("000000" + (code).toString(16)).slice(-6);
		} else {
			return "";
		}
	}
}

export class Base64ObjectUtils
{
	public static base64Encode(obj:any):any
	{
		var bytes:ByteArray = new ByteArray();
		bytes.writeUTFBytes(JSON.stringify(obj));
		bytes.compress();
		return Base64.fromUint8Array(this.bytesToUint8Array(bytes)); 
	}
	
	public static base64Decode(str:any):any
	{
		var bytes:ByteArray = Base64ObjectUtils.uint8ArrayToBytes(Base64.toUint8Array(str));
		bytes.uncompress();
		bytes.position = 0;
		bytes.objectEncoding = 3;
		var json:string = bytes.readUTFBytes(bytes.length);
		return JSON.parse(json);
	}

	private static uint8ArrayToBytes(u8s:Uint8Array):ByteArray
	{
		var bytes:ByteArray = new ByteArray();
		u8s.forEach(b => bytes.writeByte(b));
		bytes.position = 0;
		return bytes;
	}

	private static bytesToUint8Array(bytes:ByteArray):Uint8Array
	{
		var len = bytes.length;
		var array:any [] = [];
		bytes.position = 0;
		for(var i = 0;i < len;i++)
		{
			array.push(bytes.readUnsignedByte());
		}
		return new Uint8Array(array);
	}
}



@Component({
	template:`<div class="label" [style.fontSize.px]="fontSize">{{text}}</div>`,
	styles:[
		`
		`
	]
	/**
	{
		"douid": "87C6D769-AB36-8592-CB0F-126C84C5CC73",
		"coordinateExpression": "UA UK X 137 Y 229 D T 229 L 137 H 44 W 37.759765625",
		"hasScoring": true,
		"scoringType": 1,
		"scoreN": 1,
		"unitScore": 1,
		"fullScore": 1,
		"locked": false
	}
	 */
})

export class ROLabel extends ROComponent
{
	public text:string = "DefaultLabel";
	public fontSize:number = 32;
	public visible:boolean = true;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["Label"];
	}
}


class ComponentSettingConstant
{
	public static numberBallSizeArray:number[] = [22, 29, 36, 44, 52];
}