import { ApplicationRef, ComponentFactoryResolver, ElementRef, Injectable, Injector, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
// import { TranslateService } from "@ngx-translate/core";
import { Observable, ReplaySubject, Subject, Subscriber, Subscription, fromEvent } from "rxjs";
// import { FileUtils } from "src/app/common/FileUtils";

import { DynamicComponentService } from "src/app/service/dynamicComponent.service";

@Injectable({ providedIn: 'root' })
export class PrintService {
	public isPrinting:boolean = false;
	public mode:string;
	constructor(
		private router: Router
	) 
	{
		
    }
	private createPrintTask(options:any):PrintTask
	{
		var task:PrintTask = new PrintTask();
		var subscription:Subscription = new Subscription( () =>{
			this.isPrinting = false;
			options.end(task);
		});
		subscription.add(
			task.subject.subscribe((o:any)=>{
				if(o.type == "start")
				{
					
				} else if(o.type == "ready")
				{
					
				} else if(o.type == "print")
				{
					
					window.print();
					subscription.unsubscribe();
				} else if(o.type == "cancel")
				{
					subscription.unsubscribe();
				}
			})
		);
		this.isPrinting = true;
		options.start(task);
		return task;
	}
	printRoute(routes: string[]):PrintTask
	{
		this.mode = "router";
		return this.createPrintTask(
			{
				start:(task:PrintTask)=>{
					this.router.navigate(
						[
							'/', 
							{ 
								outlets: {
									  'print': routes //  ['print', documentName, documentData.join()]
								}
							}
						]
					);
				}, 
				end:(task:PrintTask)=>{
					this.router.navigate([{ outlets: { print: null }}]);
				}
			}
		);
  	}
  

	// -> 出 windows and print
	printComponent(
		container:HTMLElement,
		dcs:DynamicComponentService, 
		componentClass:any, 
		componentOption:any, 
		options:PrintOption
	) :PrintTask{
		this.mode = "container";
		/*
		this.waitFocus().then(()=>{
			this.dcs.close(ref);
		})
		*/
		return this.createPrintTask(
			{
				start:(task:PrintTask)=>{
					var ref = dcs.create(componentClass, componentOption);
					dcs.open(ref, container);
					task.reference = ref;
				}, 
				end:(task:PrintTask)=>{
					dcs.destroy(task.reference);
					// dcs.close(task.reference);
				}
			}
		);
	}

    printDOM(dom:HTMLElement, options:PrintOption):void
	{

	}
    
	printHTML(html:string, options:PrintOption):PrintTask
	{
		return this.createPrintTask(
			{
				start:(task:PrintTask)=>{
					var div:HTMLElement = document.createElement("div");
					div.classList.add("print-content");
					div.innerHTML = html;
					document.body.appendChild(div);
					task.reference = div;
				}, 
				end:(task:PrintTask)=>{
					var div:HTMLElement = task.reference;
					if(div.parentElement) div.parentElement.removeChild(div);
				}
			}
		);

		this.printHTMLInIframe(html);
	}

	private printHTMLInIframe(html:string):void
	{

		/*
		let iframe = document.createElement("iframe");
		iframe.style.position = "absolute";
		iframe.style.left = "-300px";
		iframe.style.top = "0px";
		iframe.style.width = "400px;"
		iframe.style.height = "1024px;"
		// iframe.style.display = "none";
		document.body.appendChild(iframe);
		let iframeContentWindow = iframe.contentWindow;

		iframe.focus();
		iframeContentWindow.focus();
		iframeContentWindow.document.body.onload = () => {
			iframeContentWindow.print();
			// iframeContentWindow.printPage();
		};
		iframeContentWindow.document.open();
		iframeContentWindow.document.write(html);
		iframeContentWindow.document.close();
		setTimeout(()=>{
			iframeContentWindow.print();
			
		}, 1000)
		
		this.waitFocus().then(()=>{
			if(iframe.parentElement) iframe.parentElement.removeChild(iframe);
			console.log("removing iframe");
		})
		*/
	}
	private printHTMLInWindow(html:string):void
	{

	}
	/*
	private waitFocus():Promise<any>
	{		
		var sub: Subscription = new Subscription();
		return new Promise((resolve, reject)=>{
			setTimeout(()=>{
				var events:string [] = ["mousedown", "pointerdown", "focus"];
				events.forEach((event:string)=>{
					sub.add(fromEvent(window.document.body, event).subscribe((o: any) => {
						console.log(o.type)
						resolve(o);
					}));
				})
				
			}, 1000);
		}).then((o:any)=>{
			sub.unsubscribe();
			return o;
		})
		
	}
	*/

}
export interface PrintOption
{

}
export class PrintTask
{
	public subject:Subject<any>;
	public reference:any;
	constructor()
	{
		this.subject = new ReplaySubject(1, 1);
	}

	public start():void
	{
		this.subject.next({type:"start", task:this});
	}

	public update(message:string):void
	{
		this.subject.next({type:'message', message:message});;
	}

	public cancel():void
	{
		this.subject.next({type:"cancel"});
	}

	public ready():void
	{
		this.subject.next({type:'ready'});
	}

	public print():void
	{
		this.subject.next({type:'print'});
	}
}