import { ChangeDetectorRef, Component, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";
import {  ROComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";
///////////////
@Component({
	template:`
		
		<div class="bg">
			<div class="layout"></div>
			<div class="left"></div>
			<div class="center"></div>
			<div class="right"></div>
		</div>
		
		
	`,
	styleUrls:["./ROHeading.scss"]
})

export class ROHeading extends ROComponent
{
	// iconType:string;
	// showIcon:boolean = false;
	tlf: ROComponent;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["Heading"];
	}
	protected buildContent():void
	{
		// {"ver":1,"style":"style1_F0535A","icon":"icon_1",
		// "autoWidth":true,
		// "coordinateExpression":"UA UK KH X 20 Y 28 D T 28 L 20 H 70.05 W 231",
		// "hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,
		// "fullScore":1,"locked":false,
		// "douid":"20508E46-FD06-2E11-183C-7687BE7C6ABE","x":20,"y":28}
		var tlfNode:XMLNode = this.node.children[0];
		this.tlf = this.context.createForEF(this.elementRef, tlfNode, this.page, this);
		var options:any = this.node.attributes;
		var dom:HTMLElement = this.elementRef.nativeElement;
		var parts:string [] = options.style.split("_");
		var style:string = parts[0];
		// var color:string =  "#"+ parts[1];
		dom.classList.add(style);
		dom.classList.add(options.style);
		dom.classList.add(options.icon);
		var newW:number = this.tlf.w + this.tlf.x + 22;
		if(newW > this.w)
		{
			this.w = newW;
			this.assignLTWH();
		}
	}
}

@Component({
	template:`
		<div class="icon"><div class="text-container"><div class="text">{{text}}</div></div></div>
	`,
	styleUrls:[`ROSubHeading.scss`]
})

export class ROSubHeading extends ROComponent
{
	// iconType:string;
	// showIcon:boolean = false;
	tlf: ROComponent;
	public color:string;
	public text:string;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["SubHeading"];
	}
	protected buildContent():void
	{
		/**
			{
			    "ver": 1,
			    "style": "style1_F0665F",
			    "label": "A",
			    "fontSize": 32,
			    "color": "#ffffff",
			    "fontFamily": "Noto Sans T Chinese Regular",
			    "autoWidth": false,
			    "douid": "C1CF910E-CF9B-82C0-639A-18FE43C18AB1",
			    "coordinateExpression": "UA UK KH X 449 Y 86 D T 86 L 449 H 43 W 200",
			    "hasScoring": true,
			    "scoringType": 1,
			    "scoreN": 1,
			    "unitScore": 1,
			    "fullScore": 1,
			    "locked": false,
			    "x": 449, "y": 86
			}
		 */
		var tlfNode:XMLNode = this.node.children[0];
		this.tlf = this.context.createForEF(this.elementRef, tlfNode, this.page, this);
		var options:any = this.node.attributes;
		var dom:HTMLElement = this.elementRef.nativeElement;
		var parts:string [] = options.style.split("_");
		var style:string = parts[0];
		this.color =  "#"+ parts[1];
		// StyleUtils.setStyleVariable(dom, "style-color", color);

		dom.classList.add(style);
		dom.classList.add(options.style);
		var iconOffsetY:number = options.fontSize > 20 ? (options.fontSize - 20 ) / 2 : 0;

		StyleUtils.setStyleVariable(dom, "icon-text", options.label ? JSON.stringify(options.label) : '" "');
		StyleUtils.setStyleVariable(dom, "icon-font-size", options.fontSize +"px");
		StyleUtils.setStyleVariable(dom, "icon-font-color", options.color);
		// StyleUtils.setStyleVariable(dom, "icon-font-family", this.formatFontFamily(options.fontFamily));
		dom.classList.add(this.formatFontFamily(options.fontFamily));
		if(iconOffsetY > 0) StyleUtils.setStyleVariable(dom, "icon-offset", `translate(0px, ${iconOffsetY}px)`);
		this.text = this.node.attributes.label;

		

		/*
		var icon = document.createElement("div");
		dom.appendChild(icon);
		// <!--{"ver":1,"style":"style2_F0665F","label":"★","fontSize":26,"color":"#ffffff","fontFamily":"Noto Sans T Chinese Regular","autoWidth":false,"douid":"C1CF910E-CF9B-82C0-639A-18FE43C18AB1","coordinateExpression":"UA UK KH X 459 Y 152 D T 152 L 459 H 37 W 246.37681159420288","hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,"locked":false,"x":459,"y":152}-->
		var offsetY:number = options.fontSize > 20 ? (options.fontSize - 20 ) / 2 : 0;
		icon.outerHTML = `
			
			<div
				style="
				background-image: url('/assets/img/component/component_sub_heading.svg?id=style2_F0665F#style2_F0665F');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 32px 32px;
				width: 32px;
				height:32px;
				top: 0px;
				left: 0px;
				position: absolute;
				
				"
				></div>
			<div 
				style="
				position: absolute;
				left:7.5px;
				top:6.25px;
				height:20px;
				width:20px;
				line-height: 20px;
				font-size:${options.fontSize}px;
				font-family: Noto-Sans-T-Chinese-Regular;
				text-align: center;
				color: ${options.color};

				display: flex; 
				justify-content: center; 
				"
			class="text">${options.label}</div>

		`
		*/
		
		
	}
	private formatFontFamily(fontFamily:string):string
	{
		return fontFamily.replace(/[ ]/g, "-");
		if(fontFamily == "Noto Sans T Chinese Regular")
		{
			return ""
		}
	}
}