import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { ROComponent, ROContainerComponent, ROPageComponent, ROTLFTextComponent } from "./ROComponent";
import { XMLNode } from "./xml/XMLNode";
import { StyleUtils } from "./StyleUtils";
import { Subscription } from "rxjs";
import { XMLJSNode } from "./xml/XMLParser";

@Component({
	template:`
		<div class="icon selected"></div>
		<div [class.selected]="bubbleContainer && bubbleContainer.expand" class="icon" (click)="toggle()"></div>
	`,
	styles:[`
		:host{
			z-index:var(--popup-button-z-index);
			position:absolute;
		}
		.icon{
			position:absolute;
			left:0px;
			top:0px;
			width:100%;
			height:100%;
		}
		.icon{
			background-image:var(--icon-normal-bg);
		}
		.icon.selected{
			background-image:var(--icon-down-bg);
		}
	`]
})

export class ROPopupNotePinContainer extends ROComponent 
{
	bubbleContainer: ROPopupNoteBubbleContainer;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public toggle(): void 
	{
		if(this.context.answerEnabled && this.context.config.viewMode !== "edit")
		{
			var options:any = this.node.attributes;
			var p:Promise<any> =  !this.bubbleContainer.expand && options.needPwd ? this.requestPopupPassword() : Promise.resolve(1);
			p.then(()=>{
				this.bubbleContainer.expand = !this.bubbleContainer.expand;
			});
		}
	}

	private requestPopupPassword():Promise<any>
	{
		var page:ROPageComponent = <ROPageComponent> this.page;
		var chapterOptions:any = page.chapter.node.attributes;
		var password:string = chapterOptions.popupPwd +"";
		if(!password) return Promise.resolve("no password is required");
		
		return this.context.service.numberPadService.promptAsPromise(
			this.context.service.translateService.instant("ro.components.common.popup-password"), ""
		).then((pwd:string)=>{
			if(pwd.trim() == password.trim())
			{
				return Promise.resolve("OK");
			} else {
				this.context.service.alertService.alert("ro.components.common.invalid-password", null, null, true);
				return Promise.reject("Incorrect Popup password");
			}
		});
	}
	protected initCoordinateExpression():void{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var style = dom.style;
		var options:any = this.node.attributes;
		this.x = options.pinX;
		this.y = options.pinY;
		
		this.w = options.pinW;
		this.h = options.pinH;

		style.left = this.x + "px";
		style.top = this.y + "px";
		
		style.width = this.w + "px";
		style.height = this.h + "px";

		
	}

	public getTagNames(): string[] {
		return  ["PopupNotePinContainer"];
	}
	public pinStyle:any ;// "component_notes_btn_bag|component_notes_btn_bag_press|152|54"
	
	protected buildContent(): void {
		var dom:HTMLElement = this.elementRef.nativeElement;
		var options:any = this.node.attributes;
		if(options.pinStyle == "empty")
		{
			this.pinStyle = {
				normal:"",
				down:"",
				width:this.w,
				height:this.h
			};
		} else {
			var items:string[] = options.pinStyle.split("|");
			if(items.length == 3)
			{
				this.pinStyle = {
					normal:items[0],
					down:items[0],
					width:items[1],
					height:items[2]
				};
			} else {
				this.pinStyle = {
					normal:items[0],
					down:items[1],
					width:items[2],
					height:items[3]
				};
			}
			
		}
		/**
		 * 	empty
			btn_icon_mag|60|60
			btn_icon_know|184|63
			component_notes_btn_know|component_notes_btn_know_press|184|54
			btn_icon_remember|184|63
			component_notes_btn_remember|component_notes_btn_remember_press|184|54
			btn_icon_think|154|63
			component_notes_btn_think|component_notes_btn_think_press|152|54
			component_notes_btn_bag|component_notes_btn_bag_press|152|54
			component_notes_btn_ans|component_notes_btn_ans_press|128|54
			component_notes_btn_note|component_notes_btn_note_press|128|54
			component_notes_btn_trans|component_notes_btn_trans_press|128|54
		 * 
		 */
		if(options.pinStyle == "empty")
		{
			StyleUtils.setStyleVariable(dom, "icon-down-bg", "");
			StyleUtils.setStyleVariable(dom, "icon-normal-bg", "");
		} else {
			StyleUtils.setStyleVariable(dom, "icon-down-bg", "url(./assets/ro/theme/"+this.pinStyle.down+".svg)");
			StyleUtils.setStyleVariable(dom, "icon-normal-bg", "url(./assets/ro/theme/"+this.pinStyle.normal+".svg)");	
		}
		
	}
}

@Component({
	template:`
		<div 
			[style.width.px]="w" 
			[style.height.px]="h" 
			[class.show-popup]="displayBase" class="popup-container">
			<div class="popup-shadow">
				<div class="bg"></div>
				<div class="arrow-container">
					<div class="arrow-border"></div>
				</div>
			</div>
			<div class="front">
				<div class="bg"></div>
				<div class="arrow-container">
					<div class="arrow-border"></div>
					<div class="arrow"></div>
				</div>
			</div>
			<div class="close_btn button" (tap)="closePopup()"></div>
		</div>
	`,
	styles:[`

		.close_btn:active{
			transform: translate(0px, 2px);
		}
		.close_btn{
			position:absolute;
			right:0px;
			top:0px;
			height:46px;
			width:46px;
			background-image:url(./assets/img/component/popup_close.svg);
		}
		.popup-container{
			display:none;
		}
		.popup-container.show-popup{
			display:block;
		}
		:host{
			z-index:var(--popup-z-index);
			position:absolute;
			opacity:0;
			transition: transform 0.3s, opacity 0.3s;
			transform:scale(0);
		}
		:host.show{
			opacity:1;
			transform:scale(1);
		}
		
		:host.top{
			transform-origin:top;
		}
		:host.bottom{
			transform-origin:bottom;
		}
		:host.top.left{
			transform-origin:top left;
		}	
		:host.top.right{
			transform-origin:top right;
		}
		:host.bottom.left{
			transform-origin:bottom left;
		}	
		:host.bottom.right{
			transform-origin:bottom right;
		}
		:host.top .arrow-container{
			-ms-transform:rotate(180deg);
			transform:rotate(180deg);
		}

		:host.bottom .arrow-container
		{
			bottom: 2px;
		}
		:host.top .arrow-container
		{
			top: 2px;
		}
		
		:host.left .arrow-container
		{
			left: 50px;
		}
		:host.right .arrow-container
		{
			left: calc(100% - 50px);
		}
		.popup-container{
			left:0px;
			top:0px;
			position:absolute;
			width:100%;
			height:100%;
		}
		.bg{
			left:0px;
			top:0px;
			position:absolute;
			width:100%;
			height:100%;
			border-radius:10px;
		}
		.popup-shadow, .front{
			width:100%;
			height:100%;
			left:0px;
			top:0px;
			position:absolute;
		}
		.popup-shadow{
			transform:translate(4px, 4px);
		}
		.front .bg{
			
			background-color:#FCF9D7;
			border:solid 2px #E5C882;
		}
		.popup-shadow{
			opacity:0.15;
			
		}
		.popup-shadow .bg{
			background-color:#000000;
		}
		
		.arrow-container
		{
			position: absolute;
			left:50%;
		}
		

		.arrow-border{
			content:" ";
			display:block;
			width: 0px;
			height: 0px;
			position: absolute;
			left: 0px;
			top: 0px;
			transform:translate(-14px, 0px);
			border-left: 14px solid transparent;
			border-right: 14px solid transparent;
			border-top: 14px solid #E5C882;
		}
		.arrow{
			
			width: 0px;
			height: 0px;
			position: absolute;
			left: 0px;
			top: 0px;
			transform:translate(-12px, -2px);
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-top: 12px solid #FCF9D7;
		}

		.popup-shadow .arrow-border{
			content:" ";
			display:block;
			width: 0px;
			height: 0px;
			position: absolute;
			left: 0px;
			top: 0px;
			transform:translate(-14px, 0px);
			border-left: 14px solid transparent;
			border-right: 14px solid transparent;
			border-top: 14px solid #000000;;
		}
		
	`]
})

export class ROPopupNoteBubbleContainer extends ROContainerComponent 
{
	private _expand: boolean = false;
	public popupDirection:string;
	public pinAlign: string;
	public displayBase:boolean;
	public subscription:Subscription;
	public popup:ROPopupNote;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	protected initCoordinateExpression():void{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var style = dom.style;
		var options:any = this.node.attributes;
		this.x = options.noteX;
		this.y = options.noteY;
		
		this.w = options.noteW;
		this.h = options.noteH;

		style.left = this.x + "px";
		style.top = this.y + "px";
	}

	public getTagNames(): string[] {
		return  ["PopupNoteBubbleContainer"];
	}

	
	public set expand(value:boolean)
	{
		this._expand = value;
		
		if(value)
		{
			this.popup.moveToTop();
			var options:any = this.node.attributes;
			this.context.subject.next({type:"notify", notify:{type:"popupNote", expand:"true", options:options}});
			this.onExpand();
		} else {
			
			this.onCollapse();
		}
		setTimeout(()=>{
			var dom:HTMLElement = this.elementRef.nativeElement;
			if(this._expand) dom.classList.add("show");
			else dom.classList.remove("show");
		}, 100);
	}
	public get expand():boolean
	{
		return this._expand;
	}

	
	public onExpand()
	{
		
		if(this.subscription) this.subscription.unsubscribe();
		this.subscription = this.context.subject.subscribe((data)=>{
			if(data.type == "notify" && data.notify.type == "popupNote")
			{
				if(data.notify.options.masterPopup && data.notify.options.douid != this.node.attributes.douid)
				{
					if(this._expand)
					{
						this.expand = false;
					}
				}
			}
		});

	}
	public onCollapse()
	{
		if(this.subscription) this.subscription.unsubscribe();
		this.subscription = null;
	}
	
	public closePopup():void
	{
		this.expand = false;
	}
	
	protected buildContent(): void {
		var dom:HTMLElement = this.elementRef.nativeElement;
		var options:any = this.node.attributes;
		this.displayBase = options.displayBase;
		
		this.popupDirection = options.popupDirection.toLowerCase();
		this.pinAlign = options.pinAlign.toLowerCase();
		
		dom.classList.add("popup-direction-"+this.popupDirection);
		var pt:any = {x:0, y:0};
		if(this.popupDirection.indexOf("up") != -1) 
		{
			dom.classList.add("bottom");
			pt.y = this.h;
		} else if(this.popupDirection.indexOf("down") != -1)
		{
			dom.classList.add("top");
			pt.y = 0;
		}
		if(this.pinAlign.indexOf("left") != -1) {
			dom.classList.add("left");
			pt.x = this.w / 10;
		} else if(this.pinAlign.indexOf("right") != -1) {
			dom.classList.add("right");
			pt.x = this.w * 0.9;
		} else {
			pt.x = this.w * 0.5;
		}
		dom.style.transformOrigin = `${pt.x}px ${pt.y}px`;
	}
}
@Component({
	template:`
		`
		
		,
	styles:[
		`
			
		`
	]
})

export class ROPopupNote extends ROComponent
{
	
	protected _bubbleContainer:ROPopupNoteBubbleContainer;
	protected _pinContainer:ROPopupNotePinContainer;
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	moveToTop() {
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.parentElement.appendChild(dom);
	}

	getBoundingDOM():HTMLElement
	{
		return this._pinContainer.getReferenceDOM();
	}

	getComponentRectangle():any
	{
		return {
			x:this._pinContainer.x,
			y:this._pinContainer.y,
			width:this._pinContainer.w,
			height:this._pinContainer.h
		};
	}

	getReferenceDOM():HTMLElement
	{
		return this._pinContainer.getReferenceDOM();
	}

	public activate(): void {
		
		
	}
	public deactivate(): void {
		
		this._bubbleContainer.expand = false;
	}
	assignLTWH() {
		super.assignLTWH
		var dom:HTMLElement = this.elementRef.nativeElement;
		var style:CSSStyleDeclaration = dom.style;
		style.left = this.x + "px";
		style.top = this.y + "px";
	}

	public getTagNames(): string[] {
		return  ["PopupNote2"];
	}

	protected buildContent(): void {
		var pinContainerNode = this.node.children.filter((node:XMLNode)=>{
			return node.tag == "Container" && node.attributes.coordinateExpression;
		})[0];
		/*
		var popupNodeContainerNode = this.node.children.filter((node:XMLNode)=>{
			return node.tag == "Container" && !node.attributes.coordinateExpression;
		})[0];
		*/
		var children = this.node.children.filter((node:XMLNode)=>{
			return node.tag != "Container";
		})
		var node:XMLNode
		node = new XMLJSNode().assign(
			{
				tag:"PopupNoteBubbleContainer",
				attributes:this.node.attributes,
				children:children
			}
		);
		this._bubbleContainer = this.context.createForEF(this.elementRef, node, this.page, this);
		this._bubbleContainer.popup = this;
		node = new XMLJSNode().assign(
			{
				tag:"PopupNotePinContainer",
				attributes:this.node.attributes
			}
		);
		this._pinContainer = this.context.createForEF(this.elementRef, node, this.page, this);
		this._pinContainer.bubbleContainer = this._bubbleContainer;


		/**
		
		{
		    "ver": 1.2,
		    "popupNoteMode": "MODE_FREE_STYLE",
		    "displayBase": false,
		    "pinStyle": "btn_icon_mag|60|60",
		    "autoHide": false,
		    "popupDirection": "DOWN",
		    "pinAlign": "LEFT",
		    "freePosition": true,
		    "noteX": 0,
		    "noteY": 78,
		    "noteW": 384.632919879316,
		    "noteH": 328.21929635225564,
		    "pinY": 0,
		    "pinX": 20,
		    "needPwd": false,
		    "coordinateExpression": "UA UK KW KH X 39 Y 49 D T 49 L 39 H 406.21929635225564 W 384.632919879316",
		    "hasScoring": true,
		    "scoringType": 1,
		    "scoreN": 1,
		    "unitScore": 1,
		    "fullScore": 1,
		    "expand": false,
		    "masterPopup": false,
		    "pinW": 60,
		    "pinH": 60,
		    "locked": false,
		    "douid": "90765F68-928F-06B5-4334-01748E14410E"
		}
		{
			"ver":1.2,
			"popupNoteMode":"MODE_FREE_STYLE",
			"displayBase":true,"pinStyle":"component_notes_btn_note|component_notes_btn_note_press|128|54",
			"autoHide":false,
			"popupDirection":"UP",
			"pinAlign":"MIDDLE",
			"freePosition":true,"noteX":0,"noteY":0,
			"noteW":405.1231097862947,"noteH":360.55235312505295,"pinY":407,"pinX":143,
			"needPwd":true,
			"coordinateExpression":"UA UK KW KH X 311 Y 213 D T 213 L 311 H 461 W 405.1231097862947",
			"hasScoring":true,"scoringType":1,"scoreN":1,"unitScore":1,"fullScore":1,
			"expand":true,
			"masterPopup":true,
			"pinW":128,"pinH":54,"locked":false,
			"douid":"8FBD5C61-C363-7018-BB19-A6A7F5E61400"}
		
		*/

		/*
		var div:HTMLElement = document.createElement("div");
		div.innerText = JSON.stringify(this.node.attributes);
		this.elementRef.nativeElement.appendChild(div);
		*/
		/*
		console.log("test:ArrayUtils.randomize");
		console.log(ArrayUtils.randomize([1,2,3,4]));
		console.log(ArrayUtils.randomize([1,2,3,4]));
		console.log(ArrayUtils.randomize([1,2,3,4]));
		console.log(ArrayUtils.randomize([1,2,3,4]));
		*/
	}
}