import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { Base64ObjectUtils, ROComponent, ROPageComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";
import { TextFlowUtils } from "./TextFlowUtils";
import { RecorderAndPlayer } from "./SoundRecorder";
import { PromiseUtils } from "src/app/common/PromiseUtils";
import * as Diff from 'diff'
import { CharacterType } from "./CharacterType";
import { STTTokenizer } from "./text/STTTokenizer";
import { ROContext, VocabDictionaryDatabase } from "./ROContext";
import { IAudioPlayer } from "./TTSManager";
import { ChineseTextHelper } from "src/app/common/ChineseTextHelper";
 

@Component({
	selector: 'html-container2',
	template:`
		<div #content class="html-container"></div>
	`,
	styles:[
		`
		.html-container{
			word-break: break-word;
		}   
		`
	]
})


export class HTMLContainer2 implements OnInit, AfterViewInit, OnChanges
{
	@ViewChild('content', { static: false }) public content:any;
	@Input()public html:any;
	constructor()
	{
		
	}
	ngOnChanges(changes: SimpleChanges): void {
		this.updateHTML();
	}

	ngOnInit(): void {
		
	}

	ngAfterViewInit(): void 
	{
		this.updateHTML();	
	}
	private updateHTML():void
	{
		if(this.content) this.content.nativeElement.innerHTML = this.html;
	}

	
}

@Component({
	template:`
		
		<div class="container" 
			[class.correct]="correctState == 2"
			[class.partial-correct]="correctState == 1"
			[class.incorrect]="correctState == 0"
			>
			<div 
				class="speaker button enabled" 
				[class.hidden]="ctx.showSpeaker === false"
				[class.not-visible]="myRecorder.state !== 'idle' " 
				(tap)="speak()">
			</div>
			<ng-container class="item" *ngFor="let item of items">
				<div 
					class="item question stt textflow" 
					*ngIf="item.type == 'question'"
					>
					<html-container2 [html]="item.html"></html-container2>
				</div>
				<div 
					[ngClass]="['item', fontFamily]"
					*ngIf="item.type == 'answer'"
					>
					<div class="item-content answer">

						<ng-container *ngIf="!defaultAnswer && evaluationList">
							<span *ngFor="let element of evaluationList"
								[class.text-correct]="element.correct == 1"
								[class.text-incorrect]="element.correct == 0"
							>{{element.text}}</span>
						</ng-container>

						<span *ngIf="defaultAnswer" >
							{{correctAnswer}}
						</span>
						

						<ro-verify-icon class="verify-icon" [correctState]="correctState"></ro-verify-icon>
					</div>
				</div>
				<div class="item space" *ngIf="item.type == 'space'" [style.width.px]="item.size"></div>
			</ng-container>
			<div class="flex" *ngIf="myRecorder && !processing">
				<div *ngIf="myRecorder.state == 'idle'" 
					class="mic button" 
					[class.enabled]="context.answerEnabled"
					(tap)="record()"></div>
				<ng-container
					*ngIf="myRecorder.state == 'recording'" 
					>
					<div 
						class="recorder-stop stop-button button" 
						(tap)="finishRecording()"></div>
					<div 
						class="recording time-label" 
						>
						{{myRecorder.recordingTime | date:'mm:ss'}}</div>
				</ng-container>

				
				<div 
					*ngIf="myRecorder.state == 'idle'" 
					class="play button enabled" 
					[class.has-recording]="myRecorder.hasRecording"
					[class.disabled]="!myRecorder.hasRecording"
					(tap)="play()"></div>
				<ng-container *ngIf="myRecorder.state == 'playing'" >
					<div 
						class="playing time-label" 
						>
						{{myRecorder.recordingTime | date:'mm:ss'}}
					</div>
					<div 
						class="player-stop stop-button button enabled" 
						(tap)="stop()"></div>
				</ng-container>
			
			</div>
			<div 
				class="loading" *ngIf="processing" >
			</div>
			<div 
				class="percent" 
				[class.perfect]="myData.percent == 100"
				[class.position-end]="resultPosition == 'end'"
				[class.position-question]="resultPosition == 'quesiton'"
				[class.position-none]="resultPosition == 'none'"
				[class.pass]="myData.percent > ctx.passLevel"
				[class.failed]="myData.percent <= ctx.passLevel"
				*ngIf="ctx.showPercent && myData && evaluated">
				<div class="percent-bg">
					<svg 
						height="50px" width="50px" 
						xmlns:xlink="http://www.w3.org/1999/xlink" 
						xmlns="http://www.w3.org/2000/svg" overflow="visible">
						<path 
							class="fill"  	
							fill-rule="evenodd" stroke="none"
							d="M44.8 5.2q3.7 3.7 4.5 8.8 1.5 11 0 22-.8 5.1-4.5 8.8-3.8 3.7-8.8 4.5-11 1.5-22 0-5.1-.8-8.8-4.5Q1.5 41.1.7 36q-1.4-11 0-22 .8-5.1 4.5-8.8Q8.9 1.5 14 .7q11-1.4 22 0 5 .9 8.8 4.5" />
						<path 
							class="border"
							d="M44.8 5.2q3.7 3.7 4.5 8.8 1.5 11 0 22-.8 5.1-4.5 8.8-3.8 3.7-8.8 4.5-11 1.5-22 0-5.1-.8-8.8-4.5Q1.5 41.1.7 36q-1.4-11 0-22 .8-5.1 4.5-8.8Q8.9 1.5 14 .7q11-1.4 22 0 5 .9 8.8 4.5Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
					</svg>
				</div>
				<div class="text">
					{{myData.percent}}%
				</div>
			</div>
			<!-- <button (click)="test()">test</button> -->
			<div class="icon-preloader">
				<div class="speaker"></div>
				<div class="mic"></div>
				<div class="recorder-stop"></div>
				<div class="play"></div>
				<div class="player-stop"></div>
			</div>
			<!-- <input type="text" [(ngModel)]="testPattern" /> -->
		</div>
	`,
	styles:[
		
		`
		.textflow{
			font-size:32px;
		}
		
		.hidden{
			display:none;
		}
		.not-visible{
			opacity:0;
		}
		
		.button.disabled{
			pointer-events: none;
		}

		.icon-preloader{
			width:0px;
			height:0px;
			overflow:hidden;
		}

		.flex
		{
			display:flex;
		}

		.container{
			margin-top:-2.5px;
			margin-bottom:-2.5px;
			align-items: center;
			display:flex;
			flex-wrap: wrap;
			align-content: flex-start;
		}
		
		.container > *{
			margin-top:2.5px;
			margin-bottom:2.5px;
			
		}
		
		.percent{
			width:50px;
			height:50px;
			position:relative;
			font-weight: bold;
		}
		.mic{
			width:50px;
			height:50px;
			display:inline-block;
			background-image:url('assets/img/component/stt/mic.svg');
		}
		
		.speaker{
			width:50px;
			height:50px;
			display:inline-block;
			background-image:url('assets/img/component/stt/speaker.svg');
		}
		
		.fill{
			fill:var(--fill-color, white);
		}

		.border
		{
			stroke:var(--border-color, transparent);
		}

		.answer-result.text-correct
		{
			color:green;
		}
		.answer-result.text-incorrect
		{
			color:red;
		}

		.percent.pass .percent-bg{
			--fill-color:#47A646;
			--border-color:#47A646;
		}

		.percent.failed .percent-bg{
			--fill-color:#B64141;
			--border-color:#B64141;
		}

		.percent-bg{
			width: 50px;
			height: 50px;
			display: inline-block;
		}
		
		.percent .text{
			font-size:19px;
			color:white;
			position:absolute;
			width:50px;
			height:50px;
			text-align:center;
			line-height: 50px;
			left:0px;
			top:0px;
		}
		
		.percent.perfect .text{
			font-size:15px;
		}

		.play{
			width:50px;
			height:50px;
			display:inline-block;
			background-image:url('assets/img/component/stt/play.svg');
			opacity: 0.5;
		}

		.play.has-recording{
			opacity: 1;
		}
		
		.stop-button{
			width:50px;
			height:50px;
			display:inline-block;
		}

		.stop-button.recorder-stop{
			background-image:url('assets/img/component/stt/recorder-stop.svg');
		}

		.stop-button.player-stop
		{
			background-image:url('assets/img/component/stt/player-stop.svg');
		}
		
		.loading{
			width:50px;
			height:50px;
			display:inline-block;
			background-image:url('assets/img/component/loading.svg');
			background-size:26px;
			background-position:center;
			background-repeat:no-repeat;
		}

		.time-label
		{
			color:#FFFFFF;
			line-height:50px;
			width:50px;
			text-align:center;
			font-size:15px;
		}
		.recording.time-label
		{
			color:#C24945;
		}

		.playing.time-label
		{
			color:#1D5C98;
		}
		.verify-icon{
			position:absolute;
			display:block;
			right: 0px;
			top: 0px;
		}

		.item-content.answer{
			font-size:var(--answer-box-font-size);
			position:relative;
			margin-right:10px;
			background-color:#F5EDDA;
			border-radius:10px;
			// padding-left:var(--padding-left-right, 10px);
			// padding-right:var(--padding-left-right, 10px);
			width:var(--answer-box-size-w);
			height:var(--answer-box-size-h);
			line-height:var(--answer-box-size-h);
			text-align:center;
		}
		.item.answer:empty::after{
			content:" - ";
			color:#F5EDDA;
		}
		.item.question{
			margin-right:7px;
		}
		
		.answer{
			color:#FFFFFF;
		}

		.answer .text-correct
		{
			color:#00A500;
		}
		.answer .text-partial-correct
		{
			color:#FFA801;
		}
		.answer .text-incorrect
		{
			color:#FF0B00;
		}

		.button.enabled:active{
			transform:translate(0px, 2px);
		}
		.position-end{

		}

		.position-question{
			position:absolute;
			left:var(--question-ball-x);
			top:var(--question-ball-y);
		}
		.position-none{
			display:none;
		}
		
		`
	]
})
export class ROSTT extends ROComponent
{
	
	public myRecorder:RecorderAndPlayer;
	public processing:boolean;
	private evaluator:STTAnswerEvaluator;
	private player:IAudioPlayer;
	public myData:any;
	// public result:any; 
	public text:string;
	public correctAnswer:string;
	public evaluated:boolean;
	private fileReference:any;
	public result:any;
	public evaluationList:any [];
	public question:any;
	public answer:any;
	public fontFamily:string;
	public ctx:any;
	public items:any = [];
	
	public resultPosition:string; 
	// question end
	
	public isDataComponent():boolean
	{
		return true;
	}

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	
	private onScoreChanged(d:Object):void 
	{
		
	}
	
	private setResult(r:any):void
	{
		this.result = r;
		if(this.result)
		{
			this.evaluationList = this.simplifyList(this.result);
		} else {
			this.evaluationList = [];
		}
		if (this.ctx.layout != "question")
		{
			return;
		}
	}

	private simplifyList(o:any):any[]
	{
		var item:any;
		var list:any [];
		var i:number;
		var len:number;
		var result:any []
		list = [];
		if(this.ctx.answerDisplayType == "text")
		{
			result = o.result;
			len = result.length;
			for (i = 0; i < len; i++)
			{
				item = result[i];
				
				if (item.correct)
				{
					item.value.forEach((element:any)=>
					{
						list.push({correct:1, text:element.text});
					});
				} else 
				{
					if (item.add)
					{
						
						var convertor:Function;
						if (this.ctx.lang == "en-US")
						{
							convertor = (input:string):string =>{
								return input; 
							};
						} else {

							if (this.ctx.outputEncoding == "tc")
							{
								convertor = ChineseTextHelper.toTraditionalized;
							} else if (this.ctx.outputEncoding == "sc")
							{
								convertor = ChineseTextHelper.toSimplized;
							} else {
								convertor = ChineseTextHelper.toTraditionalized;
							}
						}	
						item.add.forEach((element:any):void=>
						{
							if (element.type !== "symbol") list.push({correct:0, text:convertor.call(null, element.text)});
						});
					} else if (item.remove)
					{
						
					}
				}
				
			}
		} else {
			result = o.result;
			len = result.length;
			
			for (i = 0; i < len; i++)
			{
				item = result[i];
				
				if (item.correct)
				{
					item.value.forEach((element:any):void=>
					{
						list.push({correct:1, text:element.text});
					});
				} else 
				{
					for (var j:number = 0; j < item.wordCount;j++)
					{
						list.push({correct:0, text:"？"});
					}
				}
			}
			
		}
			
		return list;
	}

	private evaluation(firstTime:boolean):Promise<any>
	{
		return this.evaluator.init().then(()=>{
			var newResult:any = this.evaluator.compare3(this.myData.text, this.correctAnswer);
			if(firstTime) this.myData.percent =  newResult.similarity;
			this.setResult(newResult);
			this.evaluated = true;
			if (firstTime && newResult.similarity == 100)
			{
				// OKDSoundChannel.playURL("assets/sound/applause.mp3");
				// this.context.service.alertService.alert("Well Done");
				this.applause();
			}
		})
	}

	applause():void
	{
		var url:string = ("assets/ro/sound/applause.mp3");
		let snd:any = new Audio();
		snd.autoplay = true;
		snd.src = url;
		snd.load();
		snd.play();

	}

	speak()
	{
		if(this.player == null)
		{
			var sndObject = this.getSoundObject();
			var ref:any = this.context.playerManager.createReference(sndObject);
			this.player =  this.context.playerManager.createPlayer(sndObject);
			this.player.load().then(()=>{
				this.player.play();
			})
		} else {
			this.player.play();
		}
		
	}

	getSoundObject():any 
	{
		var s:string = this.node.attributes.sound;
		var sound:any;
		if (s)
		{
			sound = JSON.parse(s);
			return sound;
		} else {
			return this.getDefaultTTSSoundFormat();;
		}
	}
	
	protected getDefaultTTSSoundFormat():Object 
	{
		var lang:string;
		if (this.ctx.lang == "en-US")
		{
			lang = "en-GB"
		} else if (this.ctx.lang == "yue-Hant-HK")
		{
			lang = "zh-HK"
		} else if (this.ctx.lang == "zh")
		{
			lang = "zh-CN"
		} else {
			lang = "inherit";
		}
		return {
			target:this,
			type:"tts",
			tts:{
				engine:{
					lang:lang,
					gender:"default",
					name:""
				},
				custom:0,
				setting:{
					speed:1,
					preprocess:0,
					pitch:1
				},
				text:this.correctAnswer
			}
		};

	}
	
	record():Promise<any>
	{
		if(!this.context.isAnswerEnabled())
		{
			return Promise.reject("not allow to answer");
		}
		return (
			this.myRecorder.hasRecording ? 
			this.context.service.alertService.confirm("ro.component.recorder.overwrite-recording", true) : 
			Promise.resolve(1)
		).then((o:any)=>{
			return this.recordNow();
		}).then((data:any)=>{
			this.processing = true;
			// return this.fakeProcessRecording(null);
			return this.processRecording(data);
		}).then(
			()=>{
				this.processing = false;	
			}
		).catch(
			(reason:any)=>{
				this.processing = false;
				console.log("record failed", reason);
			}
		)
	}
	
	play()
	{
		if(this.myRecorder && this.myRecorder.hasRecording)
		{
			this.myRecorder.play().then(()=>{

			});
		}
	}
	finishRecording()
	{
		this.myRecorder.finishRecording();
	}

	stop()
	{
		
	}
	
	private fakeProcessRecording(data:any):Promise<any>
	{
		// return Promise.resolve().then(()=>{
		return PromiseUtils.delay(1000, 0).then((o:any)=>{
			this.myData = {
				key:"KEY",
				reference:"REF"
			};
			this.myData.text = "新的字2";
			return this.evaluation(true);
		}).then(()=>{
			this.onScoreChanged(null);
		});
	}
	private processRecording(data:any):Promise<any>
	{
		console.log("record response", data);
		var reference:string = this.context.createReference();
		var uid = this.context.service.dataService.userInfo.uid;
		if(this.myData == null)
		{
			this.fileReference = {
				key:this.douid,
				reference:reference,
				file:data.sound.file,
				index:0
			};
			this.myData = {
				user:uid,
				key:this.fileReference.key,
				reference:this.fileReference.reference
			}
		} else {
			this.myData.user = uid;
			this.myData.reference = reference;
			this.fileReference = {
				key:this.douid,
				reference:reference,
				file:data.sound.file,
				index:0
			};
		}
		this.answerChanged = true;
		return this.uploadAsset().then(()=>{
			return this.retryEnabledRequestSTT().then((answer:string)=>{
				this.myData.text = answer;
				return this.evaluation(true);
			}).then(()=>{
				this.onScoreChanged(null);
			});
		});
	}

	private recordNow():Promise<any>
	{
		this.myData = null;
		// this.showPercent = false;
		return this.myRecorder.record();
	}

	private retryEnabledRequestSTT():Promise<any>
	{
		return this.requestionForSTT(null).catch((reason:any)=>{
			/*
			if (reason is Error && reason.errorID == 1)
			{
				msg = LANG.instance().getText("LANG_NETWORK_FAILED_RETRY");
			} else {
				msg = LANG.instance().getText("LANG_STT_SERVER_FAILED");
			}
			Alert.confirm(msg).then(request, def.reject);
			*/
			
			// this.context.alertService.config()
			return Promise.reject("")
		});

	}

	private requestionForSTT(o:any):Promise<any>
	{
		var share:any = this.context.config.share;
		var bsid:any = share ? share.id : 0;
		//setState("processing");
		this.myRecorder.getDuration();
		var second:number = this.myRecorder.getDuration() ;
		return this.context.service.dataService.call(
			"ROSpeechRecognition.recognize_my_asset2",
			bsid,
			this.myData.key, 
			this.myData.reference,
			0,
			this.ctx.lang,
			second
		).then((o:any)=>{
			if (o && o.code == 0){
				console.log(o.result);
				return o.result;
			}
			return Promise.reject(o);
		});
	}
	
	public getTagNames():string []
	{
		return ["STT"];
	}

	private updateLayout():void
	{
		this.items = [];
		if (this.ctx.layout == "all")
		{
			this.items.push(
				this.question,
				// {type:"space", size:7},
				this.answer
				// ,
				// {type:"space", size:10}
			);
		} else if (this.ctx.layout == "leftRight")
		{
			this.items.push(
				this.answer,
				// {type:"space", size:10},
				this.question,
				// {type:"space", size:7}
			);
			
		} else if (this.ctx.layout == "upDown")
		{	
			//tf.visible = true;
			this.items.push(
				// question,
				this.question,
				this.answer,
				// {type:"space", size:10}
			);

		} else if (this.ctx.layout == "answer")
		{
			//tf.visible = true;
			this.items.push(
				this.answer,
				// {type:"space", size:10}
			);
			
			// this.showPercent = false;
		} else if (this.ctx.layout == "question")
		{
			this.items.push(
				// question,
				this.question// ,
				// {type:"space", size:7}
			);
		}
	}
	
	protected buildContent():void
	{
		this.myRecorder = new RecorderAndPlayer(this.context.service.fileIO);
		var dom:HTMLElement= this.elementRef.nativeElement;
		var options:any = this.node.attributes;
		/*
		{
			"douid": "E874D362-018B-DA32-A0A1-E353441E9279",
			"coordinateExpression": "UA UK KH KR X 92 Y 84 D T 84 L 92 H 60 W 545",
			"hasScoring": false,
			"scoringType": 1,
			"scoreN": 1,
			"unitScore": 1,
			"fullScore": 1,
			"context": "eNpNj81qwzAQhF/F7FmBtk4vvvaHQEsIdaDnxV7bIopWSKu6bsi7d+VQ6HGHmdlvLuBw4SzQADoHBpKgkF5JOJRz4vlAsSOvFomZDKBPM8Vnm4JGj0sobqFvUXeklJ0cOFmx7FUn36scMKV3+iIHzeOdAX0Xsrz4jnvrx5Lu1mzH8SYM6JI+SvZHuy8w214maO7r2sBEdpwUZftwNTBwPKMUy8BeXvFs3aJ1exauWsWsjtXTZD0lqj5ozA4jmNXars116SgD20B4ovg3sOz6z6HbIn7eIBTf4Qq9ZNrs0Mtm9wbXX7/Abxg=",
			"q": "{\"ballSize\":36,\"color\":\"noColor\",\"show\":0,\"freezed\":1,\"level\":0}",
			"s": "{\"y\":-5,\"enable\":0,\"optional\":false,\"offset\":{\"y\":0,\"x\":0},\"x\":545,\"freezed\":1,\"reference\":\"rt\"}",
			"isQuestionComponent": true,
			"locked": false,
			"questionIndex": 0,
			"qInfo": "{\"order\":0,\"root\":0,\"level\":0,\"id\":1,\"rootIndex\":0,\"index\":0,\"pid\":0}"
		}
		*/
		// var ba:ByteArray = Base64.decodeToByteArray(str);
		// ba.uncompress();
		// return JSON.parse(ba.readUTFBytes(ba.length));

		/**
		{
		    "layout": "all",
		    "state": "stop",
		    "showPercent": true,
		    "answerDisplayType": "text",
		    "resultPosition": "end",
		    "passLevel": 50,
		    "outputEncoding": "tc",
		    "recording": false,
		    "size": {
		        "width": 133,
		        "height": 42
		    },
		    "format": {
		        "fontFamily": "Noto Sans T Chinese Regular",
		        "fontSize": 32
		    },
		    "showSpeaker": true,
		    "playing": false,
		    "extraWidth": 0,
		    "lang": "yue-Hant-HK"
		}
		 */ 
		
		this.ctx = Base64ObjectUtils.base64Decode(options.context)
		if(this.ctx.showSpeaker == undefined) this.ctx.showSpeaker = true;
		this.evaluator = new STTAnswerEvaluator(this.context, this.ctx.lang);
		
		this.fontFamily = this.ctx.format.fontFamily.replace(/[ ]/g, "-");
		this.resultPosition = this.ctx.resultPosition;

		dom.appendChild(document.createComment(JSON.stringify(this.ctx)));

		dom.classList.add("result-position-"+ this.ctx.resultPosition);
		var textflow:XMLNode = this.node.children[0];
		var utils:TextFlowUtils = new TextFlowUtils();
		var textflowDOM:HTMLElement = utils.textFlowNodeToDOM(textflow, true);
		
		
		this.correctAnswer = textflowDOM.innerText;

		this.question = {type:"question", html:textflowDOM.innerHTML};
		this.answer = {type:"answer"};
		
		this.updateLayout();
		
		var extraWidth:number = this.ctx.extraWidth;
		var format:any = this.ctx.format;
		var fontSize:number = format.fontSize;
		var textWidth:number = this.ctx.size.width;
		var boxSizeW:number  = textWidth + 20 + extraWidth;
		var boxSizeH:number = fontSize + 6;
		
		StyleUtils.setStyleVariable(dom, "answer-box-size-w", boxSizeW + "px" );
		StyleUtils.setStyleVariable(dom, "answer-box-size-h", boxSizeH + "px" );
		StyleUtils.setStyleVariable(dom, "answer-box-font-size", (fontSize - 6)+"px" );
		// StyleUtils.setStyleVariable(dom, "font-family", this.fontFamily );
		if(this.qObj.x || this.qObj.y)
		{
			StyleUtils.setStyleVariable(dom, "question-ball-x", this.qObj.x+"px" );
			StyleUtils.setStyleVariable(dom, "question-ball-y", this.qObj.y+"px" );
		} else {
			StyleUtils.setStyleVariable(dom, "question-ball-x", "-60px" );
			StyleUtils.setStyleVariable(dom, "question-ball-y", "0px" );
		}
		
		
	}
	
	public set data(input:string)
	{
		this.evaluationList = [];
		this.myRecorder.reset();
		this.defaultAnswer = null;
		this.evaluated = false;
		this.answerChanged = false;
		if (input == null) {
			this.myData = null;
			this.text = "";
			this.result = null;
			return;
		}
		
		try
		{
			
			this.myData = this.stringToObject(input);
			this.evaluation(false);
			// this.verify(true);
			this.evaluated = true;
			
		} catch (err)
		{
			console.log(err);
			
		}
	
	}

	public get data():string
	{
		if (this.myData) {
			return this.objectToString2(this.myData);
		}
		return null;
	}
	
	// after assign answer
	protected onAnswerSet():void
	{
		if(this.myData)
		{
			this.loadFile(this.myData);
		}
	}

	loadFile(obj: any):void
	{
		this.context.loadFile(<ROPageComponent> this.page, this, obj).then((url:string)=>{
			this.myRecorder.setupAudioFromURL(url);
		});
	}

	private uploadAsset():Promise<any>
	{
		return this.context.uploadComponentAsset(this);
	}

	public getAnswerAssets():any []
	{
		if(this.answerChanged) return [this.fileReference];
		return null;
	}

	// ======================================================
	// data function
	// ======================================================
	public isQuestionComponent():boolean {
		return true;
	}

	private mathRound(n:number, ratio:number):number
	{
		return Math.round(n / ratio) * ratio;
	}
	public correctState:number;
	public canVerify(): boolean 
	{
		return true;
	}
	public defaultAnswer:any = null;
	
	public hideDefaultAnswer(): void {
		this.defaultAnswer = null;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}

	public showDefaultAnswer(): void {
		this.defaultAnswer = true;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}

	public verify(_showScoring:boolean):any 
	{
		var score:Number;
		var correct:number = -1;
		if (this.myData)
		{
			var fullScore:number = this.getFullScore();
			if (this.myData.hasOwnProperty("percent"))
			{
				score = this.mathRound(fullScore * this.myData.percent / 100, 10);
				if (this.myData.percent  == 100)
				{
					correct = 2;
				} else if (this.myData.percent == 0)
				{
					correct = 0;
				} else {
					correct = 1;
				}
			} else {
				score = 0;
				correct = 0;
			}
			
			this.resultObject = {
				score: score, 
				correct:correct,
				scorePer:this.myData.percent
			};
			this.correctState = correct;
			
		} else {
			this.resultObject = null;
		}
		this.correctState = _showScoring ? correct : -1;
		return this.resultObject;
	}
}


export class STTAnswerEvaluator
{
	
	private tokenizer: STTTokenizer;
	constructor(private context:ROContext, private lang:string)
	{
		
		/*
		"yue-Hant-HK"
		"zh"
		"en-US"
		*/
		
	}

	init() {
		this.tokenizer = new STTTokenizer(this.lang);
		if(!this.context.vocabDictionary)
		{
			this.context.vocabDictionary = new VocabDictionaryDatabase();
		}
		return this.context.vocabDictionary.init();
		
	}
	
	
	private getLanguageCode():string
	{
		if (this.lang == "yue-Hant-HK")
		{ // cantonese
			return "c"
		} else if (this.lang == "zh")
		{ // mandarin
			return "m";
		} else 
		{
			return "e";
		}
	}

	private assignPhoneme(tokenArray:any [], languageCode:string):void
	{
		tokenArray.forEach((element)=>{
			if(element.type == "text")
			{
				var array = this.context.vocabDictionary.fetchRows(
					"SELECT * FROM vocab_view WHERE text = ? AND type in(?, ?)",
					[element.text, languageCode, "e"]
				);
				element.pinyin = array ? array.map((e)=>{
					return e.pinyin;
				}) : [];
			}
		})
		
	}

	private isHomophone(array1, array2):boolean
	{
		for(var i:number = 0;i < array1.length;i++)
		{
			var left:any = array1[i];
			if(array2.indexOf(left) != -1)return true;
		}
		return false;
	}

	public homophoneDiff(oldText:string, newText:string):any[]
	{
		var tokenArray1:any = this.tokenizer.tokenize(oldText);
		var tokenArray2:any = this.tokenizer.tokenize(newText);
		var code:string = this.getLanguageCode();
		this.assignPhoneme(tokenArray1, code);
		this.assignPhoneme(tokenArray2, code);
		return Diff.diffArrays(tokenArray1, tokenArray2, {
			comparator:(left:any,right:any,c)=>{
				var type:string = left.type;
				if (left.type != right.type) return false;
				if (left.text == right.text) return true;
				if (type == "number") return left.number == right.number;
				if (type == "text")
				{
					if (String(left.text).toLowerCase() == String(right.text).toLowerCase())
					{
						return true;
					} else {
						return this.isHomophone(left.pinyin, right.pinyin);
					}
				}
				return false;
			}
		}).map((element:any):any=>{
			element.wordCount = element.value.length;
			if(element.added) element.type = "add";
			else if(element.removed) element.type = "remove";
			return element;
		});
	}
	
	public compare3(myAnswer:string, correct:string):Object
	{
		return this.reformatOutput(
			this.homophoneDiff(
				myAnswer,
				correct
			)
		);
	}

	private reformatOutput(original:any[]):any
	{
		var errorCount:any;
		var items:any [] = [];
		original.forEach((item:any)=>{
			if (item.type == "remove" || item.type == "add")
			{
				if (errorCount == null)
				{
					errorCount = {wordCount:0, correct:0};
					items.push(errorCount);
				}
				if (item.type == "remove")
				{
					errorCount.remove = item.value;
				} else if (item.type == "add")
				{
					errorCount.add = item.value;
				} else {
					errorCount.other = item.value;
				}
				if (item.wordCount > errorCount.wordCount)
				{
					errorCount.wordCount = item.wordCount;
				}
			} else {
				errorCount = null;
				items.push({wordCount:item.count, correct:1, value:item.value});
			}
		});
		var total:number = 0;
		var correct:number = 0;
		items.forEach((element:any)=>
		{
			total += element.wordCount;
			if (element.correct) correct += element.wordCount;
		});
		var percent:number =  total > 0 ? correct / total * 100 : 0;
		return {
			correct:correct,
			length:total,
			raw:original,
			similarity:percent,
			result:items
		};
	}
	/*
	public compare2(myAnswer:string, correct:string):Object
	{
		var output:any [];
		var original:any [] = this.homophoneDiff(
			myAnswer,
			correct
		);
		var s1:string = this.formatOutput(original);
		output = this.homophoneDiff(
			s1,
			correct
		);
		output = this.homophoneDiff(
			s1,
			correct
		);
		return this.formatResult(original, output);
	}
	
	joinString(value:any[]):string
	{
		return value.join(" ");
	}
	*/
	formatRaw(array:any[]):any
	{
		var i:number; var j:number;
		var total:number = 0;
		var correct:number = 0;
		var r:any[] = [];
		var len:number  = array.length;
		var result:any[] = [];
		for (i = 0; i < len; i++)
		{
			var item:any = array[i];
			if (item.type == "add")
			{
				
			} else if (item.type == "remove")
			{
				result.push({
					correct:0,
					type:"incorrect",
					value:item.value
				});
			} else {
				correct += item.count;
				result.push({
					correct:1,
					type:"correct",
					value:item.value
				});
			}
		}
		return result;
	}
	
	formatOutput(array:any []):string
	{
		var i:number; 
		var j:number;
		var r:any [] = [];
		var len  = array.length;
		for (i = 0; i < len; i++)
		{
			var item:any = array[i];
			if (item.type == "add")
			{
				for (j = 0; j < item.count; j++ )
				{
					r.push("+");
				}
			} else if (item.type == "remove")
			{
				for (j = 0; j < item.count; j++ )
				{
					r.push("-");
				}
			} else {
				r.push(item.value.join(" "));
			}
		}
		return r.join(" ");
	}
	
	countKeyMax(array:any[]):number
	{
		var max:number = 0;
		var valueMap:any = {};
		var len:number = array.length;
		for (var i:number = 0; i < len; i++)
		{
			var value:string = array[i];
			if (!valueMap.hasOwnProperty(value))
			{
				valueMap[value] = 0;
			}
			valueMap[value]++;
			var temp:number = valueMap[value];
			if (temp > max)
			{
				max = temp;
			}
		}
		return max;
	}

	formatResult(original:any[], array:any[]):Object
	{
		var i:number; var j:number;
		var total:number = 0;
		var correct:number = 0;
		var r:any[] = [];
		var len  = array.length;
		var result:any[] = [];
		for (i = 0; i < len; i++)
		{
			var item:any = array[i];
			if (item.type == "add")
			{
				
			} else if (item.type == "remove")
			{
				item.count = this.countKeyMax(item.value);
				total += item.count;
				
				var jlen:number = item.count;
				for (j = 0; j < jlen; j++)
				{
					result.push({
						correct:0,
						type:"incorrect",
						value:["？"]
					});
				}
			} else {
				correct += item.count;
				//r.push(s);
				total += item.count;
				result.push({
					correct:1,
					type:"correct",
					value:item.value
				});
			}
		}
		if (total == 0)
			total = 1;
		return {
			raw:array,
			rawResult:this.formatRaw(original),
			result:result,
			similarity:Math.floor(correct / total * 100)
		};
	}
	compare(myAnswer:string, correctAnswer:string):number
	{
		return this.similarity(
			this.trim(myAnswer), 
			this.trim(correctAnswer)
		);
	}
	
	similarity(text1:string, text2:string):number
	{
		var diff:any[] = Diff.diffWords(text1, text2);
		var totalLen:number = 0;
		var correctLen:number = 0;
		diff.forEach((element:any)=>{
			var textLen:number = element.value.length;;
			totalLen += textLen;
			if(!element.added && !element.removed)
			{
				correctLen += textLen;
			}
		});
		return correctLen / totalLen;
	}

	trim(input:String):string
	{
		input = input.toLowerCase();
		var items:string[] = input.split("");
		var characters:any[] = CharacterType.SYMBOL.split("");
		return items.filter((s:string)=>
		{
			return characters.indexOf(s) == -1;
		}).join("");
	}
}
