import { ByteLoader, FileUtils } from "src/app/common/FileUtils";
import JSZip from "jszip"
export class ZipUtils
{
	static loadURLAsJSON(url:string):Promise<any>
	{
		return ZipUtils.loadURLAsType(url, "blob").then((blob:any)=>{
			return blob.text();
		}).then((text:string)=>{
			return JSON.parse(text);
		});
	}
	static loadURL(url:string):Promise<any>
	{
		return new ByteLoader().load("GET", url).then((buffer:ArrayBuffer)=>{
			const zip = new JSZip();
			return zip.loadAsync(buffer);
		})
	}

	static loadURLAsFile(url:string):Promise<any>
	{
		return ZipUtils.loadURL(url).then((zipFile:any)=>{
			var fileNames:string [] = []
			for(var key in zipFile.files)
			{
				fileNames.push(key);
			}
			var firstFileName:string = fileNames[0];
			var file = zipFile.files[firstFileName];
			return file;
		});
	}

	static loadURLAsType(url:string, type:string):Promise<any>
	{
		return ZipUtils.loadURLAsFile(url).then((file:any)=>{
			return file.async(type);
		});
	}

	static loadURLAsBlob(url:string):Promise<any>
	{
		return ZipUtils.loadURLAsFile(url).then((file:any)=>{
			return file.async("blob");
		});
	}
	
	static loadURLAsText(url:string):Promise<any>
	{
		return ZipUtils.loadURLAsFile(url).then((file:any)=>{
			return file.async("text");
		});
	}
}