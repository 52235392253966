import { fromEvent, ReplaySubject, Subject, Subscription } from "rxjs";
import { DOMHelper } from "src/app/common/DOMHelper";

export class DragManager{
	constructor()
	{
		
	}
	
	dragDOM(dom:HTMLElement, event:MouseEvent):Promise<any>
	{
		// this.dragStart(event);
		return Promise.resolve(1);
	}
	getTouchPosition(event:any):Object
	{
		// 當 touch end 時，是不會傳回 touches
		if (event.touches === undefined && event.pageX !== undefined){
			return { x: event.pageX, y: event.pageY };
		}
		var touch:Touch = event.touches.length>0 ? event.touches[0] : event.changedTouches[0];
		return {
			x:touch.pageX,
			y:touch.pageY
		};
	}
	private getMousePosition(event:MouseEvent):any
	{
		return {
			x:event.pageX,
			y:event.pageY
		}
	}
	private getPointerPosition(event:PointerEvent):any
	{
		return {
			x:event.pageX,
			y:event.pageY
		};
	}
	pointerStart(event:PointerEvent, touch:HTMLElement = null):Subject<any>
	{
		var sub:Subscription = new Subscription(()=>{});
		var subject:Subject<any> = new ReplaySubject(1);
		var point:any = this.getPointerPosition(event);
		subject.next({type:"start", event:event, point:point});
		/*
		el.addEventListener("pointerdown", handleStart, false);
		el.addEventListener("pointerup", handleEnd, false);
		el.addEventListener("pointercancel", handleCancel, false);
		el.addEventListener("pointermove", handleMove, false);
		*/
		sub.add(
			fromEvent(touch ? touch : window, "pointermove").subscribe((event:PointerEvent)=>{
				// subject.next({type:"move", event:event});
				subject.next({type:"move", point:this.getPointerPosition(event), event:event});
			})
		);
		sub.add(
			fromEvent(window, "pointerup").subscribe((event:PointerEvent)=>{
				subject.next({type:"end", point:this.getPointerPosition(event), event:event});
				sub.unsubscribe();
			})
		);
		sub.add(
			fromEvent(window, "pointercancel").subscribe((event)=>{
				subject.next({type:"cancel", event:event});
				sub.unsubscribe();
			})
		);
		
		return subject;
	}
	touchStart(event:TouchEvent, touch:HTMLElement = null):Subject<any>
	{
		

		var sub:Subscription = new Subscription(()=>{});
		var subject:Subject<any> = new ReplaySubject(1);
		var point:any = this.getTouchPosition(event);
		subject.next({type:"start", event:event, point:point});
		/*
		touchcancel
		touchend
		touchmove
		touchstart
		*/
		sub.add(
			fromEvent(touch ? touch : window, "touchmove").subscribe((event:TouchEvent)=>{
				// subject.next({type:"move", event:event});
				subject.next({type:"move", point:this.getTouchPosition(event), event:event});
			})
		);
		sub.add(
			fromEvent(window, "touchend").subscribe((event:TouchEvent)=>{
				subject.next({type:"end", point:this.getTouchPosition(event), event:event});
				sub.unsubscribe();
			})
		);
		sub.add(
			fromEvent(window, "touchcancel").subscribe((event)=>{
				subject.next({type:"cancel", event:event});
				sub.unsubscribe();
			})
		);
		return subject;
	}
	
	dragStart(event:MouseEvent):Subject<any>
	{
		event.stopImmediatePropagation();
		// var subject:Subject<any> = new Subject();
		var sub:Subscription = new Subscription(()=>{});
		var subject:Subject<any> = new ReplaySubject(1);
		subject.next({type:"start", point:this.getMousePosition(event)});
		sub.add(
			fromEvent(window, "mousemove").subscribe((event:MouseEvent)=>{
				subject.next({type:"move", target:event.target, point:this.getMousePosition(event)});
			})
		);
		sub.add(
			fromEvent(window, "mouseup").subscribe((event:MouseEvent)=>{
				subject.next({type:"end", target:event.target, point:this.getMousePosition(event)});
				sub.unsubscribe();
			})
		);
		return subject;
	}


	monitorMovement(target:HTMLElement, event:any, movementCallback):void
	{
		var subject:Subject<any>;
		if(event.type.indexOf("pointer")==0)
			subject = this.pointerStart(event, null);
		else if(event.type.indexOf("touch")==0)
			subject = this.touchStart(event, null);
		else if(event.type.indexOf("mouse")==0)
			subject = this.dragStart(event);
		else
			return;
		
		let gRect:DOMRect = target.getBoundingClientRect() as DOMRect;
		var subscription:Subscription = subject.subscribe((o:any)=>{
			if(o.type == "start") {
				gRect.x -= o.point.x;
				gRect.y -= o.point.y;
			}

			movementCallback(o.type, DOMHelper.getLocalPoint(target, {x:gRect.x+o.point.x, y:gRect.y+o.point.y}));

			if(o.type == "end" || o.type == "cancel" || o.type == "timeout") {
				subscription.unsubscribe();
			}
		});
	}
}