import { Injectable } from "@angular/core";
import { CommonService } from "src/app/service/common.service";
import { DataService } from "src/app/service/data.service";
import { OupService } from "src/app/service/oup.service";
import { CacheResourceSource } from "../ROContext";

type ImageData = {
	data: Blob | string;
	index: number;
	chapterId: string;
}
@Injectable({providedIn: 'root'})
export class EBookEditorService {
	public eBookObject: any = {}
	public currentPageNumber: number = 0
	public currentChapterId: string | null = null
	public isDirty: boolean = false
	public isEnterPreviewMode: boolean = false

	// cache thumbnail image
	private imageCache: Map<string, ImageData> = new Map();
	
	constructor(
		private coms: CommonService,
		private datas: DataService,
		private oup: OupService,
		public resourceSource: CacheResourceSource
	) {}

	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// Preview Functions
	public resetObject() {
		this.eBookObject = {}
	}

	public resetPageId() {
		this.currentPageNumber = 0
	} 

	public reset() {
		this.resetObject()
		this.resetPageId()
		this.isDirty = false
		this.currentChapterId = null
		this.isEnterPreviewMode = false
	}
   	
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// Cache thumbnail Functions
	public async cacheImage(pageId: string, blob: Blob, url: string = "", pageIndex: number, chapterId: string): Promise<void> {
		const imageData = { data: blob || url, index: pageIndex, chapterId }
		this.imageCache.set(pageId, imageData);
	}

	public getCachedImage(pageId: string): Blob | string | undefined {
		const entry = this.imageCache.get(pageId);
		return entry ? entry.data : undefined;
	}

	public hasCachedImages(): boolean {
		return this.imageCache.size > 0;
	}

	public getAllCachedArray() {
		let arr = Array.from(this.imageCache, ([id, value]) => ({ id, value }));
		return arr
	}

	public async loadImage(pageId): Promise<string> {
		const cachedBlob = this.getCachedImage(pageId);
		if (cachedBlob) {
			if (cachedBlob instanceof Blob) {
				return URL.createObjectURL(cachedBlob);
			} else {
				return cachedBlob
			}
		}
		return null
	}

	public clearCache(): void {
		this.imageCache.clear();
	}

	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// Files conversion functions
	public convertToBase64(value) {
		return new Promise((resolve, reject) => {
			if (value instanceof Blob) {
				// read it as a data URL
				const reader = new FileReader();
				reader.onloadend = () => {
					// Extract the base64 string from the Data URL
					const base64data = (reader.result as string).split(",")[1];
					resolve(base64data);
				}
				reader.onerror = reject;
				reader.readAsDataURL(value);
			} else if (typeof value === 'string' && value.startsWith('http')) {
				// fetch the blob and convert to base 64
				fetch(value).then(response => response.blob()).then(blob => {
					const reader = new FileReader();
					reader.onloadend = () => {
                        const base64data = (reader.result as string).split(",")[1];
                        resolve(base64data);
                    };
					reader.onerror = reject;
                    reader.readAsDataURL(blob);
				}).catch(reject);
			} else if (typeof value === 'string' && value.startsWith('OKG')) {
				this.resourceSource.lookupResource(value).then(url => {
					fetch(url).then(response => response.blob()).then(blob => {
						const reader = new FileReader();
						reader.onloadend = () => {
							const base64data = (reader.result as string).split(",")[1];
							resolve(base64data);
						};
						reader.onerror = reject;
						reader.readAsDataURL(blob);
					}).catch(reject);
				})
			} else {
				reject(new Error("Invalid input"))
			}
		})
	}
}