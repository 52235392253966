import { ScriptService } from 'src/app/service/script.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UniqueID } from 'src/app/common/UniqueID';
import { AssetLoader } from 'src/app/common/ScriptLoader';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: "colorPicker",
	template: `
		<input #colorPicker type="text"
			id="{{itemId}}" name="color-{{itemId}}"
			[(ngModel)]="selectedColor"
		>
	`,
	styleUrls:["./color-picker.component.scss"]
})

export class ColorPickerComponent implements OnChanges, AfterViewInit {
	@ViewChild('colorPicker', { static: true }) colorPicker
	
	@Input() colors: any[] = []
	@Input() selectedColor: any
	@Input() isAllowClear: boolean = false
	@Output() colorChange: EventEmitter<any> = new EventEmitter()

	public itemId: string
	
	constructor(private scriptService: ScriptService, private trans: TranslateService) {
		this.itemId = `id_${UniqueID.getNextID()}`
	}

	ngAfterViewInit(): void {
		let view = this.colorPicker.nativeElement
		view.id = this.itemId
		view.addEventListener('change', this.onViewChange.bind(this))
		view.addEventListener('click', this.onClick.bind(this))
		
		let basePath: string = this.scriptService.assetsUrl
		AssetLoader.load(
			[
				{ type:"css", url: basePath + "coloris/coloris.css" }, 
				{ type:"js", url:basePath + "coloris/coloris.js", name:"Coloris" }
			]
		).then(result => {
			let c = window["Coloris"]
			c({
				el: '#'+this.itemId, 
				wrap: true,
				theme: 'default',
				themeMode: 'light',
				margin: 2,
				format: 'hex',
				formatToggle: false,
				alpha: false,
				swatchesOnly: false,
				focusInput: false,
				clearButton: {
					show: this.isAllowClear,
					label: this.trans.instant('commonService.clear')
				},
				swatches:this.colors,
			})
		})
	}

	ngOnChanges(changes: SimpleChanges): void {
		let view = this.colorPicker.nativeElement
		view.value = this.selectedColor
		view.dispatchEvent(new Event("setColor", { cancelable: true, bubbles: true}))
	}

	onViewChange(event: any):void {
		this.selectedColor = event.target.value
		this.colorChange.emit(this.selectedColor)
	}	

	onClick() {
		this.colorPicker.nativeElement.click()
	}

	onClose() {
		const c = window['Coloris'];
		if (c) {
		  c.close();
		}
	}
}