import { Component, HostListener, NgZone, ViewChild, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { Subject } from "rxjs";
import { debounceTime, takeUntil, delay } from "rxjs/operators";
import { CommonService } from 'src/app/service/common.service';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {OupService} from "../../../../service/oup.service";
declare var Chart;

@Component({
	selector: 'p-stickers-individual-chart',
	template: `
	<div class="card" *ngIf="chartData.stickers.length > 0">
		<div class="body">
			<div class="chart-wrapper2" *ngIf="chartDatasets">
				<div class="chart-wrapper" [style.height.px]="chartHeight">
					<canvas #canvas id="canvas_{{ts}}" height="{{chartHeight}}"></canvas>
				</div>
			</div>
		</div>
	</div>

	<div class="no-data" *ngIf="chartData.stickers.length == 0">
		<div class="no-data-image"></div>
		<div class="no-data-text">{{ {tc:'未有貼紙',sc:'未有贴纸',en:'No sticker'}[datas.lang] }}</div>
	</div>
  `,
	styleUrls: ['./personal-stickers-individual.chart.scss']
})
export class PersnoalStickersIndividualChart implements OnInit, AfterViewInit, OnDestroy {
	@Input() parent;
	public chartData:any;
	public chartDatasets:any;
	public chartLabels:any;
	public chartObj:any;
	public chartOptions:any;
	public xMax = 1;
	@ViewChild('canvas', {static: false}) canvas;
	public onDestroy = new Subject();
	public faCheck = faCheck;
	public rectangleSet = false;
	public chartHeight = 100;
	public resizeSubject = new Subject();
	private preloaded = false;
	public ts = 0;

	constructor(public lds: LoadingService, public datas: DataService, public theme: ThemeService, public coms: CommonService, public oup: OupService) {
	}

	ngOnInit() {
		this.chartData = this.parent.chartData;
		this.preloadImage();
		this.lds.add('preview',10000);
		if (this.chartData.stickers.length > 0){
			this.coms.waitFor(()=> this.preloaded, 30, 3000).then(()=>{
				this.drawChart();
				this.lds.remove('preview');
				this.parent.parent.filterChange.pipe(takeUntil(this.onDestroy)).subscribe(event=>{
					this.drawChart();
				});
				this.resizeSubject.pipe(takeUntil(this.onDestroy), debounceTime(100), delay(10)).subscribe(event => {
					this.drawChart();
				});
			});
		}
	}

	ngAfterViewInit(): void {

	}
	
	ngOnDestroy() {
		if (this.chartObj){
			this.chartObj.destroy();
		}
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	@HostListener('window:resize', ['$event']) resize($event){
		this.resizeSubject.next();
	}

	drawChart(){
		if (this.chartObj){
			this.chartObj.destroy();
			this.chartObj = null;
		}
		this.ts = +new Date();
		this.chartDatasets = null;
		this.rectangleSet = false;
		setTimeout(()=>{
			this.initChartData();
			this.initChartOptions();
			this.renderChart();
		}, 500);
	}

	preloadImage(){
		let preloaded = 0;
		this.chartData.stickers.forEach(sticker => {
			const image = new Image();
			image.src = sticker.stickerImageSrc;
			image.onload = ()=>{
				preloaded++;
				sticker.imgEle = image;
				if (preloaded == this.chartData.stickers.length){
					this.preloaded = true;
				}
			}
		});
	}

	initChartOptions(){
		this.chartOptions = {
			maintainAspectRatio: false,
			animation: {
				duration: 100,
			},
			scales: {
				y: {
					display: true,
					grid: {
						display: false,
					},
					border: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					ticks: {
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 14,
						},
						color: 'transparent',
						padding: 20
					},
					beginAtZero: true,
					title: {
						display: false,
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 15,
							color: 'transparent',
						},
						color: 'transparent',
					},
					stacked: false,
				},
				x: {
					grid: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
						display: false,
					},
					border: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					ticks: {
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 14,
							color: "#8e9295",
							weight: "bold",
						},
						padding: 20,
					},
					max: this.xMax,
					beginAtZero: true,
					title: {
						display: false,
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 18,
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#666',
						padding: 5
					},
					stacked: false,
					afterFit: axis=>{
						axis.paddingRight = 20;
					}
				},
			},
			responsive: true,
			title: true,
			layout: { padding: { left: 0, right: 20, top: 0, bottom: 0 } },
			plugins: {
				legend: {
					display: false,
					labels: {
						display: false,
					},
				},
				labels: [
					{
						render: 'value'
					}
				],
				tooltip:{
					enabled: false
				}
			},
			indexAxis: 'y',
		};
	}

	initChartData(){
		this.chartDatasets = null;
		this.chartLabels = [];
		this.xMax = 15;
		const selectedShares2 = this.chartData.shares;
		selectedShares2.forEach((share, index)=>{
			const ppl = share.peoples.find(e=> e.uid == this.chartData.uid);
			let pplObj:any = {stickers: ppl.stickers, tag_fulltitle: share.tag_fulltitle };
			pplObj.name = [(this.datas.lang == 'en'?ppl.ename:ppl.cname), share.tag_fulltitle];
			if (this.oup.isOupUser) {
				Object.defineProperty(pplObj, 'name', {
					get: () => {
						return [
							(this.datas.lang == 'en' ? ppl.ename : ppl.cname),
							(this.oup.get_class_name(share.tag_fulltitle) || share.tag_fulltitle)
						];
					},
					set: (value) => {

					}
				});
			}

			pplObj.stickers = ppl.stickers.sort((a,b)=>{
				const aKey = a.type + ':' + a.typeRefID;
				const bKey = b.type + ':' + b.typeRefID;
				if (aKey > bKey){
					return 1;
				} else if (aKey < bKey){
					return -1;
				} else {
					return 0;
				}
			});
			pplObj.stickers.forEach(sticker => {
				const found = this.chartData.stickers.find(e=> e.type == sticker.type && e.typeRefID == sticker.typeRefID);
				if (found){
					sticker.stickerImageSrc = found.stickerImageSrc;
				}
			});
			
			this.chartLabels.push(pplObj);
		});
		
		this.chartLabels.forEach(ppl => {
			this.xMax = Math.max(this.xMax, ppl.stickers.length);
		});
		this.chartDatasets = [
			{
				label: '',
				borderColor: 'transparent',
				backgroundColor: 'transparent',
				borderWidth: 1,
				itemRadius: 0,
				data: this.chartLabels.map(e=> e.stickers.length),
			}
		];
		this.chartHeight = this.chartLabels.length >= 12? this.chartLabels.length * 50: 703;
	}
	
	renderChart(){
		if (this.chartData.stickers.length == 0){
			return;
		}
		if (this.chartObj) {
			this.chartObj.destroy();
		}
		this.coms.waitFor(()=> this.canvas && this.canvas.nativeElement, 20, 200).then(()=>{
			const canvasEle = this.canvas.nativeElement;
			const ctx = canvasEle.getContext('2d');
			this.lds.add('renderChart', 5000);
			setTimeout(() => {
				this.chartObj = new Chart(ctx, {
					type: 'bar',
					data: {
						labels: this.chartLabels.map(e=> {
							let name = ''+ e.name;
							name = name.length > 15?name.substr(0,15) + '...':name;
							return name;
						}),
						datasets: this.chartDatasets,
					},
					options: this.chartOptions,
					plugins: [
						{
							beforeDraw: (chart, args, options)=> {
								this.beforeDraw(chart);
							}
						},
						{
							afterDraw: (chart, args, options)=> {
								this.afterDraw(chart);
							}
						},
						{
							afterRender: (chart, args, options)=> {
								this.lds.remove('renderChart');
							} 
						}
					]
				});
			}, 500);
		});
	}

	beforeDraw(chart){
		const ctx = chart.ctx;
		setTimeout(()=>{
			if (chart._sortedMetasets.length > 0 && this.chartDatasets){
				const x = '';
				chart._sortedMetasets.forEach((ms, msIndex) => {

				});
			}
		},100);
	}

	afterDraw(chart){
		const ctx = chart.ctx;
		setTimeout(()=>{
			if (chart._sortedMetasets.length > 0 && this.chartDatasets){
				const baseX = chart.scales.y.right - 22;
				chart._sortedMetasets.forEach((ms, msIndex) => {
					
					ms.data.forEach((data, dataIndex) => {
						ctx.textAlign = 'right';
						let font = "17px 'Noto Sans T Chinese Regular' ,'Helvetica Neue'";
						ctx.font = font;
						let fillStyle = this.theme.theme$.value == 'dark'?'#fff':'#666';
						ctx.fillStyle = fillStyle;
						const x = data.x + 30;
						let y = data.y + 10;
						const text = this.chartDatasets[msIndex].data[dataIndex];
						if (text > 0){
							ctx.fillText(text, x , y);
							ctx.save();
						}
						y = y - 10;
						font = "14px 'Noto Sans T Chinese Regular' ,'Helvetica Neue'";
						ctx.font = font;
						fillStyle = this.theme.theme$.value == 'dark'?'#fff':'#666';
						ctx.fillStyle = fillStyle;
						let studentName = '' + this.chartLabels[dataIndex].name[0];
						studentName = studentName.length > 15?studentName.substr(0,15) + '...':studentName;
						ctx.fillText(studentName, baseX, y);
						ctx.save();

						y = y + 20;
						font = "12px 'Noto Sans T Chinese Regular' ,'Helvetica Neue'";
						ctx.font = font;
						fillStyle = '#2292d5';
						if (location.href.indexOf('/course/') > -1){
							fillStyle = '#684ccc';
						}
						ctx.fillStyle = fillStyle;
						let tagName = '' + this.chartLabels[dataIndex].name[1];
						tagName = tagName.length > 15?tagName.substr(0,15) + '...':tagName;
						ctx.fillText(tagName, baseX, y);
						ctx.save();
					});
					
					ms.data.forEach((data, dataIndex) => {
						const x1 = data.x;
						const x2 = data.x - data.width;
						const y1 = data.y + data.height;
						const y2 = data.y;
						const value = this.chartLabels[dataIndex].stickers.length;
						let imageX = x2 + 5;
						const size = ((x1 - x2) / value);
						let imageY = y2 - (size / 2);
						if (!isNaN(data.width) && data.width > 0){
							for (let i = 0; i < this.chartLabels[dataIndex].stickers.length; i ++){
								const stickerImage = new Image();
								stickerImage.style.width = size + 'px';
								stickerImage.style.height = size + 'px';
								stickerImage.src = this.chartLabels[dataIndex].stickers[i].stickerImageSrc;
								setTimeout(()=>{
									ctx.drawImage(stickerImage, imageX, imageY, size, size);
									imageX = imageX + size;
								},500);
							}
						}
					});
				});
			}
			this.lds.remove('renderChart');
		},510);
	}
}