import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { DragManager } from "./DragManager";
import { fromEvent, ReplaySubject, Subject, Subscription } from "rxjs";
import { DOMHelper } from "src/app/common/DOMHelper";

// =======================================
// icon
// =======================================
import { faArrowUpRightDots, faMusic, faPalette, faRotateRight, faText, faVolume, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ROComponent, ROTLFTextComponent } from "./ROComponent";
import { Matrix } from "src/app/common/Matrix";
import { faFile, faBars, faTrash, faGripDots, faImage, faFilm, faListMusic } from '@fortawesome/pro-solid-svg-icons';
import { WhitePopupService } from "../whitePopupModule/whitePopup.service";
import { ComponentMenuBarComponent } from "../componentMenuBarModule/componentMenuBar.component";
import { ValueCache } from "ag-grid-community";


@Component({
	selector: 'SelectMarker',
	template:`
	<ng-container *ngFor="let itm of selectedItems">
		<!--
		<div class="selector" *ngIf="!itm.editInStage"
			[class.drag]="itm.settings.move" 
			[style.left.px]="itm.x + itm.offsetX" 
			[style.top.px]="itm.y + itm.offsetY" 
			[style.width.px]="itm.width" 
			[style.height.px]="itm.height" 
			(pointerdown)="onPointerDown(itm, $event)"
			>
		-->
		<div class="selector" *ngIf="!itm.editInStage"
			[class.drag]="itm.settings.move" 
			[style.left.px]="itm.gx" 
			[style.top.px]="itm.gy" 
			[style.width.px]="itm.gw" 
			[style.height.px]="itm.gh" 
			[style.transform]="itm.rotation ? 'rotate(' + itm.rotation + 'deg)' : ''"
			(pointerdown)="onPointerDown(itm, $event)"
			>
			<fa-icon *ngIf="itm.settings.rotation" class="rotate" (pointerdown)="startRotate(itm, $event, 'pointer')" [icon]="faRotateRight"></fa-icon>
			<div *ngIf="itm.settings.widthResizer" class="widthResizer" (pointerdown)="startResizeWidth(itm, $event, 'pointer')"></div>
			<div *ngIf="itm.settings.resizer" class="resizer" (pointerdown)="startResize(itm, $event, 'pointer')"></div>
		</div>
		<div class="top-selector" 
			*ngIf="inEdit!=itm && itm.settings && itm.settings.popupSelector && itm.settings.popupSelector.length>0">
			<componentMenuBar 
			[reference]="itm"
			[target]="itm.dom"
			[autoClose]="false"
			[menuItems]="itm.settings.popupSelector"
			[showSelector]="showSelector"
			[positionUpdateCount]="positionUpdateCount"
			(emitter)="onComponentMenuBarEmitter(itm,$event)"
			></componentMenuBar>

		</div>
		<div class="propertyPanelContainer">
			<!-- <propertyPanel 
				*ngIf="inEdit!=itm && itm.settings && itm.settings.popupSelector && itm.settings.popupSelector.length>0"
				[headerStyle]="propertyPanelConfig.headerStyle" 
				[headerTitle]="propertyPanelConfig.headerTitle"
				[contentStyle]="propertyPanelConfig.contentStyle"
				[items]="propertyPanelConfig.items"
				[selectedItems]="selectedItems"
			>
			</propertyPanel> -->
		</div>
	</ng-container>
	
	<componentMenuBar *ngIf="inEdit"
	[positionTarget]="topCenter"
	[target]="inEdit.dom"
	[autoClose]="false"
	[menuItems]="inEdit.settings.popupSelector"
	[showSelector]="showSelector"
	[positionUpdateCount]="positionUpdateCount"
	(emitter)="onComponentMenuBarEmitter(inEdit,$event)"
	></componentMenuBar>


	`,


	styles:[`
	:host {
		pointer-events: none;
		position: absolute;
		left:0px;
		top:0px;
	}
	.top-selector{
		position:absolute;
		line-height:40px;
		font-size:12px;
		display:flex;
		height:40px;
		background-color:blue;
		border-radius:10px;
	}
	.top-selector > .item{
		padding-left:5px;
		padding-right:5px;
		background-color:red;
	}
	
	.selector.drag{cursor: move;}
	.selector{
		/*pointer-events: all;*/
		position:absolute;
		border: 2px solid #3D901E;
		transform-origin: left top;
	}
	.selector .widthResizer{
		touch-action:none;
		cursor: ew-resize;
		pointer-events: all;
		position:absolute;

		right:-13px;
		top:calc(50% - 13px);
		margin:7px;
		width:12px;
		height:12px;
		background-color:#fff;
		border: 2px solid #3D901E;
		border-radius:50%;
	}
	.selector .resizer{
		touch-action:none;
		cursor: se-resize;
		pointer-events: all;
		position:absolute;
		right:-13px;
		bottom:-13px;
		width:26px;
		height:26px;
		background-color:#fff;
		border: 2px solid #3D901E;
		border-radius:50%;
	}
	.selector .resizer:before {
		display:block;
		margin:6px;
		content: "";
		background-color:#3D901E;
		width:10px;
		height:10px;
		clip-path: polygon(0px 7px, 7px 0px, 0px 0px, 0px 7px, 3px 10px, 10px 10px, 10px 3px, 3px 10px);
	}

	.selector .rotate{
		touch-action:none;
		cursor: default;
		display:flex;
		pointer-events: all;
		position:absolute;
		right:-13px;
		top:-13px;
		width:26px;
		height:26px;
		background-color:#fff;
		border: 2px solid #3D901E;
		border-radius:50%;
		color: #3D901E;
	}

	.propertyPanelContainer {
		width: 100%;
		height: 100%;
	}

	::ng-deep fa-icon.rotate svg{transform: rotate(135deg);pointer-events: none;margin:auto}
	`]
})

export class SelectMarkerComponent {
	@Input() public useInROPage:boolean;
	@Input() public topCenter:HTMLElement;
	
	@Output() public change:EventEmitter<any> = new EventEmitter<any>();
	@Output() public emitter:EventEmitter<any> = new EventEmitter<any>();
	@Input() public showSelector:boolean = true;
	@Output() public positionUpdateCount:number = 0;

	@ViewChild(ComponentMenuBarComponent, { static: false }) public componentMenuBar:ComponentMenuBarComponent;

	public faRotateRight:IconDefinition = faRotateRight;
	public _selectedItems:any[] = [];
	public set selectedItems(data:any[]) {
		this._selectedItems = data;
	}
	public get selectedItems():any[] {
		return this._selectedItems;
	}
	private dd:DragManager;
	public inEdit:any = null;
	protected releaseOutSideSubscription:Subscription;

	public defaultPropertyPanelConfig = {
		headerStyle: {},
		contentStyle: {},
		headerTitle: "",
		items: [],
		tabs: []
	}
	public propertyPanelConfig: { headerStyle: any, contentStyle: any, headerTitle: string, items: any[], tabs: any[] } = this.defaultPropertyPanelConfig

	public fontFamilyList: any[] = [
		{label: "Arial", value: "Arial"}, 
		{label: "Arial PinYin", value: "ArialPinYin"}, 
		{label: "Arial PinYin 2018", value: "ArialPinYin2018"}, 
		{label: "PinYin Narrow", value: "PinYinNarrow"}, 
		{label: "Berkshire Swash", value: "BerkshireSwash"}, 
		{label: "Bree Serif", value: "BreeSerif"}, 
		{label: "Comic Neue Bold", value: "ComicNeueBold"}, 
		{label: "Comic Neue", value: "ComicNeue"}, 
		{label: "Comic Neue Angular", value: "ComicNeueAngular"}, 
		{label: "Droid Serif", value: "DroidSerif"}, 
		{label: "Handlee", value: "Handlee"}, 
		{label: "Lobster", value: "Lobster"}, 
		{label: "Major Mono Display", value: "MajorMonoDisplay"}, 
		{label: "Marmelad", value: "Marmelad"}, 
		{label: "Music", value: "Music"}, 
		{label: "Niconne", value: "Niconne"}, 
		{label: "Noto Sans Devanagari", value: "NotoSansDevanagari"}, 
		{label: "Nova Mono", value: "NovaMono"}, 
		{label: "Nunito", value: "Nunito"}, 
		{label: "Open Sans", value: "OpenSans"}, 
		{label: "Roboto", value: "Roboto"}, 
		{label: "Rochester", value: "Rochester"}, 
		{label: "Shadows into Light", value: "ShadowsintoLight"}, 
		{label: "Ultra", value: "Ultra"}, 
		{label: "黑體", value: "FNT_NOTO_BLACK"} , 
		{label: "棤書", value: "DFHKStdKai"} , 
		{label: "棤書 (香港)", value: "FNT_HK_KAI"} , 
		{label: "圓體", value: "FNT_YUANTI"} , 
		{label: "黑體 (極幼)", value: "FNT_NOTO_TC_THIN"} , 
		{label: "黑體 (幼)", value: "FNT_NOTO_TC_LIGHT"} , 
		{label: "黑體 (標準)", value: "FNT_NOTO_TC_REGULAR"} , 
		{label: "黑體 (中粗)", value: "FNT_NOTO_TC_MEDIUM"} , 
		{label: "黑體 (粗)", value: "FNT_NOTO_TC_BOLD"} , 
		{label: "黑體 (極粗)", value: "FNT_NOTO_TC_BLACK"} , 
	];
	
	constructor(
		private elRef:ElementRef,
		private whitePopupService:WhitePopupService
		
		) {
		this.dd = new DragManager();

		this.releaseOutSideSubscription = new Subscription(()=>{});
		["pointerdown", "tap", "mousedown", "wheel"].forEach((eventType:string)=>{
			this.releaseOutSideSubscription.add(
				fromEvent(window, eventType).subscribe((event)=>{
					if(this.selectedItems && this.selectedItems.length>0) {
						if(this.useInROPage) {
							// TODO

						} else {
							var target = this.foundAssetTarget(<HTMLElement>event.target);
							if(target && ("SELECTMARKER"==target.nodeName || this.selectedItems.find(e => e.target == target))) {
								console.log("in same target");
								return true;
							}
							
							console.log("release out side");
							this.selectTarget(null);

						}
					}
				})
			);
		});
	}

	ngOnDestroy(): void {
		this.releaseOutSideSubscription.unsubscribe();
		this.releaseOutSideSubscription = null;
	}
	////////////////////////////
	// propertyPanelConfigWIP //
	////////////////////////////

	public updatePropertyConfig(type: string) {
		const configurations = {
			roComponent: {
				headerStyle: { backgroundColor: "#063C38" },
				contentStyle: { backgroundColor: "#1C6D67" },
				headerTitle: "bookEditor.wordComponent",
				items: [],
				tabs: []
			},
		};

		const items = []
		const tabs = []
		if (this.selectedItems && this.selectedItems.length > 0 && this.selectedItems[0].obj instanceof ROTLFTextComponent) {
			tabs.push({
				tab: "wordComponentEdit",
				icon: faPalette,
			},{
				tab: "wordComponentMove",
				icon: 'property_btn_coordinate',
			},{
				tab: "wordComponentSound",
				icon: faVolume,
			})
			items.push({
				type: "textMarkup",
				buttonColor: "#ffffff",
				component: this.selectedItems[0].obj,
				content: {
					bold: this.selectedItems[0].obj.getPropertiesThroughPanel("markupBold"),
					italic: this.selectedItems[0].obj.getPropertiesThroughPanel("markupItalic"),
					underline: this.selectedItems[0].obj.getPropertiesThroughPanel("markupUnderline"),
					strikethrough: this.selectedItems[0].obj.getPropertiesThroughPanel("markupStrikethrough"),
				},
				callback: (event: any) => {
					if (typeof event === 'string') {
						this.selectedItems[0].obj.setPropertiesThroughPanel(event);
					}
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "textAlign",
				buttonColor: "#ffffff",
				component: this.selectedItems[0].obj,
				// content: this.selectedItems[0].obj.getPropertiesThroughPanel("align") !== 'start' ? this.selectedItems[0].obj.getPropertiesThroughPanel("align") : "left",
				content: this.selectedItems[0].obj.getPropertiesThroughPanel("align"),
				callback: (event: any) => {
					if (typeof event === 'string') {
						this.selectedItems[0].obj.setPropertiesThroughPanel(event);
					}
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "pulldown",
				name: "bookEditor.fontFamily",
				options: this.fontFamilyList,
				content: this.selectedItems[0].obj.getPropertiesThroughPanel("fontFamily"),
				buttonColor: "#000000",
				component: this.selectedItems[0].obj,
				callback: (value: string) => {
					this.selectedItems[0].obj.setPropertiesThroughPanel("fontFamily", value);
					this.selectedItems[0].width = this.selectedItems[0].obj.w;
					this.selectedItems[0].height = this.selectedItems[0].obj.h;
					this.updateItemBoundingOnSelector(this.selectedItems[0]);
					this.emitPropertyPanel("fontFamily");
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "colorPicker",
				name: "bookEditor.textColor",
				content: this.rgbToHex(this.selectedItems[0].obj.getPropertiesThroughPanel("color")),
				// colors: this.selectedItems[0].obj.getPropertiesThroughPanel("color"),
				component: this.selectedItems[0].obj,
				callback: (event: any) => {
					console.log(this.selectedItems[0].obj.getPropertiesThroughPanel("color"))
					this.selectedItems[0].obj.setPropertiesThroughPanel("color", event);
					this.propertyPanelConfig.items.find((e: any) => e.type == "colorPicker").content = event;
					console.log("bookEditor.colorPicker", event);
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "number",
				name: "bookEditor.fontSize",
				buttonColor: "#ffffff",
				value: this.selectedItems[0].obj.getPropertiesThroughPanel("fontSize"),
				key: "fontSize",
				component: this.selectedItems[0].obj,
				callback: (event: any) => {
					if (typeof event === 'number') {
						this.selectedItems[0].obj.setPropertiesThroughPanel("fontSize", event);
					
						const fontSizeItem = this.selectedItems[0].settings.popupSelector.find((e: any) => e.key == "fontSize");
						if (fontSizeItem) {
							const fontSize = this.selectedItems[0].obj.getPropertiesThroughPanel("fontSize");
							fontSizeItem.newValue = fontSize;
							fontSizeItem.value = fontSize;
						}
						this.selectedItems[0].width = this.selectedItems[0].obj.w;
						this.selectedItems[0].height = this.selectedItems[0].obj.h;
						this.updateItemBoundingOnSelector(this.selectedItems[0]);
						this.emitPropertyPanel("font-size");
					}
					// console.log(this.selectedItems[0]);
					// this.updateItemOriginalPosition(null, this.selectedItems[0]);
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "number",
				name: "bookEditor.lineSpacing",
				buttonColor: "#ffffff",
				value: parseInt(this.selectedItems[0].obj.getPropertiesThroughPanel("lineSpacing")),
				key: "lineSpacing",
				component: this.selectedItems[0].obj,
				callback: (event: any) => {
					if (typeof event === 'number') {
						this.selectedItems[0].obj.setPropertiesThroughPanel("lineSpacing", event);
					}
					this.updateItemBoundingOnSelector(this.selectedItems[0]);
					this.emitPropertyPanel("lineSpacing");
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "number",
				name: "bookEditor.letterSpacing",
				buttonColor: "#ffffff",
				value: this.selectedItems[0].obj.getPropertiesThroughPanel("letterSpacing"),
				key: "letterSpacing",
				component: this.selectedItems[0].obj,
				callback: (event: any) => {
					if (typeof event === 'number') {
						this.selectedItems[0].obj.setPropertiesThroughPanel("letterSpacing", event);
					}
					this.updateItemBoundingOnSelector(this.selectedItems[0]);
					this.emitPropertyPanel("letterSpacing");
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "number",
				name: "bookEditor.transparency",
				buttonColor: "#ffffff",
				value: this.selectedItems[0].obj.getPropertiesThroughPanel("transparency"),
				key: "transparency",
				component: this.selectedItems[0].obj,
				step: 0.01,
				dp: 2,
				min: 0,
				max: 1,
				callback: (event: any) => {
					if (typeof event === 'number') {
						this.selectedItems[0].obj.setPropertiesThroughPanel("transparency", event);
					}
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "pulldown",
				name: "bookEditor.autoWidth",
				options: [{label: "bookEditor.autoWidthManual", value: false}, {label: "bookEditor.autoWidthAuto", value: true}],
				content: this.selectedItems[0].obj.getPropertiesThroughPanel("autoWidth"),
				callback: (value: string) => {
					this.selectedItems[0].obj.setPropertiesThroughPanel("autoWidth", value);
					this.selectedItems[0].settings.widthResizer = !value;
					// this.selectedItems[0].width = this.selectedItems[0].obj.w;
					// this.selectedItems[0].height = this.selectedItems[0].obj.h;
					this.updateItemBoundingOnSelector(this.selectedItems[0]);
					this.emitPropertyPanel("autoWidth");
					console.log("bookEditor.pulldown", value);
					console.log(this.selectedItems[0])
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			},{
				type: "pulldown",
				name: "bookEditor.wordDirection",
				options: [{label: "bookEditor.wordDirectionLeftToRight", value: "ltr"}, {label: "bookEditor.wordDirectionRightToLeft", value: "rtl"}],
				content: this.selectedItems[0].obj.getPropertiesThroughPanel("wordDirection"),
				callback: (value: string) => {
					this.selectedItems[0].obj.setPropertiesThroughPanel("wordDirection", value);
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentEdit"
				}
			// },{
			// 	type: "switch",
			// 	name: "bookEditor.manualWordSeperation",
			// 	content: "bookEditor.manualWordSeperation",
			// 	callback: () => {
			// 		console.log("bookEditor.switch");
			// 	},
			// 	showWhen: (tag: string) => {
			// 		return tag === "wordComponentEdit"
			// 	}
			},{
				type: "doubleNumber",
				label1: "x",
				label2: "y",
				lock: false,
				value1: this.selectedItems[0].obj.getPropertiesThroughPanel("x"),
				value2: this.selectedItems[0].obj.getPropertiesThroughPanel("y"),
				callback: (value: number, numberLabel: string) => {
					if (typeof value !== 'number' || typeof numberLabel !== 'string') return;
					var property = '';
					if (numberLabel === "1") {
						property = 'x';
					} else if (numberLabel === "2") {
						property = 'y';
					}
					this.selectedItems[0].obj.setPropertiesThroughPanel(property, Math.round(value));
					this.updateItemBoundingOnSelector(this.selectedItems[0]);
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentMove"
				}
			},{
				type: "doubleNumber",
				label1: "w",
				label2: "h",
				value1: this.selectedItems[0].obj.getPropertiesThroughPanel("w"),
				value2: this.selectedItems[0].obj.getPropertiesThroughPanel("h"),
				disabled1: this.selectedItems[0].obj.getPropertiesThroughPanel("autoWidth"),
				disabled2: true,
				callback: (value: number, numberLabel: string) => {
					if (typeof value !== 'number' || typeof numberLabel !== 'string') return;
					var property = '';
					if (numberLabel === "1") {
						property = 'w';
					} else if (numberLabel === "2") {
						property = 'h';
					}
					this.selectedItems[0].obj.setPropertiesThroughPanel(property, Math.round(value));
					// this.selectedItems[0].obj.resizeWidth(value)
					if (numberLabel === "1") {
						// this.selectedItems[0].width = value;
						// this.selectedItems[0].height = this.selectedItems[0].obj.h;
						this.updateItemBoundingOnSelector(this.selectedItems[0]);
						this.emitPropertyPanel("w h");
					} else if (numberLabel === "2") {
						// this.selectedItems[0].height = value;
						this.updateItemBoundingOnSelector(this.selectedItems[0]);
					}
					// console.log('this.selectedItems[0]', this.selectedItems[0])
					// this.updateItemBoundingOnSelector(this.selectedItems[0]);
					// this.updateItemBoundingOnSelector(this.selectedItems[0]);
				},
				showWhen: (tag: string) => {
					return tag === "wordComponentMove"
				}
			});
			this.propertyPanelConfig = { ...configurations[type], items, tabs }
		} else {
			this.propertyPanelConfig = this.defaultPropertyPanelConfig
		}
	}

	public emitPropertyPanel(s: string = null):void {
		console.log('emitpropertypanel ', s)
		this.updatePropertyConfig('roComponent')
		this.emitter.emit({
			target: "component",
			type: "propertyPanel",
			selectedItems: this.selectedItems,
			panelConfig: this.propertyPanelConfig
		});
	}

	private rgbToHex(rgb: string): string {
		const [r, g, b] = rgb.match(/\d+/g);
		return `#${(+r).toString(16).padStart(2, '0')}${(+g).toString(16).padStart(2, '0')}${(+b).toString(16).padStart(2, '0')}`;
	}

	//////////////////////////////////////////////////////

	protected foundAssetTarget(target:HTMLElement):HTMLElement {
		if(target) {
			if(["IMG","VIDEO","AUDIOPLAYER","SELECTMARKER"].indexOf(target.nodeName)>=0) 
				return target;
			return this.foundAssetTarget(target.parentElement);
		}
		return null;
	}

	public setSelectedComponents(components:ROComponent[]):void
	{
		this.selectedItems = components.map((com:ROComponent)=>{
			return {
				obj:com, 
				target:com.getElementRef().nativeElement
			};
		})
		this.updateSelectedItemsSetting();
		this.updateOriginalPosition();
	}
	public getSelectedComponents():ROComponent[]{
		if(this.selectedItems && this.selectedItems.length)
		{
			return this.selectedItems.map((item:any)=>{
				return item.obj;
			});
		}
		return null;
	}
	public cancelSelect():void {
		//HERE
		this.selectedItems = [];
		if (this.selectedItems.length === 0) {
			this.propertyPanelConfig = this.defaultPropertyPanelConfig;
			this.emitter.emit({
				target: "component",
				type: "propertyPanel",
				selectedItems: this.selectedItems,
				panelConfig: this.propertyPanelConfig
			});
		}
		this.showSelector = false;
	}


	@Input() set target(data:any)
	{
		this.selectTarget(data);
	}

	public selectTarget(data:any):void 
	{
		//HERE
		var candel:boolean = false;

		// {type:"comMenu", qObj:this.qObj, key:key, asset:asset, target:target})
		this.selectedItems = data?[data] : [];
		if (this.selectedItems.length > 0) {
			this.emitPropertyPanel("selectTarget");
		}
		this.selectedItems.forEach((item:any)=>{
			item.editInStage = false;

			if(item.obj instanceof ROComponent) {
				var com:ROComponent = item.obj;
				item.dom = com.getBoundingDOM();
				item.editInStage = item.dom.editInStage;

			} else if(this.inEdit) {
				if((<ROComponent>this.inEdit.obj).canDelete(item.target))
					candel = true;
			}
		});

		console.log("this.inEdit 11111111111111", this.inEdit)
		if(this.inEdit) {
			console.log("this.inEdit 222222222222222", this.inEdit)
			var ary:any[] = this.inEdit ? this.inEdit.obj.getPopupSelector() : [];
			this.inEdit.settings.popupSelector = candel ? ary.concat(this.inEdit.obj.getPSDel()) : ary;
		}
		this.updateSelectedItemsSetting();
		this.updateOriginalPosition();
	}

	public refresh(obj:any):void {
		var item = this.selectedItems.find(e => e.obj == obj);
		if(item && item.obj instanceof ROComponent) {
			var com:ROComponent = item.obj;
			var rectangle:any = com.getComponentRectangle();
			item.width = rectangle.width;
			item.height = rectangle.height;
			this.positionUpdateCount++;
		}
	}

	onComponentMenuBarEmitter(itm, data:any)
	{
		console.log("onComponentMenuBarEmitter", itm, data);
		/*
		// this.emitter.emit(o);
		this.emitter.emit({
			reference:o,
			dom:event.target,
			target:o.target,
			type:"action",
			action:o.action,
			component:o.component
		})
		*/
		/*
		var o:any {
			type:"update", event:event, 
			item:item, parameter:parameter,
			value:newValue
		}
		*/
		
		if(data.type == "onInit")
		{

		} else if(data.type == "open")
		{

		} else if(data.type == "process")
		{
			/*
			if(o.type == "action")
			{
				
				this.emitter.emit({
					reference:o,
					dom:event.target,
					target:o.target,
					type:"action",
					action:o.action,
					component:o.component
				})
			}
			*/
			// debugger;
			/*
			var reference:any = o.item;
			var event:any = o.event;
			this.emitter.emit({
				reference:reference,
				dom:event.currentTarget,
				target:reference.target,
				type:o.type,
				component:reference.component,
				key:reference.key,
				value:o.value
			});
			*/
			
			var event:any = data.event;
			var o:any = data.item;
			var parameter = data.parameter;
			var r = data.reference;
			
			if(o.type == "action")
			{
/*				if(o.action == "editLayer") {
					// 將component 設定為 edit in stage
					itm.editInStage = true;
					itm.obj.editInStage = true;
					// 取得轉換後顯示的menu
					itm.settings.popupSelector = itm.obj.getPopupSelector();
					// 將 menu 以 bar 模式顯示
					this.inEdit = itm;
					this.selectedItems = [];
					this.positionUpdateCount++;
				}*/

				this.emitter.emit({
					reference:o,
					dom:event.target,
					target:o.target,
					type:"action",
					action:o.action,
					component:o.component
				});
			} else if(o.type == "number")
			{
				var tmpValue:number = o.value;
				var stepSize = o.step ? o.step :1;
				if(parameter == "-") tmpValue -= stepSize;
				else if(parameter == "+") tmpValue += stepSize;
				else if(parameter == "update") tmpValue = data.value;
				if(tmpValue === null) return;
				tmpValue = parseFloat(tmpValue.toFixed(o.dp));
				if(tmpValue > o.max) tmpValue = o.max;
				else if(tmpValue < o.min) tmpValue = o.min;
				o.newValue = tmpValue;
				this.emitter.emit({
					reference:o,
					target:"component",
					type:"update",
					component:o.component
				})
				if (o.key == "fontSize") {
					r.width = r.obj.w;
					r.height = r.obj.h;
					this.emitPropertyPanel("componentMenuBar");
				}
				this.updateItemBoundingOnSelector(r);
			} else if(o.type=="options")
			{
				if(o.multiSelect)
				{
					var subscriptions:Subscription = this.whitePopupService.showMultiSelection(
						event.currentTarget, 
						o.options, 
						{
							bindLabel:"label",
							direction:"auto-up-down"
						}
					).subscribe((data:any)=>{
						if(data.type == "close")
						{
							subscriptions.unsubscribe();
						} else if(data.type =="update")
						{
							o.newValue = data.selections.filter((selection:any)=>{
								return selection.selected ? true : false;
							}).map((selection)=>{
								return selection.value;
							});
							this.emitter.emit({
								reference:o,
								target:"component",
								type:"update",
								action:o.action,
								component:o.component
							});
							this.updateItemBoundingOnSelector(r);
							this.onValueChanged();
						}
					})
				} else {
					this.whitePopupService.showSelection(
						event.currentTarget, 
						o.options, 
						{
							bindLabel:"label",
							direction:"auto-up-down"
						}
					).then((selection:any)=>{
						o.newValue = selection.value;
						this.emitter.emit({
							reference:o,
							target:"component",
							type:"update",
							action:o.action,
							component:o.component
						});
						this.updateItemBoundingOnSelector(r);
						this.onValueChanged();
					}).catch((reason:any)=>{
						console.log("selectection rejected", reason);
					})
				}

			} else if(o.type=="complete") {
				this.emitter.emit({
					reference:o,
					dom:event.target,
					target:o.target,
					type:"action",
					action:"complete",
					component:o.component
				});
			} else if(o.type == "boolean")
			{
				o.newValue = data.event;
				//o.newValue = tmpValue;
				this.emitter.emit({
					reference:o,
					target:"component",
					type:"update",
					component:o.component
				});
			}
			this.updateItemBoundingOnSelector(r);
		}
	}
	private onValueChanged():void
	{
		this.updateSelectedItemsSetting();
	}
	public updateSelectedItemsSetting():void
	{
		if(!this.selectedItems.length) return;
		this.positionUpdateCount++;
		
		this.selectedItems.forEach(itm => {
			if(itm.obj instanceof ROComponent) {
				var component:ROComponent = itm.obj;
				itm.settings = component.getSelectorSettings();
				itm.editInStage = component.editInStage;
				var component:ROComponent = <ROComponent>itm.obj;
				if(itm && itm.settings && itm.settings.popupSelector )
				{
					itm.settings.popupSelector.forEach((item:any)=>{
						if(!item) return;
						item.component = component;

						if(item.type == "number")
						{
							item.value = (<ROComponent>itm.obj).getPropertiesThroughPanel(item.key);
							item.newValue = item.value;
						}

						if(item.type == "options")
						{
							var value:any = (<ROComponent>itm.obj).getPropertiesThroughPanel(item.key);
							item.newValue = value;
							item.value = value;
							
							if(item.multiSelect)
							{
								var array:any [] = value;
								item.options.forEach((option:any)=>{
									option.selected = (array.indexOf(option.value) != -1);
								});
							} else {
								item.options.forEach((option:any)=>{
									option.selected = (option.value == value);
								});
							}
						}
					})
				}
			} else if(!itm.settings){
				itm.settings = {rotation:false,lockedAspectRatio:false,widthResizer:false,heightResizer:false,resizer:itm.target.nodeName == "IMG",move:false};
			}
		});
	}

	private updateItemOriginalPosition(position:any, itm:any):void
	{
		if(itm && !itm.target && itm.dom) {
			itm.target = itm.dom;
		}
		
		// 取得在 selector marker layer 實際 x,y,width,height
		let gRect:DOMRect = itm.target.getBoundingClientRect() as DOMRect; // 取得 target 的 global 座標
		let result = DOMHelper.convertGRect(gRect, this.elRef.nativeElement);
		// itm.gx = itm.x;
		// itm.gy = itm.y;
		// itm.gw = result.width;
		// itm.gh = result.height;
		// itm.gw = itm.target.clientWidth;
		// itm.gh = itm.target.clientHeight;
		// itm.gw = itm.width;
		// itm.gh = itm.height;
		itm.rotation = itm.obj.rotation;
		
		if(itm.obj instanceof ROComponent) {
			var component:ROComponent =  <ROComponent>itm.obj;
			var rectangle:any = component.getComponentRectangle(); // component local position and size
			itm.x = itm.orgx = component.x;
			itm.y = itm.orgy = component.y;
			itm.offsetX = rectangle.x;
			itm.offsetY = rectangle.y;
			itm.width = rectangle.width;
			itm.height = rectangle.height;
			
		} else if(itm.target) {
			itm.offsetX = 0;
			itm.offsetY = 0;
			result = DOMHelper.convertGRect(gRect, itm.target);
			itm.x = itm.orgx = result.x;
			itm.y = itm.orgy = result.y;
			itm.width = result.width;
			itm.height = result.height;
		}
		itm.gx = itm.x;
		itm.gy = itm.y;
		itm.gw = itm.width;
		itm.gh = itm.height;
	}
	public updateOriginalPosition():void {
		
		if(!this.selectedItems.length) return;
		this.positionUpdateCount++;
		var position:any = DOMHelper.getDOMGlobalPosition(this.elRef.nativeElement);

		this.selectedItems.forEach(itm => {
			this.updateItemOriginalPosition(position, itm);
			
		});
	}

	public onPointerDown(itm, event:any):void
	{
		this.startMove(itm, event);
	}
	
	public startMove(itm, event):void {
		console.log('startMove')
		if(itm && 
			((itm.obj && (itm.obj instanceof ROComponent) && (<ROComponent>itm.obj).editInStage) || !itm.obj))
			return;
		event.stopImmediatePropagation();

		if(itm.obj instanceof ROComponent)
		{
			var com:ROComponent = itm.obj ;
			if(com.lockXY())
			{
				return;
			}
		}
		if(this.useInROPage) {
			var startPt;
			var subject:Subject<any> = this.dd.pointerStart(event);
			var subscription:Subscription = subject.subscribe((o:any)=>{

				var component:ROComponent = itm.obj instanceof ROComponent ? itm.obj : null;
				if(o.type == "start") {
					startPt = DOMHelper.getLocalPoint(this.elRef.nativeElement, o.point);
					var position:any = DOMHelper.getDOMGlobalPosition(this.elRef.nativeElement);
					this.updateItemOriginalPosition(position, itm);
					if(component)
						component.onTransformBegin();
					this.emitter.emit({type:"moveStart", target:"editor"});
				} else if(o.type == "move") {
					var newPt = DOMHelper.getLocalPoint(this.elRef.nativeElement, o.point);
					itm.x = newPt.x - startPt.x + itm.orgx;
					itm.y = newPt.y - startPt.y + itm.orgy;

					if(component) {
						component.setPosition(itm.x, itm.y);
						component.onTransforming();
					}
				} else if(o.type == "end" || o.type == "cancel" || o.type == "timeout") {
					this.positionUpdateCount++;
					var newPt = DOMHelper.getLocalPoint(this.elRef.nativeElement, o.point);
					if(component) {
						component.setPosition(Math.round(itm.x), Math.round(itm.y));
						component.onTransformComplete();
						component.saveCoordinateExpression();
					}
					subscription.unsubscribe();
					this.emitter.emit({
						type:"moveEnd", target:"editor", 
						newX: Math.round(itm.x), newY: Math.round(itm.y),
						height: itm.height, width: itm.width
					});
					this.emitPropertyPanel();
				}

				this.updateItemBoundingOnSelector(itm);
			});

		}
	}

	protected updateItemBoundingOnSelector(itm:any):void {
		
		if(!itm)
			return;
		
		let gRect:DOMRect = itm.target.getBoundingClientRect() as DOMRect; // 取得 target 的 global 座標
		// gRect.width = itm.target.clientWidth;
		// gRect.height = itm.target.clientHeight;
		let result = DOMHelper.convertGRect(gRect, this.elRef.nativeElement);
		// itm.gx = result.x;
		// itm.gy = result.y;
		// itm.gw = result.width;
		// itm.gh = result.height;
		itm.gx = itm.target.offsetLeft;
		itm.gy = itm.target.offsetTop;
		itm.gw = itm.target.clientWidth;
		itm.gh = itm.target.clientHeight;
		itm.rotation = itm.obj.rotation;

	}

	public transformDistanceWithDegree(pt:any, degree:number):any {
		return {
			x: pt.x * Math.cos(degree * Math.PI / 180), 
			y: pt.y * Math.sin(degree * Math.PI / 180)
		};
	}

	public startResizeWidth(itm, event, type:string):void {
		event.stopImmediatePropagation();
		if(!(itm.obj instanceof ROComponent))
			return;
		var offset:any = {x:0,y:0};
		var subject:Subject<any> = this.dd.pointerStart(event);
		var subscription:Subscription = subject.subscribe((o:any)=>{
			var newPt = DOMHelper.getLocalPoint(this.elRef.nativeElement, o.point);
			
			var component:ROComponent =  itm.obj;
			if(o.type == "start") {
				offset.x = Math.round(newPt.x) - component.x - component.w;
				offset.y = Math.round(newPt.y) - component.y - component.h;
				component.onTransformBegin();
				this.emitter.emit({type:"resizeStart", target:"editor"});
			}

			itm.width = (newPt.x - itm.x) * Math.cos(itm.rotation * Math.PI / 180) + (newPt.y - itm.y) * Math.sin(itm.rotation * Math.PI / 180);

			var result:any = component.resizeWidth(itm.width);
			if(result) {
				// 有修正
				itm.width = result.width;
				itm.height = result.height;
			}
			component.onTransforming();

			if(o.type == "end" || o.type == "cancel" || o.type == "timeout") {
				subscription.unsubscribe();
				component.onTransformComplete();
				this.emitter.emit({type:"resizeEnd", target:"editor"});
				this.emitPropertyPanel();
			}
					
			this.updateItemBoundingOnSelector(itm);
		});
	}
	
	public startResize(itm, event, type:string):void {
		event.stopImmediatePropagation();

		var offset:any = {x:0,y:0};
		var subject:Subject<any> = this.dd.pointerStart(event);
		var subscription:Subscription = subject.subscribe((o:any)=>{
			var newPt = DOMHelper.getLocalPoint(this.elRef.nativeElement, o.point);

			if(itm.obj instanceof ROComponent) {
				var component:ROComponent =  <ROComponent>itm.obj;
				if(o.type == "start") {
					offset.x = Math.round(newPt.x) - component.x - component.w;
					offset.y = Math.round(newPt.y) - component.y - component.h;
					component.onTransformBegin();
					this.emitter.emit({type:"resizeStart", target:"editor"});
				}

				var w:number = newPt.x - itm.x - offset.x;
				var h:number = newPt.y - itm.y - offset.y;
				var r:number = component.w/component.h;
				if(r >= w/h) {
					// fit width
					itm.width = w;
					itm.height = w/r;
				} else {
					// fit height
					itm.height = h;
					itm.width = h*r;
				}
				var result:any = component.resize(itm.width, itm.height);
				if(result) {
					// 有修正
					itm.width = result.width;
					itm.height = result.height;
				}
				component.onTransforming();

				if(o.type == "end" || o.type == "cancel" || o.type == "timeout") {
					subscription.unsubscribe();
					component.onTransformComplete();
					this.emitter.emit({type:"resizeEnd", target:"editor"});
				}

			} else if(itm.target && itm.target.nodeName == "IMG") {
				var gRect = itm.target.getBoundingClientRect(); // 取得 target 的 global 座標
				if(o.type == "start") {
					offset.x = Math.round(newPt.x) - gRect.x - gRect.width;
					offset.y = Math.round(newPt.y) - gRect.y - gRect.height;
				}

				var w:number = newPt.x - gRect.x - offset.x;
				var h:number = newPt.y - gRect.y - offset.y;
				var r:number = itm.target.naturalWidth/itm.target.naturalHeight;
				if(r >= w/h) {
					// fit width
					itm.width = w;
					itm.height = w/r;
				} else {
					// fit height
					itm.height = h;
					itm.width = h*r;
				}

				itm.type = "resize";
				this.change.emit(itm);

				if(o.type == "end" || o.type == "cancel" || o.type == "timeout") {
					subscription.unsubscribe();
					itm.type = "resizeEnd";
					this.change.emit(itm);
				}

			}

			this.updateItemBoundingOnSelector(itm);
			

		});
	}

/*	public getLocalToGlobalMatrix(layer:any):any {
		var m:any = new Matrix();
		// var rect = layer.getBoundingClientRect();
		// var _scale:number = 1;
		while( layer && !isNaN( layer.offsetLeft ) && !isNaN( layer.offsetTop ) ) {
			var rotate:number = DOMHelper.getElementTransformRotate(layer);
			var scale:number = DOMHelper.getElementTransformScale(layer);
			m.translate(layer.offsetLeft, layer.offsetTop);
			m.rotateDeg(rotate);
			m.scale(scale, scale);
			layer = layer.offsetParent;
		}
		return m;
	}
	*/
	public startRotate(itm, event, type:string):void {
		event.stopImmediatePropagation();
        var component:ROComponent = itm.obj instanceof ROComponent ? itm.obj : null;
		
		var subject:Subject<any> = this.dd.pointerStart(event);
		// var cPt:any = {x:itm.x+itm.width/2, y:itm.y+itm.height/2};
		var cPt:any = {x:itm.x, y:itm.y};
		var startAng:number;
		var subscription:Subscription = subject.subscribe((o:any)=>{
			var newPt = DOMHelper.getLocalPoint(this.elRef.nativeElement, o.point);
			if(o.type == "start") {
				startAng = this.p2pAngle(cPt.x, cPt.y, newPt.x, newPt.y);
			} else if(o.type == "move") {
				var ang:number = this.p2pAngle(cPt.x, cPt.y, newPt.x, newPt.y);
				event.target.parentNode.style.transform = "rotate("+ang+"deg)";
				itm.rotation = ang;
				component.rotation = ang;
				component.updateRotation();

			} else if(o.type == "end") {
				component.saveCoordinateExpression();
				subscription.unsubscribe();
			} else if(o.type == "cancel" || o.type == "timeout") {
			}
		});
	}

	public posAngle(angle:number):number {
		while(angle<0)
			angle+=360;
	
		return angle%360;
	}

	public toDegree(radian:number):number {
		return radian * 180.0 / Math.PI;
	}

	public p2pAngle(x1:number, y1:number, x2:number, y2:number):number {
		return this.posAngle(this.toDegree(Math.atan2(y2 - y1, x2 - x1)));
	}

}