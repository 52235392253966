import { EventEmitter, Injectable } from '@angular/core';
import { AlertComponent, PromptDialogComponent, SelectionComponent, SelectionComponent2 } from '../sharedModule/alertModule/alert.component';
import { DynamicComponentService } from './dynamicComponent.service';
import { TranslateService } from '@ngx-translate/core';
import { Alert2Component } from '../sharedModule/alert2Module/alert2.component';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class AlertService {
	private _root:HTMLElement;
    constructor(private dcs:DynamicComponentService, private translate:TranslateService, private toast: MessageService) {
    }

	public setupRootElement(root:HTMLElement):void
	{
		this._root = root;
	}
    //---------------------------------------------------------------------------------------------
    public ifWaitTooLong(timeoutCallback:Function, waitCallback:Function, cancelCallback:Function, ms:number=18000):any {
        let alert:AlertComponent = this.dcs.create(AlertComponent);
        alert.showCnlBtn = true;
        alert.confirmClick.subscribe(()=>waitCallback());
        alert.cancelClick.subscribe(()=>cancelCallback());

        let killer:any = {
            dismissed: false
        };     

        killer.timer = setTimeout(() => {
            alert.message = this.translate.instant("commonService.processTimeoutMsg");
            alert.confirmLabel = this.translate.instant("commonService.processTimeoutWait");
            alert.cancelLabel = this.translate.instant("commonService.processTimeoutCancel");
            timeoutCallback();
            alert.open();
        }, ms);

        killer.dismiss = ()=>{
            killer.dismissed = true;
            alert.close();
            clearTimeout(killer.timer);
        };

        return killer;
    }

	public prompt(title:string, currentValue:string):Promise<any>
	{
		let component:PromptDialogComponent = this.dcs.create(PromptDialogComponent);
		component.title = title;
		component.value = currentValue;
		// component.open(this._root ? this._root : document.body);
		component.open(this.getFirstDOM([this._root, document.body]));
		return this.eventEmitterToPromise(component.emitter);
	}

	public confirm(message:string, translate:boolean = false, options:any={}):Promise<any>
	{
		let component: AlertComponent = this.dcs.create(AlertComponent);
		component.message = translate ? this.translate.instant(message) : message;
		component.showCnlBtn = true;
		for(const prop in options){
			if (component[prop] !== undefined){
				component[prop] = options[prop];
			}
		}
		// component.open(this._root ? this._root : document.body);
		component.open(this.getFirstDOM([this._root, document.body]));
		return this.eventEmitterToPromise(component.emitter);
	}

	public showSelection(target:any, list:any [], direction:string = "down"):Promise<any>
	{
		let component: SelectionComponent2 = this.dcs.create(SelectionComponent2);
		component.direction = direction;
		component.target = target;
		component.selection = list;
		// component.open(this._root ? this._root : document.body);
		component.open(this.getFirstDOM([this._root, document.body]));
		return this.eventEmitterToPromise(component.emitter);
	}
	private eventEmitterToPromise(emitter:EventEmitter<any>):Promise<any>
	{
		return new Promise((resolve, reject) => {
			emitter.subscribe({
				next: (value: any) =>
				{
					resolve(value);
				},
				error: (reason: any) =>
				{
					reject(reason);
				}
			});
		});
	}
	/**
	 	this.alertService.promptSelection("title",  
		[
			{ label: 1, value: 1 },
			{ label: 2, value: 2 },
			{ label: 3, value: 3 }
		])
	 */
	public promptSelection(title:string, selections:any, value:any = null):Promise<any>
	{
		// var dcs:DynamicComponentService = SingleInstance.get("dcs");
		let dialog: SelectionComponent = this.dcs.create(SelectionComponent);
		dialog.message = title;
		dialog.selections = selections;
		dialog.selectedObject = value;
		// dialog.open(this._root ? this._root : document.body);
		dialog.open(this.getFirstDOM([this._root, document.body]));
		return dialog.getPromise();
	}
    //---------------------------------------------------------------------------------------------
    public alert(message:string='', params:any=null, alertOptions:any={}, translate:boolean = true):Promise<void> {
        return new Promise((resolve:Function, reject:Function)=>{
            let alert:AlertComponent = this.dcs.create(AlertComponent);
            for (let key in alertOptions) if (alert.hasOwnProperty(key)) alert[key]=alertOptions[key];
            //alert._showShadow = showShadow;
            if (message) alert.message = translate ? this.translate.instant(message, params) || message : message;
            alert.confirmClick.subscribe(()=>resolve());
            alert.cancelClick.subscribe(()=>reject());
            // alert.open(this._root ? this._root : document.body)
			alert.open(this.getFirstDOM([this._root, document.body]));
        });
    }
    //---------------------------------------------------------------------------------------------
    public alert2(message:string='', params:any=null, alertOptions:any={}, ele:HTMLElement=null):Promise<string> {
        return new Promise((resolve:Function, reject:Function)=>{
            let alert:Alert2Component = this.dcs.create(Alert2Component);
            for (let key in alertOptions) if (alert.hasOwnProperty(key)) alert[key]=alertOptions[key];
            if (message) alert.message = this.translate.instant(message, params) || message;
            alert.btnClick.subscribe((key)=>resolve(key));
			alert.open(this.getFirstDOM([ele, this._root, document.body]));
		});
    }
	public createAlertComponent(alertOptions:any={}):Alert2Component
	{
		let alert:Alert2Component = this.dcs.create(Alert2Component);
		for (let key in alertOptions) if (alert.hasOwnProperty(key)) alert[key]=alertOptions[key];
        return alert;
	}
    //---------------------------------------------------------------------------------------------
	public okAlert(message='', obj={}){
		return this.alert2(message, obj,{btns:[[{key:'ok',labelKey:"alert.ok", ngStyle:{backgroundColor:"var(--alert-okbuttoncolor)", width: '278px'} }]]});
	}
	
	public deleteAlert(message='', obj={}){
		return this.alert2(message, obj, {btns: [['delete', 'cancel']] });
	}

	public yesNoAlert(message='', obj={}){
		return this.alert2(message, obj, {btns: [['yes', 'no']] });
	}

	private getFirstDOM(doms:HTMLElement []):HTMLElement
	{
		for(var i = 0;i < doms.length;i++)
		{
			var dom:HTMLElement = doms[i];
			if(dom) return dom;
		}
		return null;
	}

	// new alert popup 
	public toastError(message:string = null, translateObj:any = {}){
		if (message == null){
			message = this.translate.instant("common.pls-complete-all-required-item");
		}
		const translatedMessage = this.translate.instant(message, translateObj);
		this.toast.add({key: 'tc', severity:'error', summary: translatedMessage });
	}

	public toastSuccess(message:string = null, translateObj:any = {}){
		if (message == null){
			message = 'Success message';
		}
		const translatedMessage = this.translate.instant(message, translateObj);
		this.toast.add({key: 'tc', severity:'success', summary: translatedMessage });
	}

}
