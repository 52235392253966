import {NgModule} from '@angular/core';
import {BookTocModalComponent} from "./book-toc-modal.component";
import {Modal2Module} from "../../../../../sharedModule/modal2Module/modal2.module";
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
	imports: [
		Modal2Module,
		CommonModule,
		FontAwesomeModule
	],
	exports: [
		BookTocModalComponent,
	],
	declarations: [
		BookTocModalComponent,
	],
	providers: [],
})
export class BookTocModule {
}
