
import { DataService } from 'src/app/service/data.service';
import { ROBookStructureReader, RODocumentDecoder } from 'src/app/ro/hk/openknowledge/ro/RODocumentDecoder';
import { GUID } from 'src/app/class/hk/openknowledge/encryption/GUID';

/**
	
 	this.documentService.open({id:1622654}).then((o:any)=>{
		console.log(o);
		debugger;
	})
 */
export class RODocumentService
{
	constructor(private datas:DataService)
	{
		
	}
	openDocument(entry:any):Promise<any>
	{
		return this.datas.call("ROBook.get_template_content", entry).then(
			(response:any):any=>{
				var decoder:RODocumentDecoder = new RODocumentDecoder();
				if(response.code == 200)
				{
					var chapter:any = response.chapter;
					var xml:string = decoder.decodeContent(chapter.content); 
					return {
						title:chapter.title,
						id:chapter.id,
						xml:xml
					};
				} else {
					return Promise.reject(response);
				}
			}
		);
	}

	open(book:any):Promise<any>
	{
		/*
		debugger;
		var folderID:number = 284589;
		var title:string = "(1)new book title";
		var xml:string = `<Doc lastModifyUser="Acer"><Chapter><Page w="1024" h="768" broadcast="true" slideTitle="Title 1" pageFitType="auto" pageStyle="None" questionCount="0" questionStartIndex="1" teacherNote="" color="#808080"></Page></Chapter></Doc>`;
		this.datas.call("ROBook.add_book_from_xml", folderID, title, xml).then((response:any)=>{
			debugger;
			console.log(response);
		});
		return Promise.reject(null);
		*/
		return this.datas.call("ROBook.get_book_setting_and_content", book).then(
			(a)=>{
				var decoder:RODocumentDecoder = new RODocumentDecoder();
				var setting:any;
				try{
					setting = decoder.decodeBookSetting(a.setting);
				} catch(err)
				{
					
				}

				if(!setting) {
					setting = {
						fontFamily:"Noto Sans T Chinese Regular",
						fontSize:32,
						glossary:"<Glossary/>",
						grpMarker:"engChar",
						qSize:3,
						scoreSize:3
					};
				}
				
				var chapters:any [] = a.chapters.map((chapter) => {
					// var xml:string = decoder.decodeContent(chapter.content); 
					var xml:string = chapter.content ? 
						decoder.decodeContent(chapter.content) :
						`<Doc douid="${this.createDouid()}" lastModifyUser=""><Chapter><Page douid="${this.createDouid()}" w="1024" h="768" broadcast="true" ver="1.0" color="#ffffff" slideTitle=""/></Chapter></Doc>`; 
					
					return {
						title:chapter.title,
						id:chapter.id,
						sort:chapter.sort,
						xml:xml
					};
				});
				return {
					book:{...a.book, pid: book.pid},
					setting:setting,
					chapters:chapters
				}
			}
		);
	}
	/*
	this.documentService.getBookStructure({id:1622654}).then((o:any)=>{
		console.log(o);
		debugger;
	})
	*/
	getBookStructure(book:any):Promise<any>
	{
		return this.open(book).then((o)=>{
			var reader:ROBookStructureReader = new ROBookStructureReader();
			var bookStrcture:any =  reader.getBookStructure(o);
			return bookStrcture;
		})
	}

	private createDouid():string {
		return GUID.create("OKD guid");
	}
}
