export class StyleUtils
{
	public static colorCodeToHex(dColor, defaultValue:string = null):string{
		if(dColor === "noColor")
		{
			return defaultValue;
		} else {
			var code = ( dColor & 0xFFFFFF);
			return '#' + ("000000" + (code).toString(16)).slice(-6);
		}
	}

	public static  applyStyleObj(ele:HTMLElement, styleObj:any):void 
	{
		for(var key in styleObj)
		{
			var value = styleObj[key];
			StyleUtils.setStyleVariable(ele, key, value);
		}
	}

	public static  setStyleVariable(ele:HTMLElement, key:string, value:any):void
	{
		var originalValue:any = StyleUtils.getStyleVariable(ele, key);
		if(originalValue !== value)
		{
			ele.style.setProperty('--'+key, value);
		}
	}

	public static getStyleVariable(ele:HTMLElement, key:string):any
	{
		return ele.style.getPropertyValue('--'+key);
	}

	public static getDeepStyleVariable(ele:HTMLElement, key:string):any
	{
		key = '--'+key;
		while(ele)
		{
			var value = ele.style.getPropertyValue(key);
			if(value) return value;
			ele = ele.parentElement;
		}
		return '';
	}
}
