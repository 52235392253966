import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, HostListener, Input, OnInit, ViewChild, ViewContainerRef } from "@angular/core";

import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";
import { StyleUtils } from "./StyleUtils";
import { ROComponent } from "./ROComponent";
import { PinYinObject, PinYinWord } from "./PinYin";
import { PinYinUtils } from "./PinYinUtils";
import { Subject } from "rxjs";
import { DOMHelper } from "src/app/common/DOMHelper";
import { PinYinMapUtils } from "./PinYinMapUtils";
import { ObjectUtils } from "src/app/common/ObjectUtils";
///////////
@Component({
	template:``
})

export class ROPinYinTextBasicComponent extends ROComponent
{
	private ctx:any;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	

	public getTagNames():string []
	{
		return ["PinYinTextBasic"];
	}
	
	
	private applyHighlightColorCode(name:string, highlight:any)
	{
		if(highlight != undefined  && highlight != "noColor" && highlight != false)
		{
			var colorCode:string = StyleUtils.colorCodeToHex(highlight);
			StyleUtils.setStyleVariable(this.elementRef.nativeElement, name, colorCode);
		}
	}
	
	protected buildContent():void
	{
		/**
		{
			"lineHeight":120,"spacing":0.2,
			"pinyin":{"color":29115,"size":12,"ratio":0.4,"font":"Arial PinYin 2018"},
			"text":{"font":"Noto Sans T Chinese Regular","color":0,"size":32},"letterSpacing":20,"highlight":15749978}'
		 */
		this.ctx = JSON.parse(this.node.getAttribute("context"));
		this.ctx.blockSize = this.ctx.text.size + this.ctx.letterSpacing; // hard code calculation;

		var textFlow:XMLNode = this.node.children[0];
		var utils:TextFlowUtils = new TextFlowUtils();

		
		utils.replaceHandler( "PYElement", new PinYinNodeCreator(this, this.ctx) );

		
		var dom:HTMLElement = utils.textFlowNodeToDOM(textFlow, false);
		
		var pinyinColorCode:string = StyleUtils.colorCodeToHex(this.ctx.pinyin.color);
		var textColorCode:string = StyleUtils.colorCodeToHex(this.ctx.text.color);
		/*
		this.ctx.text.font.split(" ").forEach((part)=>{
			dom.classList.add(part);
		})
		*/
		
		this.applyHighlightColorCode("highlight-color", this.ctx.highlight);
		this.applyHighlightColorCode("pinyin-color", this.ctx.pinyin ? this.ctx.pinyin.color : 0);
		this.applyHighlightColorCode("text-color", this.ctx.txt ? this.ctx.txt.color : 0);
		this.applyHighlightColorCode("letter-spacing", this.ctx.letterSpacing+"px");
		

		// this.applyHighlightColorCode("highlight-color", this.ctx.style.highlight);
		
		// this.formatBC(this.ctx.text.color);

		var spacing = (this.ctx.text.size * this.ctx.spacing);

		var lineHeight:number = ( this.ctx.pinyin.size + spacing + this.ctx.text.size);
		var marginBottom:number = (this.ctx.lineHeight - 100 ) / 100  * lineHeight;
		var totalNumber:number = lineHeight + marginBottom;
		// var textHeight:number = totalNumber - this.ctx.pinyin.size;
		var textHeight:number = this.ctx.text.size;
		var intent1:number = (this.ctx.text.size + this.ctx.letterSpacing)  * this.ctx.indent;
		var intent2:number = -intent1;
		var blockSize = this.ctx.text.size + this.ctx.letterSpacing;
		var textBlockHeight = totalNumber - this.ctx.pinyin.size;
		StyleUtils.applyStyleObj(
			dom, 
			{
				"margin-bottom" :  marginBottom + "px",
				"text-size" : this.ctx.text.size + "px",
				"pinyin-spacing" : spacing + "px",
				"pinyin-size" :  this.ctx.pinyin.size + "px",
				"line-height" : lineHeight + "px",
				"block-size" : blockSize + "px",
				"text-color": textColorCode,
				"pinyin-color":pinyinColorCode,
				"text-height":textHeight + "px",
				"text-block-height":textBlockHeight+"px",
				"total-line-height":totalNumber+ "px",
				// "pinyin-letter-spacing":this.ctx.letterSpacing + "px",
				"indent1":intent1 + "px",
				"indent2":intent2 + "px"
				
			}
		);
		

		//StyleUtils.setStyleVariable(dom, "margin-bottom",  marginBottom + "em");
		

		this.elementRef.nativeElement.appendChild(dom);
		// var words = [{"options":null,"pinyin":{"s":"p","tonePosition":0,"y":"in","pinyin":"pīn","shengmu":"p","selectedTone":null,"yunmu":"in","yunmutone":"īn","toneNumber":1,"isER":0},"text":"拼"}]
		// console.log(this.node)
	}

}

@Component({
	template:``
})

export class ROPinYinTextFillingComponent extends ROComponent
{
	private ctx:any;
	
	public filling:any [];
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public canVerify(): boolean {
		return true;
	}

	public getTagNames():string []
	{
		return ["PinYinTextFillingBasic"];
	}
	
	public isQuestionComponent(): boolean {
		return true;
	}

	private applyHighlightColorCode(name:string, highlight:any)
	{
		if(highlight != undefined  && highlight != "noColor" && highlight != false)
		{
			var colorCode:string = StyleUtils.colorCodeToHex(highlight);
			StyleUtils.setStyleVariable(this.elementRef.nativeElement, name, colorCode);
		}
	}

	protected buildContent():void
	{
		/**
		{
			"lineHeight":120,"spacing":0.2,
			"pinyin":{"color":29115,"size":12,"ratio":0.4,"font":"Arial PinYin 2018"},
			"text":{"font":"Noto Sans T Chinese Regular","color":0,"size":32},"letterSpacing":20,"highlight":15749978}'
		 */
		this.ctx = JSON.parse(this.node.getAttribute("context"));
		
		var textSize = this.ctx.text.size;
		this.ctx.blockSize = this.ctx.text.size + this.ctx.letterSpacing; // hard code calculation;

		var textFlow:XMLNode = this.node.children[0];
		var utils:TextFlowUtils = new TextFlowUtils();
		var creator:PinYinNodeCreator = new PinYinNodeCreator(this, this.ctx) ;
		this.filling = [];
		creator.filling = this.filling;
		utils.replaceHandler( "PYElement", creator);
		utils.replaceHandler( "PYFElement", creator );
		

		
		var dom:HTMLElement = utils.textFlowNodeToDOM(textFlow, false);
		var offset = Math.round(textSize * 0.2);
		dom.style.transform = `translate(0px, ${offset}px)`;
		var pinyinColorCode:string = StyleUtils.colorCodeToHex(this.ctx.pinyin.color);
		var textColorCode:string = StyleUtils.colorCodeToHex(this.ctx.text.color);
		/*
		this.ctx.text.font.split(" ").forEach((part)=>{
			dom.classList.add(part);
		})
		*/
		
		this.applyHighlightColorCode("highlight-color", this.ctx.highlight);
		this.applyHighlightColorCode("pinyin-color", this.ctx.pinyin ? this.ctx.pinyin.color : 0);
		this.applyHighlightColorCode("text-color", this.ctx.txt ? this.ctx.txt.color : 0);
		this.applyHighlightColorCode("letter-spacing", this.ctx.letterSpacing+"px");
		

		var spacing = (this.ctx.text.size * this.ctx.spacing);

		var lineHeight:number = ( this.ctx.pinyin.size + spacing + this.ctx.text.size);
		var marginBottom:number = (this.ctx.lineHeight - 100 ) / 100  * lineHeight;
		var totalNumber:number = lineHeight + marginBottom;
		// var textHeight:number = totalNumber - this.ctx.pinyin.size;
		var textHeight:number = this.ctx.text.size;
		var intent1:number = (this.ctx.text.size + this.ctx.letterSpacing)  * this.ctx.indent;
		var intent2:number = -intent1;
		var blockSize = this.ctx.text.size + this.ctx.letterSpacing;
		var textBlockHeight = totalNumber - this.ctx.pinyin.size;

		var pinyinQuestionMinWidth:number = Math.round(this.ctx.pinyin.size * 1.425+18);
		var unlineOffset = Math.round(this.ctx.pinyin.size  / 5) + 1;

		StyleUtils.applyStyleObj(
			dom, 
			{
				"text-translate-underline":"translateY(-"+unlineOffset + "px)",
				"underline-offset":unlineOffset + "px",
				"min-pinyin-width":pinyinQuestionMinWidth+"px",
				"margin-bottom" :  marginBottom + "px",
				"text-size" : this.ctx.text.size + "px",
				"pinyin-spacing" : spacing + "px",
				"pinyin-size" :  this.ctx.pinyin.size + "px",
				"line-height" : lineHeight + "px",
				"block-size" : blockSize + "px",
				"text-color": textColorCode,
				"pinyin-color":pinyinColorCode,
				"text-height":textHeight + "px",
				"text-block-height":textBlockHeight+"px",
				"total-line-height":totalNumber+ "px",
				// "pinyin-letter-spacing":this.ctx.letterSpacing + "px",
				"indent1":intent1 + "px",
				"indent2":intent2 + "px"
				
			}
		);
		this.elementRef.nativeElement.appendChild(dom);
	}

	public reset():void
	{
		this.filling.forEach((control:PinYinFillingDOMController)=>{
			control.setData(null);
			control.render();
		});
	}

	public set data(value:string) {
		
		this.answerChanged = false;
		if(value)
		{
			var answerArray:any = [] = JSON.parse(value);
			if(answerArray.length == this.filling.length)
			{
				this.filling.forEach((control:PinYinFillingDOMController, index:number)=>{
					var obj:any = answerArray[index];
					control.setData( obj ? new PinYinObject().fromObject(obj) : null );
					control.render();
				});
			} else {
				this.reset();
			}
		} else {
			this.reset();
		}
	}

	public get data():string {
		this.answerChanged = false;
		if(this.hasAnswer() == false) return null;
		
		var answerArray:any [] = this.filling.map((control:PinYinFillingDOMController, index:number)=>{
			if(control.changed) this.answerChanged = true;
			if(control.data)
			{// "[{"s":"b","yunmu":"a","shengmu":"b","toneNumber":1},{"s":"zh","yunmu":"o","shengmu":"zh","toneNumber":4},{"s":"r","yunmu":null,"shengmu":"r","toneNumber":1}]"
				var py:PinYinObject = control.data;
				// py.update();
				return {
					selectedTone:py.selectedTone,
					s:py.s,
					yunmu:py.yunmu,
					shengmu:py.shengmu,
					toneNumber:py.toneNumber
				};
			} else
			{
				return null;
			}
		})
		return JSON.stringify(answerArray);
	}
	
	public hasAnswer():boolean
	{
		var hasAnswer:boolean = false;
		this.filling.forEach((control:PinYinFillingDOMController, index:number)=>{
			if(control.data)hasAnswer = true;
		});
		return hasAnswer;
	}
	public defaultAnswer:any;

	public showDefaultAnswer(): void {
		this.filling.forEach((control:PinYinFillingDOMController, index:number)=>{
			control.showDefaultAnswer();
		});
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}
	public hideDefaultAnswer(): void {
		this.filling.forEach((control:PinYinFillingDOMController, index:number)=>{
			control.hideDefaultAnswer();
		});
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}

	public verify(_showScoring: boolean):any 
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.classList.remove("verify");
		if(_showScoring)
		{
			dom.classList.add("verify");
		}
		var total:number = this.filling.length;
		var correctCount:number = 0;
		this.filling.forEach((control:PinYinFillingDOMController, index:number)=>{
			if(control.verify(_showScoring))
			{
				correctCount++;
			}
		});
		if(correctCount == total)
		{
			this.resultObject = {correct:2};
		} else if(correctCount >  0) {
			this.resultObject = {correct:1};
		} else {
			this.resultObject = {correct:0};
		}
		if(total > 0)
		{
			this.resultObject.score = Math.round(correctCount / total * 10) / 10;
		}
		if(_showScoring)
		{
			this.sObj.showScoring(_showScoring, "view", this.resultObject);	
		} else {
			this.sObj.hideScore();
		}
		return this.resultObject;
	}
}
////////////
@Component({
	template:`
		<div class="pinyin-keyboard" #input tabindex="0">{{ defaultAnswer ? defaultAnswer.text : text}}</div>
	`,
	styles:[
		// <div ></div>
		/**
		
		
		

		 * 
		 */
		`
		:host{
			padding:10px;
		}
		

		.pinyin-keyboard{
			-webkit-user-select: none; /* Safari */
			-ms-user-select: none; /* IE 10+ */
			user-select: none;
			  
			text-align: center;
			color:#604228;
			border-radius: 10px;
			background-color:#E2FCC0;
			outline:none;
			height:100%;
			font-size: var(--font-size);
			line-height: 100%;
			border:none;
		}

		.pinyin-keyboard:focus{
			outline:solid 2px #543922;
			border:solid 1px #543922;
		}

		.pinyin-keyboard:empty
		{
			background-color:#F5EDDA;
		}
		
		:host.verify::after{
			content:" ";
			display:block;
			position:absolute;
			width:46px;
			height:46px;
			top: -10px;
    		right: -10px;
		}

		:host.verify.correct::after{
			background-image:url('./assets/img/component/mc_icons.svg?t=1#correct_icon');
		}
		:host.verify.incorrect::after{
			background-image:url('./assets/img/component/mc_icons.svg?t=2#incorrect_icon');
		}
		
		`

	]
})

export class ROPinYinBasicElementComponent extends ROComponent 
{
	@ViewChild('input', { static: false }) input: ElementRef;
	private ctx:any;
	pinyinType: string;
	private studentPY:any;
	public text:string;
	public correctPY: any;
	// public correctState:string = "";
	
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public isQuestionComponent(): boolean {
		return true;
	}
	
	public getTagNames():string []
	{
		return ["PinYinBasicElement"];
	}
	
	protected buildContent():void
	{
		this.ctx = JSON.parse(this.node.getAttribute("context"));
		var dom:HTMLElement = this.elementRef.nativeElement;
		StyleUtils.setStyleVariable(dom, "font-size", this.ctx.size+"px");
		this.correctPY = JSON.parse(this.node.attributes.py);
		this.pinyinType = this.getPinYinType();
	}

	getPinYinType(): string {
		return Object.keys(this.correctPY)[0];
	}

	ngAfterViewInit(): void {
		var subject = new Subject();
		var keyboardOptions:any = {
			isQP:false,
			isQN:false,
			shengmu:false,
			yunmu:false,
			toneNumber:false
		};
		keyboardOptions[this.pinyinType] = true;
	   	this.input.nativeElement.target = {
			subject:subject,
			options:keyboardOptions
	   	};
		subject.subscribe(this.onChange.bind(this));
	}
	private onChange(data:any):void
	{
		if(data.type == "assign")
		{
			this.answerChanged = true;
			this.studentPY = {};
			this.studentPY[data.key] = data.value;
			this.text = data.text;
		} else if(data.type == "clear")
		{
			this.answerChanged = true;
			this.studentPY = null;
			this.text = "";
		}
	}

	public reset():void
	{
		this.studentPY = null;
		this.text = "";
	}

	public set data(value:string) {
		this.defaultAnswer = null;
		this.answerChanged = false;
		this.reset();
		if(value)
		{
			this.studentPY = JSON.parse(value);
			for(var type in this.studentPY)
			{
				if(type == "toneNumber")
				{
					var array:string [] = ["", "ˉ","ˊ","ˇ","ˋ","."];
					this.text = array[this.studentPY[type]];
				} else {
					this.text = this.studentPY[type];
				}
				
			}
		}
	}

	public get data():string {
		return this.studentPY ? JSON.stringify(this.studentPY) : null;
	}
	public defaultAnswer:any = null;
	private getCorrectAnswer():string
	{
		var key:string;
		if (this.correctPY.shengmu)
		{
			key = "shengmu";
		} else if (this.correctPY.yunmu)
		{
			key = "yunmu";
		} else if (this.correctPY.toneNumber)
		{
			key = "toneNumber";
		}
		return this.correctPY[key] 
	}
	public showDefaultAnswer(): void {
		this.defaultAnswer = {
			text:this.getCorrectAnswer()
		};
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}
	public hideDefaultAnswer(): void {
		this.defaultAnswer = null;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}
	public verify(_showScoring: boolean) 
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.classList.remove("verify");
		dom.classList.remove("correct");
		dom.classList.remove("incorrect");
		if(this.studentPY)
		{
			this.resultObject = {correct:this.isCorrect() ? 2 : 0};
			this.resultObject.score = this.resultObject.correct == 2 ? this.getFullScore() : 0;
			
			if(_showScoring)
			{
				this.sObj.showScoring(_showScoring, "view", this.resultObject);	
				dom.classList.add(
					"verify",
					this.resultObject.correct == 2 ? "correct" : "incorrect"
				);
			} else {
				this.sObj.hideScore();
			}
		} else {
			this.sObj.hideScore();
			this.resultObject = null;
		}
		return this.resultObject;
	}
	
	private isCorrect():boolean
	{
		if(this.studentPY)
		{
			var key:string;
			if (this.correctPY.shengmu)
			{
				key = "shengmu";
			} else if (this.correctPY.yunmu)
			{
				key = "yunmu";
			} else if (this.correctPY.toneNumber)
			{
				key = "toneNumber";
			}
			return this.correctPY[key] == this.studentPY[key] 
		}
		return false;
	}
}


class PinYinNodeCreator
{
	filling: any[];
	constructor(public component:ROComponent, public ctx:any)
	{
		
	}
	private isPinYinQuestion(word:any):boolean
	{
		return (word && word.pinyin && word.options && word.options.pinyin && word.options.pinyin.isQ);
	}
	
	private isQuestion(words:PinYinWord []):boolean
	{
		for(var i = 0;i < words.length;i++)
		{
			if(this.isPinYinQuestion(words[i]))return true;
		}
		return false;
	}
	
	private createPinYinDOM(words:PinYinWord []):HTMLElement
	{
		// this.component = C1;
		var dom:HTMLElement = this.createNode({class:"pinyin-container"});
		if(this.isQuestion(words))
		{
			dom.classList.add("question");
			var controller = new PinYinFillingDOMController(this.component, this, dom, words);
			this.filling.push(controller);
			dom.style.minWidth = "var(--min-pinyin-width, 30px)";
		} else {
			this.createPinYinTextDOM(dom, words, null)
		}
		return dom;
	}

	public hasCompleteAnswer(word:any, answer:PinYinObject):boolean
	{
		var py:any = word.options.pinyin;
		var flag:boolean;
		if (py.yunmu.isQ)
		{
			if (!answer.yunmu)
			{
				return false;
			}
		}
		
		if (py.tone.isQ)
		{
			if (!answer.toneNumber)
			{
				return false;
			}
			
		}
		if (py.shengmu.isQ && !py.yunmu.isQ)
		{
			if (!answer.shengmu)
			{
				return false;
			}
		}
		return true;
	}
	
	public renderPinYinDefaultAnswer(container:HTMLElement, firstWord:any):void
	{
		var answer:PinYinObject = new PinYinObject();
		
		var completed:boolean = this.hasCompleteAnswer(firstWord, answer);
		var dom:HTMLElement = this.createNode({
			class:"content", 
			container:container
		});
		
		if(completed){
			container.classList.add("complete");
		}
		dom.style.pointerEvents = "none";
		
		var i:number;
		var len:number;
		var txt:String;
		var shengmu:String;
		var py:any = firstWord.options.pinyin;
		var pinyin:PinYinObject = firstWord.pinyin;
		var list1:any [] = [];
		var list2:any [] = [];

		var shengmuFormat:any = this.createElementFormat(this.ctx, py.shengmu.isQ, false, false);
		var yunmuFormat:any = this.createElementFormat(this.ctx, py.yunmu.isQ, false, false);
		var toneFormat:any = this.createElementFormat(this.ctx, py.tone.isQ, false, false);
		var isShengmuQN:boolean = py.shengmu.isQN && py.shengmu.isQ && py.yunmu.isQ;
		//var lightPosition:int;
		answer.shengmu = pinyin.shengmu;
		answer.yunmu = pinyin.yunmu;
		answer.toneNumber = py.light ? 5 : pinyin.toneNumber;
		
		answer.ti = pinyin.ti;
		answer.selectedTone = null;

		var y:String;
		
		if (answer.shengmu || answer.s)
		{
			isShengmuQN = false;		
		}

		var o:any = PinYinMapUtils.convertPinYin(pinyin.shengmu, py.yunmu.isQ, answer.shengmu, answer.yunmu, answer.toneNumber, isShengmuQN, answer.selectedTone);
		answer.yunmutone = o.v.yt;
		if (py.shengmu.isQ)
		{
			if (answer.shengmu)
			{
				answer.s = answer.shengmu;
			} else {
				if (isShengmuQN)
				{
					// answer.s = o.v.s ;
				} else {
					// something is wrong
					if (py.shengmu.isQ && !py.yunmu.isQ)
					{
						//answer.s = "";
					} else {
						answer.s = o.v.s;
					}
					
				}
			}
			
		} else {
			answer.s = o.v.s ;
		}
		
		answer.y = o.v.y;
		answer.selectedTone = o.v.t;
		
		var sh:string = answer.s;
		var yt:string = o.v.yt;
		var yu:string = answer.y;
		
		if (py.cap )
		{
			if (py.shengmu.isQ && !py.yunmu.isQ)
			{
				if (sh)
				{
					sh = PinYinUtils.capitalize(sh);
				}
			} else {
				if (sh)
				{
					sh = PinYinUtils.capitalize(sh);
				} else {
					yu = PinYinUtils.capitalize(yu);
					yt = PinYinUtils.capitalize(yt);
				}
			}
		}
		if (py.shengmu.isQ)
		{
			if (!answer.s && !py.yunmu.isQ)
			{
				shengmu = "    ";
				// shengmuFormat = format;
				
				yu = PinYinUtils.basicYunmuForm(answer.yunmu);
				
				
				yt = PinYinUtils.toPrimitiveYT(yu, answer.toneNumber, answer.selectedTone);
			} else {
				shengmu = sh;
				if (!py.yunmu.isQ)
				{
					yu = answer.y;
					yt = answer.yunmutone;
				}
			}
		} else {
			shengmu = sh;
		}
		
		list1.push({text:shengmu, format:shengmuFormat, underline:py.shengmu.isQ})
		
		
		var emptyHeader:Boolean = (py.tone.isQ && answer.toneNumber == 5 ) || (!py.tone.isQ && py.light);
		var ytIsSame:Boolean = (py.yunmu.isQ == py.tone.isQ || emptyHeader);
		if (yu && ytIsSame)
		{
			txt = emptyHeader ? yu : yt;
			list1.push({text:txt, format:yunmuFormat, underline:py.yunmu.isQ});
		} else {
			var topAndBoth:string ;
			var bottom:string 
			if (yu)
			{
				if (answer.toneNumber)
				{
					topAndBoth = yt;
					//lightPosition = shengmu.length + answer.ti ;//  PinYinUtils.yunmuPositionMap[answer.yunmu];
					bottom = PinYinUtils.hideToneMark(yt, true)
					
				} else {
					topAndBoth = "     ";
					bottom = yu;
				}
			} else {
				if (answer.toneNumber)
				{
					if (yt)
					{
						topAndBoth = "  " + yt  + "  " ;
					} else {
						topAndBoth = "    " ;
					}
					
					//lightPosition = shengmu.length + 1;
				} else {
					topAndBoth = "     "
				}
				bottom = "     ";
				// yunmuFormat = format;
			}
			if (topAndBoth)
			{
				list1.push({text:topAndBoth, format:toneFormat, underline:py.yunmu.isQ});
				// list1.push({text:topAndBoth, underline:py.yunmu.isQ});
			}
			list2.push({text:shengmu, format:shengmuFormat});
			list2.push({text:bottom, format:yunmuFormat});
			// list2.push({text:shengmu});
			// list2.push({text:bottom});
			
		}
		
		len = list1.length;

		var bottomDOM:HTMLElement = this.createNode({
			class:"bottom", 
			container:dom
		});
		var topDOM:HTMLElement = this.createNode({
			class:"top", 
			container:dom
		});
		
		
		len = list2.length;
		if (len)
		{
			
		}
		
		list1.forEach((item:any)=>{
			if (item.text)
			{
				var span = this.createNode({
					tag:"span",
					container:bottomDOM,
					text:item.text
				});
				if(item.format.color)
				{
					var hex = StyleUtils.colorCodeToHex(item.format.color);
					span.style.color = hex;
				}
			}
		})
		list2.forEach((item:any)=>{
			if (item.text)
			{
				var span = this.createNode({
					tag:"span",
					container:topDOM,
					text:item.text
				});
				if(item.format.color)
				{
					var hex = StyleUtils.colorCodeToHex(item.format.color);
					span.style.color = hex;
				}
			}
		})

	}
	
	

	public renderPinYinStudentAnswer(container:HTMLElement, firstWord:any, answer:PinYinObject):PinYinObject
	{
		if(answer == null)
		{
			answer = new PinYinObject();
		}
		var completed:boolean = this.hasCompleteAnswer(firstWord, answer);
		var dom:HTMLElement = this.createNode({
			class:"content", 
			container:container
		});
		
		if(completed){
			container.classList.add("complete");
		}
		dom.style.pointerEvents = "none";
		if(!answer)
		{
			answer = new PinYinObject();
		}
		var i:number;
		var len:number;
		var txt:String;
		var shengmu:String;
		var py:any = firstWord.options.pinyin;
		var pinyin:PinYinObject = firstWord.pinyin;
		var list1:any [] = [];
		var list2:any [] = [];

		var shengmuFormat:any = this.createElementFormat(this.ctx, py.shengmu.isQ, false, false);
		var yunmuFormat:any = this.createElementFormat(this.ctx, py.yunmu.isQ, false, false);
		var toneFormat:any = this.createElementFormat(this.ctx, py.tone.isQ, false, false);
		var transparentToneFormat:any = ObjectUtils.clone(toneFormat);
		transparentToneFormat.color = "transparent";
		var isShengmuQN:boolean = py.shengmu.isQN && py.shengmu.isQ && py.yunmu.isQ;
		//var lightPosition:int;
		if (!py.shengmu.isQ)
		{
			answer.shengmu = pinyin.shengmu;
		}
		if (!py.yunmu.isQ)
		{
			answer.yunmu = pinyin.yunmu;
		}
		if (!py.tone.isQ)
		{
			if (py.light)
			{
				answer.toneNumber = 5;
			} else {
				answer.toneNumber= pinyin.toneNumber;
			}
		}
		if (!py.tone.isQP)
		{
			answer.ti = pinyin.ti;
			answer.selectedTone = null;
		}
		//isShengmuQN
		if (py.shengmu.isQ && !py.yunmu.isQ)
		{
			answer.s = "";
		}
		if (answer.shengmu || answer.s)
		{
			isShengmuQN = false;		
		}
		var o:any = PinYinMapUtils.convertPinYin(pinyin.shengmu, py.yunmu.isQ, answer.shengmu, answer.yunmu, answer.toneNumber, isShengmuQN, answer.selectedTone);
		answer.yunmutone = o.v.yt;
		if (py.shengmu.isQ)
		{
			if (answer.shengmu)
			{
				answer.s = answer.shengmu;
			} else {
				if (isShengmuQN)
				{
					// answer.s = o.v.s ;
				} else {
					// something is wrong
					if (py.shengmu.isQ && !py.yunmu.isQ)
					{
						//answer.s = "";
					} else {
						answer.s = o.v.s;
					}
					
				}
			}
			
		} else {
			answer.s = o.v.s ;
		}
		
		answer.y = o.v.y;
		answer.selectedTone = o.v.t;
		
		var sh:string = answer.s;
		var yt:string = o.v.yt;
		var yu:string = answer.y;
		
		if (py.cap )
		{
			if (py.shengmu.isQ && !py.yunmu.isQ)
			{
				if (sh)
				{
					sh = PinYinUtils.capitalize(sh);
				}
			} else {
				if (sh)
				{
					sh = PinYinUtils.capitalize(sh);
				} else {
					yu = PinYinUtils.capitalize(yu);
					yt = PinYinUtils.capitalize(yt);
				}
			}
		}
		if (py.shengmu.isQ)
		{
			if (!answer.s && !py.yunmu.isQ)
			{
				shengmu = "    ";
				// shengmuFormat = format;
				
				yu = PinYinUtils.basicYunmuForm(answer.yunmu);
				
				
				yt = PinYinUtils.toPrimitiveYT(yu, answer.toneNumber, answer.selectedTone);
			} else {
				shengmu = sh;
				if (!py.yunmu.isQ)
				{
					yu = answer.y;
					yt = answer.yunmutone;
				}
			}
		} else {
			shengmu = sh;
		}
		if(!shengmu) shengmu = "  ";
		list1.push({text:shengmu, format:shengmuFormat, underline:py.shengmu.isQ})
		
		
		var emptyHeader:boolean = (py.tone.isQ && answer.toneNumber == 5 ) || (!py.tone.isQ && py.light);
		var ytIsSame:boolean = (py.yunmu.isQ == py.tone.isQ || emptyHeader);
		var transparentTop:boolean = false;;
		if (yu && ytIsSame)
		{
			txt = emptyHeader ? yu : yt;
			list1.push({text:txt, format:yunmuFormat, underline:py.yunmu.isQ});
		} else {
			var topAndBoth:string ;
			var bottom:string 
			if (yu)
			{
				if (answer.toneNumber)
				{
					topAndBoth = yt;
					//lightPosition = shengmu.length + answer.ti ;//  PinYinUtils.yunmuPositionMap[answer.yunmu];
					bottom = PinYinUtils.hideToneMark(yt, true)
					
				} else {
					// topAndBoth = "     ";
					topAndBoth = yu;
					transparentTop = true;
					bottom = yu;
				}
			} else {
				if (answer.toneNumber)
				{
					if (yt)
					{
						bottom = topAndBoth = yt;
					} else {
						bottom = topAndBoth = "   " ;
					}
				} else {
					bottom = topAndBoth = "   ";
				}
			}
			if (topAndBoth)
			{
				if(transparentTop)
				{
					list1.push({text:topAndBoth, format:transparentToneFormat, underline:py.yunmu.isQ});
				} else {
					list1.push({text:topAndBoth, format:toneFormat, underline:py.yunmu.isQ});
				}
			}
			list2.push({text:shengmu, format:shengmuFormat});
			list2.push({text:bottom, format:yunmuFormat});
		}
		
		
		var bottomDOM:HTMLElement = this.createNode({
			class:"bottom", 
			container:dom
		});
		var topDOM:HTMLElement = this.createNode({
			class:"top", 
			container:dom
		});
		
		
		list1.forEach((item:any)=>{
			if (item.text)
			{
				var span = this.createNode({
					tag:"span",
					container:bottomDOM,
					text:item.text
				});
				if(item.format.color)
				{
					var hex = StyleUtils.colorCodeToHex(item.format.color);
					span.style.color = hex;
				}
				if(item.underline)
				{
					span.style.textDecoration = "underline";
				}
			}
		})

		list2.forEach((item:any)=>{
			if (item.text)
			{
				var span = this.createNode({
					tag:"span",
					container:topDOM,
					text:item.text
				});
				if(item.format.color)
				{
					var hex = StyleUtils.colorCodeToHex(item.format.color);
					span.style.color = hex;
				}
				if(item.underline)
				{
					span.style.textDecoration = "underline";
				}
			}
		})

		return answer;
	}
	
	
	protected createElementFormat(context:any, highlight:any, bold:any , italic:any):any
	{
		var format:any = {};
		var flagH:boolean =   (highlight !== undefined && highlight != "noColor" && highlight !== false ) ;
		var flagB:boolean = (bold !== undefined && bold !== false );
		var flagI:boolean = (italic !== undefined && italic !== false );
		if (flagH || flagB || flagI)
		{
			if (flagH)
			{
				if (highlight === true)
				{
					format.color = context.highlight;
				} else {
					format.color = highlight;
				}
			}
			if (flagB || flagI)
			{
				if (flagB)
				{
					format.fontWeight = "bold";
				}
				if (flagI)
				{
					format.fontPosture = "italic"; /// FontPosture.ITALIC;
				}
			}
		}
		return format;
	}
	/**
	 * 
	 * @param dom 
	 * @param words 
	 * @param data  // [{s: 'b', yunmu: 'a', shengmu: 'b', toneNumber: 1}]
	 */
	public createPinYinTextDOM(container:HTMLElement, words:PinYinWord [], dataArray:PinYinObject[] = null, isQuestion:boolean = false):void
	{
		var dom:HTMLElement = this.createNode({
			class:"content", 
			container:container
		});
		
		var list1:any [] = [];
		var list2:any [] = [];
		
		var len:number = words.length;
		var hasPrevious:boolean = false;
		
		var separatorObject:Object = {
			text:"’", 
			type:"separator"
			// format:format
		};
		var isCap:boolean;
		var t:string ;
		var o:any;
		
		hasPrevious = false;
		
		for (var i:number = 0; i < len; i++)
		{
			var word:PinYinWord = words[i];
			var data:PinYinObject = dataArray && dataArray[i] ? dataArray[i]: new PinYinObject();
			var isQ:boolean = word.options && word.options.pinyin&& word.options.pinyin.isQ;
			var questionOption:any ;
			if(isQ)
			{
				questionOption = {
					shengmu : word.options.pinyin.shengmu.isQ,
					yunmu : word.options.pinyin.yunmu.isQ,
					tone : word.options.pinyin.tone.isQ,
					er: word.options.pinyin.er.isQ
				};
				
			} else {
				questionOption = {
					shengmu : false,
					yunmu : false,
					tone : false,
					er: false
				};
			}
			var targetPinYin:PinYinObject = new PinYinObject();
			// targetPinYin
			// "[{"s":"b","yunmu":"a","shengmu":"b","toneNumber":1},{"s":"zh","yunmu":"o","shengmu":"zh","toneNumber":4},{"s":"r","yunmu":null,"shengmu":"r","toneNumber":1}]"
						
			targetPinYin.setup(
				questionOption.shengmu ? (data ? data.shengmu :"")  : (word.pinyin ? word.pinyin.shengmu : ""),
				questionOption.yunmu ? (data ? data.yunmu : "") : (word.pinyin ? word.pinyin.yunmu :""),
				questionOption.tone ? (data ? data.toneNumber : 5): (word.pinyin ? word.pinyin.toneNumber : -1)
			);
			if(data && word.pinyin)
			{
				if(!questionOption.shengmu) data.shengmu = word.pinyin.shengmu;
				if(!questionOption.yunmu) data.yunmu = word.pinyin.yunmu;
				if(!questionOption.toneNumber) data.toneNumber = word.pinyin.toneNumber;
			}
			targetPinYin.update();
			
			if (word.pinyin && word.options && word.options.pinyin)
			{
				var shengmuHighlight:boolean = word.options.pinyin.shengmu.highlight ? true : false;
				var yunmuHighlight:boolean = word.options.pinyin.yunmu.highlight ? true : false;
				var toneHighlight:boolean = word.options.pinyin.tone.highlight ? true : false;
				var erHighlight:boolean = word.options.pinyin.er.highlight ? true : false;
				if (word.options.pinyin.cap)
				{
					isCap = true;
				}
						
				if (hasPrevious && word.pinyin.shouldBeSeparated() && !word.pinyin.isER)
				{
					list1.push(separatorObject);
					list2.push(separatorObject);
				}
				if (!word.options.pinyin.hidden)
				{
					if (word.pinyin.isER)
					{
						o = {
							text:"r", 
							type:"er",
							highlight:erHighlight
							// format:erFormat 
						};
						list1.push(o);
						list2.push(o);
					} else 
					{
						// t = targetPinYin.shengmu;
						t = targetPinYin.s;
						if (t)
						{
							o = {
								text:t, 
								type:"shengmu",
								highlight:shengmuHighlight
								// format:shengmuFormat
							};
							list1.push(o);
							list2.push(o);
						}
						/*
						questionOption = {
							shengmu : false,
							yunmu : false,
							tone : false,
							er: false
						};
						*/
						
						if (word.options.pinyin.light)
						{
							if (targetPinYin.yunmu)
							{
								t = PinYinUtils.hideToneMark(targetPinYin.yunmutone, false);
								if (t)
								{
									o = {
										text:t, 
										type:"yunmu",
										highlight:yunmuHighlight
										// format:yunmuFormat
									};
									list1.push(o);
									list2.push(o);
								}
							}
						} else {
							if (targetPinYin.yunmutone)
							{
								
								t = targetPinYin.yunmutone;
								o = {
									text:t, 
									// format:toneFormat
									type:"tone",
									highlight:toneHighlight
								};
								list1.push(o);
								t = PinYinUtils.hideToneMark(targetPinYin.yunmutone)
								o = {
									text:t, 
									type:"yunmu",
									highlight:yunmuHighlight
								};
								list2.push(o);
								
							}
						}
						
					}
					hasPrevious = true;
				}
				
				
			}
		}
		if (isCap)
		{
			if (list1.length)
			{
				var first:any; 
				first = list1[0];
				
				first.text = PinYinUtils.capitalize(first.text);
				first = list2[0];
				first.text = PinYinUtils.capitalize(first.text);
			}
		}
		if(list1.length)
		{
			// var bottom:HTMLElement = document.createElement("div");
			var bottomDOM:HTMLElement = this.createNode({class:"bottom", container:dom});

			list1.forEach((element)=>{

				// var span:HTMLElement = 
				// var classList:any [] = [element.type];
				var classList:any [] = [];
				if(element.highlight)
				{
					classList.push("highlight");
				}
				this.createNode({
					tag:"span", 
					container:bottomDOM, 
					text:element.text, 
					class:classList
				});
			});
		} 
		
		if(list2.length)
		{
			var topDOM:HTMLElement = this.createNode({class:"top", container:dom});
			list2.forEach((element:any)=>{
				var classList:any [] = [element.type];
				if(element.highlight)
				{
					classList.push("highlight");
				}
				this.createNode({
					tag:"span", 
					container:topDOM, 
					text:element.text, 
					class:classList
				});
			});
		}
		
	}


	private createNode(options:any = {})
	{
		var tag:string = (options.tag)? options.tag : "div";
		var childElement:HTMLElement = document.createElement(tag);
		if(options.container) options.container.appendChild(childElement);
		if(options.class) {
			if(options.class instanceof Array)
			{
				options.class.forEach(className => {
					if(className) childElement.classList.add(className);
				});
			} else {
				childElement.classList.add(options.class);
			}
		}
		if(options.text) childElement.innerText = options.text;
		if(options.html) childElement.innerHTML = options.html;
		return childElement;
	}

	
	public createTextFlowDOM(rootNode:XMLNode, listElement:any, node:XMLNode, withBG:boolean):HTMLElement
	{
		var textFont:string = this.ctx.text.font.replace(/[ ]/g, "-");
		var wordsField:string = node.getAttribute("words");
		// console.log(wordsField);
		
		var words:any [] = JSON.parse(wordsField);
		
		var pinyinContainer:HTMLElement = this.createNode({class:"pinyin-element"});
		var pinyinElement:HTMLElement = this.createNode({class:"pinyin", container:pinyinContainer});
		var textElement:HTMLElement = this.createNode({class:"text", container:pinyinContainer});
		
		var tmp:PinYinWord [] = words.map((word:any):PinYinWord=>{
			var w:PinYinWord = new PinYinWord();
			w.options = word.options;
			w.pinyin = new PinYinObject().fromObject(word.pinyin);
			return w;
		})
		var pinyinDOM:HTMLElement = this.createPinYinDOM(tmp);
		pinyinElement.appendChild(pinyinDOM);
		
		/*
		words.forEach((word:any)=>{
			if(word.options && word.options.pinyin)
			{
				var pinyin = word.options.pinyin;
				if(pinyin && pinyin.shengmu)
				{
					console.log("highlight", pinyin.shengmu.highlight);
				}
				// pinyin.shengmu.highlight
			}
			// console.log(word.options.pinyin.shengmu.highlight)
		// this.applyHighlightColorCode("shengmu-highlight", word.options.pinyin.shengmu.highlight)

		})
		*/
		
		words.forEach((word:any)=>{

			var textblock:HTMLElement = this.createNode({
				container:textElement, 
				text:(word.options && word.options.text.hidden) ? "": word.text,
				class:textFont
			});

			if(word.options && word.options.pinyin && word.options.pinyin.hasOwnProperty("bg") )
			{
				var bg = StyleUtils.colorCodeToHex(word.options.pinyin.bg);
				StyleUtils.setStyleVariable(pinyinElement, "pinyin-bg", bg);
			}
			if(word.options && word.options.pinyin && word.options.pinyin.isQ)
			{

			}
			if(word.options && word.options.text )
			{
				if(
					word.options.text.hasOwnProperty("bg")  && 
					word.options.text.bg !== false
				)
				{
					var bg = StyleUtils.colorCodeToHex(word.options.text.bg);
					StyleUtils.setStyleVariable(textElement, "text-bg", bg);
				}
				if(
					word.options.text.hasOwnProperty("highlight") && 
					word.options.text.highlight !== false
				)
				{
					var highlight = StyleUtils.colorCodeToHex(word.options.text.highlight);
					StyleUtils.setStyleVariable(textElement, "text-color", highlight);
				}
			}
			if(word.options && word.options.underline)textblock.classList.add("underline");
			if(word.options && word.options.bold) textblock.classList.add("bold");
			if(word.options && word.options.lineThrough) textblock.classList.add("lineThrough");
			/*
			if(word.text == "兒")
			{
				debugger;
			}
			*/
			if(word.pinyin && word.pinyin.isER) textblock.classList.add("er");
		});
		// 縮排
		// 字距
		// 
		
		
		if(words.length)
		{
			/*
			var er:number = this.numberOfER(tmp);
			if(er)
			{
				var width:number = words.length * this.ctx.text.size;
				// var letterSpacing:int = ObjectUtils.navigate(this.ctx.style., "style.letterSpacing");
				var tSize:number = this.ctx.text.size;
				var letterSpacing:number = 0;
				var width:number = (words.length - (er * 0.2)) * tSize + letterSpacing;
			} else {
				var width:number = words.length * this.ctx.text.size;
			}
			*/
			
			var er:number = this.numberOfER(tmp);
			var width:number;
			if(er)
			{
				var width:number = words.length * this.ctx.text.size;
				// var letterSpacing:int = ObjectUtils.navigate(this.ctx.style., "style.letterSpacing");
				var tSize:number = this.ctx.text.size;
				var letterSpacing:number = 0;
				width = (words.length - (er * 0.2)) * tSize + letterSpacing;
			} else {
				width = words.length * this.ctx.blockSize;
			}
			// var width:number = words.length * this.ctx.blockSize;
			pinyinElement.style.width = pinyinContainer.style.width = width + "px";
			
		}
		// pinyinContainer.style.height = "50px";
		// pinyinContainer.style.border = "solid 1px red";
		

		return pinyinContainer;
	}

	protected numberOfER(words:PinYinWord []):number
	{
		var count:number = 0;
		words.forEach(
			(py:any)=>
			{ // var py:PinYinObject
				if (py && py.pinyin && py.pinyin.isER)
				{
					count++;
				}
			}
		)
		return count;
	}

}


class PinYinFillingDOMController
{
	
	private subject: Subject<any>;
	public data:PinYinObject;
	public changed:boolean = false;
	constructor(private component:ROComponent, private nodeCreator:PinYinNodeCreator, public dom:any, private words:any [])
	{
		this.subject = new Subject();
		
		dom.target = {
			control:this,
			data:null,
			subject:this.subject,
			options:this.getKeyboardOptions()
		}
		
		this.subject.subscribe(this.onChange.bind(this));
		this.init();
		this.render();
	}

	public getPinYinObject():PinYinObject
	{
		return this.currentAnswer;
	}

	private getKeyboardOptions():any
	{
		var word:any = this.words[0];
		var pyOption:any  = word.options.pinyin;
		return {
			isQP:pyOption.tone.isQ && pyOption.tone.isQP,
			isQN:pyOption.shengmu.isQN,
			shengmu:pyOption.shengmu.isQ,
			yunmu:pyOption.yunmu.isQ,
			toneNumber:pyOption.tone.isQ
		};
	}

	init() {
		this.dom.classList.add("pinyin-keyboard");
		this.dom.classList.add("blank");
		this.dom.setAttribute("tabindex", "0");
		this.dom.tabindex = 0;
	}
	public setData(d:any):void
	{
		this.data = d;
		this.changed = false;
	}
	
	onChange(a:any)
	{
		if(a.type == "assign")
		{
			this.changed = true;
			// "[{"s":"b","yunmu":"a","shengmu":"b","toneNumber":1},{"s":"zh","yunmu":"o","shengmu":"zh","toneNumber":4},{"s":"r","yunmu":null,"shengmu":"r","toneNumber":1}]"
			if(this.data == null)
			{
				this.data = new PinYinObject();
				this.data.shengmu = "";
				this.data.yunmu = "";
				this.data.toneNumber = 0;
			}

			if(a.key == "shengmu")
			{
				this.data.shengmu = a.value;
				
				// this.data.shengmu = a.value;
			} else if(a.key == "yunmu")
			{
				if(!this.data.shengmu)
				{
					this.data.s = a.s;
				}
				this.data.yunmu = a.value;
			} else if(a.key == "toneNumber")
			{
				this.data.toneNumber = a.value;
				this.data.selectedTone = a.selectedTone ? a.selectedTone : null;
			}
			// this.data.update();
		} else if(a.type == "clear")
		{
			this.changed = true;
			this.data = null;
		}
		if(this.component.isQuestionComponent()) this.component.answerChanged = true;
		// how to update data ???
		this.render();
	}
	public render():void
	{
		DOMHelper.removeChildren(this.dom);
		this.dom.classList.remove("complete");
		// this.dom.target.data = this.data;
		if(this.data)
		{
			this.currentAnswer = this.nodeCreator.renderPinYinStudentAnswer(this.dom, this.words[0], this.data);
		} else {
			this.currentAnswer = this.nodeCreator.renderPinYinStudentAnswer(this.dom, this.words[0], this.data);
		}
	}

	public currentAnswer:PinYinObject;

	showDefaultAnswer() {
		this.nodeCreator.renderPinYinDefaultAnswer(this.dom, this.words[0]);
	}

	hideDefaultAnswer(){
		this.render();
	}

	verify(_showScoring: boolean):boolean{
		var tmpDOM:HTMLElement = this.dom;
		// tmpDOM.classList.add("question");
		tmpDOM.classList.remove("verify");
		tmpDOM.classList.remove("incorrect");
		tmpDOM.classList.remove("correct");
		if(this.data)
		{
			
			if(_showScoring) tmpDOM.classList.add("verify");
			if(this.isCorrect() == 2)
			{
				if(_showScoring) tmpDOM.classList.add("correct");
				return true;
			} else {
				if(_showScoring) tmpDOM.classList.add("incorrect");
				return false;
			}
		}
		return false;
	}
	private isCorrect():number
	{
		var answer:PinYinObject = this.data;
		//super.verify
		var word:any = this.words[0];
		var pyObject:PinYinObject = word.pinyin;
		var pyOption:any  = word.options.pinyin;
		
		var hasCorrect:boolean = false;
		var hasIncorrect:boolean = false;
		if (pyOption.shengmu.isQ)
		{
			// if (pyObject.shengmu == answer.shengmu || (!pyObject.shengmu && !pyObject.shengmu ))

			if (
				pyObject.s == answer.s ||
				(!pyObject.s && !answer.s)
			)
			{
				hasCorrect = true;
			} else {
				hasIncorrect = true;
			}
		}
		var yunmuCorrect:boolean;
		if (pyOption.yunmu.isQ)
		{
			// if (pyObject.yunmu == answer.yunmu || (!word.pinyin.yunmu && !answer.yunmu ))
			if (pyObject.yunmu == answer.yunmu )
			{
				hasCorrect = true;
				yunmuCorrect = true;
			} else {
				hasIncorrect = true;
				yunmuCorrect = false;
			}
		} else {
			yunmuCorrect = true;
		}
		
		if (pyOption.tone.isQ)
		{
			if (pyOption.light )
			{
				if (pyOption.light && answer.toneNumber == 5)
				{
					hasCorrect = true;
				} else {
					hasIncorrect = true;
				}
				
				if (pyOption.tone.isQP)
				{
					hasCorrect = true;
				}
			} else {
				if (pyObject.toneNumber == answer.toneNumber)
				{
					hasCorrect = true;
				} else {
					hasIncorrect = true;
				}
				
				if (pyOption.tone.isQP)
				{
					if (yunmuCorrect)
					{
						if (pyObject.toneNumber == 5 || pyObject.toneNumber == 0)
						{
							if (answer.toneNumber == 5)
							{
								hasCorrect = true;
							} else {
								hasIncorrect = true;
							}
						} else {
							
							if (pyObject.selectedTone == null)
							{
								var o:any = PinYinMapUtils.basicAnalysis(pyObject.shengmu, pyObject.yunmu, pyObject.toneNumber);
								pyObject.selectedTone = o.tone;
							}
							if (pyObject.selectedTone == answer.selectedTone)
							{
								hasCorrect = true;
							} else {
								hasIncorrect = true;
							}
						}
					} else {
						hasIncorrect = true;
					}
					
				}
			}
			
		}
		if (hasIncorrect == false)
		{
			return 2;
		} else if (hasCorrect)
		{
			return 1;
			
		} else {
			return 0;
		}
		/*
		if(this.words.length == 1)
		{
			var word:any = this.words[0];
			// console.log(word);
			var correctCount:number = 0;
			var total:number = 0;
			if(word.options.pinyin.shengmu.isQ)
			{
				if(this.data.shengmu == word.pinyin.shengmu)
				{
					correctCount++;
				}
				total++;
			}
			if(word.options.pinyin.yunmu.isQ)
			{
				if(this.data.yunmu == word.pinyin.yunmu)
				{
					correctCount++;
				}
				total++;
			}
			if(word.options.pinyin.tone.isQ)
			{
				if(this.data.toneNumber == word.pinyin.toneNumber)
				{
					correctCount++;
				}
				total++;
			}
			if(total == correctCount)
			{
				return true;
			} else {
				return false;
			}
		}
		return true;
		*/
	}
	
}