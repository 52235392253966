import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { speechBox } from "./speechBox";
import { AlertService } from "src/app/service/alert.service";
import { ChatService } from "src/app/service/chatGPT.service";
import { DataService } from "src/app/service/data.service";
import { StorageService } from "src/app/service/storage.service";
import { LoadingService } from "../../loadingModule/loading.service";
import * as RecordRTC from 'recordrtc';
import { UploadService } from "../../uploadModule/upload.service";
import { ThemeService } from "src/app/service/theme.service";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import * as moment from 'moment';
import { faTrashCanXmark } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "conversationPractice",
	templateUrl: "./conversationPractice.html",
	styleUrls: ["./conversationPractice.scss"],
})
export class conversationPractice implements OnChanges, OnDestroy {
	@Input() public parent;
	@Input() public context;
	@Input() public redoConversation;
	@Input() public studentLevel;
	@Input() public topic;
	@Input() public description;
	@Input() public question;
	@Input() public objective;
	@Input() public EDBScore;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	@ViewChild("speechBox", { static: false }) speechBoxContainer: speechBox;
	@ViewChild('dialogContainer', { static: false }) dialogContainer!: any;
	@ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;

	// Config
	public isInstructionShown = false;
	public instruction = "";

	public isContentShown = false;
	public speed = 1.1;
	public AiSpeedOptions: any[] = [
		{ label: "Slow", value: 0.9 },
		{ label: "Normal", value: 1.1 },
		{ label: "Fast", value: 1.3 },
	];

	public networkCheckOn: boolean = false;

	public status: string = "idle";							// "idle" / "recording" / "loading" / "playing"
	public robotImageStatus: string = "idle";				// "idle" / "talk" / "correct" / "wrong" / "wrong_last"

	public userName;
	public audioInfo = [];
	public promptLog = [];
	public conversation = [];
	public eachQuestionScore = [];
	public result = [];
	public questionNumber = 1;

	public studentChar = '1';
	public teacherChar = '1';
	public voiceSelection;
	public voiceLibrary = [
		{ gender: "F", name: "en-GB-LibbyNeural"},
		{ gender: "M", name: "en-US-RogerNeural"},
		{ gender: "F", name: "en-US-AriaNeural"},
		{ gender: "F", name: "en-US-AnaNeural"},
		{ gender: "F", name: "en-GB-AdaMultilingualNeural"},
		{ gender: "M", name: "en-US-AdamMultilingualNeural"},
		{ gender: "M", name: "en-GB-RyanNeural"}
	];

	levelConfig = {
		p4: { level: "primary 4", wordLimit: 10, syllable:3, character: 6 },
		p5: { level: "primary 5", wordLimit: 15, syllable:3, character: 6 },
		p6: { level: "primary 6", wordLimit: 20, syllable:3, character: 6 },
		s1: { level: "secondary 1", wordLimit: 25, syllable:5, character: 12 },
		s2: { level: "secondary 2", wordLimit: 25, syllable:5, character: 12 },
		s3: { level: "secondary 3", wordLimit: 25, syllable:5, character: 12 }
	}

	public hintQuota = 3;
	public recordEnable = false;
	public isAnswerDefaultQuestion = false;
	public userGreetingPass = false;
	public userGreetingLimit = Math.floor(Math.random() * 2) + 3;
	public userGreetingCounter = 1;

	public userEDBLimit = Math.floor(Math.random() * 2) + 3;
	// public userEDBLimit = 1;
	public userEDBCounter = 1;
	// public userEDB_retry_limit = 2;
	public userEDB_retry_limit = 0;
	public userEDB_retry = 0;
	public userSecondEDBPass = false;
	public userDefaultQuestion_retry_limit = 3;
	public userDefaultQuestion_retry = 0;
	public defaultQuestionFinish = false;
	public receiveMyData = false;

	// For media
	public recorder: any = null;
	public stream: any = null;
	public timer;
	public recordingTime = 30;
	public recordTimer;
	public isResReceived = false;

	// For sound wave
	public soundWaveStream: any = null;
	private audioContext: AudioContext | null = null;
	private mediaStreamAudioSourceNode: MediaStreamAudioSourceNode | null = null;
	private analyserNode: AnalyserNode | null = null;
	private animationFrameId: number | null = null;
	private frameCount: number = 0;
	private framesToSkip: number = 4;

	// For audio
	public audio: HTMLAudioElement = new Audio();
	private isDestroyed:boolean= false;

	// For Curriculum
	public is_objective_box_open = false;
	public objectiveUsed = [];
	public learningObjective = [];
	public curriculumNumber = 0;
	public tempPronunciationScore = {
		PronScore: 0,
		AccuracyScore: 0,
		CompletenessScore: 0,
		FluencyScore: 0
	};
	public tempPronScoreCounter = 0;
	public curriculumScore = 0

	// For Default Question
	public questionIndex = 0;

	public cancelBtn = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/btn_myaibuddy_del.svg')`;

	constructor(
		private chat: ChatService, public dataService: DataService, public storage: StorageService, private lds: LoadingService,
		private alert: AlertService, public upload: UploadService, public datas: DataService, private eleRef: ElementRef, private theme: ThemeService
	) {
		this.theme.applyStyleObj({
			"default": { "section-maincolor": "#0052AF" },
			"dark": { "section-maincolor": "#0052AF" }
		}, this.eleRef.nativeElement);
	}

	async ngOnInit() {
		try {
			console.log(this.studentLevel)
			console.log(this.question)
			if (this.topic === "Short exercise for test") this.topic = "Study, School Life, and Work: Study problems";
				
			let viewMode = this.context.config.viewMode;
			this.userName = this.dataService.userInfo.nickname;
			this.studentChar = sessionStorage.getItem('studentChar') || '1';
			this.teacherChar = sessionStorage.getItem('teacherChar') || '1';
			this.preloadImage();
			this.setInstruction();
			this.setObjective(this.objective);
			this.voiceSelection = this.voiceLibrary[this.teacherChar].name;

			if (viewMode !== "scoring" && viewMode !== "preview" && viewMode !== "review") {
				this.soundWaveStream = await navigator.mediaDevices.getUserMedia({ audio: true });
				this.audioContext = new AudioContext();
				this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.soundWaveStream);
				this.analyserNode = this.audioContext.createAnalyser();
				this.analyserNode.fftSize = 1024;
				this.mediaStreamAudioSourceNode.connect(this.analyserNode);

				this.isInstructionShown = true
			} else if (viewMode === "review") {
				console.log(this.parent.myData)
				this.conversation = this.parent.myData.conversationRecord;
				this.isInstructionShown = false;
				this.isContentShown = true;
			}
		} catch (err) {
			console.error('Error accessing microphone:', err);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		let redo = changes.redoConversation;
		if (redo && redo.previousValue === false && redo.currentValue === true) {
			this.hintQuota = 3;
			this.objectiveUsed = [];
			this.EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

			this.audioInfo = [];
			this.promptLog = [];
			this.conversation = [];
			this.eachQuestionScore = [];
			this.questionNumber = 1;

			this.isAnswerDefaultQuestion = false;
			this.userGreetingPass = false;
			this.userGreetingLimit = Math.floor(Math.random() * 2) + 3;
			this.userGreetingCounter = 1;
			this.userEDBLimit = Math.floor(Math.random() * 2) + 3;
			this.userEDBCounter = 1;
			// this.userEDB_retry_limit = 2;
			this.userEDB_retry_limit = 0;
			this.userEDB_retry = 0;
			this.userSecondEDBPass = false;
			this.userDefaultQuestion_retry_limit = 3;
			this.userDefaultQuestion_retry = 0;
			this.defaultQuestionFinish = false;
			this.greeting();
		} else if (this.context.config.viewMode === "scoring") {
			this.conversation = this.parent.myData.conversationRecord;
			this.isContentShown = true;
		}
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		this.audio.pause();
		this.audio.currentTime = 0;
		if (this.audioContext) this.closeMicrophone();
	}

	/////////////////////////////////////////
	//             Media function          //
	/////////////////////////////////////////
	recordAction() {
		this.status = "recording";
		this.record();
		this.startChecking();
	}

	record(): void {
		navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
			let options = { mimeType: "audio/wav", numberOfAudioChannels: 1, desiredSampRate: 16000 };
			let StereoAudioRecorder: any = RecordRTC.StereoAudioRecorder;
			this.recorder = new StereoAudioRecorder(stream, options);
			this.stream = stream;
			this.recorder.record();
		});

		if (this.timer) clearInterval(this.timer)
		this.timer = setInterval(() => {
			if (this.recordingTime === 0) this.finishRecording();
			else this.recordingTime = this.recordingTime - 1;
		}, 1000);
	}

	startChecking() {
		if (this.analyserNode) {
			const canvas = this.canvas.nativeElement;
			const canvasCtx = canvas.getContext('2d');
			const data = new Float32Array(this.analyserNode.fftSize);

			const drawWaveform = () => {
				this.frameCount++;
				if (this.frameCount % this.framesToSkip === 0) {
					this.analyserNode.getFloatTimeDomainData(data);
					canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
					const barWidth = (canvas.width / data.length) * 80;
					const barGap = 15;
					let x = 0;
					for (let i = 0; i < data.length; i++) {
						const v = data[i] * 1000.0;
						canvasCtx.fillStyle = 'rgb(255, 255, 255)';
						canvasCtx.fillRect(x, canvas.height / 2 - v / 2, barWidth, v);
						x += barWidth + barGap;
					}
				}
				this.animationFrameId = requestAnimationFrame(drawWaveform);
			};
			drawWaveform();
		}
	}

	finishRecording(): void {
		this.status = "loading";
		this.recordEnable = false;
		this.recordingTime = 30;
		clearInterval(this.timer);

		this.recorder.stop((blob) => {
			let file: File = new File([blob], "record.mp3");

			let reference: string = this.context.createReference();
			let uid = this.context.service.dataService.userInfo.uid;

			if (this.parent.myData == null) {
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
				this.parent.myData = {
					user: uid,
					key: this.parent.fileReference.key,
					reference: this.parent.fileReference.reference
				}
			} else {
				this.parent.myData.user = uid;
				this.parent.myData.reference = reference;
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
			}

			this.parent.answerChanged = true;

			this.upload.uploadFileObject(file).then((res) => {
				const hostname = location.href.indexOf('localhost') > -1 ? 'dev.openknowledge.hk' : location.hostname;
				let filePath = '//' + hostname + '/RainbowOne/' + res.path;
				if (this.parent.subType === "oralAI") {
					let file: File = new File([blob], "test.mp3");
					this.getSttApi(file, filePath);
				} else {
					this.ttsApi(filePath);
				}
			});

			let tracks = this.stream.getTracks();
			for (let track of tracks) track.stop();
		});
		this.status = "idle";
	}

	cancelAction() {
		this.status = "idle";
		this.recordingTime = 30;
		clearInterval(this.timer);
		this.stopChecking();
	}

	stopChecking() {
		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId);
			this.animationFrameId = null;
		}
	}

	async closeMicrophone() {
		if (!this.soundWaveStream) return;
		try {
			console.log("closeMicrophone")
			await this.audioContext.close();
			this.soundWaveStream.getTracks().forEach(track => track.stop());			
		} catch (error) {
			console.error('Error accessing microphone:', error);
		}
	}

	getSttApi(wavFile: File, filePath): Promise<void> {
		let lang = "en-US";
		let data: FormData = new FormData();
		data.append('api', 'ROSpeechRecognition.recognize_tts');
		data.append("json", JSON.stringify([filePath, "", lang]));
		const delayTime = 2000;

		return this.datas.post2({ data: data, loading: false }).then(async (res: any) => {
			console.log("delayTime", delayTime);
			var message: string = "";

			if (!res.result || !res.result.DisplayText) {
				setTimeout(() => {
					this.alert.alert2(this.translateText(
						"偵測不到聲音，請重新再試。",
						"偵測不到聲音，請重新再試。",
						"Cannot detect the sound recording, please try again"
					), null, { btns: [["ok"]] });
				}, 2000);
			} else {
				message = res.result.DisplayText;

			}
			this.promptLog.push({ role: "user", content: message })
			this.conversation.push({ role: "user", content: message, audioPath: filePath })

			let promptInput = [...this.promptLog].filter(message => !message.isHint).slice(-4);
			promptInput.unshift(this.promptLog[0])
			const robotRes = await this.sentPromptApi(promptInput);
			this.scrollToBottom();
			let content = JSON.parse(robotRes).choices[0].message.content;

			let audio_res = await this.playAudio(content, this.replaceSymbol(content), false);
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
			await audio_res.is_finish;
			this.recordEnable = true;
			return;
		}).catch((err: any) => {
			return;
		});
	}

	async ttsApi(filePath: string) {
		let lang = "en-US";
		let dataWithAnswer: FormData = new FormData();
		let dataWithoutAnswer: FormData = new FormData();
		dataWithAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithoutAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		if (this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.question[this.questionIndex].answer, lang]));
		} else {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.question[this.questionIndex].name, lang]));
		}
		dataWithoutAnswer.append("json", JSON.stringify([filePath, "", lang]));

		try {
			this.isResReceived = false;
			let firstRecordTime = Date.now();

			const recordTimeCount = setInterval(async () => {
				this.recordTimer = Date.now();
				console.log(this.recordTimer - firstRecordTime)

				// 超時
				if (this.recordTimer - firstRecordTime > 10000 && !this.isResReceived) {
					clearInterval(recordTimeCount);
					this.alert.toastError(this.translateText(
						"網絡繁忙，自動重試中，請稍候",
						"网络繁忙，自动重试中，请稍后",
						"Network latency, repeat processing automatically, result is going to release"
					));
				}
			}, 1000);

			if (this.isAnswerDefaultQuestion) {
				const firstRequest = await this.dataService.post2({ data: dataWithAnswer, loading: false });
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: false });
				Promise.all([firstRequest, secondRequest]).then(async ([firstRequest, secondRequest]) => {
					if (!firstRequest.result || !secondRequest.result ||
						firstRequest.result.RecognitionStatus === "InitialSilenceTimeout" ||
						secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						this.recordEnable = true;
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						let foulLanguageCheck = await this.parent.foulLanguageCheck(firstRequest.result.NBest[0]);
						console.log(foulLanguageCheck)
						let userAnswer = this.handleUserAnswer(secondRequest.result);
						if (foulLanguageCheck) userAnswer = "****";
						// console.log(userAnswer)
						if (this.parent.subType === "oralSentence") {
							this.conversation.push(
								{
									role: "user",
									content: this.question[this.questionIndex].name,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						} else if (this.parent.subType === "oralParagraph") {
							this.conversation.push(
								{
									role: "user",
									content: this.question[this.questionIndex].name,
									isParagraph: true,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						} else {
							this.conversation.push({ role: "user", content: userAnswer, audioPath: filePath })
						}
						this.promptLog.push({ role: "user", content: firstRequest.result.NBest[0].ITN })

						// console.log(firstRequest.result)
						this.eachQuestionScore[this.questionIndex] = {
							PronScore: Math.round(firstRequest.result.NBest[0].PronScore),
							AccuracyScore: Math.round(firstRequest.result.NBest[0].AccuracyScore),
							CompletenessScore: Math.round(firstRequest.result.NBest[0].CompletenessScore),
							FluencyScore: Math.round(firstRequest.result.NBest[0].FluencyScore)
						};

						this.scrollToBottom();
						if (this.parent.subType === "oralVocab" || this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
							console.log(firstRequest.result)
							console.log(secondRequest.result)
							let content = firstRequest.result.NBest[0].ITN;
							let result = firstRequest.result;
							let score = firstRequest.result.NBest[0];
							this.checkPronunciation(content, result, score);
						} else if (this.parent.subType === "oralSentence" || this.parent.subType === "oralParagraph") {
							console.log(firstRequest.result)
							console.log(secondRequest.result)
							let content = secondRequest.result.DisplayText;
							let result = firstRequest.result;
							let score = firstRequest.result.NBest[0];
							this.checkPronunciation(content, result, score);
						}
					}
				})
			} else {
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: false });
				Promise.all([secondRequest]).then(async ([secondRequest]) => {
					if (!secondRequest.result || secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						this.recordEnable = true;
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						console.log(secondRequest)
						let text = secondRequest.result.DisplayText;
						let score = secondRequest.result.NBest[0];

						let foulLanguageCheck = await this.parent.foulLanguageCheck(secondRequest.result.NBest[0]);
						console.log(foulLanguageCheck)
						let userAnswer = this.handleUserAnswer(secondRequest.result);
						if (foulLanguageCheck) userAnswer = "****";

						this.promptLog.push({ role: "user", content: secondRequest.result.NBest[0].ITN });
						this.conversation.push({ role: "user", content: userAnswer, audioPath: filePath });
						this.scrollToBottom();

						this.checkUserCurriculum(score)
					}
				})
			}
		} catch (err) {
			console.error("Assessment Fail", err);
		}
	}

	/////////////////////////////////////////
	//           GPT chat function         //
	/////////////////////////////////////////
	async sentPromptApi(prompt: any[]) {
		let res = await this.chat.oralChat(prompt, "EP_AI_ENG_CHAT");
		if (JSON.parse(res)['error']) {
			this.alert.alert2(this.translateText(
				"偵測到不適合用詞，請重新再試。",
				"侦测到不适合用词，请重新再试。",
				"Inappropriate word detected, please try again."
			), null, { btns: [["ok"]] });
			this.recordEnable = true;
			return `{"choices":[{"message":{"content":"Inappropriate word detected, please try again.","error":true}}]}`
		}
		return res
	}

	async greeting() {
		if (this.parent.subType !== "oralAI") {
			console.log(this.levelConfig[this.studentLevel])
			let background = { role: "system", content: "" };
			background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of ${this.levelConfig[this.studentLevel].level} student. The topic is ${this.topic}. From now on, all the sentences that you generate must be within ${this.levelConfig[this.studentLevel].wordLimit} words and each word within ${this.levelConfig[this.studentLevel].syllable} syllables and ${this.levelConfig[this.studentLevel].character} characters.`;

			let scenario = {
				role: "system",
				content: `If the student answer you in a good response, please smoothly start the topic. The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): <Foul>: Please be polite and speak in a positive way. <Yes>: Could you explain more please, so I can better understand? <No>: Are you sure? I promise it will be fun! <I don't know>: I'm sorry to hear that. What do you need help on? <What>: Would you like me to repeat that? I don't mind. <Um, like, you know>: You've got a point! Just try to say it more directly without the fillers. <Overly long response>: That's a rich answer! It could be even better with some trimming.`
			};
			this.promptLog.push(background);
			this.promptLog.push(scenario);
			let show_datetime = false;
			if(Math.random() < 0.3) {
				show_datetime = true;
			}

			this.promptLog.push({
				role: "system",
				content: `
					Please greet the student and include his/her name ${this.userName} without starting the topic.
					${show_datetime ? `Current Date Time: ${moment().format('YYYY-MM-DD HH:mm A')}
					You can make various greetings according to the time.` : ''}
					Do not use emoji. Be more creative on the greeting. 
					Let the student respond to your greeting.`
			});
		}

		if (this.parent.subType === "oralAI") {
			if (this.studentLevel === "p4") {
				this.promptLog.push({
					role: "system",
					content: `Assistant is user's best friend John, Assistant has been living in Germany for five years and will be returning to Hong Kong during the Easter holidays. Assistant misses the local food in Hong Kong and wants user to take him to user's favorite restaurant. Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "p5") {
				this.promptLog.push({
					role: "system",
					content: `To keep tourism blooming, some suggest promoting rural areas to showcase Hong Kong's cultural convergence, such as the historic Hakka village Lai Chi Wo. Assistant is going to ask user, is there any disadvantages of this suggestion. Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "p6") {
				this.promptLog.push({
					role: "system",
					content: `Imagine the Earth could talk, what do you think it would most likely say to you? What can you do to make the world a better place? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "s1") {
				this.promptLog.push({
					role: "system",
					content: `Some students study by memorizing as much as possible, while others prefer studying by understanding basic principles and building from there. Assistant is going to ask user, which way is more effective and how do the user like to study? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "s2") {
				this.promptLog.push({
					role: "system",
					content: `Do you think criminals deserve forgiveness? Should prisons be punitive or rehabilitative? What if the criminal committed a very serious crime, such as murder? Do such criminals deserve a second chance? Where do you draw the line between punishment and rehabilitation? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "s3") {
				this.promptLog.push({
					role: "system",
					content: `Share your perspectives on a famous successful person. What traits did that person have? Of that person's journey to success, how much of it was that person's own will and perseverance, and how much of it was luck? What can you take away from their journey to add to your own? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			}
		}

		try {
			let content = "";
			if (this.parent.subType === "oralAI") {
				if (this.levelConfig[this.studentLevel].level === "p4") {
					content = `Hi ${this.userName}, It's so great to be back in Hong Kong! I've really missed the food here. What restaurant do you recommend we go to?`
				} else if (this.levelConfig[this.studentLevel].level === "p5") {
					content = `To keep tourism blooming, some suggest promoting rural areas to showcase Hong Kong's cultural convergence, such as the historic Hakka village Lai Chi Wo. Given this, what do you think are the disadvantages of this suggestion?`;
				} else if (this.levelConfig[this.studentLevel].level === "p6") {
					content = `Imagine the Earth could talk, what do you think it would most likely say to you? What can you do to make the world a better place?`;
				} else if (this.levelConfig[this.studentLevel].level === "s1") {
					content = `Some students study by memorizing as much as possible, while others prefer studying by understanding basic principles and building from there. Which do you think is more effective? How do you like to study?`;
				} else if (this.levelConfig[this.studentLevel].level === "s2") {
					content = `Do you think criminals deserve forgiveness? Should prisons be punitive or rehabilitative? What if the criminal committed a very serious crime, such as murder? Do such criminals deserve a second chance? Where do you draw the line between punishment and rehabilitation?`;
				} else if (this.levelConfig[this.studentLevel].level === "s3") {
					content = `Share your perspectives on a famous successful person. What traits did that person have? Of that person's journey to success, how much of it was that person's own will and perseverance, and how much of it was luck? What can you take away from their journey to add to your own?`;
				} 

				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
				await audioRes.is_finish;
				this.recordEnable = true;
			} else {
				const res = await this.sentPromptApi(this.promptLog);
				content = JSON.parse(res).choices[0].message.content;
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

				if (res) {
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(() => this.recordEnable = true, audioRes.duration * 1000);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserGreeting(content: string, score: any) {
		const prompt = {
			role: "system",
			content: `
				The student response is "${content}".
				Please determine whether the student's response correctly or not. 

				If the student gave you a correct greet, please reply <correctGreet>. 
				If the student gave you a wrong greet and said something inappropriate or irrelevant or you cannot understand or not even a sentence, reply <wrongGreet>. 
				If the student did not greet and say something that need caring, please reply <correctGreet>.

				Please give your reason and then reply <correctGreet>, <wrongGreet>.`,
		}
		this.promptLog.push(prompt);
		try {
			const res = await this.sentPromptApi(this.promptLog);

			let res_obj = JSON.parse(res);
			if (res_obj.choices[0].message.error) return;

			const content = res_obj.choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

			let result = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
			let resPrompt = { role: "system", content: "", };
			// Failure greeting
			if (result.includes("wrongGreet")) {
				if (this.userGreetingCounter === this.userGreetingLimit) {
					resPrompt.content = `Determine the 'student scenarios' of student greet: ${content}. According to the scenario to respond but do not use the same words in the scenario list. Also tell the student that he/she can not talk more like this and we must start our discussion topic now. (important) Do not use interrogative sentence or ask any question.`
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						const content = JSON.parse(res).choices[0].message.content;
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

						if (res) {
							let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
							if (audioRes) setTimeout(() => {
								this.userGreetingPass = true;
								this.curriculumSection();
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					resPrompt.content = `Determine the 'student scenarios' of student greet: ${content}. According to the scenario to respond but do not use the same words in the scenario list. If you don't understand what the student is talking about, please ask the student to explain, otherwise try to encourage the student to greet again. Do not start the topic.`
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						const content = JSON.parse(res).choices[0].message.content;
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

						if (res) {
							let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
							if (audioRes) setTimeout(() => {
								this.recordEnable = true;
								this.userGreetingCounter++;
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				}
			} else if (result.includes("correctGreet")) {
				resPrompt.content = `Please respond to the student's greeting without questions. Also, answer their question if you can. If the student needs attention, use a sentence or two to comfort him or her. Finally, describe the topic in one sentence without asking the student any questions. No question allowed. Please respond to the student smoothly. Please maintain coherence and fluency in the overall content.`
				this.promptLog.push(resPrompt);

				try {
					const res = await this.sentPromptApi(this.promptLog);
					const content = JSON.parse(res).choices[0].message.content;
					this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

					if (res) {
						let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
						if (audioRes) setTimeout(() => {
							this.userGreetingPass = true;
							this.curriculumSection();
						}, audioRes.duration * 1000);
					}
				} catch (error) {
					console.log(error);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async curriculumSection() {
		// this.curriculumNumber = Math.floor(Math.random() * 7);
		this.curriculumNumber = 0;
		let prompt = {
			role: "system",
			content: `You are a teacher. You are going to discuss with a ${this.levelConfig[this.studentLevel].level} student about the topic of ${this.topic}. At the beginning, please tell the student that he has to show the skill ${this.learningObjective[this.curriculumNumber]} during the conversation. Please try to use simple sentence and words. Please respond to the student accordingly. Please also let the student know that he can ask questions anytime. You can chat with the students a few times, after that, please smoothly tell the students that we will then start speaking tasks section. Use less than ${this.levelConfig[this.studentLevel].wordLimit} words for each sentence. If you can only ask one question each time.`
		};
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			const content = JSON.parse(res).choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
			this.objectiveUsed.push(this.curriculumNumber);

			if (res) {
				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				if (audioRes) setTimeout(() => this.recordEnable = true, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserCurriculum(score: any) {
		console.log(score)
		const prompt = {
			role: "system",
			content: `Follow the requirements:
				1. Respond to the student
				2. If the conversation has been continued several times (at least 3 times), start speaking tasks section
				2.1 Do not use interrogative sentence
				2.2 Rate the performance of student on the skill out of ten
				2.3 Reply the rating with the following format: <rating you provide/10>
			`,
		}
		this.promptLog.push(prompt);
		try {
			const res = await this.sentPromptApi(this.promptLog);
			let content = JSON.parse(res).choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
			console.log(content)

			this.tempPronScoreCounter++;
			this.tempPronunciationScore.PronScore += score.PronScore;
			this.tempPronunciationScore.AccuracyScore += score.AccuracyScore;
			this.tempPronunciationScore.CompletenessScore += score.CompletenessScore;
			this.tempPronunciationScore.FluencyScore += score.FluencyScore;

			if (res) {
				let audioContent = content.replace(/[^.]*\/10[^.]*\./, '').trim();
				console.log(audioContent)
				let audioRes = await this.playAudio(audioContent, this.replaceSymbol(audioContent), false);
				if (audioRes) setTimeout(() => {
					this.recordEnable = true;

					let match = content.match(/(\d+)\/10/);
					let result = match ? match[1] : null;
					
					if (match) {
						console.log(this.tempPronunciationScore, this.tempPronScoreCounter)
						this.tempPronunciationScore.PronScore = this.tempPronunciationScore.PronScore / this.tempPronScoreCounter;
						this.tempPronunciationScore.AccuracyScore = this.tempPronunciationScore.AccuracyScore / this.tempPronScoreCounter;
						this.tempPronunciationScore.CompletenessScore = this.tempPronunciationScore.CompletenessScore / this.tempPronScoreCounter;
						this.tempPronunciationScore.FluencyScore = this.tempPronunciationScore.FluencyScore / this.tempPronScoreCounter;
						
						let curriculumScore = [{number: 0}, {score: result}];
						this.curriculumScore = result;
						this.updateResult(this.tempPronunciationScore.PronScore, this.tempPronunciationScore, curriculumScore);
						this.defaultQuestionSession();
					}
				}, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async defaultQuestionSession() {
		this.isAnswerDefaultQuestion = true;
		try {
			if (this.questionIndex < 1) {
				const prompt = {
					role: "system",
					content: `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to inform ${this.userName} that you are going to start the exercise about today's topic. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 15 words.`
				};
				this.promptLog.slice(0, 1);
				this.promptLog.push(prompt);

				const res = await this.sentPromptApi(this.promptLog);
				const content = JSON.parse(res).choices[0].message.content;
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

				if (res) {
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(() => this.askDefaultQuestion(), audioRes.duration * 1000);
				}
			} else {
				this.askDefaultQuestion();
			}
		} catch (error) {
			console.log(error)
		}
	}

	async askDefaultQuestion() {
		let intro = "";
		let question = this.question[this.questionIndex].name;
		let reading = this.question[this.questionIndex].reading;
		let audioContent;

		if (this.parent.subType === "oralFillIn") intro = `Please answer the missing part of the phrase:`
		else if (this.parent.subType === "oralMc") intro = `Which response would you choose, if someone says:`
		else if (this.parent.subType === "oralParagraph") intro = `Please read this:`
		else if (this.parent.subType === "oralSentence") intro = `Please read the phrase:`
		else if (this.parent.subType === "oralVocab") intro = `Please read the vocab:`
		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(intro) });

		if (this.parent.subType === "oralFillIn") {
			const split = question.split("(");
			let firstPartReadingText = split[0].trim();
			let secondPartReadingText = split[1].split(")")[1].trim();
			let fillInTypeTopic = `${intro} ${firstPartReadingText} ${''} ${secondPartReadingText}`;

			if (this.parent.difficulty !== "hard") {
				audioContent = { intro: `${intro} ${reading}`, question: `${intro} ${question}` };
			} else {
				audioContent = { intro: fillInTypeTopic, question: `${intro} ${question}` };
			}
		} else if (this.parent.subType === "oralMc") {
			audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
		} else {
			if (this.parent.difficulty !== "hard") {
				audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
			} else {
				audioContent = { intro: intro, question: `${intro} ${question}` };
			}
		}

		let audioRes = await this.playAudio(audioContent.intro, audioContent.question, true);
		if (audioRes) setTimeout(async () => {
			this.parent.totalPageNumber = this.question.length;
			this.parent.pageIndex = this.questionNumber;
			if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {
					for (let content of this.get_display_choices(this.question[this.questionIndex].choice)) {
						let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
						await audioRes.is_finish;
					}

					this.recordEnable = true;
				} else {
					for (let content of this.get_display_choices(this.question[this.questionIndex].choice)) {
						this.conversation.push({role: "assistant", content: content});
					}

					this.recordEnable = true;
					this.scrollToBottom();
				}
			} else {
				this.recordEnable = true;
			}
		}, audioRes.duration * 1000);
	}

	async checkPronunciation(content: any, result: any, score: any) {
		let correct = true;
		if (content !== this.question[this.questionIndex].name.toLowerCase()) correct = false;
		if (score.AccuracyScore < 50 || score.PronScore < 50) correct = false;
		this.question[this.questionIndex]['pass_status'] = (correct ? 'pass' : 'failed')

		let prompt = { role: "system", content: "", };
		if (this.parent.subType === "oralVocab") {
			prompt.content = `
			The question type is oral vocabulary.
			The question answer is "${this.question[this.questionIndex].name}"
			The student's answer is "${content}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralSentence") {
			prompt.content = `
			The question type is oral sentence.
			The question is "${this.question[this.questionIndex].name}"
			The question pronunciation pass level is ${this.parent.ctx.passLevel}
			The student's answer is "${content}"
			The student's pronunciation score is "${result.NBest[0].PronScore}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralFillIn") {
			let userAnswer = result.NBest[0].Lexical.charAt(0).toUpperCase() + result.NBest[0].Lexical.slice(1);

			prompt.content = `
			The question type is oral fill in.
			The question is "${this.question[this.questionIndex].name}"
			The question answer is "${this.question[this.questionIndex].answer}"
			The student's answer is "${userAnswer}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralMc") {
			let choices = this.get_display_choices(this.question[this.questionIndex].choice).join("\n");

			prompt.content = `
			The question type is oral multiple choice.
			The question is "${this.question[this.questionIndex].name}"
			The choices are: ${choices}
			The question answer is "${this.question[this.questionIndex].answer}"
			The student's answer is "${content}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralParagraph") {
			prompt.content = `
			The question type is oral paragraph.
			The question answer is "${this.question[this.questionIndex].name}"
			The question pronunciation pass level is ${this.parent.ctx.passLevel}
			The student's answer is "${content}"
			The student's pronunciation score is "${result.NBest[0].PronScore}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		}
		let is_last_question = this.questionIndex === this.question.length - 1;
		if (is_last_question) {
			this.promptLog.push({
				role: "system",
				content: `This is the last question of this exercise.`
			});
		}
		this.promptLog.push(prompt);
		this.promptLog.push({
			role: "system",
			content: `
				The student answer is determined as ${correct?"correct":"incorrect"}.
				If you need to encourage the student, do not use the same words before to encourage the student. Please try to use different words or sentences to encourage or point out the mistake of the student. If the student needs attention, use a sentence or two to comfort him or her.The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): <Foul>: Please be polite and speak in a positive way. <Yes>: Could you explain more please, so I can better understand? <No>: Are you sure? I promise it will be fun! <I don't know>: I'm sorry to hear that. What do you need help on? <What>: Would you like me to repeat that? I don't mind. <Um, like, you know>: You've got a point! Just try to say it more directly without the fillers. <Overly long response>: That's a rich answer! It could be even better with some trimming.
				Don't ask student to retry
			`
		})

		try {
			const res = await this.sentPromptApi(this.promptLog);
			console.log(this.promptLog);
			console.log(res);
			// debugger
			let res_obj = JSON.parse(res);
			if (res_obj.choices[0].message.error) {
				this.askDefaultQuestion();
				return;
			}
			let content = res_obj.choices[0].message.content;

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
			if (res) {
				if (correct) this.updateResult(score.PronScore, score, null);
				else this.updateResult(0, score, null);
				
				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				if (audioRes) setTimeout(() => {
					if (this.questionIndex < this.question.length - 1) {
						this.questionIndex++;
						this.questionNumber++;
						this.defaultQuestionSession();
					} else {
						this.finishing();
					}
				}, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error)
		}
	}
	
	public async finishing() {
		try {
			let content = "Your exercise is finished! Here is your result."
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
			await audioRes.is_finish;
			this.submission();
			console.log(this.result)
		} catch (error) {
			console.log(error);
		}
	}

	submission() {
		this.parent.result = JSON.stringify(this.result);

		this.parent.myData.conversationData = this.eachQuestionScore;
		this.parent.myData.objectiveUsed = [...(new Set(this.objectiveUsed)).values()];
		this.parent.myData.EDBScore = this.EDBScore;

		let passStatus = this.question.map(item => item.pass_status);
		let stringifyStatus = JSON.stringify(passStatus);
		let uriEncodeStatus = encodeURIComponent(stringifyStatus);
		let encodeStatus = btoa(uriEncodeStatus);
		this.parent.myData.questionPassStatus = encodeStatus;

		let stringifyResult = JSON.stringify(this.conversation);
		let uriEncodeResult = encodeURIComponent(stringifyResult);
		let encodeResult = btoa(uriEncodeResult);
		this.parent.myData.conversationRecord = encodeResult;

		let stringifyFoul = JSON.stringify(this.parent.foulLanguage);
		let uriEncodeFoul = encodeURIComponent(stringifyFoul);
		let encodeFoul = btoa(uriEncodeFoul);
		this.parent.myData.foulLanguage = encodeFoul;

		this.parent.answerChanged = true;
		this.parent.conversationData = this.eachQuestionScore;
		this.parent.myData.conversationFinish = true;
		this.parent.myData.objective = [...this.objective]

		this.parent.pronScore = this.handlePronunciationScore('PronScore');
		this.parent.accuracyScore = this.handlePronunciationScore('AccuracyScore');
		this.parent.completenessScore = this.handlePronunciationScore('CompletenessScore');
		this.parent.fluencyScore = this.handlePronunciationScore('FluencyScore');
		
		console.log(this.parent.pronScore, this.parent.accuracyScore, this.parent.completenessScore, this.parent.fluencyScore, this.parent.myData)
		
		this.parent.autoSubmit();

		setTimeout(() => {
			this.parent.isConversationReportOpen = true;
			this.emitter.emit({ action: "close", isFinalReportOpen: true });
		}, 5000);
	}

	testSubmission() {
		let result = [
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{
				score: 2, 
				pronunciationScore: [{pronunciation: 2}, {accuracy: 4}, {completeness: 3}, {fluency: 2}],
				curriculumScore: [{number: 10}, {score: 3}]
			},
			{
				score: 3, 
				pronunciationScore: [{pronunciation: 3}, {accuracy: 3}, {completeness: 3}, {fluency: 3}],
				curriculumScore: [{number: 11}, {score: 5}]
			},
			{
				score: 5,
				pronunciationScore: [{pronunciation: 6}, {accuracy: 7}, {completeness: 5}, {fluency: 6}],
				curriculumScore: [{number: 12}, {score: 1}]
			},
			{
				score: null, 
				pronunciationScore: [],
				curriculumScore: []
			},
			{
				score: null, 
				pronunciationScore: [],
				curriculumScore: []
			},
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
			{ score: 10, pronunciationScore: [{pronunciation: 8}, {accuracy: 9}, {completeness: 8}, {fluency: 7}] },
		]
		this.parent.result = JSON.stringify(result);

		let conversationData = [
			{
				"PronScore": 97,
				"AccuracyScore": 95,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 97,
				"AccuracyScore": 95,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 99,
				"AccuracyScore": 98,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 3,
				"AccuracyScore": 14,
				"CompletenessScore": 0,
				"FluencyScore": 0
			},
			{
				"PronScore": 87,
				"AccuracyScore": 78,
				"CompletenessScore": 100,
				"FluencyScore": 100
			}
		];
		let objectiveUsed = ['10', '11', '12'];
		let EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 10, 0, 0, 0];
		let questionPassStatus = ['pass', 'pass', 'pass', 'failed', 'failed'];
		let conversationRecord = [
			{
				"role": "assistant",
				"content": "Good evening, Mary Lam! How has your day gone?",
				"loading": false
			},
			{
				"role": "user",
				"content": "Hello, how are you?",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181331-671f63cb-1671f63cb9e827-5c891a4e.mp3"
			},
			{
				"role": "assistant",
				"content": "I'm doing well, thank you! Today, we will talk about food and drink, especially our favorite dishes and beverages. It's a fun topic that everyone enjoys! 🙂",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What do you like most about your favorite restaurant?",
				"loading": false,
				"EDBNumber": "10"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181401-671f63e9-1671f63e9d032e-7f988147.mp3"
			},
			{
				"role": "assistant",
				"content": "That's okay! Let's chat about something else, then.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What do you think makes food really delicious?",
				"loading": false,
				"EDBNumber": "11"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181436-671f640c-1671f640c4d9b4-4330797c.mp3"
			},
			{
				"role": "assistant",
				"content": "That's fine! Let's switch to a new topic now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Can you share your thoughts on your favorite snack?",
				"loading": false,
				"EDBNumber": "10"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181610-671f646a-1671f646ab0479-64523c15.mp3"
			},
			{
				"role": "assistant",
				"content": "No worries! Let's turn to a new question now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What is your favorite drink and why do you like it?",
				"loading": false,
				"EDBNumber": "12"
			},
			{
				"role": "user",
				"content": "Test.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181648-671f6490-1671f64901e9fc-252d68d7.mp3"
			},
			{
				"role": "assistant",
				"content": "It looks like we need to change topics now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Now, we will begin the exercise about our favorite food and drink. Let's enjoy!",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Chef",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/chef.png"
			},
			{
				"role": "user",
				"content": "Chef.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181740-671f64c4-1671f64c467751-216e6d17.mp3"
			},
			{
				"role": "assistant",
				"content": "Great job, Mary! You are doing well.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Organic",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/organic.png"
			},
			{
				"role": "user",
				"content": "Organic.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181753-671f64d1-1671f64d176b05-2803cc12.mp3"
			},
			{
				"role": "assistant",
				"content": "Well done, Mary! Keep it up.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Menu",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/menu.png"
			},
			{
				"role": "user",
				"content": "Menu.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181807-671f64df-1671f64df4ef1b-adb6754.mp3"
			},
			{
				"role": "assistant",
				"content": "Nice work, Mary! You're excelling today.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Condiment",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/condiment.png"
			},
			{
				"role": "user",
				"content": "Ha ha.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181820-671f64ec-1671f64ec874cb-4e157540.mp3"
			},
			{
				"role": "assistant",
				"content": "No problem at all! Let's move on.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Brunch",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/brunch.png"
			},
			{
				"role": "user",
				"content": "Lunch.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181841-671f6501-1671f6501e50e7-746975dc.mp3"
			},
			{
				"role": "assistant",
				"content": "That's okay! Let's continue onward now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Your exercise is finished! Here is your result.",
				"loading": false
			}
		];

		this.eachQuestionScore = conversationData;
		this.parent.myData.conversationData = conversationData;
		this.parent.myData.objectiveUsed = [...(new Set(objectiveUsed)).values()];;
		this.parent.myData.EDBScore = EDBScore;

		let stringifyStatus = JSON.stringify(questionPassStatus);
		let uriEncodeStatus = encodeURIComponent(stringifyStatus);
		let encodeStatus = btoa(uriEncodeStatus);
		this.parent.myData.questionPassStatus = encodeStatus;

		let stringifyResult = JSON.stringify(conversationRecord);
		let uriEncodeResult = encodeURIComponent(stringifyResult);
		let encodeResult = btoa(uriEncodeResult);
		this.parent.myData.conversationRecord = encodeResult;

		let stringifyFoul = JSON.stringify(this.parent.foulLanguage);
		let uriEncodeFoul = encodeURIComponent(stringifyFoul);
		let encodeFoul = btoa(uriEncodeFoul);
		this.parent.myData.foulLanguage = encodeFoul;

		this.parent.conversationData = this.eachQuestionScore;
		this.parent.myData.conversationFinish = true;
		this.parent.myData.objective = [...this.objective]

		this.parent.pronScore = this.handlePronunciationScore('PronScore');
		this.parent.accuracyScore = this.handlePronunciationScore('AccuracyScore');
		this.parent.completenessScore = this.handlePronunciationScore('CompletenessScore');
		this.parent.fluencyScore = this.handlePronunciationScore('FluencyScore');
		
		console.log(this.parent.pronScore, this.parent.accuracyScore, this.parent.completenessScore, this.parent.fluencyScore, this.parent.myData)
		
		this.parent.autoSubmit();

		setTimeout(() => {
			this.parent.isConversationReportOpen = true;
			this.emitter.emit({ action: "close", isFinalReportOpen: true });
		}, 5000);
	}

	/////////////////////////////////////////
	//          js handling function       //
	/////////////////////////////////////////
	scrollToBottom(): void {
		if (this.dialogContainer) {
			const container = this.dialogContainer.nativeElement;
			setTimeout(() => {
				container.scrollTop = container.scrollHeight;
			}, 500);
		}
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.parent.lang];
		return text;
	}

	setRobotImageStatus(status: string) {
		if (this.robotImageStatus === status) return;
		this.robotImageStatus = status;
		if (status === "wrong") {
			setTimeout(() => {
				this.setRobotImageStatus("wrong_last");
			}, 3000);
		} else if (status === "correct") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			});
		} else if (status === "wrong_last") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			}, 3000);
		}
	}

	startGreeting() {
		this.isInstructionShown = false;
		this.isContentShown = true;
		this.emitter.emit({ action: "loadAudio" });
		// this.greeting();
		this.curriculumSection();
		// this.defaultQuestionSession();
		// this.submission();
		// this.testSubmission();
	}

	async playAudio(text: string, conversationContent: string, askDefaultQuestion: boolean, EDBNumber?: null | string) {
		if (this.isDestroyed) return;
		let preLoad = { role: "assistant", content: "", loading: true };
		this.conversation.push(preLoad);
		this.scrollToBottom();

		try {
			let res = await this.getAudioDuration(text)
			if (res) {
				preLoad.loading = false;
				this.audioInfo.push({ url: res.url, duration: res.duration })

				if (EDBNumber) {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent), EDBNumber: EDBNumber }, preLoad
					);
				} else if (this.parent.subType === "oralParagraph" && this.isAnswerDefaultQuestion) {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent), isParagraph: true }, preLoad
					);
				} else {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent) }, preLoad
					);
				}

				if (askDefaultQuestion) {
					if (this.parent.subType === "oralVocab" && this.question[this.questionIndex].url !== '') {
						this.conversation.push({ role: "assistant", content: this.question[this.questionIndex].url });
					}
				}

				this.scrollToBottom();
				this.status = 'playing';
				this.setRobotImageStatus("talk");
				this.audio.setAttribute("src", res.url);
				this.audio.play();
				res.is_finish = new Promise((resolve) => {
					setTimeout(() => resolve(true), res.duration * 1000);
				})

				setTimeout(() => {
					this.setRobotImageStatus("idle");
					this.status = 'idle';
				}, res.duration * 1000);

				return (res);
			}
		} catch (error) {
			console.log(error)
		}
	}

	getAudioDuration(text: string): Promise<any> {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";

		let data: any = {
			gender: this.voiceLibrary[this.teacherChar].gender,
			txt: text,
			speed: this.speed,
			lang: this.voiceSelection.includes("en-GB") ? "en-GB" : "en-US",
			pitch: 1,
			name: this.voiceLibrary[this.teacherChar].name,
			style: "",
		};
		this.setRobotImageStatus("talk");

		return new Promise((resolve) => {
			this.datas.get2({ url: url, data: data, jwt: false, loading: false }).then((res: any) => {
				if (res.token && res.token.url) {
					let statusUrl: string = res.token.url;
					if (statusUrl.substr(0, 7) == "http://") statusUrl = "https://" + statusUrl.substring(7);
					this.datas.get2({ url: statusUrl, jwt: false, loading: false }).then((res: any) => {
						if (res.url) {
							const audio = new Audio(`${res.url}`);
							audio.addEventListener('loadedmetadata', () => {
								const url = res.url;
								const duration = Math.round(audio.duration);
								resolve({ url, duration });
							});
						}
					});
				}
			});
		});
	}

	updateResult(score: number, pronunciationScore: any, curriculumScore: any) {
		let result: any = {
			score: Math.round(score / 10),
			pronunciationScore: [
				{pronunciation: Math.round(pronunciationScore.PronScore / 10)},
				{accuracy: Math.round(pronunciationScore.AccuracyScore / 10)},
				{completeness: Math.round(pronunciationScore.CompletenessScore / 10)},
				{fluency: Math.round(pronunciationScore.FluencyScore / 10)}
			]
		};
		if (curriculumScore) result.curriculumScore = curriculumScore;
		this.result.push(result);

		if (this.questionIndex === this.question.length - 1) {
			let fulfil = 11 - this.result.length;
			for (let i = 0; i < fulfil; i++) {
				this.result.push({score: null, pronunciationScore: [], curriculumScore: []})
			}
			console.log(this.result)
		}
		console.log(this.result)
	}

	listenButtonClicked(filePath) {
		this.audio.setAttribute("src", filePath);
		this.audio.play();
	}

	replaceSymbol(content) {
		try {
			if (content.includes('’')) content = content.replace(/’/g, "'");
			if (content.includes('"')) content = content.replace(/"/g, "");
			if (content.includes(';')) content = content.replace(/-/g, ",");
			return content;
		} catch (error) {
			console.error(error);
			return "";
		}
	}

	handleUserAnswer(userAnswer: any) {
		if (this.parent.subType === "oralFillIn") {
			let checkingNumber = parseInt(userAnswer.NBest[0].ITN);
			let question = this.question[this.questionIndex].reading;
			let answer = this.question[this.questionIndex].answer;
			let isIncluded = question.toLowerCase().includes(answer.toLowerCase());
			let index = question.toLowerCase().indexOf(answer.toLowerCase());
			let isUppercase = false;
			if (index !== -1) {
				isUppercase = question[index] === question[index].toUpperCase();
			}

			if (isUppercase) {
				if (checkingNumber) {
					return `${userAnswer.NBest[0].Lexical.charAt(0).toUpperCase() + userAnswer.NBest[0].Lexical.substring(1)}.`;
				}
				return userAnswer.DisplayText;
			} else {
				if (checkingNumber) {
					return `${userAnswer.NBest[0].Lexical}.`;
				}
				return userAnswer.DisplayText.charAt(0).toLowerCase() + userAnswer.DisplayText.slice(1);
			}
		} else if (this.parent.subType === "oralMc") {
			return userAnswer.DisplayText;
		} else {
			return userAnswer.DisplayText;
		}
	}

	handleRecognitionWord(word: Array<any>) {
		let recognitionWordArray = []
		for (let item of word) {
			recognitionWordArray.push(item);
		}
		// console.log(recognitionWordArray);
		return recognitionWordArray;
	}

	handlePronunciationScore(scoreType: string) {
		return Math.round(this.eachQuestionScore.reduce((sum, item) => sum + item[scoreType], 0) / this.eachQuestionScore.length);
	}

	readText(text: string): void {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: this.voiceLibrary[this.teacherChar].gender,
			txt: text,
			speed: this.speed,
			lang: this.voiceSelection.includes("en-GB") ? "en-GB" : "en-US",
			pitch: 1,
			name: this.voiceLibrary[this.teacherChar].name,
			style: "",
		};
		this.setRobotImageStatus("talk");

		this.datas.get2({ url: url, data: data, jwt: false }).then((res: any) => {
			if (res.token && res.token.url) {
				let statusUrl: string = res.token.url;
				if (statusUrl.substr(0, 7) == "http://")
					statusUrl = "https://" + statusUrl.substring(7); //statusUrl = statusUrl.replace('http://', 'https://');
				this.datas
					.get2({ url: statusUrl, jwt: false })
					.then((res: any) => {
						if (res.url) {
							this.audio.setAttribute("src", res.url);
							this.audio.play();
							setTimeout(() => {
								this.setRobotImageStatus("idle");
							}, 5000);

							//});
						}
					});
			}
		});
	}

	getHint() {
		if (this.hintQuota <= 0) {
			return
		}
		//只用最後4條訊息獲取對話 節省tokens
		var tempConversation = [...this.conversation].filter(message => !message.isHint).slice(-4);
		tempConversation.push(
			{
				role: 'user',
				content: "How should I respond your question? give me an example in 20 words"
			}
		)

		this.chat.oralChat(tempConversation).then(res => {
			let content = JSON.parse(res).choices[0].message.content;
			this.conversation.push({ role: "user", content: this.replaceSymbol(content), isHint: true });
			this.hintQuota--;
			this.scrollToBottom();
		});
	}

	not_repeat_hint(hint, hint_idx) {
		for (const index in this.objectiveUsed) {
			if (index >= hint_idx) {
				break
			}
			if (this.objectiveUsed[index] == hint) {
				return false
			}
		}
		return true
	}

	get_display_choices(choices) {
		let choice_letter = "A";
		choices = choices
			.filter(choice => choice !== "")
			.map(choice => {
				let display_choice = `${choice_letter}: ${choice}`;
				choice_letter = String.fromCharCode(choice_letter.charCodeAt(0) + 1);
				return display_choice;
			})
		return choices;
	}

	preloadImage() {
		let url = [
			'oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/inBookImage/robot_waiter_talk.png',
			'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_correct.png',
			'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong.gif',
			'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong_last.png'
		];
		if (this.studentChar == '2') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/inBookImage/panda_talking.png');
		} else if (this.studentChar == '3') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/inBookImage/robot_talk.png');
		} else {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/inBookImage/student_talk.png');
		}
		url.forEach(e => {
			const imgEle = new Image();
			imgEle.src = '//' + e;
		});
	}

	setInstruction() {
		if (this.parent.subType === "oralVocab") {
			this.instruction = "Speak aloud the vocabulary asked by the bot.";
		} else if (this.parent.subType === "oralSentence") {
			this.instruction = "Speak aloud the phrase asked by the bot.";
		} else if (this.parent.subType === "oralFillIn") {
			this.instruction = "Speak aloud your answer when the bot asks you questions.";
		} else if (this.parent.subType === "oralMc") {
			this.instruction = "Speak aloud your answer when the bot asks you questions. No need to speak out the alphabet.";
		} else if (this.parent.subType === "oralParagraph") {
			this.instruction = "Speak aloud the paragraph asked by the bot.";
		} else if (this.parent.subType === "oralAI") {
			this.instruction = "Press the close button when you want to finish.";
		}
	}

	async setObjective(learningObjective) {
		let objective = [];
		for (const key in learningObjective) {
			if (learningObjective[key].length !== 0) {
				learningObjective[key].forEach(item => objective.push(item));
			}
		}
		
		const res = await this.dataService.post2({
			data: {
				api: "SpeechPractice.searchAssessmentFormPoint",
				json: [objective]
			}
		})

		if (res) {
			res.result.map((item, index) => {
				item["number"] = index + 1;
				if (item.name.includes('\n')) item.name = item.name.replace(/\n/g, "");
			})
			this.learningObjective = res.result.filter(item => !item.key.includes("3460")).map(item => item.name);
		}
	}
}