import { Component, HostListener, Output, EventEmitter, Input, ElementRef, OnInit, ViewChild, ApplicationRef, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { faBullseyeArrow, faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { SubjectService } from './subject.service';
import { ArrayUtils } from 'src/app/common/ArrayUtils';
import { Observable, Subscription, throwError } from 'rxjs';
import { debounceTime, map, takeUntil, throttleTime, } from 'rxjs/operators';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { ThemeService } from 'src/app/service/theme.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { AlertService } from 'src/app/service/alert.service';

export class BasicTreeConfig {
	iconSet: any;
	selectedItems: any[];
	selectedItem: any;
	type: string; // LO // LOForm EVForm
	mode: string; // point / form
	@Output() emitter: EventEmitter<any> = new EventEmitter();
	onSelectionChange: any;

	// emitter
	constructor() {
		console.log("BasicTreeConfig");
	}
}

@Component({
	selector: "expandable-component",
	template: `
		<div>
			<div class="line">
				<div class="expand-icon" (click)="toggleExpansion()">
					<fa-icon class="open" [icon]="(expanded ? iconSet.faChevronDown : iconSet.faChevronRight)" ></fa-icon>
				</div>
				<span class="name">{{name}}</span>
			</div>
			<div class="children">
				<ng-content *ngIf="expanded"></ng-content>
			</div>
		</div>
	`,
	styles: [

		`
		.line
		{
			font-weight: bold;
			color: white;
		}
		.expand-icon
		{
			display: inline-flex;
			width: 30px;
			height: 30px;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
			cursor: pointer;
			font-size: 16px;
		}
		
		.name{
			font-size: 14px;
		}
		.children{
			padding-left:15px;
		}
		`
	]

})

export class ExpandableComponent {
	@Input() name: string;
	public expanded: boolean = true;
	@Input() iconSet: any;
	// @Output() emitter:EventEmitter<any> = new EventEmitter();
	isSelected(node) {
		return node.selected;
	}

	toggleExpansion(): void {
		this.expanded = !this.expanded;
	}
}

@Component({
	selector: "tree-node",
	template: `
	<div class="c">
		<div *ngIf="node.level > 0" class="line">
			<div class="check" [class.cursorReset]="node.children.length == 0" (click)="toggleExpansion()">
				<fa-icon class="open" [class.hidden]="node.children.length==0" [icon]="(node.expanded ? config.iconSet.faChevronDown : config.iconSet.faChevronRight)" ></fa-icon>
			</div>
			<div *ngIf="node.isAllowCheck" class="check" [class.cursorReset]="!node.isAllowCheck" (click)="toggleSelection(node)">
				<fa-icon class="checkIcon" [class.checked]="node.selected" [class.greyChecked]="false" [icon]="(node.selected ? config.iconSet.faCheckCircle:config.iconSet.faCheckCircleLight)"></fa-icon>
			</div>
			<span class="nodeText" [class.evaluate-level]="node.type == 'evaluate'" [class.topic-level]="node.type == 'topic'" [class.cursorPointer]="node.isAllowCheck || node.children.length != 0" (click)="(node.isAllowCheck ? toggleSelection(node) : toggleExpansion())">
			<span *ngIf="node.type == 'topic'" class="topic-level"> (課題層)</span>
			<span *ngIf="node.type == 'evaluate'" class="evaluate-level"> (評分點)</span>
			 {{node.name}}
			</span>
		</div>
		
		<div class="children" *ngIf="node.expanded && node.children && node.children.length">
		
			<ng-container *ngFor="let childNode of node.children" >
				<tree-node [isTopicSelectControl]="isTopicSelectControl" [selectableLevels]="selectableLevels" [form]="form" [node]="childNode" [config]="config"></tree-node>
			</ng-container>
		</div>
	</div>
	`,
	styles: [

		`
		.topic-level{
			color: orange;
		}
		.evaluate-level{
			color: #73ff73;
		}
		.c{
			border:none;
		}
		.line
		{
			color:white;
            /*text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;*/
			display: flex;
		}
		.nodeText {
			white-space: pre-line;
			padding-top: 4px;
			padding-left: 5px;
		}
		.children{
			padding-left:15px;
		}
		.check
		{
			display: inline-flex;
			width: 30px;
			height: 30px;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: center;
			justify-content: center;
			cursor: pointer;
			font-size: 22px;
			flex: none;
		}
        .checkIcon {
            color: #fff;
        }
        .open {
            font-size: 16px;
        }
		.check fa-icon.checked {
			--fa-primary-color: #fff;
			--fa-secondary-color: #e4015a;
			--fa-primary-opacity: 1;
			--fa-secondary-opacity: 1;
			&.greyChecked{
				--fa-secondary-color: #999;
			}
		}
		.hiden{
			visibility: hidden;
		}
		.cursorReset {
			cursor: unset;
		}
		.cursorPointer {
			cursor: pointer;
		}
		`
	]

})

export class TreeNodeComponent implements OnInit {
	@Input() node: any;
	@Input() isTopicSelectControl: boolean = false;
	@Input() selectableLevels: number[] | true = true;
	@Input() form: any;
	//@Output() expanded:boolean;
	protected expanded: boolean;
	private selectedChildCount = 0;
	public llchildrenSelected = false;

	get isAllChildrenSelected(): boolean {
		if(!this.isTopicSelectControl){
			return true;
		}

		let allSelected = true;
		if (this.node.children && this.node.children.length > 0) {
			for (let i = 0; i < this.node.children.length; i++) {
				if (!this.node.children[i].selected) {
					allSelected = false;
					break;
				}
			};
		}
		return allSelected;
	}

	@Input() config: BasicTreeConfig;

	constructor(private als:AlertService, private datas: DataService) {
	}

	ngOnInit(): void {

		if (this.node.level == 0) {
			//this.expanded = true;
			this.node.expanded = true;
		}
	}

	isSelected(node) {
		return node.selected;
	}
	toggleSelection(node) {
		console.log("this", this);
		node.selected = !node.selected;
		ArrayUtils.removeElement(this.config.selectedItems, node.key);
		if (node.selected) {
			if (this.config.type == 'EVForm' || this.config.type == 'LOForm') {
				this.config.selectedItems = [node.key];
			} else {
				this.config.selectedItems.push(node.key);
			}
		}

		if (this.isTopicSelectControl) {
			if (this.form) {
				if (node.selected) {
					//select topic parent
					this.selectAllAncestors(node, this.form.points);
					let childCount = 0;
					this.selectAllDescendants(node, childCount);
					if(this.selectedChildCount > 1000){
						this.als.toastError({"tc":"每次最多只可選取1000個學習重點","sc":"每次最多只可选取1000个学习重点","en":"Maximum 1000 learning objectives"}[this.datas.lang]);
						this.selectedChildCount = 0;
					}
					
				} else {
					this.unselectAllChildren(node);
				}
			}
		}

		this.config.emitter.emit({ type: "selectionChanged" });
	}

	//for教學計劃
	private unselectAllChildren(node) {
		if (node.children && node.children.length > 0) {
			node.children.forEach(child => {
				child.selected = false;
				ArrayUtils.removeElement(this.config.selectedItems, child.key);
				this.unselectAllChildren(child);
			});
		}
	}

	//for教學計劃
	private selectAllDescendants(node, count = 0) {
		if (node.children && node.children.length > 0) {
			node.children.forEach(child => {
				child.selected = true;
				this.selectedChildCount++;
				if (this.config.selectedItems.indexOf(child.key) == -1) {
					this.config.selectedItems.push(child.key);
				}
				if(this.selectedChildCount < 1000){
					this.selectAllDescendants(child, count);
				}
			});
		}
	}

	//for教學計劃
	private selectAllAncestors(node, points = []){
		const parentId = node.parent_id;
		let parent = points.find(e => e.point_id == parentId);
		if (parent) {
			parent.selected = true;
			if (this.config.selectedItems.indexOf(parent.key) == -1) {
				this.config.selectedItems.push(parent.key);
			}
			this.selectAllAncestors(parent, points);
		}
	}

	//for教學計劃
	private findTopicPoint(parentId, points = []) {
		if (parentId == 0) {
			return;
		}

		let parent = points.find(e => e.point_id == parentId);

		if (parent.type == "topic") {
			return parent;
		}
		else {
			return this.findTopicPoint(parent.parent_id, points);
		}
	}


	toggleExpansion(): void {
		//this.expanded = ! this.expanded;
		this.node.expanded = !this.node.expanded;
	}

}
@Component({
	selector: "tree-selector",
	template: `
		<!-- (click)="toggleTree()" -->
		<div class="option-row group-row" (click)="toggleTree()">
			<fa-icon *ngIf="config.type != 'EVForm' && config.type != 'LOForm'" 
				class="open" [icon]="(expanded? config.iconSet.faChevronDown : config.iconSet.faChevronRight)" ></fa-icon>
			<div *ngIf="config.type == 'EVForm' || config.type == 'LOForm'" class="check" (click)="toggleSelection(form)">
				<fa-icon class="checkIcon" [class.checked]="form.selected" [icon]="(form.selected ? config.iconSet.faCheckCircle:config.iconSet.faCheckCircleLight)"></fa-icon>
			</div>
			<span>
				{{form.name}} 
			</span>
		</div>
		
		<ng-container *ngIf="ready && expanded && config.type != 'EVForm' && config.type != 'LOForm'">
			<tree-node [isTopicSelectControl]="isTopicSelectControl" [selectableLevels]="selectableLevels" [form]="form" [config]="config" [node]="root"></tree-node>
			<!-- 
				<div *ngFor="let sj of items" class="option-row tag-row" [attr.sjid]="sj.id">
				<div class="check" (click)="toggle(sj)">
					<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?iconSet.faCheckCircle:iconSet.faCheckCircleLight)"></fa-icon>
				</div>
				<span (click)="toggle(sj)">{{sj.name}} - {{sj.level}}</span>
				<div *ngIf="!showFav" class="option-row__heart {{isFav(sj) ?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>
				</div>
			-->
		</ng-container>
	`,
	styleUrls: ['./tree-selector.component.scss']
})

export class TreeSelectorComponent implements OnInit {
	public expanded: boolean;
	@Input() public form: any;
	@Input() public favSubjects: any[];
	@Input() public triggerAutoexpand;
	@Input() public isOnlySpecificLevel: boolean;
	public items: any[];
	// @Output() public iconSet:any;
	public showFav: boolean;
	public selected: boolean;
	public ifFav: boolean;
	public ready: boolean;
	public root: any;
	@Input() mode: string = LearningObjectiveSelectorMode.POINT;
	@Input() config: BasicTreeConfig;
	@Input() isTopicSelectControl: boolean = false;
	@Input() selectableLevels: number[] | true = true;
	constructor(public datas: DataService) {
		this.showFav = false;
		this.form = {
			type: "public",
			name: "小學(公用)"
		};
		this.items = [{ name: "中國語文" }, { name: "普通話" }];
		this.favSubjects = [];


	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.triggerAutoexpand && this.form && this.form.points && this.form.points.length > 0) {
			if (!this.ready) {
				this.ready = true;
				this.items = this.form.points;
				this.fillLevel();
				this.config.emitter.emit({ type: "formLoaded" });
				this.autoexpand();
			} else if (this.hasSelectedItems()) {
				this.autoexpand();
			}
		}
	}

	hasSelectedItems(): boolean {
		for (var i: number = 0; i < this.config.selectedItems.length; i++) {
			if (this.items.find(itm => itm.key == this.config.selectedItems[i]))
				return true;
		}
		return false;
	}

	autoexpand(): void {
		this.expanded = true;

		var map: any = {};
		this.items.forEach(e => {
			map[e.point_id] = e;
		});
		this.config.selectedItems.forEach(e => {
			var node = this.items.find(itm => itm.key == e);
			if (node) {
				this.expanded = true;
				//console.log("from:",node.name);
				this.expandParentNode(map, node.parent_id);
			}
		});
	}

	expandParentNode(map: any, parentID: number): void {
		if (map.hasOwnProperty(parentID)) {
			var node: any = map[parentID];
			node.expanded = true;
			//console.log(">",node.name);
			this.expandParentNode(map, node.parent_id);
		}
	}

	toggleTree() {
		if (this.config.type == 'EVForm' || this.config.type == 'LOForm') {
			return;
		}
		if (this.ready) {
			this.expanded = !this.expanded;

		} else {
			this.datas.call("AssessmentForm.get_detail", this.form.id).then((response) => {
				var form = response.form;
				this.form.points = this.items = form.points;
				this.expanded = !this.expanded;
				this.fillLevel();
				this.ready = true;
				this.config.emitter.emit({ type: "formLoaded" });
			})
		}
	}

	toggleSelection(form) {
		form.selected = !form.selected;
		if (form.selected) {
			const found = this.config.selectedItems.find(e => e.id == form.id);
			if (!found) {
				this.config.selectedItems = [form];
			}
		} else {
			this.config.selectedItems = this.config.selectedItems.filter(e => e.id != form.id);
		}
		this.config.emitter.emit({ type: "selectionChanged" });
	}

	fillLevel() {
		var map: any = {
			root: {
				name: "root",
				level: 0,
				children: [],
				form: this.form
			}
		};
		ArrayUtils.sortOn(
			this.items,
			[
				{ key: "sorting_order", order: "ASC", type: "numeric" }
			]
		);

		this.items.forEach((node: any) => {
			node.id = parseInt(node.id);
			node.children = [];
			var key = node.point_id;
			map[key] = node;
			node.root = map.root;
			node.form = this.form;
			node.selected = false;
			node.expanded = false;
			node.key = this.form.id + "-" + node.point_id;
		});

		this.root = map.root;
		this.items.forEach((point: any) => {
			if (map.hasOwnProperty(point.parent_id)) {
				var parentPoint = map[point.parent_id];
				parentPoint.children.push(point);
			} else {
				map.root.children.push(point);
			}
		})

		var iterate = (node, fn) => {
			fn.call(null, node);
			node.children.forEach(child => {
				iterate(child, fn);
			});
		}
		iterate(map.root, (node) => {
			var nextLevel = node.level + 1;
			node.children.forEach(child => {
				if (this.isOnlySpecificLevel) {
					child.isAllowCheck = child.evaluate_enabled
				} else if (this.isTopicSelectControl){
					child.isAllowCheck = true;
				} else if (Array.isArray(this.selectableLevels)) {
					child.isAllowCheck = this.selectableLevels.includes(child.level);
				} else {
					child.isAllowCheck = true;
				}
				child.level = nextLevel;
			});
		});
	}
	isFav(sj: any) {
		return this.ifFav;
	}
	isSelected(pt: any) {
		return this.selected;
	}

	public toggle(pt: any) {
		this.selected = !this.selected;
	}
	subjectHeartClick(data: any): void {
		this.ifFav = !this.ifFav;
	}

}
@Component({
	selector: "tree-selector-content",
	template: `
	<div class="tree-content" [class.wide]="type=='LO'" [class.height]="type=='LO'" [class.large-popup]="isLargePopup">
		<div class="left" [class.wide]="type=='LO'" [class.height]="type=='LO'"  [class.large-popup]="isLargePopup">
			<div class="label">{{
				{tc:'搜尋', sc:'搜寻', en:'Search '}[translate.currentLang] + 
				({
					LO: {tc:'學習重點', sc:'学习重点', en:'learning objective'},
					LOForm: {tc:'學習重點', sc:'学习重点', en:'learning objective'},
					EV: {tc:'評估點', sc:'评估点', en:'evaluation point'},
					EVForm: {tc:'評估表', sc:'评估表', en:'evaluation form'},
					LPF: {tc:'LPF', sc:'LPF', en:'LPF'}
				}[type]||{tc:'', sc:'', en:''})[translate.currentLang]
			}}:</div>
			<div class="sourceType">
				<div class="label">{{{tc:'來源', sc:'来源', en:'Source'}[translate.currentLang]}}:</div>
				<okaPulldown2 
					[options]="sourceType" bindLabel="label" bindValue="value" 
					[(ngModel)]="selectedSourceType" alwaysApplyTheme="light" 
					[themeStyles]="{default:{'common-textcolor': '#333333'}}" [okaPulldownStyle]="{width:'100%'}" 
					style="width: 100%;" [isDisabled]="isPulldownDisabled"
					(ngModelChange)="selectedSourceTypeChange()"
				></okaPulldown2>
			</div>
			<div class="searchBox">
				<input placeholder="{{'workspace.please-enter-keywords' | translate}}" [(ngModel)]="searchText" (ngModelChange)="searchTextChange.next($event)">
				<div [ngClass]="['icon',searchText==''?'zoom':'close']" (click)="searchText=''"></div>
			</div>
			<div *ngIf="!searchText" class="tagRowContainer">
				<perfect-scrollbar #scrollContent [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
					<ng-container *ngIf="isShowFormOnly">
						<ng-container *ngIf="selectedSourceType.indexOf('b') > -1">
							<div *ngFor="let form of public_forms">
								<div class="option-row group-row" >
									<div class="check" (click)="toggleForm(form)">
										<fa-icon class="checkIcon" 
											[class.checked]="treeConfig.selectedItem == form" 
											[icon]="(treeConfig.selectedItem == form ? treeConfig.iconSet.faCheckCircle:treeConfig.iconSet.faCheckCircleLight)"></fa-icon>
									</div>
									{{form.name}}
								</div>
								
							</div>
						</ng-container>
						<ng-container *ngIf="selectedSourceType.indexOf('p') > -1">
							<div *ngFor="let form of LPF_forms">
								<div class="option-row group-row" >
									<div class="check" (click)="toggleForm(form)">
										<fa-icon class="checkIcon" 
											[class.checked]="treeConfig.selectedItem == form" 
											[icon]="(treeConfig.selectedItem == form ? treeConfig.iconSet.faCheckCircle:treeConfig.iconSet.faCheckCircleLight)"></fa-icon>
									</div>
									{{form.name}}
								</div>
								
							</div>
						</ng-container>
						<ng-container *ngIf="selectedSourceType.indexOf('s') > -1">
							<div *ngFor="let form of school_forms">
								<div class="option-row group-row" >
									<div class="check" (click)="toggleForm(form)">
										<fa-icon class="checkIcon" 
											[class.checked]="treeConfig.selectedItem == form" 
											[icon]="( treeConfig.selectedItem == form ? treeConfig.iconSet.faCheckCircle:treeConfig.iconSet.faCheckCircleLight)"></fa-icon>
									</div>
									{{form.name}}
								</div>
								
							</div>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!isShowFormOnly">
					<ng-container *ngIf="options.allNode">
						<div class="option-row lineBelow" (click)="selectNode(allNode)">
							<div class="check">
								<fa-icon [class.checked]="isSelected(allNode)" [icon]="(isSelected(allNode)?treeConfig.iconSet.faCheckCircle:treeConfig.iconSet.faCheckCircleLight)"></fa-icon>
							</div>
							<span>{{allNode.title}}</span>
						</div>
					
						<div class="line"></div>
					</ng-container>
					<!-- LPF -->
					<expandable-component *ngIf="forms && treeConfig && selectedSourceType.indexOf('p') > -1" [iconSet]="treeConfig.iconSet" [name]="(type == 'LO' ? 'LPF' : ('LPF' | translate))">
						<div *ngFor="let form of LPF_forms">
							<tree-selector [isTopicSelectControl]="isTopicSelectControl" [selectableLevels]="selectableLevels" [form]="form" [config]="treeConfig" [triggerAutoexpand]="openCounter" [isOnlySpecificLevel]="isOnlySpecificLevel"></tree-selector>
						</div>
					</expandable-component>
					<!-- 公用表 -->
					<expandable-component *ngIf="forms && treeConfig && selectedSourceType.indexOf('b') > -1" [iconSet]="treeConfig.iconSet" [name]="('common.public' | translate)">
						<div *ngFor="let form of public_forms">
							<tree-selector [isTopicSelectControl]="isTopicSelectControl" [selectableLevels]="selectableLevels" [form]="form" [config]="treeConfig" [triggerAutoexpand]="openCounter" [isOnlySpecificLevel]="isOnlySpecificLevel"></tree-selector>
						</div>
					</expandable-component>
					<!-- 學校評估表 -->
					<expandable-component *ngIf="forms && treeConfig && selectedSourceType.indexOf('s') > -1" [iconSet]="treeConfig.iconSet" name="{{'common.school'|translate}}">
						<div class="noPointReminder" *ngIf="school_forms && school_forms.length == 0">{{(translate.currentLang == 'en') ? 'No learning point.' : '未有重點' }}</div>
						<div *ngFor="let form of school_forms">
							<tree-selector [isTopicSelectControl]="isTopicSelectControl" [selectableLevels]="selectableLevels" [form]="form" [config]="treeConfig" [triggerAutoexpand]="openCounter" isOnlySpecificLevel [isOnlySpecificLevel]="isOnlySpecificLevel"></tree-selector>
						</div>
					</expandable-component>
					</ng-container>
				</perfect-scrollbar>
			</div>

			<div *ngIf="searchText" class="tagRowContainer">
				<perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
					<ng-container *ngIf="searching">
						<div class="option-row tag-row">
							{{'commonService.searching'|translate}}
						</div>
					</ng-container>
					<ng-container *ngIf="treeConfig.mode == 'form'">
						<ng-container *ngFor="let sj of searchedNodes">
							<div class="option-row tag-row"  [attr.sjid]="sj.id">
								<div class="check" (click)="selectNode(sj)">
									<fa-icon 
										[class.checked]="sj == treeConfig.selectedItem" 
										[icon]="(sj == treeConfig.selectedItem ? treeConfig.iconSet.faCheckCircle:treeConfig.iconSet.faCheckCircleLight)"></fa-icon>
								</div>
								<span>{{sj.name}}</span>
							</div>
						</ng-container>
					</ng-container>
					
					<ng-container *ngIf="treeConfig.mode != 'form'">
						<ng-container *ngFor="let sj of searchedNodes">
							<div class="option-row tag-row"  [attr.sjid]="sj.id">
								<div class="check" (click)="selectNode(sj)">
									<fa-icon [class.checked]="isSelected(sj)" [icon]="(isSelected(sj)?treeConfig.iconSet.faCheckCircle:treeConfig.iconSet.faCheckCircleLight)"></fa-icon>
								</div>
								<span>{{ sj.name}}</span>
								<!--<span (click)="selectNode(sj)">{{sj.title}} ({{('workspace.subject-type-' + sj.type)|translate}}, {{('workspace.subject-level-' + sj.level) |translate}})</span>
								<div *ngIf="!options.publicOnly" class="option-row__heart {{favSubjects.indexOf(sj) > -1?'bookmarked':''}}" (click)="subjectHeartClick(sj)"></div>-->
							</div>
						</ng-container>
					</ng-container>
					
				</perfect-scrollbar>
			</div>
		</div>
		<div class="right" [class.wide]="type=='LO'" [class.height]="type=='LO'"  [class.large-popup]="isLargePopup">
			<div class="label">
				<span>{{'workspace.selected' | translate}} ({{modalSelectedItems.length}}):</span>
				<div class="clear-all" (click)="clear()">{{'workspace.clear-all'|translate}}</div>
			</div>
			<div class="selected-list" [class.accept-custom]="options.acceptCustom">
				<ng-container *ngIf="treeConfig.mode == 'point'">
					<ng-container *ngIf="treeConfig.type == 'EVForm' || treeConfig.type == 'LOForm'">
						<div class="list1 selected-list__selected-item" *ngFor="let form of modalSelectedItems">
							<fa-icon class="icon" [icon]="treeConfig.iconSet.faBullseyeArrow"></fa-icon>
							<div class="text">
								<span>{{ form.name }}</span>
							</div>
							<fa-icon class="trash" [icon]="treeConfig.iconSet.faTrash" (click)="clear(form)"></fa-icon>
						</div>
					</ng-container>
					<ng-container *ngIf="treeConfig.type != 'EVForm' && treeConfig.type != 'LOForm'">
						<div class="list2 selected-list__selected-item" *ngFor="let subject of modalSelectedItems">
							<!-- <div class="icon"></div> -->
							<fa-icon class="icon" [icon]="treeConfig.iconSet.faBullseyeArrow"></fa-icon>
							<div class="text" *ngIf="subject.form.id != 'custom'">
								<span>{{subject.name}}</span>
							</div>
							<div class="text custom-node" *ngIf="subject.form.id == 'custom'">
								<span>{{subject.name}}</span>
								<span class="custom-node-label">{{'workspace.custom-node'|translate}}</span>
							</div>
							<fa-icon class="trash" [icon]="treeConfig.iconSet.faTrash" (click)="clear(subject)"></fa-icon>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="treeConfig.mode == 'form'">
					<ng-container *ngIf="treeConfig.type == 'EVForm' || treeConfig.type == 'LOForm' || treeConfig.type == 'LO'  || treeConfig.type == 'PLO'">
						<div class="list1 selected-list__selected-item" *ngFor="let form of modalSelectedItems">
							<fa-icon class="icon" [icon]="treeConfig.iconSet.faBullseyeArrow"></fa-icon>
							<div class="text">
								<span>{{ form.name }}</span>
							</div>
							<fa-icon class="trash" [icon]="treeConfig.iconSet.faTrash" (click)="clear(null, form)"></fa-icon>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div class="custom-panel" *ngIf="options.acceptCustom">
				<div class="custom-panel-label">{{'workspace.custom-node'|translate}}:</div>
				<div class="custom-panel-row">
					<input type="text" [(ngModel)]="customNode" maxlength="80" />
					<div class="custom-panel-clear custom-panel-clear-left" *ngIf="customNode != ''" (click)="customNode = ''"></div>
					<div class="custom-panel-button" (click)="customNodeAdd($event)">{{'common.add'|translate}}</div>
				</div>
			</div>
			<div class="button-panel">
				<div class="confirm" (click)="confirmClick()">{{'alert.confirm'|translate}}</div>
				<div class="cancel" (click)="cancelClick()">{{'alert.cancel'|translate}}</div>
			</div>
		</div>
	</div>
	
	`,
	styleUrls: ['./tree-selector-container.scss']
})



export class TreeSelectorContentComponent implements OnInit, OnDestroy {
	@ViewChild('scrollContent', { static: false }) scrollContent: PerfectScrollbarComponent;

	@Input() public lastPosition: number = 0;
	@Input() public selectedItems: any = [];
	@Output() public selectedItemsChange = new EventEmitter<any>();
	@Output() public confirm = new EventEmitter<any>();
	@Output() public cancel = new EventEmitter<any>();
	@Input() public options: any = {};
	@Input() public type: string;
	@Input() public isOnlySpecificLevel: boolean
	@Input() public selectedSourceType: string = "s"; // p=lpf, b=public, s=school
	@Input() public isPulldownDisabled: boolean = false
	@Input() public subjectId: string | null = null
	@Input() public mode: string = LearningObjectiveSelectorMode.POINT;
	@Input() public isTopicSelectControl: boolean = false;
	@Input() public selectableLevels: number[] | true = true;
	@Input() public isLargePopup: boolean = false;

	public modalSelectedItems: any = [];

	public searchText: string = "";

	public treeConfig: BasicTreeConfig;

	public isTagLoaded: boolean = false;

	public favSubjects: any[] = [];
	public allNode: any;
	public isForAll: boolean = false;
	public year: any = null;
	public selectedSubjectTitle = '';
	public forms: any[];
	public specificForm: any;
	public public_forms: any[];
	public LPF_forms: any[];
	public school_forms: any[];
	public openCounter: number = 0;
	public sourceType: any[] = [{ label: "", value: "p" }, { label: "", value: "b" }, { label: "", value: "s" }]; // p lpf, b 公用, s 本校
	public searchedNodes: any[] = [];
	public searchTextChange = new Subject();
	public onDestroy = new Subject();
	public customNode = '';
	public isShowFormOnly: boolean = false;
	public includeFormDetail: boolean;
	constructor(public subject: SubjectService, public lds: LoadingService,
		public translate: TranslateService, public datas: DataService, private loadingService: LoadingService, public appRef: ApplicationRef, private trans: TranslateService) {


		// init tree config
		this.treeConfig = new BasicTreeConfig();

		this.treeConfig.iconSet = {
			faBullseyeArrow: faBullseyeArrow,
			faSearch: faSearch,
			faCheck: faCheck,
			faCircle: faCircle,
			faTrash: faTrash,
			faChevronRight: faChevronRight,
			faChevronDown: faChevronDown,
			faTimes: faTimes,
			faCheckCircle: faCheckCircle,
			faCheckCircleLight: faCheckCircleLight
		}
		this.treeConfig.selectedItems = [];

		this.watchPointChanges();
	}
	watchPointChanges(): void {
		this.treeConfig.emitter.subscribe(() => {
			this.updateSelection();
		});
	}

	reset() {
		this.searchText = "";
		this.specificForm = null;
		this.treeConfig.selectedItem = null;
		this.treeConfig.selectedItems = [];
		// this.searchedNodes = [];
		this.forms = null;
		this.modalSelectedItems = [];
	}

	resetSelected(){
		this.searchText = "";
		this.treeConfig.selectedItem = null;
		this.treeConfig.selectedItems = [];
		this.modalSelectedItems = [];
	}

	private matchSearch(searchParts: string[], text: string): boolean {
		text = text.toLocaleLowerCase();
		var len: number = searchParts.length;
		for (var i = 0; i < len; i++) {
			var searchPart: string = searchParts[i];
			if (text.indexOf(searchPart) == -1) {
				return false;
			}
		}
		return true;
	}
	ngOnInit(): void {
		this.treeConfig.type = this.type;
		this.allNode = { id: "all", title: this.translate.instant("schoolSetting.label.subject_level_type.all") };
		//		this.load(); // init 時唔需要 load form
		this.sourceType.map((typeObj: any) => {
			typeObj.label = {
				tc: { p: 'LPF', b: '公用', s: '學校' },
				sc: { p: 'LPF', b: '公用', s: '学校' },
				en: { p: 'LPF', b: 'Public', s: 'School' }
			}[this.translate.currentLang][typeObj.value];
		});
		this.searchTextChange.pipe(takeUntil(this.onDestroy), debounceTime(500)).subscribe(this.onSearchText.bind(this));
	}

	public selectedSourceTypeChange():void {
		if(this.searchText!="")
			this.onSearchText(this.searchText);
	}

	private onSearchText(searchText) {
		if (this.treeConfig.mode == LearningObjectiveSelectorMode.FORM) {
			var searchPattern = this.searchText.toLocaleLowerCase().split(/[ ,]/g).filter((text) => {
				return text;
			});
			if (this.selectedSourceType.indexOf('b') > -1) {
				if (searchPattern.length == 0) {
					this.searchedNodes = this.public_forms.concat();
				} else {
					this.searchedNodes = this.public_forms.filter((form: any) => {
						return this.matchSearch(searchPattern, form.name);
					})
				}
			} else if (this.selectedSourceType.indexOf('p') > -1) {
				if (searchPattern.length == 0) {
					this.searchedNodes = this.LPF_forms.concat();
				} else {
					this.searchedNodes = this.LPF_forms.filter((form: any) => {
						return this.matchSearch(searchPattern, form.name);
					})
				}
			}
			else if (this.selectedSourceType.indexOf('s') > -1) {
				if (searchPattern.length == 0) {
					this.searchedNodes = this.school_forms.concat();
				} else {
					this.searchedNodes = this.school_forms.filter((form: any) => {
						return this.matchSearch(searchPattern, form.name);
					})
				}
			} else {
				this.searchedNodes = [];
			}

		} else {
			if (this.specificForm) {
				var searchPattern = this.searchText.toLocaleLowerCase().split(/[ ,]/g).filter((text) => {
					return text;
				});
				this.searchedNodes = [];
				this.forms.forEach((form) => {
					form.points.forEach((e) => {
						if (this.matchSearch(searchPattern, e.name.toLocaleLowerCase())) {
							this.searchedNodes.push(e);
						}
					});
				});
			} else {
				this.searchedNodes = [];
				// this.loadingService.add('find_points_by_keyword', 3000);
				this.searching = true;
				var observable: Observable<any>;
				var searchType:string = this.selectedSourceType=="b" ? "PLO" : "LO";
				if(this.selectedSourceType == "p") searchType = "LPF";
				if (this.specificForm)
					observable = this.datas.post('AssessmentForm.find_points_by_keyword', [searchText, searchType, this.subjectId, [this.specificForm.id], this.specificForm.version]);
				else
					observable = this.datas.post('AssessmentForm.find_points_by_keyword', [searchText, searchType, this.subjectId]);

				observable.subscribe((res: any) => {
					this.searchedNodes = res.points.map(e => {
						e.form = { id: e.assessment_form_id, name: e.form_name, school_id: e.school_id };
						e.children = [];
						// this.loadingService.remove('find_points_by_keyword');
						return e;
					});
					this.searching = false;
				});
			}
		}
	}
	public searching: boolean = false;
	ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}

/*	public isShowCheckBox(searchedNode): boolean {
		console.log("searchedNode", searchedNode);
		return true;
	}*/


	toggleForm(form) {
		this.treeConfig.selectedItem = form;
		this.treeConfig.selectedItems = [form];
		this.treeConfig.emitter.emit({ type: "selectionChanged" });
	}

	public load(reload = false): void {

		this.treeConfig.mode = this.isShowFormOnly ? LearningObjectiveSelectorMode.FORM : LearningObjectiveSelectorMode.POINT;

		if (this.specificForm && this.treeConfig.mode == LearningObjectiveSelectorMode.POINT) {
			this.lds.add("loadingLearningObjectives");
			this.datas.post(
				'AssessmentForm.get_specific_form_points_version',
				[
					this.specificForm.id,
					this.specificForm.version,
					this.selectedItems,
					this.type,
					null,
					this.includeFormDetail
				]).subscribe((data: any) => {
					// 無load過form先update
					// 將form資料排序
					data.forms.forEach(form => {
						form.id = parseInt(form.id);
						form.school_id = parseInt(form.school_id);
					});
					ArrayUtils.sortOn(
						data.forms,
						[
							{ key: "school_id", order: "ASC", type: "numeric" },
							{ key: "id", order: "ASC", type: "numeric" }
						]
					);
					this.forms = data.forms;
					console.log("this.forms", this.forms)
					this.selectedItems.forEach(item => {
						let selectedFormId = item;
						if (typeof item === 'object') {
							selectedFormId = item.id;
						}
						const found = this.forms.find(e => e.id == selectedFormId);
						if (found) {
							found.selected = true;
						}
					});


					var points = [];
					this.forms.forEach((form) => {
						form.points = data.points.filter(e => e.assessment_form_id == form.id);
						form.points.forEach((node) => {
							node.form = form;
							var key: string = node.assessment_form_id + "-" + node.point_id;
							node.key = key;
							points.push(node);
						});
					});

					this.school_forms = this.forms.filter((form) => form.school_id != '0');
					this.public_forms = this.forms.filter(form => (form.school_id == '0' && form.type != 'LPF') || form.type == 'PLO');
					this.LPF_forms = this.forms.filter(form => form.type == 'LPF');
					/*
					debugger;
					this.treeConfig.emitter.subscribe(()=>{
						this.updateSelection();
					});
					*/
					this.lds.remove("loadingLearningObjectives");
					this.fillFormPoints();
					this.updateModalSelectedItems();
				});
			return;
		}
		/*
		if(reload == false && this.forms)
		{
			return;
		}
		*/
		// this.includeFormDetail
		if (this.treeConfig.type == 'EVForm' || this.treeConfig.type == 'LOForm') {
			this.datas.post('AssessmentForm.get_form_points', [[], this.type, null, this.includeFormDetail]).subscribe((data: any) => {
				if (!this.forms) {
					// 無load過form先update
					// 將form資料排序
					data.forms.forEach(form => {
						form.id = parseInt(form.id);
						form.school_id = parseInt(form.school_id);
					});
					ArrayUtils.sortOn(
						data.forms,
						[
							{ key: "school_id", order: "ASC", type: "numeric" },
							{ key: "id", order: "ASC", type: "numeric" }
						]
					);

					this.forms = data.forms;
				}
				this.selectedItems.forEach(item => {
					let selectedFormId = item;
					if (typeof item === 'object') {
						selectedFormId = item.id;
					}
					const found = this.forms.find(e => e.id == selectedFormId);
					if (found) {
						found.selected = true;
					}
				});
				this.school_forms = this.forms.filter((form) => form.school_id != '0');
				this.LPF_forms = this.forms.filter(form => form.type == 'LPF');
				this.public_forms = this.forms.filter(form => (form.school_id == '0' && form.type != 'LPF') || form.type == 'PLO');
			});
			/*
			debugger;
			this.treeConfig.emitter.subscribe(()=>{
				this.updateSelection();
			});
			*/
			return;
		}

		if (reload) {
			this.forms = null
		}
		var loadPt: any[] = this.fillFormPoints();

		if (!this.forms || loadPt.length > 0) {
			if (this.treeConfig.mode == "form") {
				var sub: Subscription = this.loadSelectedPoints([]).subscribe(e => {
					this.updateSelection();
					sub.unsubscribe();
					this.scrollToLastPosition();
				});
			} else {
				var sub: Subscription = this.loadSelectedPoints(loadPt).subscribe(e => {
					this.updateSelection();
					sub.unsubscribe();

					this.scrollToLastPosition();
				});
			}

		} else {


			this.updateModalSelectedItems();
		}
	}
	private fillFormPoints(): any[] {
		var loadPt: any[] = this.selectedItems.concat([]);
		if (this.forms) {
			//console.log("非首次");
			// 檢查節點資料是否已載入過？
			this.forms.forEach(f => {
				f.points.forEach(p => {
					// 過濾已載入過的不需要再載入
					loadPt = loadPt.filter(itm => itm != p.key);
				});
			});
			this.openCounter++;
		}
		return loadPt;
	}

	private updateModalSelectedItems(): void {
		// 重建 node 已選擇記錄
		var array = [];
		var map: any = {};
		this.forms.forEach((f, formIndex) => {
			f.points.forEach((p, pointIndex) => {
				map[p.assessment_form_id + "-" + p.point_id + "-" + p.version] = p;
				map[p.assessment_form_id + "-" + p.point_id] = p;
				map[p.assessment_form_id + "-" + p.id] = p;
				map[p.id] = p;
				p.selected = false;
				/*
				var s = this.selectedItems.find(itm=>itm == p.key);
				if(s) {
					console.log(formIndex, pointIndex);
					debugger;
				}
				
				// 在DB 找到記錄先顯示出來
				var s = this.selectedItems.find(itm=>itm == p.key);
				if(s) {
					this.modalSelectedItems.push(p);
					p.selected = true;
				} else
					p.selected = false;
				*/
			});
		});
		this.selectedItems.forEach((item) => {
			if (map.hasOwnProperty(item)) {
				var point: any = map[item];
				if(this.isTopicSelectControl){
					const form = this.forms.find(e => e.id == point.assessment_form_id);
					this.selectAllParent(form, point, array);
				}
				point.selected = true;
				array.push(point);
			}
		})
		this.modalSelectedItems = array;
		if(this.isTopicSelectControl){
			array = array.filter((item, index) => array.indexOf(item) === index);
			this.treeConfig.selectedItems = array.map(e => e.key);
		}

		this.scrollToLastPosition();
	}

	private selectAllParent(form: any, point: any, array): void {
		if (point.parent_id) {
			const parent = form.points.find(e => e.point_id == point.parent_id);
			if (parent && !parent.selected) {
				parent.selected = true;
				array.push(parent);
				this.selectAllParent(form, parent, array);
			}
		}
	}

	protected scrollToLastPosition(): void {
		if (this.lastPosition != 0) {
			setTimeout(() => {
				this.scrollContent.directiveRef.scrollTo(0, this.lastPosition);
			}, 0);
		}
	}

	public updateSelection(): void {
		if (!this.forms) return;
		if (this.treeConfig.mode == "form") {
			if (this.treeConfig.selectedItem) {
				var item: any = this.treeConfig.selectedItem;
				var type: string = typeof item;
				var selectedFormId: any = (type == "number" || type == "string") ? item : item.id;
				this.modalSelectedItems = [];
				if (selectedFormId) {
					this.forms.forEach(form => {
						if (selectedFormId == form.id) {
							this.treeConfig.selectedItem = form;
							this.modalSelectedItems.push(form);
							form.selected = true;
						} else {
							form.selected = false;
						}
					});
				}
			}

		} else if (this.treeConfig.type == 'EVForm' || this.treeConfig.type == 'LOForm') {
			this.modalSelectedItems = [];
			if (this.treeConfig.selectedItems.length > 0) {
				const selectedFormId = typeof this.treeConfig.selectedItems[0] === 'object' ? this.treeConfig.selectedItems[0].id : this.treeConfig.selectedItems[0];
				this.forms.forEach(form => {
					if (selectedFormId == form.id) {
						this.modalSelectedItems.push(form);
						form.selected = true;
					} else {
						form.selected = false;
					}
				});
			} else {
				this.forms.forEach(form => {
					form.selected = false;
				});
			}
		} else {
			this.forms.forEach(form => {
				form.points.forEach(point => {
					point.selected = false;
				});
			});
			console.log("this.treeConfig.selectedItems", this.treeConfig.selectedItems);
			this.modalSelectedItems = this.treeConfig.selectedItems.map(e => {
				if (e == "all")
					return this.allNode;
				return this.findPoint(e);
			}).filter(e => e);
			this.modalSelectedItems.forEach((node) => {
				node.selected = true;
			});
		}
	}

	private findPoint(e: string): any {
		if (e) {
			var array: string[] = e ? e.split("-") : [];
			if (array.length > 1) {
				var formID = parseInt(array[0]);
				var pointID = parseInt(array[1]);
				var formLen = this.forms.length;
				for (var formIndex = 0; formIndex < formLen; formIndex++) {
					var form = this.forms[formIndex];
					if (form.id == formID) {
						var pointLen = form.points.length;
						for (var pIndex = 0; pIndex < pointLen; pIndex++) {
							var point = form.points[pIndex];
							if (point.id == pointID || point.point_id == pointID) {
								return point;
							}
						}
					}
				}
			} else if (e.indexOf('custom|') === 0) {
				const label = this.trans.instant('workspace.custom-node');
				const arr = e.split('|');
				let school_id = this.datas.userInfo ? this.datas.userInfo.school_id : null;
				if (school_id == null && this.datas.jwtObj) {
					school_id = this.datas.jwtObj.school_id;
				}
				let customNode = { id: e, key: e, name: arr[1], form: { id: 'custom', name: label, school_id: school_id } };
				return customNode;
			}

		}
		return null;

	}

	private loadSelectedPoints(pts: any[]): Observable<any> {
		return this.datas.post('AssessmentForm.get_form_points', [pts, this.type, this.subjectId, this.includeFormDetail]).pipe(map(
			(data: any) => {
				if (!this.forms) {
					// 無load過form先update
					// 將form資料排序
					data.forms.forEach(form => {
						form.id = parseInt(form.id);
						form.school_id = parseInt(form.school_id);
					});
					ArrayUtils.sortOn(
						data.forms,
						[
							{ key: "school_id", order: "ASC", type: "numeric" },
							{ key: "id", order: "ASC", type: "numeric" }
						]
					);

					this.forms = data.forms;
				}

				// 將point資料排序
				data.points.forEach(p => {
					p.id = parseInt(p.id);
					p.point_id = parseInt(p.point_id);
					p.parent_id = parseInt(p.parent_id);
					p.status = parseInt(p.status);
					p.selected = parseInt(p.selected);
					p.sorting_order = parseInt(p.sorting_order);
				});
				ArrayUtils.sortOn(
					data.points,
					[
						{ key: "sorting_order", order: "ASC", type: "numeric" }
					]
				);

				var points = [];
				this.forms.forEach((form) => {
					form.points = data.points.filter(e => e.assessment_form_id == form.id);
					form.points.forEach((node) => {
						node.form = form;
						var key: string = node.assessment_form_id + "-" + node.point_id;
						node.key = key;
						points.push(node);
					});
				});
				this.modalSelectedItems = points;
				if (this.type == "LO") {
					this.datas.post2({
						data: {
							api: 'AssessmentForm.get_form_points',
							json: [pts, "PLO"]
						}
					}).then((res) => {
						this.public_forms = res.forms.filter((form) => {
							return form.type == 'PLO';
						})
						this.public_forms.forEach((form) => {
							form.points = data.points.filter(e => e.assessment_form_id == form.id);
							form.points.forEach((node) => {
								node.form = form;
								var key: string = node.assessment_form_id + "-" + node.point_id;
								node.key = key;
								points.push(node);
							});
						});
						var formMap: any = {};
						var array = [];

						this.public_forms.forEach((form: any) => {
							formMap[form.id] = form;
							array.push(form);
						})
						this.forms.forEach((form: any) => {
							if (formMap.hasOwnProperty(form.id) == false) {
								formMap[form.id] = form;
								array.push(form);
							}
						});
						// this.forms = this.forms.concat(this.public_forms)
						this.forms = array;
					});
					this.datas.post2({
						data: {
							api: 'AssessmentForm.get_form_points',
							json: [pts, "LPF"]
						}
					}).then((res) => {
						
						this.LPF_forms = res.forms.filter((form) => {
							return form.school_id == 0 && form.type == 'LPF';
						})
						this.LPF_forms.forEach((form) => {
							form.points = data.points.filter(e => e.assessment_form_id == form.id);
							form.points.forEach((node) => {
								node.form = form;
								var key: string = node.assessment_form_id + "-" + node.point_id;
								node.key = key;
								points.push(node);
							});
						});
						var formMap: any = {};
						var array = [];

						this.LPF_forms.forEach((form: any) => {
							formMap[form.id] = form;
							array.push(form);
						})
						this.forms.forEach((form: any) => {
							if (formMap.hasOwnProperty(form.id) == false) {
								formMap[form.id] = form;
								array.push(form);
							}
						});
						// this.forms = this.forms.concat(this.public_forms)
						this.forms = array;
					})
					
				} else if(this.type == "LPF") {
					this.LPF_forms = this.forms.filter((form) => {
						return form.school_id == 0;
					})
				}else{
					this.public_forms = this.forms.filter(form => (form.school_id == '0' && form.type != 'LPF') || form.type == 'PLO');
				}

				this.school_forms = this.forms.filter((form) => {
					return form.school_id != 0;
				})
				/*
				debugger;
				this.treeConfig.emitter.subscribe(()=>{
					this.updateSelection();
				});
				*/
				this.treeConfig.selectedItems = this.selectedItems;
				this.openCounter++;
				return this.forms;
			}
		))

	}
	selectNode(sj) {
		console.log("this", this);

		if (this.treeConfig.mode == "form") {
			this.treeConfig.selectedItem = sj;
		} else {
			let found = this.modalSelectedItems.find(e => e.id == sj.id);
			if (found) {
				// 已是選取的，就取消選取
				this.modalSelectedItems = this.modalSelectedItems.filter(e => e.id != sj.id);
				return;
			}

			// 未選取
			if (sj == this.allNode) {
				// 如果選擇 all ，就只有 all
				this.modalSelectedItems = [sj];
				return;
			}

			// 不選擇 all ，則要移除 all
			this.modalSelectedItems = this.modalSelectedItems.filter(e => e != this.allNode);

			// 加入選擇
			this.modalSelectedItems.push(sj);
			this.selectedItems = this.modalSelectedItems.map(e => e.key);
			if (this.treeConfig) {
				this.treeConfig.selectedItems = [...this.selectedItems];
			}
		}
	}

	public searchNodes() {
		// this.datas.post('AssessmentForm.find_points_by_keyword', [this.searchText, this.type]).pipe(map((res:any)=>{
		// 	return res.points;
		// } ) ).subscribe((points:any)=>{
		// 	debugger;
		// });
		return this.subject.subjects.filter((node) => {
			return node.title.indexOf(this.searchText) > -1 && (!this.options.publicOnly || node.type == 'public')
		});
	}

	subjectHeartClick(sj) {
		const index = this.favSubjects.indexOf(sj);
		this.loadingService.add('fav');
		this.datas.post('ROWorkspace.setFavSubject', [sj.id]).subscribe((res: any) => {
			console.log(res);
			if (index == -1) {
				this.favSubjects.push(sj);
			} else {
				this.favSubjects.splice(index, 1);
			}
			let ps = this.datas.userInfo.personalSettings;
			ps.workspace_fav_subjects = res.personalSettings.workspace_fav_subjects;
			this.loadingService.remove('fav');
		});
	}

	isSelected(item) {
		return this.modalSelectedItems.find(e => e.id == item.id);
	}

	clear(subject = null, form = null) {
		if (subject) {
			subject.selected = false;
			this.modalSelectedItems = this.modalSelectedItems.filter(e => e.id != subject.id);
			ArrayUtils.removeElement(this.treeConfig.selectedItems, subject.key);
		} else if (form) {
			this.modalSelectedItems = this.modalSelectedItems.filter(e => e.id != form.id);
			this.treeConfig.selectedItems = this.treeConfig.selectedItems.filter(e => e.id != form.id);
			this.treeConfig.selectedItem = null;
		} else {
			this.modalSelectedItems.forEach(node => {
				node.selected = false;
			});
			this.treeConfig.selectedItems = [];
			this.treeConfig.selectedItem = null;
			this.modalSelectedItems = [];
		}

	}

	confirmClick() {
		let key = 'key';
		if (this.treeConfig.type == 'EVForm' || this.treeConfig.type == 'LOForm') {
			key = 'id';
		}
		var keyArray = this.modalSelectedItems.map(e => e[key]);
		this.selectedItems = keyArray;
		this.selectedItemsChange.emit(keyArray);
		this.confirm.emit(keyArray);
	}

	cancelClick() {
		this.cancel.emit(null);
	}

	customNodeAdd($event) {
		console.log(this.customNode);
		const label = this.trans.instant('workspace.custom-node');
		let customNode: any = { id: 'custom|' + this.customNode, name: this.customNode, form: { id: 'custom', name: label, school_id: this.datas.userInfo.school_id } };
		customNode.key = customNode.id;
		this.selectNode(customNode);
	}


}

export class LearningObjectiveSelectorMode {
	static POINT: string = "point";
	static FORM: string = "form";
}

/**
	learning objective popup 
	form mode 
		<learningObjectivePopupSelector type="LO" #learningObjectiveSelector selectedSourceType="s" [isPulldownDisabled]="true" [isShowFormOnly]="true"></learningObjectivePopupSelector>
	point mode
		<learningObjectivePopupSelector type="LO" [options]="{acceptCustom: 1}" selectedSourceType="p" #learningObjectiveSelector></learningObjectivePopupSelector>
 */
@Component({
	selector: "learningObjectivePopupSelector",
	template: `
	<bubbleBox2 
		#bubblebox 
		[backgroundColor]="'var(--section-maincolor)'"
		[boxShadow]="themeService.getStyleValue('wpopup-shadow') || '0px 5px 30px rgba(0,0,0,0.3)'"

		[padding]="0"
		[submitOnClose]="true"
        [rejectOnClose]="true"
		>
		<!-- [backgroundColor]="'#1D6DBA'"  -->
		<tree-selector-content
			#treeContent
			(confirm)="onConfirm($event)"
			(cancel)="onCancel($event)"
			[selectedItems]="selectedItems"
			[options]="options"
			[type]="type"
			[lastPosition]="lastPosition"
			[isTopicSelectControl]="isTopicSelectControl"
			[isLargePopup]="isLargePopup"
			[selectableLevels]="selectableLevels"
			></tree-selector-content>
	</bubbleBox2>
	`
})

export class LearningObjectivePopupSelector implements OnChanges, OnInit {

	@ViewChild('treeContent', { static: false }) treeContent: TreeSelectorContentComponent;
	@ViewChild('bubblebox', { static: false }) bubblebox: BubbleBox2Component;
	@Input() public type: string;
	@Input() public options: any = {};
	private _selectedItems: any[] = [];
	// @Input() public selectedItems: any[] = [];
	@Input() public selectedItem: any = null;
	@Input() public isShowFormOnly: boolean = false;
	@Input() public isOnlySpecificLevel: boolean = false
	@Input() public selectedSourceType: string //p=public, s=school
	@Input() public isPulldownDisabled: boolean = false
	@Input() public subjectId: string | null = null;
	@Input() public includeFormDetail: boolean = false;
	@Input() public returnFormat: any = "id"; // point // (point)=>{ return point.id};
	@Input() public isTopicSelectControl: boolean = false;
	@Input() public isLargePopup: boolean = false;
	@Input() public selectableLevels: number[] | true = true;  // true = all levels
	/**
	 * "latest" or null = Latest
	 */
	@Input() public version: any = "latest";
	@Input() public mode: string = LearningObjectiveSelectorMode.POINT;
	// @Input() public includeVersion:boolean;
	public confirmFlag: boolean;
	public reject: boolean = false;
	public lastPosition: number = 0;

	get selectedItems() {
		return this._selectedItems;
	}

	@Input() set selectedItems(value: any[]) {
		this.reset();
		this._selectedItems = value;
	}

	constructor(public datas: DataService, public translate: TranslateService, public themeService: ThemeService) {

	}

	ngOnInit(){
		if (this.selectedSourceType == null && this.datas.userInfo){
			const commonSchoolType = this.datas.userInfo.school.common_school_type;
			if (commonSchoolType == 'Special'){
				this.selectedSourceType = 'p';
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.subjectId && changes.subjectId.previousValue) {
			this.subjectId = changes.subjectId.currentValue;
			this.treeContent.subjectId = this.subjectId;
			this.treeContent.isShowFormOnly = this.isShowFormOnly;
			this.treeContent.includeFormDetail = this.includeFormDetail;
			this.treeContent.load(true);
		}
	}

	public setFormVersion(id: any, version: any): void {
		this.treeContent.specificForm = {
			id: id,
			version: version
		}
	}

	reset() {
		this._selectedItems = [];
		if(this.treeContent){
			this.treeContent.resetSelected();
		}

	}
	public getAllForms(): any[] {
		return this.treeContent.forms;
	}
	public getSelectedForm(): any {
		return this.treeContent.treeConfig.selectedItem;
	}
	open(target: any, nativeElement: HTMLElement = document.body): Promise<any> {
		this.confirmFlag = false;
		// 將 input copy 一份，令未完成的更改不要取代原有資料
		// this.selectedItems = this.selectedItems.map(e=>e);
		var config: BasicTreeConfig = this.treeContent.treeConfig;
		this.selectedItems = this.selectedItems.map((pt: string) => {
			if (/^[0-9]{1,}-[0-9]{1,}-[0-9]{1,}$/.test(pt)) {
				return pt.replace(/^([0-9]{1,}-[0-9]{1,})-[0-9]{1,}$/, "$1");
			} else {
				return pt;
			}
		});

		this.treeContent.type = this.type;
		this.treeContent.isOnlySpecificLevel = this.isOnlySpecificLevel
		this.treeContent.selectedItems = this.selectedItems;
		this.treeContent.selectedSourceType = this.selectedSourceType ? this.selectedSourceType :
			this.type == "LO" ? 's' : 'p';
		this.treeContent.isPulldownDisabled = this.type == "LPF" ? true : this.isPulldownDisabled;
		this.treeContent.subjectId = this.subjectId
		this.treeContent.isShowFormOnly = this.isShowFormOnly;

		config.mode = this.isShowFormOnly ? "form" : "point";
		this.treeContent.includeFormDetail = this.includeFormDetail;
		this.treeContent.isTopicSelectControl = this.isTopicSelectControl;
		this.treeContent.selectableLevels = this.selectableLevels;
		config.selectedItems = this.selectedItems;
		config.selectedItem = this.selectedItem;
		this.treeContent.updateSelection();
		this.treeContent.load();
		return new Promise<any>((_resolve, _reject) => {
			this.bubblebox.open(target, nativeElement).then((o: any) => {
				var that = this;
				if (this.treeContent.scrollContent) {
					this.lastPosition = this.treeContent.scrollContent.directiveRef.elementRef.nativeElement.scrollTop;
				}
				if (this.confirmFlag) {
					if (config.mode == "form") {
						_resolve(config.selectedItem);
					} else {
						var points: any[];
						if (this.returnFormat == "id") {
							points = this.selectedItems;
						} else if (this.returnFormat == "point") {
							points = this.treeContent.modalSelectedItems.concat();
						} else if (this.returnFormat instanceof Function) {
							// return `${pt.assessment_form_id}-${pt.id}-${pt.version}`;
							points = this.treeContent.modalSelectedItems.map(this.returnFormat);
						} else {
							points = this.treeContent.modalSelectedItems.concat();
						}

						_resolve(points);
					}
				} else {
					// release outside, click cancel
					_reject("cancel");
				}
			}).catch((reason: any) => {
				// click on same cell release
				this.onCancel(null);
			});

		}).then((o) => {
			console.log("selected learning objective", o);
			return o;
		})
	}

	onConfirm(data): void {
		if (this.treeContent && this.treeContent.scrollContent) {
			this.lastPosition = this.treeContent.scrollContent.directiveRef.elementRef.nativeElement.scrollTop;
		}
		this._selectedItems = data;
		this.confirmFlag = true;
		this.bubblebox.close();
	}

	onCancel(data: any): void {
		if (this.treeContent && this.treeContent.scrollContent) {
			this.lastPosition = this.treeContent.scrollContent.directiveRef.elementRef.nativeElement.scrollTop;
		}
		this.confirmFlag = false;
		this.bubblebox.close();
	}

	// [options]="options"
	// (confirm)="onConfirm($event)"
	// (cancel)="onCancel($event)"
}

