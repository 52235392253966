import { ObjectUtils } from "src/app/common/ObjectUtils";
import {Html5Entities} from 'html-entities';
import { moveItemInArray } from "@angular/cdk/drag-drop";

export interface XMLNodeOptions
{
	assignTag:(element:any, nodeXML:XMLNode)=>void;
	assignAttributes:(element:any, nodeXML:XMLNode)=>void;
	assignText:(element:any, node:XMLNode)=>void;
	getChildren:(element:any)=> any[] ;
	removeChild:(parentNode:XMLNode, node:XMLNode)=>void;
}

export class XMLNode
{
	
	
	public children:XMLNode[];
	public tag:string;
	public text:string;
	public attributes:any;
	public element:any;
	
	constructor()
	{
		this.children = [];
	}
	
	public get localName():string
	{
		return this.tag;
	}

	public initElement(element:any):void
	{
		this.element = element;
	}

	public assign(o:any):XMLNode
	{
		for(var key in o) this[key] = o[key];
		return this;
	}

	public createNode(tag:string, attributes:any):XMLNode
	{
		var element:any = {
			type:"element",
			name:tag,
			attributes:attributes,
			elements:[]
		}
		this.initElement(element);
		return this;
	}
	
	public createElement():void
	{
		if(!this.element && this.attributes && this.tag)
			this.element = {attributes:this.attributes, elements:[], name:this.tag, type:"element"};
	}
	
	public hasAttribute(name:string):boolean
	{
		return this.attributes.hasOwnProperty(name);
	}

	public setAttribute(name:string, value:any):void
	{
		this.attributes[name] = value;
		if(this.element) this.element[name] = value;
	}

	removeAttribute(name: string):void
	{
		delete this.attributes[name];
		if(this.element) delete this.element.attributes[name];
	}

	public getAttribute(name:string, defaultValue:any = null):any
	{
		return this.attributes && this.attributes.hasOwnProperty(name) ? this.attributes[name] : defaultValue;
	}

	public addChild(child:XMLNode):void
	{
		this.children.push(child);
	}

	public removeChild(child:XMLNode):void
	{
		this.children = this.children.filter(e => e!=child);
	}

	public reorderChild(child: XMLNode, option: string): void {
		const index = this.children.indexOf(child);
		if (index === -1) return;
		
		if (option === "top") {
			// Move the child to the end of the array
			moveItemInArray(this.children, index, this.children.length - 1);
		} else if (option === "up" && index < this.children.length - 1) {
			// Move the child down by one position
			moveItemInArray(this.children, index, index + 1);
		} else if (option === "down" && index > 0) {
			// Move the child up by one position
			moveItemInArray(this.children, index, index - 1);
		} else if (option === 'bottom') {
			moveItemInArray(this.children, index, 0)
		}
	}
	
	selectAll(path: string): XMLNode [] {
		var parts:any [] = path.split(".");
		var len:number = parts.length;
		var containerNodes:XMLNode[] = [this];
		for(var i = 0;i < len;i++)
		{
			var childNodes:XMLNode[] = [];
			var p:string = parts[i];
			containerNodes.forEach((containerNode:XMLNode)=>{
				containerNode.selectChildren(p).forEach((c)=>{
					childNodes.push(c);
				});
			})
			containerNodes = childNodes;
		}
		return containerNodes;
	}
	
	
	selectChildren(tagName:string):XMLNode []
	{
		return this.children.filter((node:XMLNode)=>{
			return node.tag == tagName;
		});
	}

	deepCopy() {
		var copy:XMLNode = new XMLNode();
		copy.children = this.children ? this.children.map((child:XMLNode)=>{
			return child.deepCopy();
		}) : []
		copy.tag = this.tag;
		copy.text = this.text;
		copy.attributes = ObjectUtils.clone(this.attributes);
		copy.element = ObjectUtils.clone(this.element);
		return copy;
	}
	
	public toXMLString():string{
		if(this.text)
		{
			return Html5Entities.encode(this.text);
		} else {
			var att:string = this.getAttributeString();
			if(this.children && this.children.length)
			{
				var content:string = this.children.map((childNode:XMLNode)=>{
					return childNode.toXMLString();
				}).join("");
				return `<${this.tag} ${att}>${content}</${this.tag}>`;
			} else {
				return `<${this.tag} ${att}/>`;
			}
		}
	}

	private getAttributeString():string
	{
		var array:string [] = [];
		for(var key in this.attributes)
		{
			var value = this.encodeAttributeValue( this.attributes[key] );
			array.push(`${key}="${value}"`);
		}
		return array.join(" ");
	}
	private encodeAttributeValue(value:any):string
	{
		if(typeof value == "string")
		{
			return Html5Entities.encode(value);
		} else {
			return value;
		}
	}
}

export class XMLNodeIterator extends XMLNode
{
	options: XMLNodeOptions;
	constructor()
	{
		super();
	}
	build( element:any, options:XMLNodeOptions):XMLNodeIterator
	{
		this.options = options;
		this.element = element;
		this.children = [];
		if(options)
		{
			options.assignTag(element, this);
			options.assignText(element, this);
			options.assignAttributes(element, this);
			options.getChildren(element).forEach((childElement:any)=>{
				this.children.push(new XMLNodeIterator().build(
					childElement, options
				));
			});
			this.removeChild
		}
		return this;
	}
	
	public removeChild(child: XMLNode): void {
		this.options.removeChild(this, child);
	}

	public hasAttribute(name:string):boolean
	{
		return this.attributes.hasOwnProperty(name);
	}

	public getAttribute(name:string, defaultValue:any = null):any
	{
		return this.attributes && this.attributes.hasOwnProperty(name) ? this.attributes[name] : defaultValue;
	}

	public setAttribute(name:string, value:any):void
	{
		this.attributes[name] = value;
		this.element.attributes[name] = value;
	}
	
	removeAttribute(name: string):void
	{
		delete this.attributes[name];
		delete this.element.attributes[name];
	}
	
	selectAll(path: string): XMLNode [] {
		var parts:any [] = path.split(".");
		var len:number = parts.length;
		var containerNodes:XMLNode[] = [this];
		for(var i = 0;i < len;i++)
		{
			var childNodes:XMLNode[] = [];
			var p:string = parts[i];
			containerNodes.forEach((containerNode:XMLNode)=>{
				containerNode.selectChildren(p).forEach((c)=>{
					childNodes.push(c);
				});
			})
			containerNodes = childNodes;
		}
		return containerNodes;
	}
	
	
	selectChildren(tagName:string):XMLNode []
	{
		return this.children.filter((node:XMLNode)=>{
			return node.tag == tagName;
		});
	}
}