import { Injectable } from "@angular/core";
import { Subject, fromEvent, merge } from "rxjs";
import { UserIdleService } from "./UserIdleService";

@Injectable({ providedIn: 'root' })
export class WindowUnloadService
{
	subscription: any;

	constructor(
		public unfinishedBusinessService:UnfinishedBusinessService,
		public idleService:UserIdleService
	)
	{
		this.init();
	}
	init() {
		this.subscription = merge(
				fromEvent(window, "beforeunload"),
				fromEvent(window, "popstate")
			).subscribe((event:any)=>{
			if(this.unfinishedBusinessService.hasUnfinishedBusiness())
			{				
				event.preventDefault();
				event.returnValue = 'stop';
				this.unfinishedBusinessService.completeUnfinishedBusiness();
			}
		});
	}
	
}



@Injectable({ providedIn: 'root' })
export class UnfinishedBusinessService
{
	private list:any [];
	constructor()
	{
		this.list = [];
	}

	public add(fn:Function, context:any = null, autoRun:boolean = true):Task
	{
		var task:Task = new Task(fn, context)
		task.autoRun = autoRun;
		this.list.push(task);
		task.wait().then(()=>{
			this.removeTask(task);
		}).catch(()=>{
			this.removeTask(task);
		})
		return task;
	}

	public remove(fn:Function) {
		this.list.forEach((task:Task)=>{
			if(task.operation == fn)
			{
				this.removeTask(task);
			}
		});
	}

	private removeTask(task:Task):void
	{
		var index:number = this.list.indexOf(task);
		if(index != -1) this.list.splice(index, 1);
	}

	public hasUnfinishedBusiness():boolean
	{
		return this.list.length > 0;
	}

	public completeUnfinishedBusiness():Promise<any>
	{
		return Promise.all(
			this.list.map((task:Task)=>{
				return this.runTask(task);
			})
		).then(()=>{
			this.list = [];
		});
	}
	private runTask(task:Task):Promise<any>
	{
		if(task.running == false )
		{
			if(task.autoRun)
			{
				return task.run();
			} else {
				return Promise.reject("DO NOT AutoRun");
			}
		} else {
			return task.wait();
		}
	}

	public restoreUnfinishedBusiness(savedTasks: any[]) {
		// Add back the saved tasks to the unfinished business service
		savedTasks.forEach(task => {
			this.add(task.operation, task.context, task.autoRun);
		});
		// Clear the saved tasks
		savedTasks = []; 
	}
}

class Task
{
	
	isCompleted:boolean = false;
	running:boolean = false;
	subject: Subject<any>;
	autoRun:boolean = false;
	constructor(public operation:Function, public context:any)
	{
		this.subject = new Subject();
	}

	run():Promise<any>{
		if(!this.running) return this.runNow();
		return this.subject.toPromise();
	}
	private runNow():Promise<any>
	{
		return Promise.resolve(this.operation.call(this.context)).then(
			(response:any)=>{
				this.subject.next(response);
				this.subject.complete();
				this.isCompleted = true;
			}
		).catch((reason)=>{
			this.subject.error(reason);
		});
	}
	wait():Promise<any>
	{
		return this.subject.toPromise();
	}

	cancel() {
		this.subject.error("cancel");
	}
}
