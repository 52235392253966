import {Injectable, EventEmitter} from '@angular/core';
import {WebSocketService} from "../../../../service/webSocket.service";
import {CommonService} from "../../../../service/common.service";
import {LoadingService} from "../../../../sharedModule/loadingModule/loading.service";
import {DataService} from "../../../../service/data.service";
import {DomSanitizer} from "@angular/platform-browser";
import {UserInfoHelperService} from "../../../../service/UserInfoHelper.service";
import {Router} from "@angular/router";
import moment from "moment/moment";
import {OupService} from "../../../../service/oup.service";
import {AlertService} from "../../../../service/alert.service";
import {ROContext} from "../../../../sharedModule/roBookModule/ROContext";

@Injectable({providedIn: 'root'})
export class WebLessonService {

	student_list = [];
	room_id = null;
	message_type = "web_lesson";
	lesson_id = null;
	lesson = null;
	subscriptions = [];
	room_members = [];
	room_member_uids = [];
	session_id = null;
	session = null;
	is_teacher = false;
	is_teacher_arrived = false;
	teacher = null;
	lesson_teacher = null;
	seating_plan = null;
	account_update_setting = null;
	share = null;
	share_id = null;
	book = null;
	book_id = null;
	published = null;
	last_page_no = null;
	context: ROContext;

	on_message = new EventEmitter();

	lesson_state = {
		page_sync_method: 'semi',
		student_black_screen: false,
		student_lock_screen: false,
		student_lock_screen_silent: false,
		current_student_tools: [],
		book_id: null,
		published: null,
		share_id: null,
		lesson_id: null,
		session_id: null,
		is_started: false,
		page_id: null,
		force_student_action: 0,
		timer: {
			show: false,
			time_is_up: false,
			student_time: 0,
		},
		pointer: {
			show: false,
		},
		scoring_mode: {
			enabled: false,
			current_student_uid: null,
			index: null,
			student_sync: false,
			student_like: false
		},
		evaluation: {
			self_evaluation: true,
			student_evaluation: true,
			student_sync: false,
			page_id: null,
			current_student_uid: null,
			is_open: false,
		}
	}

	public result_panel_modal: {
		bsid: any,
		book_id: any,
		tag: any,
		bookName: any,
		bookType: any,
		share: any,
		selected_tab?: any,
		thumbnail_clicked?: Function,
	} = null;

	constructor(
		public wss: WebSocketService,
		public coms: CommonService,
		public loading: LoadingService,
		private datas: DataService,
		private dom_sanitizer: DomSanitizer,
		public user_info_helper: UserInfoHelperService,
		public router: Router,
		public oup: OupService,
		public alert: AlertService,
	) {
		this.is_teacher = this.datas.userInfo.user_role === "3";
		window.stop = () => {
			debugger
		};
	}

	is_connected() {
		return this.wss.isConnected();
	}

	async init(lesson_id, share_id, book_id, published) {
		console.log('web lesson init start', this.lesson, this.book, this.share);

		this.lesson_id = lesson_id;
		this.share_id = share_id;
		this.book_id = book_id;
		this.published = published;

		if (!this.session_id) {
			await this.find_last_session();
		}

		if (!this.lesson) {
			await this.join_lesson(this.lesson_id);
		}

		if (!this.book) {
			await this.get_book_entry(book_id);
			this.book.published = published;
		}

		if (!this.share) {
			let shares = await this.find_all_lesson_book_share(this.book);
			this.share = shares.find(s => `${s.id}` === `${this.share_id}`);
			this.share_id = this.share.id;
		}

		console.log('web lesson init', this.lesson, this.book, this.share);
		this.lesson_state.lesson_id = this.lesson_id;
		this.lesson_state.session_id = this.session_id;
		this.lesson_state.book_id = this.book_id;
		this.lesson_state.published = this.published;
		this.lesson_state.share_id = this.share_id;
		this.lesson_state.is_started = true;
		this.lesson_state.force_student_action = 0;
		if (this.is_teacher) {
			this.update_lesson_state();
		}
	}

	public async join_lesson(lesson_id) {
		this.lesson_id = lesson_id;
		this.room_id = `lesson-${this.lesson_id}`;
		let room_id = this.room_id;
		let close_loading = this.loading.add();
		let is_teacher = this.is_teacher ? 1 : 0;
		let token = this.get_device_token();
		let response = await this.datas.call('ROLesson.join_lesson', this.lesson_id, token, is_teacher);
		console.log(response);
		if (response.code === 0) {
			this.lesson = response.lesson;
			console.log(this.lesson);
			await this.refresh_student_list();
			if (is_teacher) {
				if (!this.session_id) {
					await this.create_session();
				} else {
					await this.find_last_session();
				}
			}
			await this.get_seating_plan();
			await this.get_update_setting();
		}
		this.subscriptions.push(this.wss.getMessageObservable("roomInfo", room_id).subscribe(async (message: any) => {
			console.log(message);
			this.room_members = message.content.members;
			this.room_member_uids = this.room_members.map(m => `${m.uid}`);
			this.teacher = this.room_members.find(m => `${m.user_role}` === "3");
			if (this.teacher) {
				let teacher_profile_map = await this.get_user_photos([this.teacher.uid]);
				let profile_image_url = null;
				if (teacher_profile_map[`${this.teacher.uid}`]) {
					profile_image_url = teacher_profile_map[`${this.teacher.uid}`];
				} else {
					profile_image_url = this.user_info_helper.getProfileImgPath(this.teacher);
				}
				this.teacher.profile_image_url = this.dom_sanitizer.bypassSecurityTrustResourceUrl(profile_image_url);
				this.lesson_teacher = this.teacher;
			}

			if(this.is_teacher) {
				this.update_lesson_state();
			}else {
				if(!this.teacher) {
					// 恢復控制權
					this.lesson_state.student_black_screen = false;
					this.lesson_state.student_lock_screen = false;
				}
			}
			this.is_teacher_arrived = !!this.teacher;
		}));
		this.subscriptions.push(this.wss.getMessageObservable(this.message_type, room_id).subscribe((message: any) => {
			// console.log(message);
			let action = message.content.action;
			if (!this.is_teacher) {
				if (action === 'open_book') {
					this.share_id = message.content.share_id;
					this.book_id = message.content.book_id;
					this.published = message.content.published;
					this.lesson_id = message.content.lesson_id;
					this.session_id = message.content.session_id;
					this.open_book();
				} else if (action === 'update_lesson_state') {
					this.lesson_state = message.content.lesson_state;
				}else if (action === 'send_alert_to_student') {
					this.coms.setZIndex(999999);
					this.alert.okAlert(message.content.msg);
				}
			}
			this.on_message.emit(message);

		}));
		this.subscriptions.push(this.wss.getEventObservable('close').subscribe((event: any) => console.log('ws closed')));

		await this.coms.waitFor(() => this.is_connected(), 30)
		this.send_join_lesson_message();
		if (close_loading) {
			close_loading()
		}

	}

	send_join_lesson_message() {
		let token = this.get_device_token();
		let jwt = this.datas.jwt;
		let lesson_id = this.lesson_id;
		this.wss.send({
			messageType: this.message_type,
			content: {
				action: "join_lesson",
				token,
				lesson_id,
				jwt,
			},
			roomId: this.room_id
		});
	}

	public async create_session() {
		let response = await this.datas.call('ROLesson.create_session', this.lesson_id);
		console.log(response);
		if (response.code === 0) {
			this.session = response.session;
			this.session_id = response.session.id;
		}
	}

	public async find_last_session() {
		let response = await this.datas.call('ROLesson.find_last_session', this.lesson_id);
		console.log(response);
		if (response.code === 0) {
			this.session = response.session;
			this.session_id = response.session.id;
		}
	}

	async get_user_photos(uid_list) {
		let response = await this.datas.call('KenCore.getPhotos', uid_list);
		console.log(response);
		let {assetsUrl, photo} = response;

		let profile_image_map = {};
		for (let record of photo) {
			profile_image_map[record.uid] = assetsUrl[record.server] + record.url;
		}
		return profile_image_map;
	}

	async refresh_student_list() {
		let response = await this.datas.call('ROLesson.get_student_list', this.lesson.tid);
		let student_list = response.members.filter(m => m.user_role === "2")
		console.log(student_list);
		let uid_list = student_list.map(s => s.uid);
		let profile_image_map = await this.get_user_photos(uid_list);

		// let user_icons = response.records;
		// console.log(user_icons);
		for (let student of student_list) {
			// set a getter to student for is_online
			Object.defineProperty(student, 'is_online', {
				get: () => this.is_student_online(`${student.uid}`)
			});
			let profile_image_url = null;
			if (profile_image_map[`${student.uid}`]) {
				profile_image_url = profile_image_map[`${student.uid}`];
			} else {
				profile_image_url = this.user_info_helper.getProfileImgPath(student);
			}
			student.profile_image_url = this.dom_sanitizer.bypassSecurityTrustResourceUrl(profile_image_url);
		}

		this.student_list = student_list;
	}

	async end_session() {
		if (!this.is_teacher) {
			return;
		}
		let response = await this.datas.call('ROLesson.end_session', this.session);
		console.log(response);
		if (response.code === 0) {
			this.session = null;
		}
	}

	get_device_token() {
		let token = localStorage.getItem('device_token') || this.coms.uuidv4();
		localStorage.setItem('device_token', token);
		return token;
	}

	async get_seating_plan() {
		let response = await this.datas.call('ROLesson.get_seating_plan', this.lesson_id);
		console.log(response);
		if(response.code === 0) {
			this.seating_plan = JSON.parse(response.info.info);
		}else {
			this.seating_plan = this.generate_seating_plan();
			await this.add_seating_plan(this.seating_plan);
		}
	}

	generate_seating_plan() {
		let x = 20;
		let x_init = 20;
		let y = 20;
		let x_offset = 130;
		let y_offset = 70;
		let count = 7;

		let seating_plan = this.student_list.map((student, index) => {
			let seat = {
				x,
				y,
				group: -1,
				id: parseInt(student.uid),
			}
			x += x_offset;
			if (index % count === 0) {
				x = x_init;
				y += y_offset;
			}

			return seat;
		});

		seating_plan.push({
			x: 410,
			y: 510,
			group: -1,
			id: -1,
		})

		console.log(seating_plan);
		return seating_plan;
	}

	async add_seating_plan(seating_plan) {
		let response = await this.datas.call('ROLesson.add_seating_plan', this.lesson_id, JSON.stringify(seating_plan));
		console.log(response);
		if(response.code === 0) {
			this.seating_plan = seating_plan;
		}
	}

	async set_end_time_to_book_share(is_pause = 0) {
		// let response = await this.datas.call(
		// 	'ROLessonSession.set_end_time_to_book_share',
		// 	this.share.id,
		// 	moment().format("YYYY-MM-DD HH:mm:ss"),
		// 	is_pause
		// );
		// console.log(response);

		let books = await this.get_session_books();
		for (let book of books) {
			if(book.type !== 'lesson') {
				continue;
			}
			let response = await this.datas.call(
				'ROLessonSession.set_end_time_to_book_share',
				book.id,
				moment().format("YYYY-MM-DD HH:mm:ss"),
				is_pause
			);

			console.log('set_end_time_to_book_share', response);

		}
	}

	async clear_end_time_in_book_share() {
		// let response = await this.datas.call(
		// 	'ROLessonSession.set_end_time_to_book_share',
		// 	this.share.id,
		// 	moment().format("YYYY-MM-DD HH:mm:ss"),
		// 	is_pause
		// );
		// console.log(response);

		let books = await this.get_session_books();
		for (let book of books) {
			if(book.type !== 'lesson') {
				continue;
			}
			let response = await this.datas.call(
				'ROLessonSession.set_end_time_to_book_share',
				book.id,
				null,
				0
			);

			console.log('set_end_time_to_book_share', response);

		}
	}

	// OUP
	async pause_lesson() {
		await this.set_end_time_to_book_share(1);
		await this.end_session();
		return this.leave_lesson();
	}

	// OUP
	async end_lesson() {
		await this.set_end_time_to_book_share(0);
		await this.end_session();
		return this.leave_lesson();
	}

	async set_last_page_to_book_share() {
		let response = await this.datas.call(
			'ROLessonSession.set_last_page_to_book_share',
			this.share.id,
			this.book_id,
			this.lesson_state.page_id,
			this.last_page_no,
		);

		return response.code === 0;
	}

	async leave_lesson() {
		if (!this.lesson_id) {
			return;
		}
		let token = this.get_device_token();
		let response = await this.datas.call('ROLesson.leave_lesson', this.lesson_id, token);
		console.log(response);
		if (response.code === 0) {
			if (this.is_teacher) {
				this.reset_lesson_state();
				this.wss.send({
					messageType: this.message_type,
					content: {action: "teacher_leave_lesson"},
					roomId: this.room_id
				})
			} else {
				this.wss.send({
					messageType: this.message_type,
					content: {action: "leave_lesson"},
					roomId: this.room_id
				})
			}
			this.lesson = null;
			this.student_list = [];
			this.room_members = [];
			this.subscriptions.map(sub => sub.unsubscribe());
			this.subscriptions = [];
			this.room_id = null;
			this.lesson_id = null;
			this.session_id = null;
			this.session = null;
			this.book = null;
			this.book_id = null;
			this.share = null;
			this.share_id = null;
			this.is_teacher_arrived = false;
			this.teacher = null;
			this.seating_plan = null;
			this.account_update_setting = null;
			this.lesson_teacher = null;
			this.last_page_no = null;
			this.context = null;
		}
		this.subscriptions.map(sub => sub.unsubscribe());
		this.subscriptions = [];
	}

	async get_update_setting() {
		let response = await this.datas.call('Account.getUpdateSetting');
		console.log(response);
		this.account_update_setting = response;
	}

	get_student_display_name(student) {

		if(this.oup.isOupUser) {
			let uid = student.uid;
			return this.oup.get_user_display_name(uid);
		}

		let name = "";
		if (this.datas.lang === 'en') {
			if (student.ename) {
				name = student.ename;
			} else if (student.last_name && student.first_name) {
				name = student.last_name + ' ' + student.first_name;
			}
		} else if (['tc', 'sc'].includes(this.datas.lang)) {
			if (student.cname) {
				name = student.cname;
			} else if (student.c_last_name && student.c_first_name) {
				name = `${student.c_last_name}${student.c_first_name}`;
			}
		}
		name = `${name}`.trim();
		if (!name.length) {
			name = student.nickname;
		}

		return name;
	}

	async find_book_share_by_book(book_id) {
		let response = await this.datas.call('ROBookShare.find_book_share_by_book', book_id);
		console.log(response);
		return response;
	}

	async find_all_lesson_book_share(book) {
		let response = await this.datas.call("ROBookShare.find_all_lesson_book_share", this.lesson_id, book)
		console.log(response);

		let shares = response.shares || [];

		shares = shares.filter(i => !!i);

		// reverse order
		shares.reverse();

		shares.forEach(share => {
			share.display_datetime = moment(share.created_at).format("YYYY-MM-DD HH:mm");
		});

		return shares;
	}

	async create_new_share(book, open_book = true) {
		let response = await this.datas.call('ROLessonSession.create_book_share', this.lesson_id, this.session, book, null);

		this.share = response.share;
		this.share_id = response.share.id;
		await this.add_session_book(book, open_book);
	}

	async add_session_book(book, open_book = true) {
		let bid, title;
		this.book = book;
		this.book_id = book.id;
		this.published = book.published;

		if (this.book.published) {
			bid = "ro_final_entries/" + this.book_id;
		} else {
			bid = "ro_entries/" + this.book_id;
		}
		title = this.book.title;

		let response = await this.datas.call(
			"ROLessonSession.add_session_book",
			this.session,
			this.share_id,
			bid,
			title
		);

		console.log(response);

		if (open_book) {
			this.open_book();
		}

	}

	open_book() {
		if (!this.share_id || !this.book_id || !this.lesson_id || this.published === null) {
			return false;
		}
		if (this.is_teacher) {
			this.wss.send({
				messageType: this.message_type,
				content: {
					action: "open_book",
					share_id: this.share_id,
					book_id: this.book_id,
					published: this.published,
					lesson_id: this.lesson_id,
					session_id: this.session_id
				},
				roomId: this.room_id,
			})
		}
		this.lesson_state.lesson_id = this.lesson_id;
		this.lesson_state.session_id = this.session_id;
		this.lesson_state.book_id = this.book_id;
		this.lesson_state.published = this.published;
		this.lesson_state.share_id = this.share_id;
		this.lesson_state.is_started = true;
		this.update_lesson_state();
		this.router.navigate(['bookViewer', this.share_id, this.book_id, this.published, this.lesson_id, 'lesson']);
	}

	async get_book_entry(book_id, get_only = false) {
		let response = await this.datas.call("ROBook.get_book_entry", book_id);
		console.log(response);
		if (get_only) {
			return response;
		}
		this.book = response;
		this.book_id = book_id;
		return this.book;
	}

	async go_to_page(page_id) {
		if (this.lesson_state.page_sync_method === 'none') {
			return;
		}
		console.log('go_to_page', page_id);
		this.wss.send({
			messageType: this.message_type,
			content: {
				action: "go_to_page",
				page_id: page_id
			},
			roomId: this.room_id,
		});
		this.lesson_state.page_id = page_id;
		this.update_lesson_state();
	}

	async update_lesson_state() {
		this.wss.send({
			messageType: this.message_type,
			content: {
				action: "update_lesson_state",
				lesson_state: this.lesson_state
			},
			roomId: this.room_id,
		});
	}

	toggle_student_black_screen(status = null) {
		this.lesson_state.student_black_screen = !this.lesson_state.student_black_screen;
		if(status !== null) {
			this.lesson_state.student_black_screen = status;
		}
		this.update_lesson_state();
	}

	toggle_student_lock_screen(status = null) {
		this.lesson_state.student_lock_screen = !this.lesson_state.student_lock_screen;
		if(status !== null) {
			this.lesson_state.student_lock_screen = status;
		}
		this.update_lesson_state();
	}

	toggle_current_student_tools(tool) {
		let tools = this.lesson_state.current_student_tools;
		if (tools.includes(tool)) {
			tools = tools.filter(t => t !== tool);
		} else {
			tools.push(tool);
		}
		this.update_current_student_tools(tools);
	}

	is_current_student_tool_active(tool) {
		return this.lesson_state.current_student_tools.includes(tool);
	}

	update_current_student_tools(toolbar_tools) {
		this.lesson_state.current_student_tools = toolbar_tools;
		this.update_lesson_state();
	}

	reset_lesson_state() {
		this.lesson_state = {
			page_sync_method: 'semi',
			student_black_screen: false,
			student_lock_screen: false,
			student_lock_screen_silent: false,
			current_student_tools: [],
			book_id: null,
			published: null,
			share_id: null,
			lesson_id: null,
			session_id: null,
			is_started: false,
			page_id: null,
			force_student_action: 0,
			timer: {
				show: false,
				time_is_up: false,
				student_time: 0,
			},
			pointer: {
				show: false,
			},
			scoring_mode: {
				enabled: false,
				current_student_uid: null,
				index: null,
				student_sync: false,
				student_like: false
			},
			evaluation: {
				self_evaluation: false,
				student_evaluation: false,
				student_sync: false,
				page_id: null,
				current_student_uid: null,
				is_open: false,
			}
		};
		this.update_lesson_state();
	}

	async reconnect() {
		console.log('reconnect');
		this.wss.disconnect()

		await this.coms.timeout(500);

		this.wss.checkBeforeConnect();

		await this.coms.waitFor(() => this.is_connected());

		this.send_join_lesson_message();
	}

	async setup_lesson_share(shares) {
		let session = this.session;

		shares = shares.filter(share => share.action !== 'none');

		let response = await this.datas.call('ROLessonSession.setup_lesson_share', session, shares);
		console.log(response);

		return response.code === 0;
	}

	force_student(action) {
		if (action === 0) {
			return;
		}

		this.lesson_state.force_student_action = action;
		this.update_lesson_state();
	}

	async get_session_books() {
		let response = await this.datas.call('ROLessonSession.get_session_book_shares', {id: this.session_id});
		console.log({response});
		// response = await this.datas.call('ROLessonSession.get_session_books', {id: this.session_id});
		// console.log({response});
		let books = response.records;
		books.sort((a, b) => {
			// bid asc
			return a.bid - b.bid;
		});
		return books;
	}

	async get_self_photo() {
		let uid = this.datas.userInfo.uid;
		let map = await this.get_user_photos([uid]);
		let profile_image_url = null;
		if (map[`${uid}`]) {
			profile_image_url = map[`${uid}`];
		} else {
			profile_image_url = this.user_info_helper.getProfileImgPath(this.datas.userInfo);
		}

		return profile_image_url;
	}

	back_to_lesson_list() {
		this.router.navigate(['lesson', this.datas.lang, this.datas.appId,]);
	}

	async get_page_thumbnails(douids) {
		let thumbnail_map = {};
		let response = await this.datas.call('Resource.get_specific_thumbnails2', douids)
		console.log(response);
		for (let thumbnail of response.thumbnails) {
			thumbnail_map[thumbnail.pid] = this.dom_sanitizer.bypassSecurityTrustResourceUrl(`${response.base}${thumbnail.url}`);
		}

		return thumbnail_map;
	}

	async get_lesson_by_owner_and_tag_id(uid, tag_id) {
		let response = await this.datas.call('ROLessonWeb.get_by_owner_and_tag_id', uid, tag_id);
		console.log(response);
		return response.lessons;
	}

	async create_lesson(lesson) {
		let response = await this.datas.call('ROLessonWeb.addLesson', lesson);
		console.log(response);
		return response;
	}

	async send_alert_to_student(msg) {
		this.wss.send({
			messageType: this.message_type,
			content: {
				action: "send_alert_to_student",
				msg
			},
			roomId: this.room_id
		})
	}

	toggle_pointer() {
		this.lesson_state.pointer.show = !this.lesson_state.pointer.show;
		this.update_lesson_state();
	}

	broadcast_msg(content) {
		this.wss.send({
			messageType: this.message_type,
			content: {
				action: "broadcast",
				...content
			},
			roomId: this.room_id
		})
	}

	update_pointer(pointer) {
		this.broadcast_msg({
			type: 'update_pointer',
			pointer: [pointer.x, pointer.y, pointer.is_using_epen]
		})
	}

	get_page_remain_submit_count(page_id) {
		if (!this.lesson || !this.context || !this.context.config || !this.context.config.dataSource) {
			return null;
		}
		if (Object.keys(this.context.config.dataSource.answerMap).length && Object.keys(this.context.pageSubmitCountMap).length === 0) {
			this.context.refresh_page_submit_count_map();
		}
		let remain = this.lesson.max_submit_per_page;
		let max_submission_count = this.context.pageSubmitCountMap[page_id] || 0;

		return Math.max(remain - max_submission_count, 0);
	}

	is_student_online(uid) {
		return this.room_member_uids.includes(`${uid}`);
	}

	scoring_mode_sync() {
		// this.lesson_state.student_lock_screen_silent = this.lesson_state.scoring_mode.student_sync;
		this.update_lesson_state();
	}

	evaluation_sync(sync) {
		this.lesson_state.evaluation.student_sync = sync;
		this.update_lesson_state();
		if (!sync) {
			this.lesson_state.evaluation.is_open = false;
			this.update_lesson_state();
			if (!this.lesson_state.current_student_tools.includes('evaluation')) {
				this.broadcast_msg({
					type: 'close_evaluation_tool',
				})
			}
		}else {
			this.lesson_state.evaluation.is_open = true;
			this.update_lesson_state();
			this.broadcast_msg({
				type: 'open_evaluation_tool',
			})
		}
	}
}