import { AfterViewInit, ApplicationRef, Component, ComponentRef, ElementRef, EventEmitter, HostListener, Injector, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef, isDevMode } from "@angular/core";
import { ReplaySubject, Subject, Subscription, interval } from "rxjs";

import { DynamicComponentService } from "src/app/service/dynamicComponent.service";
import { PromiseUtils } from "src/app/common/PromiseUtils";
import * as moment from 'moment';
import { StudentInfo } from "src/app/common/StudentInfo";
import { AssessmentViewerComponent } from "./AssessmentViewer.component";
import { delay } from "src/app/common/customFunction";
@Component({
	template:`
		<ng-container #container >
			<ng-container *ngIf="printInfo && context && bookConfig">
				<div *ngFor="let pageInfo of pageInfoArray;let i=index;" class="paper-container"
					[style.width.px]="printInfo.pageSize.width"
					>
					<div 
						class="paper"
						[style.transform]="'scale(' + pageInfo.scale+','+ pageInfo.scale+')'"
						>
						<div
							class="border-container" [class.hideMarking]="!printOption.showMarking"
							[class.hideSticker]="!printOption.showSticker"
							[style.width.px]="pageInfo.width">
							<div class="info">{{ {tc:'學生',sc:'',en:''}[lang] + ': ' + jobs[i].studentName }}</div>
							<AssessmentViewer #assessmentViewerRef class="show-border"
								[viewMode]="'print'" [share]="avp.data.share" [fontSize]="avp.fontSize" 
								[data]="avp.data" [bookViewerDataSource]="avp.dataSource"
								[onoff_student_list]="false" 
								[onoff_marking_layer]="true"
								[onoff_teacher_sticker_list]="false"
								[forceReadOnly]="true" [sourceIndex]="printOption.submissionIndex"
								[entryAndContent]="avp.entryAndContent" 
								></AssessmentViewer>
							<div 
								*ngIf="printOption.showContentBorder"
								class="border"
								></div>
						</div>
					</div>
					<div class="footer">
						<!--<div class="timestamp" *ngIf="printOption.showTimestamp">{{"ro.print.print-timestamp-format"|translate: printInfo  }}</div>
						<div class="page_info" *ngIf="printOption.showTitle">{{pageInfo.title}}</div>-->
					</div>
				</div>
			</ng-container>
		</ng-container>
	`,
	styleUrls:[ './PrintAssessmentView.scss']
	/**
		page-break-after  : always ;
		page-break-before : always ;	
	 */
})


export class PrintAssessmentView implements OnInit, AfterViewInit, OnDestroy
{
	@Input() public bookConfig;
	@Input() public context;
	@Input() public printOption:any;
	@Input() public pageInfo:any;
	@Input() public pageInfoArray:any [];
	@Input() public jobs:any [];
	public avp:any;
	@ViewChildren('assessmentViewerRef', { read: false }) assessmentViewerRef: QueryList<AssessmentViewerComponent>;

	@Output() public progress:number = 0;
	// 
	
	@ViewChild("container", {read: ViewContainerRef, static: false}) container: ViewContainerRef;
	// public pages:any[];
	public subject:Subject<any>;
	public componentRefArray:ComponentRef<any> [];
	public printInfo: any;
	public styleContainer:HTMLElement;
	private width = 990;
	public lang;
	constructor(private dcs:DynamicComponentService, private elementRef:ElementRef)
	{
		this.styleContainer = document.createElement("style");
		this.elementRef.nativeElement.appendChild(this.styleContainer);
		// StyleUtils.applyStyleObj(
		// 	this.elementRef.nativeElement, 
		// 	{
		// 		"page-title-visible" :  "block"
		// 	}
		// );
		this.subject = new ReplaySubject(1);
		this.subject.subscribe((o)=>{

		})
		this.componentRefArray = [];
	}

	ngOnInit(): void {
		this.avp = this.printOption.assessmentViewerParams;
		this.lang = localStorage.getItem('lang');
	}

	toLandscapeMode()
	{
		this.styleContainer.innerText = `
			@media print {
				@page {
					size: A4 landscape !important;
				}
				body{
					padding:0 !important;
					margin: 0mm !important;
				}
		  }
		`.replace(/[\r\n]/g, " ");
	}
	toPortraitMode()
	{
		this.styleContainer.innerText = `
			@media print {
				@page {
					size: A4 portrait !important;
				}
				body{
					padding:0 !important;
					margin: 0mm !important;
				}
		  }
		  `.replace(/[\r\n]/g, " ");
	}
	ngOnDestroy(): void {
		this.componentRefArray.forEach((compRef:ComponentRef<any>)=>{
			compRef.destroy();
		});
	}

	ngAfterViewInit(): void {
		
		setTimeout(()=>{
			this.buildAllPages();
		}, 100);
	}
	/*
	private loadData():Promise<any>
	{
		if(this.printOption.includeAnswer)
		{
			return Promise.resolve(null);
		} else {
			return Promise.resolve(null);
		}
	}
	this.printOption.includeAnswer
	this.bookConfig.dataSource
	*/

	private calculatePrintInfo2():any{
		let height = 'auto';
		let scale = 1;
		return {orientation: 'portrait', scale: scale, pageSize: {width: this.width, height: height} };
	}
	
	public stopped:boolean = false;
	private buildAllPages():Promise<any>
	{
		this.printInfo = this.calculatePrintInfo2();
		// new Date()
		// var m = moment;
		// this.printInfo.timestamp = "2023/8/16 5:30 pm **";
		this.printInfo.timestamp = moment(new Date()).format("YYYY/M/D h:mma");
		// 'YYYY/M/D h:mma'

		this.toPortraitMode();
		
		var _pageInfoArray = [];
		this.jobs.forEach((info)=>{
			if(info.showStudent){
				info.studentName = StudentInfo.getDisplayName(info.student, "tc");
			}
			const title = info.studentName;
			let item:any = {info: info, width: this.width, height: 'auto', scale: this.printInfo.scale, title: title };
			_pageInfoArray.push(item);
		});
		this.pageInfoArray = _pageInfoArray;
		
		return new Promise((resolve, reject)=>{
			setTimeout(()=>{
				resolve(null)
			}, 1000);
		}).then(()=>{
			var views:AssessmentViewerComponent[] = [];
			this.assessmentViewerRef.forEach((view:AssessmentViewerComponent, index: number)=>{
				const job = this.jobs[index];
				view.context.showSticker = this.printOption.showSticker;
				view.context.config.showMarking = this.printOption.showMarking;
				view.setDataSource(job.student.uid, job.submissions[0]);
				views.push(view);
			});
			var completed = 0;
			var total = views.length;
			return PromiseUtils.reduce(
				views, 
				(prev:any, view:AssessmentViewerComponent, index:number, reference:any [])=>{
					return delay(500).then(()=>{
						// page content ready
						if(this.stopped)
						{
							this.subject.next({type:"stopped"});
							return Promise.reject({type:"stopped"});
						} else {
							return Promise.resolve(null);
						}
					}).then((o:any)=>{
						completed++;
						this.progress = Math.round(completed / total * 100);
						this.subject.next({type:"message", "message":`${completed}/${total}`});
					})
				}, 
				null
			).then(()=>{
				return views;
			})
		}).then((o:any)=>{
			delay(500).then(()=>{
				this.updateContainer();
			});
			return delay(1000);
		}).then(()=>{
			this.subject.next({type:"ready"});
			return null;
		})
		
	}
	
	private updateContainer(){
		let containers = this.elementRef.nativeElement.querySelectorAll('.paper-container');
		[...containers].forEach(container=>{
			const avEle = container.querySelector('AssessmentViewer');
			const rect = avEle.getBoundingClientRect(); 
			container.style.height = rect.height + 'px';
			avEle.style.setProperty("--viewWidth", rect.width + "px");
			avEle.style.setProperty("--viewHeight", (rect.height + 100) + "px");
		});
	}

}

