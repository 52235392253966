import { Component, ElementRef, HostListener, OnDestroy, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/service/common.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { Subject, forkJoin } from 'rxjs';
import { AgGridService } from 'src/app/service/agGrid.service';
import { SubjectService } from 'src/app/sharedModule/subjectModule/subject.service';
import { DomSanitizer } from '@angular/platform-browser';
import { faTrash, faPlus, faClone  } from '@fortawesome/pro-solid-svg-icons';
import { BubbleBox2Component } from 'src/app/sharedModule/bubbleBox2Module/bubbleBox2.component';
import { WhitePopupComponent } from 'src/app/sharedModule/whitePopupModule/whitePopup.component';
import { AlertService } from 'src/app/service/alert.service';
import { ThemeService } from 'src/app/service/theme.service';
import { IconBtnRenderer } from 'src/app/sharedModule/customAgGridModule/iconBtn.renderer';
import { XlsxService } from 'src/app/service/xlsxService';
import { FileFormatter2Component } from 'src/app/sharedModule/customAgGridModule/fileFormatter2.component';
import { UploadService } from 'src/app/sharedModule/uploadModule/upload.service';
import downloadjs from "downloadjs";
import { AdditionRemarkBallHeader } from 'src/app/sharedModule/customAgGridModule/additionRemarkBall.header';
import { CellEditorComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';

@Component({
	selector: 'question-aggrid',
	template: `
	<ag-grid-angular
		*ngIf="gridOptions && parent"
		class="ag-theme-alpine forGrid {{theme.theme$.value}}-theme question-aggrid" 
		[gridOptions]="gridOptions"
		></ag-grid-angular>
	<div class="btn-add" (click)="btnAddClick()">
		<fa-icon [icon]="faPlus"></fa-icon>
	</div>
	<div class="btn-imp" (click)="btnImportClick()">{{ {tc:'匯入Excel',sc:'匯入Excel',en:'Import'}[lang] }}</div>
	<div class="btn-exp" (click)="btnExportClick()">{{ {tc:'匯出Excel',sc:'匯出Excel',en:'Export'}[lang] }}</div>
`,
	styleUrls: ['./question.aggrid.scss']
})

export class QuestionAggrid implements OnInit, AfterViewInit, OnDestroy {
	@Input() parent;
	@Input() format;
	@ViewChild('bubbleBox', { static: false }) bubbleBox: BubbleBox2Component;
	@ViewChild('whitePopup', { static: false }) whitePopup: WhitePopupComponent;
	public gridOptions;
	@Input() public questions = [];
	public gridQuestions:any = [];
	public subjects;
	public onDestroy = new Subject<void>();
	public bubbleBoxData;
	public whitePopupData;
	public faTrash = faTrash;
	public faClone = faClone;
	public faPlus = faPlus;
	public selectedItems: any[] = [];
	public updatedFormat:any[] = [];
	public lang;
	constructor(public datas: DataService, private trans: TranslateService, public ag: AgGridService, public coms: CommonService, private subject: SubjectService, private sans: DomSanitizer, private lds: LoadingService, private als: AlertService, public theme: ThemeService, public xlsx: XlsxService, public upload: UploadService, public elRef: ElementRef) {

	}

	ngOnInit(): void {
		this.lang = this.datas.lang;
		this.initGrid(this.questions, this.format);
	}

	ngAfterViewInit(): void {
		
	}

	ngOnDestroy(): void {
		this.coms.audioPlay(); //stop audio if any
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	initGrid(questions, format){
		const lang = this.lang;
		let updatedFormat = [];
		this.gridQuestions = JSON.parse(JSON.stringify(questions));
		format.forEach(item=>{
			const dataType = item.dataType;
			if (dataType == 'const'){
				//ignore
			} else if (dataType.indexOf('$') == -1){
				let obj:any = JSON.parse(JSON.stringify(item));
				if (typeof obj.title === 'object'){
					obj.label = obj.title[lang];
				} else if (obj.title){
					obj.label = obj.title;
				}
				updatedFormat.push(obj);
			} else if (dataType == '$score'){
				const label = {tc:'分數',sc:'分數',en:'Score'}[lang];
				let obj:any = {prop: 'score', dataType: 'float', label: label};
				updatedFormat.push(obj);
			} else if (dataType.indexOf('$mc') > -1){
				const arr = dataType.split(':');
				arr[2] = parseInt(arr[2]);
				let indexOptions:any = [];
				for(let i = 1;i <= arr[2]; i++){
					const charCode = String.fromCharCode(64 + i);
					const label = {tc:'答案' + charCode,sc:'答案' + charCode,en:'Answer ' + charCode}[lang];
					let obj:any = {prop:'$answer'+i, label: label, dataType: arr[1]};
					updatedFormat.push(obj);
					indexOptions.push({label: charCode, value: i - 1});
				}
				let obj:any = {prop: '$answerIndex', label: {tc:'答案',sc:'答案',en:'Answer'}[lang], dataType: 'select', options: indexOptions };
				updatedFormat.push(obj);
				this.gridQuestions.forEach(q=>{
					if (q.answers === undefined){
						q.answers = [];
					}
					for(let i = 1;i <= arr[2]; i++){
						if (q['$answer' + i] === undefined && q.answers && q.answers.length >= i){
							q['$answer' + i] = q.answers[i - 1];
						} else if (q.answers.length < i){
							q.answers.push(q['$answer' + i]);
						}
					}
					q['$answerIndex'] = String.fromCharCode(65 + q.correctAnswerIndex);
				});

			}
			
		});

		this.updatedFormat = updatedFormat;

		let colDefs: any = [
			{ headerName: { tc: '次序', sc: '次序', en: 'Sort' }[lang], field: '$sort', rowDrag: true, width: 60, suppressSizeToFit: true, editable: false, resizable: false, pinned: 'left' }
		];
				
		const mcTitleItem = this.format.find(item => item.prop === 'Mctitle');
		if (mcTitleItem) {
			this.gridQuestions.forEach(question => {
				if (!question['Mctitle']){
					question['Mctitle'] = mcTitleItem.default;
				}
			});
			colDefs.push({
			headerName: { tc: '題目', sc: '題目', en: 'Mctitle' }[lang], 
			field: 'Mctitle', 
			editable: true, cellEditor: 'agSelectCellEditor', 
			cellEditorParams: {
				values: mcTitleItem.options.map(option => option.label)
				},
				
			});
			colDefs.push({
        	headerName: { tc: '字詞', sc: '字詞', en: 'Fixed Select' }[lang], field: 'fixedselectValue',
			 editable: false,
			 cellRenderer: params => {
				const selectedOption = mcTitleItem.options.find(option => option.label === params.data.Mctitle);
				return selectedOption ? selectedOption.value : '';
				}
   			});
		} else {
    	const titleItem = this.format.find(item => item.prop === 'title');
   		if (titleItem) {
        colDefs.push({
            headerName: 'Title', field: 'title', editable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: {
                values: titleItem.options.map(option => option.label)
           	 		}
        		});
    		}
		}
		let frameworkComponents:any = { iconBtnRenderer: IconBtnRenderer };
		const fileFormatterDataType = ['image','images','audio','audios'];
		if (format.find(e=> fileFormatterDataType.indexOf(e.dataType) > -1)){
			frameworkComponents.fileFormatterComponent = FileFormatter2Component;
		}
		if (format.find(e=> e.info)){
			frameworkComponents.additionRemarkBallHeader = AdditionRemarkBallHeader;
		}
		let rowHeight = 30; 
		updatedFormat.forEach(item=>{
			const dataType = item.dataType;
			rowHeight = Math.max(rowHeight, item.height || 30);
			let colDef:any = { headerName: item.label, field: item.prop };
			if (item.dataType == 'select'|| item.dataType == '$fixedselect'){
				colDef.cellEditor = 'agRichSelectCellEditor';
				colDef.cellEditorParams = { 
					values: item.options.map(e=> e.label), 
					allowTyping: false,
					valueListMaxHeight: 100,
				};
				
				colDef.valueSetter = (params)=>{
					const valuedChanged = item.options.find(e=> e.label == params.newValue);
					if (valuedChanged){
						params.data[item.prop] = params.newValue;
						return true;
					}
					return false;
				};

				colDef.valueGetter = (params)=>{
					let selected = item.options.find(e=> e.value == params.data[item.prop]);
					if (selected){
						return selected.label;
					}
					selected = item.options.find(e=> e.label == params.data[item.prop]);
					if (selected){
						return selected.label;
					}
				};
			} else if (dataType == 'int' || dataType == 'float'){
				colDef.valueSetter = (params)=>{
					const parsed = dataType == 'init'?parseInt(params.newValue):parseFloat(params.newValue);
					const valueChanged = params.data[item.prop] !== parsed;
					if (valueChanged){
						params.data[item.prop] = parsed?parsed: params.oldValue;
					}
					return valueChanged;
				};
			} else if (fileFormatterDataType.indexOf(item.dataType) > -1){
				rowHeight = Math.max(rowHeight, item.height?item.height: 80);
				colDef.cellRenderer = 'fileFormatterComponent';
				colDef.cellRendererParams  = (params)=>{
					if(!params.data)
						return null;
					if (params.data[item.prop] === undefined || params.data[item.prop] === null){
						params.data[item.prop] = [];
					}
					const dataType = item.dataType;
					const jsonObj = { files: params.data[item.prop] };
					let display = 'name';
					let cellRendererParams:any  = {jsonObj: jsonObj, multiAssets: false, height: item.height?item.height:80, editable: true };
					if (dataType == 'images' || dataType == 'audios'){
						cellRendererParams.multiAssets = true;
					}
					if (dataType.indexOf('image') > -1){
						cellRendererParams.label = {tc:'選擇圖片',sc:'选择图片',en:'Select'}[lang];
						display = 'thumbnail';
					} else if (dataType.indexOf('audio') > -1){
						cellRendererParams.label = {tc:'選擇音訊',sc:'选择音讯',en:'Select'}[lang];
					} else {
						cellRendererParams.label = {tc:'選擇檔案',sc:'选择档案',en:'Select'}[lang];
					}
					cellRendererParams.display = display;
					if (dataType.substr(dataType.length - 1) == 's'){ //multiple files
						cellRendererParams.onFileAdd = (params, fileObj, index)=> this.onFileAdd(params, dataType, null);
						cellRendererParams.onFileReplace = (params, fileObj, index)=> this.onFileAdd(params, dataType, index);
						cellRendererParams.onFileDelete = (params, fileObj, index)=> this.onFileDelete(params, fileObj, index);
						if (dataType.indexOf('audio') > -1){
							cellRendererParams.onAudioPlay = (params, url)=> this.onAudioPlay(params, url);
						}
					} else { // single file
						if (params.data[item.prop].length == 0){
							cellRendererParams.onFileAdd = (params)=> this.onFileAdd(params, dataType, null);
						} else {
							cellRendererParams.onFileReplace = (params, fileObj, index)=> this.onFileAdd(params, dataType, index);
							cellRendererParams.onFileDelete = (params, fileObj, index)=> this.onFileDelete(params, fileObj, index);
							cellRendererParams.onFileDownload = (params, fileObj)=> this.onFileDownload(params, fileObj);
							if (dataType.indexOf('audio') > -1){
								cellRendererParams.onAudioPlay = (params, fileObj)=> this.onAudioPlay(params, fileObj);
							}
						}
					}
					return cellRendererParams;
				};
				colDef.valueSetter = (params)=>{
					if (params.newValue){
						const field = params.colDef.field;
						params.data[item.prop] = [ params.newValue ];
						this.questions[params.node.rowIndex][field] = [params.newValue];
						this.gridOptions.api.refreshCells({force: true, rowNodes: [ params.node ], columns: [ field ]});
					}
				}
				colDef.cellEditor = 'fileFormatterComponent';
				colDef.cellEditorParams = colDef.cellRendererParams;
			}
			colDefs.push(colDef);
		});
		colDefs[colDefs.length - 1].flex = 1;
		colDefs.push({
			width:30, headerName:"", field:'clone',
			lockPosition: 'right', editable:false, resizable: false, pinned: 'right', lockPinned:true, 
			cellClass: 'iconBtn',
			suppressSizeToFit: true,
			cellRendererFramework: IconBtnRenderer,
			cellRendererParams: {
				iconType: 'fa',
				icon: faClone,
				iconStyle: {},
				onClick: (event)=>{
					const rowIndex = event.node.rowIndex;
					let question = JSON.parse(JSON.stringify(this.questions[rowIndex]));
					this.questions.splice(rowIndex + 1, 0, question);
					let gridQuestion = JSON.parse(JSON.stringify(this.gridQuestions[rowIndex]));
					this.gridQuestions.splice(rowIndex + 1, 0, gridQuestion);
					this.resort();
					this.gridOptions.api.applyTransaction({add: [ gridQuestion ], addIndex: rowIndex + 1});
				}
			}
		});
		colDefs.push({
			width:30, headerName:"", field:'delete',
			lockPosition: 'right', editable:false, resizable: false, pinned: 'right', lockPinned:true, 
			cellClass: 'iconBtn',
			suppressSizeToFit: true,
			cellRendererFramework: IconBtnRenderer,
			cellRendererParams: {
				iconType: 'fa',
				icon: faTrash,
				iconStyle: {},
				onClick: (event)=>{
					if (this.questions.length <= 1){
						const msg = {tc:'遊戲最少要有一題題目。',sc:'游戏最少要有一题题目。',en:'The game must have at least one question.'}[lang];
						this.als.toastError(msg);
						return;
					}
					const rowIndex = event.node.rowIndex;
					this.questions.splice(rowIndex, 1);
					this.gridQuestions.splice(rowIndex, 1);
					this.resort();
					this.gridOptions.api.applyTransaction({remove: [event.node.data]});
				}
			}
		});

		format.forEach(item=>{
			if (item.info){
				const colDef = colDefs.find(e=> e.field == item.prop);
				if (colDef){
					colDef.headerComponent = 'additionRemarkBallHeader';
					if (typeof item.info === 'object'){
						colDef.headerComponentParams = { bubbleText: item.info[lang] };
					}
				}
			}
		});

		this.gridOptions = {
			rowDragManaged: true,
			animateRows: true,
			singleClickEdit: false,
			suppressRowTransform: true,
			suppressClickEdit: true,
			suppressDragLeaveHidesColumns: true,
			stopEditingWhenCellsLoseFocus: true,
			headerHeight: 30,
			rowHeight: rowHeight,
			maintainColumnOrder: true,
			suppressLoadingOverlay: true,
			rowData: this.gridQuestions,
			cellSelection: true,
			enableRangeSelection: true,
			enterMovesDown: true,
			defaultColDef: {
				editable: true,
				resizable: true,
				suppressMenu: true, suppressSizeToFit: false, width: 100,
				cellStyle: { fontSize: '15px', color: 'var(--common-textcolor)', alignItems: 'center', display: 'inline-flex' }
			},
			columnDefs: colDefs,
			frameworkComponents: frameworkComponents,
			onGridReady: event =>{
				setTimeout(()=>{
					(event) => event.api.sizeColumnsToFit();
				},100);
			},
			onColumnResized: (event) => {

			},
			onCellValueChanged: (event) => {
				const rowIndex = event.node.rowIndex;
				const field = event.colDef.field;
				const newValue = event.newValue;
				const updatedQuestion = this.questions[rowIndex];
				let eles = this.elRef.nativeElement.querySelectorAll('.ag-row[row-index="' + rowIndex + '"].invalid-red');
				eles.forEach(ele=>{
					ele.classList.remove('invalid-red');
				}); 
				if (field === 'Mctitle') {
					const mcTitleItem = this.format.find(item => item.prop === 'Mctitle');
					if (mcTitleItem) {
						const selectedOption = mcTitleItem.options.find(option => option.label === newValue);
						if (selectedOption) {
							event.data['fixedselectValue'] = selectedOption.value;
							setTimeout(() => {
								event.api.refreshCells({ force: true, rowNodes: [event.node], columns: ['fixedselectValue'] });
							});
						}
					}
				}
				if (field.indexOf('$') == -1){
					updatedQuestion[field] = newValue; 
					
				} else if (field == '$answerIndex'){
					if (isNaN(newValue)){
						updatedQuestion.correctAnswerIndex = newValue.charCodeAt(0) - 65;
					} else {
						updatedQuestion.correctAnswerIndex = parseInt(newValue) - 1;
					}
					updatedQuestion.correctAnswer = updatedQuestion.answers[updatedQuestion.correctAnswerIndex];						
				} else if (field.indexOf('$answer') > -1){
					const num = parseInt(field.split('$answer')[1]);
					updatedQuestion.answers[num - 1] = newValue;
					if (updatedQuestion.correctAnswerIndex == num - 1){
						updatedQuestion.correctAnswer = newValue;
					}
				}
				const mapToOther = this.updatedFormat.find(e=> e.mapToOther === field);
				if(mapToOther){
					const mapedValue = mapToOther.map[newValue];
					event.data[mapToOther.prop] = mapedValue;
					setTimeout(() => {
						event.api.refreshCells({ force:true,rowNodes: [event.node],columns:[mapToOther.prop] });
					});
				}
				if(updatedQuestion.dataType === '$fixedselect' ) {
					const selectedOption = updatedQuestion.options.find(option => option.label === newValue);
					if (selectedOption) {

						event.data['fixedselectValue'] = selectedOption.value;
						setTimeout(() => {
							event.api.refreshCells({ force: true, rowNodes: [event.node], columns: [field,'fixedselectValue'] });
						});
					}
				}
				if (updatedQuestion.dataType === 'select' ) {
					const selectedOption = updatedQuestion.options.find(option => option.label === newValue);
					if (selectedOption) {
						event.data[field] = selectedOption.label;
						setTimeout(() => {
							event.api.refreshCells({ force: true, rowNodes: [event.node], columns: [field, field + 'Value'] });
						});
					}
				}
			},
			onCellDoubleClicked: params => {
				const colId = params.column.getId(); 
				const rowIndex = params.node.rowIndex;
				params.api.setFocusedCell(rowIndex, colId); 
				params.api.startEditingCell({ 
					rowIndex: rowIndex, colKey: colId
					, keyPress: 'F2'
				});
				setTimeout(()=>{
					const ele:any = document.activeElement;
					console.log(ele);
					ele.selectionStart = 10000;
					ele.selectionEnd = 10000;
				}, 0);
			},
			onRowDragEnd: (params)=>{
				console.log('onRowDragEnd');
				const fromIndex = params.node.data.$sort - 1;
				const toIndex = params.overIndex;
				if(fromIndex!=toIndex) {
					const [ movedGridQuestion ] = this.gridQuestions.splice(fromIndex, 1);
					this.gridQuestions.splice(toIndex, 0, movedGridQuestion);
					const [ movedQuestion ] = this.questions.splice(fromIndex, 1);
					this.questions.splice(toIndex, 0, movedQuestion);
					this.resort();
					this.gridOptions.api.refreshCells({columns: ['$sort']});
				}
			},
		};

	}

	resort(){
		for(let i = 1; i <= this.questions.length;i++){
			this.gridQuestions[i - 1].$sort = i;
			this.gridQuestions[i - 1].id = i - 1;
			this.questions[i - 1].$sort = i;
			this.questions[i - 1].id = i - 1;
		}
	}

	btnAddClick(){
		const updatedFormat = this.updatedFormat;
		const lastIndex = this.questions.length - 1;
		const lang = this.lang;
		let gridQuestion:any = {};
		let question:any = {};
		updatedFormat.forEach(item=>{
			const dataType = item.dataType;
			if (dataType.indexOf('$') == -1){
				let defaultValue:any = null;
				if (item.dataType == 'int' || item.dataType == 'float'){
					defaultValue = 0;
				} else if (item.dataType == 'string' || item.dataType == 'text'){
					defaultValue = '';
				} else if (item.dataType.indexOf('|') > -1){
					const arr = item.dataType.split('|');
					defaultValue = arr[0];
				} else if (item.dataType == 'select'){
					defaultValue = item.options[0].label;
				}
				if (item.default !== undefined){
					defaultValue = item.default;
				}
				gridQuestion[item.prop] = defaultValue;
				question[item.prop] = defaultValue;
			} else if (dataType.indexOf('$mc') > -1){
				const arr = dataType.split(':');
				arr[2] = parseInt(arr[2]);
				if (arr[1] == 'string' || arr[1] == 'text'){
					question.answers = new Array(arr[2]).fill('');
				} else if (arr[1] == 'int' || arr[1] == 'float'){
					question.answers = new Array(arr[2]).fill(0);
				}
				if (item.default !== undefined){
					question.answers = new Array(arr[2]).fill(item.default);
				}
				gridQuestion.$answerIndex = 'A';
			}
		});
		this.format.forEach(item=>{
			const dataType = item.dataType;
			if (dataType.indexOf('$mc') > -1){
				const arr = dataType.split(':');
				arr[2] = parseInt(arr[2]);
				if (arr[1] == 'string' || arr[1] == 'text'){
					question.answers = new Array(arr[2]).fill('');
				} else if (arr[1] == 'int' || arr[1] == 'float'){
					question.answers = new Array(arr[2]).fill(0);
				}
				if (item.default !== undefined){
					question.answers = new Array(arr[2]).fill(item.default);
				}
				question.correctAnswerIndex = 0;
				
				question.correctAnswer = question.answers[0];
			}else if(dataType == 'select'){
				const defaultValue = item.default !== undefined ? item.default : item.options[0].label;
				gridQuestion[item.prop] = defaultValue;
				question[item.prop] = defaultValue;
			}else if (dataType == '$fixedselect'){
				const defaultValue = item.default !== undefined ? item.default : item.options[0].label;
				gridQuestion[item.prop] = defaultValue;
				question[item.prop] = defaultValue;
				const selectedOption = item.options.find(option => option.label === defaultValue);
				if(selectedOption){
					gridQuestion['fixedselectValue'] = selectedOption.value;
					question['fixedselectValue'] = selectedOption.value;
				}
			}  else if (dataType == 'const'){
				question[item.prop] = item.default === undefined? '' : item.default;
			}
		});
		gridQuestion.$sort = this.gridQuestions[lastIndex].$sort + 1;
		gridQuestion.id = this.gridQuestions[lastIndex].id + 1;
		question.id = gridQuestion.id;
		question.media = [];
		if (question.star === undefined){
			question.star = 0;
		}
		this.gridQuestions.push(gridQuestion);
		this.questions.push(question);
		console.log(this.gridQuestions, this.questions);
		this.gridOptions.api.applyTransaction({add: [ gridQuestion ], addIndex: lastIndex + 1 });
	}

	btnExportClick(){
		const tableValid = this.validate();
		if (!tableValid){
			return;
		}
		const displayedColumns = this.gridOptions.columnDefs;
		const exceptionColumns = ['delete','$sort','clone'];
		const exportedColumns = displayedColumns.map(e=> e.field).filter(e=> exceptionColumns.indexOf(e) == -1);
		this.gridOptions.api.exportDataAsExcel({
			columnKeys: exportedColumns
		});
	}

	btnImportClick(){
		const lang = this.lang;
		this.xlsx.openFile().then((file:File)=>{
            this.xlsx.loadFirstSheetAsJSON(file).then((objArray:any)=>{
				let gridQuestions = [];
				let questions = [];
				const updatedFormat = this.updatedFormat;
				let containMedia = false;
				objArray.forEach(obj=>{
					let question:any = {};
					let gridQuestion:any = {};
					
					updatedFormat.forEach(item=>{
						const value = obj[item.label];
						gridQuestion[item.prop] = value;
						const dataType = item.dataType;
						const assetType = ['image','images','audio','audios'];
						if (assetType.indexOf(item.dataType) == -1){
							if (item.prop && item.prop.indexOf('$') == -1){
								question[item.prop] = value;
							}
						} else {
							containMedia = true;
						}
					});

					this.format.forEach(item=>{
						const dataType = item.dataType;
						if (dataType == 'const'){
							question[item.prop] = item.default === undefined?'':item.default;
						} else if (dataType.indexOf('$mc') > -1){
							let arr = dataType.split(':');
							arr[2] = parseInt(arr[2]); 
							question.answers = [];
							for(let i=1;i<=arr[2];i++){
								const charCode = String.fromCharCode(64 + i);
								const label = {tc:'答案' + charCode,sc:'答案' + charCode, en:'Answer ' + charCode}[lang];
								question.answers.push(obj[label]);
							}
							const answerLabel = {tc:'答案',sc:'答案',en:'Answer'}[lang];
							question.correctAnswerIndex = parseInt(obj[answerLabel]) - 1;
							question.correctAnswer = question.answers[question.correctAnswerIndex];
						}
					});
					gridQuestions.push(gridQuestion);
					questions.push(question);
				});
				this.questions = questions;
				this.gridQuestions = gridQuestions;
				this.resort();
				setTimeout(()=>{this.parent.questionRows = this.questions;}, 10);
				console.log(gridQuestions, questions);
				this.gridOptions.api.setRowData(this.gridQuestions);
				if (containMedia){
					const msg = {tc:'媒體不支援匯入，請手動處理。',sc:'媒体不支援汇入，请手动处理。',en:'Media can\'t be imported. Please handle.'}[lang];
					this.als.toastSuccess(msg);
				}
			}).catch(e=>{
				const msg = {tc:'資料格式異常。',sc:'资料格式异常。',en:'Data error.'}[lang];
				this.als.toastError(msg);
			});
		});
	}

	validate(){
		const api = this.gridOptions.api;
		const gridQuestions = this.gridQuestions;
		const updatedFormat = this.updatedFormat;
		let tableValid = true;
		let num = 0;
		let toasted = false;
		gridQuestions.forEach((gq,index)=>{
			updatedFormat.forEach(item=>{
				const value = gq[item.prop];
				let itemValid = true;
				if (value === null){
					itemValid = false;
				}
				if (Array.isArray(value) && value.length == 0){
					itemValid = false;
				}
				if (value == '' && !item.optional){
					itemValid = false;
				}
				if (item.dataType == 'select'){
					let inOption = item.options.find(e=>e.value == value);
					if (!inOption){
						inOption = item.options.find(e=>e.label == value);
					}
					if (!inOption){
						itemValid = false;
					}
				}
				if (item.dataType == 'image' || item.dataType == 'audio'){
					if (!Array.isArray(value) || value.length == 0 || value.length > 1){
						const msg = {tc:'資料類型不符',sc:'资料类型不符',en:'data error'}[this.lang];
						this.als.toastError(msg);
						toasted = true;
						itemValid = false;
					}
				}
				if (item.validate){
					if (this.itemValidate(item.validate, gq) == false){
						itemValid = false;
					}
				}
				if (!itemValid){
					const rowNode = api.getRowNode(index);
					api.flashCells({ rowNodes: [ rowNode ], columns: [ item.prop ] });
					const eles = this.elRef.nativeElement.querySelectorAll('.ag-row[row-index="' + rowNode.rowIndex + '"]');
					eles.forEach(ele=>{
						ele.classList.add('invalid-red');
					});
					tableValid = false;
				}
			});
		})
		if (!tableValid && !toasted){
			const msg = {tc:'部份題目欠缺資料或輸入錯誤，請檢查表格。',sc:'部份题目欠缺资料或输入错误，请检查表格。',en:'Some column in table is missing or invalid.'}[this.lang];
			this.als.alert2(msg, null, { btns: [['ok']] });
		}
		return tableValid;
	}

	onFileAdd(params, dataType='image', index=null){
		const gridQuestion = params.data;
		const field = params.colDef.field;
		const multiAssetType = ['images','audios','files'];
		const dataTypeInputed = dataType == 'images' || dataType == 'audios'?dataType.substring(0, dataType.length - 1):dataType;
		let options = {toMediaServer:'game', fileType: dataTypeInputed, size: 50*1024*1024};
		this.upload.upload(options).then((data:any)=>{
			const fileObj = {name: data.originFilename, url: data.url };

			if (multiAssetType.indexOf(dataType) > -1){
				if (Array.isArray(gridQuestion[field])){
					if (index === null){
						gridQuestion[field].push(data.url);
					} else {
						gridQuestion[field].splice(index, 1, data.url);
					}
				}
				if (Array.isArray(this.questions[params.rowIndex][field])){
					if (index === null){//add
						this.questions[params.rowIndex][field].push(data.url);
					} else {//replace
						this.questions[params.rowIndex][field].splice(index, 1, data.url);
					}
				}
			} else {
				gridQuestion[field] = [data.url];
				this.questions[params.rowIndex][field] = [data.url];
			}
			this.gridOptions.api.refreshCells({force: true, rowNodes: [ params.node ], columns: [ field ]});
		});
	}

	onFileReplace(params){

	}

	onFileDelete(params, fileObj, index=null){
		const gridQuestion = params.data;
		const field = params.colDef.field;
		if (index === null){
			gridQuestion[field] = [];
			this.questions[params.rowIndex][field] = [];
		} else {
			gridQuestion[field].splice(index, 1);
			this.questions[params.rowIndex][field].splice(index, 1);
		}
		
		this.gridOptions.api.refreshCells({force: true, rowNodes: [ gridQuestion ], columns: [ field ]});
	}

	onFileDownload(params, fileObj, index=null){
		// const field = params.colDef.field;
		// const [ url ] = params.data[field];
		if (typeof fileObj == 'object'){
			this.datas.get(fileObj.url, { responseType: 'blob' }).subscribe(blob=>{
				downloadjs(blob, fileObj.name, blob.type);
			});
		} else {
			let arr = fileObj.split('/');
			let name = arr[arr.length - 1];
			name = name.replace(/time\(\d+\)-/g, '');
			this.datas.get(fileObj, { responseType: 'blob' }).subscribe(blob=>{
				downloadjs(blob, name, blob.type);
			});
		}
	}

	onAudioPlay(params, fileObj){
		let url = typeof fileObj === 'object'?fileObj.url:fileObj;
		url = (url.indexOf('//') == -1?'//oka.blob.core.windows.net/media/':'') + url;
		this.coms.audioPlay(url).then(()=>{
			this.coms.audioPlay();
		});
	}

	itemValidate(validateKey, row){
		if (validateKey == 'star_wish_a'){
			if (row.question.length != row.correctAnswer.length){
				return false;
			}
		}
		return true;
	}

}