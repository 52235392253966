import { faClone, faText, faVolume, faCopy, faFile, faBars, faTrash, faGripDots, faImage, faFilm, faListMusic , faPencil, faCheck, faAdd, faArrowLeft, faPen, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
export class SelectMarkerIcon{
	static COMPLETE_ICON:any = faCheck;
	static DELETE_ICON:any = faTrash;
	static DUPLICATE_ICON:any = faClone;
	static PENCIL_ICON:any = faPen;
	static IMAGE_ICON:any = faImage;
	static SOUND_ICON:any = faListMusic;
	static MOVIE_ICON:any = faFilm;
	static ADD_ICON:any = faAdd;
	static BACK_ICON:any = faArrowLeft;
	static TEXT_ICON:any = faText;
	static VOLUME_ICON:any = faVolume;
	static MINUS_ICON:any = faMinus;
	static PLUS_ICON:any = faPlus;
}