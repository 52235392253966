import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import {  ROTLFTextComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";

@Component({
	template:`
	<div #inpc  *ngFor="let e of elements; let i = index" 
		class="filling-blank"
		[style.fontSize.px]="e.fontSize"
		[style.fontFamily]="e.fontFamily"
		[ngClass]="myAnswers[i].verifyState">
		<div [style.width.px]="getFillingBlankWidth(e.w)"
		>
		
		<input 
		*ngIf="!defaultAnswer"
		type="text" [ngClass]="['T'+ctx.displayType]"
		[attr.maxlength]="ctx.maxLen"
		autocomplete="off" [(ngModel)]="myAnswers[i].text" (change)='markAnsChange(i)'
		[disabled]="context && !context.answerEnabled"
		>

		<input 
			*ngIf="defaultAnswer"
			type="text"  [ngClass]="['T'+ctx.displayType]"
			autocomplete="off" [ngModel]="elements[i].txt2" 
			disabled
		>
		
		</div>
	</div>
	`,
	// 最簡單對法，對 center，不過這樣不同 font 字會有高低
	// 最理想做法，對 baseline，要 resize font size 去目標高度，
	// 然後再根據baseline 和中心點的誤差調整 bg color 和位置
	/*
	background-image: linear-gradient(var(--bg-color),var(--bg-color));
	background-size: 100% var(--font-size);
	background-repeat: no-repeat;
	background-position: 0px;
	*/
	styles:[``]
})
export class ROFillingBlankComponent extends ROTLFTextComponent implements AfterViewInit
{
	@ViewChildren('inpc') inpc: QueryList<ElementRef>;

	public ctx:any;
	public elements:any [] = [] ;
	public myAnswers:any[] = [];
	public container:HTMLElement;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public canVerify(): boolean {
		return true;	
	}
	
	@HostListener('mouseDown', ['$event'])
	onResize(event:MouseEvent) {
		event.preventDefault();
		// this.resizeSubject.next(event);
	}
	
	ngAfterViewInit(): void
	{
		/**
		 * '{
		 * "extraWidth":0,"color":16117210,
		 * "maxLen":100,"bg":"solid","maxWidth":105,
		 * "displayType":"color_bg",
		 * "type":"T","unifiedWidth":1,"wideFilling":0,"verifyOption":"text","answerColor":0}'
		 */
		StyleUtils.setStyleVariable(this.elementRef.nativeElement, "answer-bg-color", StyleUtils.colorCodeToHex(this.ctx.color));
		StyleUtils.setStyleVariable(this.elementRef.nativeElement, "answer-color", StyleUtils.colorCodeToHex(this.ctx.answerColor));
		
		this.inpc.forEach((inp,i) => {
			var element:any= this.elements[i];
			element.txt2 = element.txt.replace(/[\[\]]/g, "");
			
			var b = inp.nativeElement;
			element.container.appendChild(b);
		});

		// 要砌返輸入先準確計到頭一行高度
		let cRect = this.elementRef.nativeElement.getBoundingClientRect();
		let firstInpRect = this.elements[0].container.querySelector("input").getBoundingClientRect();
		let diff:number = firstInpRect.top - cRect.top;
		if(this.firstLineHeight <= diff) {
			// 沒有輸入在第一行，永遠在字高的頂
			// 不要行高增加的上半部份
			this.textflowOffset = -(this.firstLineHeight - this.elements[0].fontSize)/2;
		} else {
			var lineHeight = this.elementRef.nativeElement.querySelector("p").style.getPropertyValue("--span-line-height");
			lineHeight = (!lineHeight || lineHeight=="") ? 1.2 : parseFloat(lineHeight)/100;
			this.textflowOffset = -((lineHeight-1)*this.elements[0].fontSize)/2 + this.elements[0].fontSize*0.2;
		}
		if(this.qObj)
			this.qObj.updatePositionByLineHeight(this.firstLineHeight);
		if(this.sObj)
			this.sObj.updatePositionByLineHeight(this.firstLineHeight);
		this.container.style.transform = `translate(0px, ${this.textflowOffset}px)`;
	}
	public getTagNames():string []
	{
		return ["FillingBlank"];
	}

	protected buildContent():void
	{
		this.ctx = JSON.parse(this.node.getAttribute("blank"));;
		
		var utils:TextFlowUtils = new TextFlowUtils();
		utils.replaceHandler("Blank",{
			createTextFlowDOM:(rootNode:XMLNode, listElement:any, node:XMLNode, withBG:boolean):HTMLElement =>{
				//debugger;
				/*
				{"qc":1,"txt":"[蘋果]",
				"markup":"<p xmlns=\"http://ns.adobe.com/textLayout/2008\"><span fontSize=\"32\">
				[蘋果]</span></p>",
				"w":105,"ascent":25,"descent":6,"fontSize":32,"fontFamily":"DFHKStdKai-B5"}
				*/
				var div = document.createElement("div");
				div.style.display = "inline";
				var attr = ObjectUtils.clone(node.attributes);
				attr.fontFamily = utils.mapFont(attr.fontFamily);
				attr.container = div;
				this.elements.push(attr);
				this.myAnswers.push({text:"", verifyState:""});

				return div;
			}
		})
		var textFlow:string = this.node.children[0].text;
		var dom:HTMLElement = this.elementRef.nativeElement;
		this.container = document.createElement("div");
		dom.appendChild(this.container);
		var flow:HTMLElement= utils.textFlowToDOM(textFlow, true);
		this.estimateFirstLine(flow);
		this.container.appendChild(flow);
			
		var transparency:number = this.node.getAttribute("transparency");
		
		if(transparency != 100)
		{
			dom.style.opacity = `${transparency}%`; 
		}
		if(this.textflowOffset!=0) this.container.style.transform = `translate(0px, ${this.textflowOffset}px)`;
	}

	protected estimateFirstLine(flow:HTMLElement):void
	{
		// 原則上所有 font 是一樣大
		if(this.elements.length == 0)
			return super.estimateFirstLine(flow); // 無 FIB 用返原本計算

		this.textflowOffset = 0;

		var lineHeight = StyleUtils.getDeepStyleVariable(this.elements[0].container, "span-line-height");
		var l:number;
		if(lineHeight)
		{
			l = parseFloat(lineHeight) / 100;
		} else {
			l = 1.2;
		}

		this.firstLineHeight = this.elements[0].fontSize * l;
	}

	public getFillingBlankWidth(curWidth:number):number {
		return (parseInt(this.ctx.unifiedWidth)==1 ? this.ctx.maxWidth : curWidth)+this.ctx.extraWidth;
	}


	// ======================================================
	// data function
	// ======================================================
	public isQuestionComponent():boolean {
		return true;
	}

	public markAnsChange(index:number):void {
		this.answerChanged = true;
		// 清除 verify 顯示
		this.myAnswers[index].verifyState = "";
	}

	public reset():void {
		this.myAnswers.forEach(ans => {
			ans.verifyState = "";
			ans.text = "";
		});

		this.sObj.hideScore();
	}

	set data(value:string) {
		this.defaultAnswer = null;
		// clear verify and answer
		this.answerChanged = true;
		this.reset();

		if(value) {
			try {
				let ansObj:any = JSON.parse(value).data;
				this.myAnswers.forEach((ans,i) => {
					ans.text = ansObj[i].text;
				});
				this.answerChanged = false; // 載入的資料不算有用戶改答案
				
			} catch(e) {

			}
			
		}
	}

	get data(): string {
		let output = this.getBlankValue();
		return JSON.stringify({type:this.ctx.type, data:output});
	}

	protected getBlankValue():any[] {
		let output = [];
		this.myAnswers.forEach((ans,i) => {
			if(this.ctx.type == "T") {
				output.push({text:ans.text});
			} else if(this.ctx.type == "hw-box") {
				// 未支援
				// todo
				output.push(null);
			} else if(this.ctx.type == "hw-full") {
				// 未支援
				// todo
				output.push(null);
			} else if(this.ctx.type == "R") {
				// 數字
				output.push({text:ans.text, value:this.getNumber(ans.text)});
			} else if(this.ctx.type == "R2") {
				// 數字(完全一樣)
				output.push({text:ans.text, value:this.getNumber(ans.text)});
			} else if(this.ctx.type == "eq") {
				// 數式
				output.push({text:ans.text, value:ans.text});
			}
		});

		return output;
	} 
	public defaultAnswer:any = null;
	public showDefaultAnswer(): void {
		this.defaultAnswer = true;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}
	public hideDefaultAnswer(): void {
		this.defaultAnswer = null;
		if(this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}
	public verify(_showScoring:boolean):any {
		// reset verify
		this.myAnswers.forEach(ans => {
			ans.verifyState = "";
		});
		// reset score display
		this.sObj.hideScore();


		if(this.ctx.type == "hw-box" || this.ctx.type == "hw-full") {
			if(!this.resultObject)
				this.resultObject = {correct:-1};
		} else {
			let correct:number = 0;
			let wrong:number = 0;
			let totalAns:number = this.myAnswers.length;

			this.myAnswers.forEach((ans,i) => {
				let a = ans.text;
				if(a!="") {
					let ansList = this.elements[i].txt;
					ansList = ansList.substr(1,ansList.length-2);
					ansList = ansList.indexOf("/")>=0 ? ansList.split("/") : [ansList];
					let isCorrect:boolean = false;

					if(this.ctx.type == "T" || this.ctx.type == "R2") {
						// 文字 / 數字(完全一樣)
						if(ansList.indexOf(a)>=0)
							isCorrect = true;
					} else if(this.ctx.type == "R") {
						// 數字
						a = parseFloat(a);
						ansList.forEach(e2 => {
							if(parseFloat(e2) == a)
								isCorrect = true;
						});
					} else if(this.ctx.type == "eq") {
						// 數式 (這裡只用文字對)
						if(ansList.indexOf(a)>=0)
							isCorrect = true;
					}

					if(isCorrect)
						correct++;
					else
						wrong++;
					
					if(_showScoring) {
						// 顯示個別對錯狀態
						ans.verifyState = isCorrect ? "correct" : "incorrect";
					}
						
					
				}
			});

			// 取得對錯狀態, 計分
			let attr = this.node.attributes;
			if(correct == totalAns && wrong==0) {
				// 全對
				this.resultObject = {correct:2, score:attr.fullScore, maxScore:attr.fullScore};
			} else if(correct==0) {
				// 全錯
				this.resultObject = {correct:0,score:0, maxScore:attr.fullScore};
			} else {
				// 部份對 (這裡非細項計分用百分比計算)
				this.resultObject = {
					correct:1,
					score:(attr.scoringType == 1) ? 
					Math.floor((10*attr.fullScore * correct / totalAns)) / 10 : // 非細項計分
					correct * attr.unitScore // 細項計分
					, maxScore:attr.fullScore
				};
			}
		}

		// 顯示分數 / 已提交
		this.sObj.showScoring(_showScoring, "view", this.resultObject);
		return this.resultObject;
	}

	protected getNumber(inp:any):number {
		inp = parseFloat(inp);
		return isNaN(inp) ? 0 : inp;
	}

}