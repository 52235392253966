import { Component, OnInit, Input, OnDestroy, HostListener, Output, EventEmitter, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'selectionPanel',
  template: `
<div
    [ngClass]="['page-panel', panelClass]"
    [@openClose]="(isOpen()?open:close)"
    [ngStyle]="style"
    [style.left.px]="style.left"
    [style.zIndex]="zIndex"
    [style.width]="width"
    [style.marginLeft]="getMarginLeft()"
>
    <div class="page-panel__num" *ngIf="!showSelectedNum" style="width:6px;"></div>
    <div class="page-panel__num" *ngIf="showSelectedNum">{{selectedItems.length}}</div>
    <div class="page-panel__num-label" *ngIf="showSelectedNum">{{'selectionPanel.selectedItem'|translate}}</div>
    <div class="page-panel__close" (click)="closePanel()"></div>
    <ng-container *ngFor="let item of config.items;let idx = index">
	  <ng-container *ngIf="!item.showWhen || item.showWhen()">
		<div #itemEle [ngClass]="{'page-panel__item2': true,'disabled':(selectedItems.length > 1 && !item.multiple) || (item.disableWhen && item.disableWhen())}" (click)="itemClick(item, $event, itemEle)">
		  <div *ngIf="item.img" class="image" [style.backgroundImage]="'url(assets/img/' + item.img + ')'"></div>
		  <div *ngIf="item.icon" class="icon"><fa-icon [icon]="item.icon" [style.fontSize]="item.iconSize||'22px'"></fa-icon></div>
          <div class="name">{{ item.label ? item.label : ('selectionPanel.' + item.name|translate) }}</div>
		</div>
	  </ng-container>
    </ng-container>
  </div>
  `
  , styleUrls: ['./selectionPanel.component.scss'],
  animations: [
    trigger('openClose', [
      state('open-0', style({ bottom: '10px', opacity: '1' })), state('close-0', style({ bottom: '-80px', opacity: '0' })),
      state('open-1', style({ bottom: '30px' })), state('close-1', style({ bottom: '-180px', display: 'none' })),
      transition('open-0 => close-0', [animate('0.1s')]), transition('close-0 => open-0', [animate('0.1s')]),
      transition('open-1 => close-1', [animate('0.1s')]), transition('close-1 => open-1', [animate('0.1s')]),
    ])
  ]
})

export class SelectionPanelComponent implements OnInit, OnDestroy{
    @HostListener('dblclick', []) onDblClick() { console.log(this); }

	@ViewChildren('itemEle') itemEles: QueryList<ElementRef>;
  @Input() config: {items:any[],style:any,panelClass:string};
  @Input() selectedItems: any[] = [];
  public items;
  public style: any;
  public open = 'open-0';
  public close = 'close-0';
  public zIndex = 9;
  public width = '600px';
  public showSelectedNum = true;

    @Output() public onClose:EventEmitter<void> = new EventEmitter();

  constructor(public translate:TranslateService, private sanitization:DomSanitizer) {
  }

  ngOnInit() {
    this.style = this.config.style || {};
    this.open = 'open-' + (this.style.bottomLevel || '0');
    this.close = 'close-' + (this.style.bottomLevel || '0');
    this.zIndex = (this.style.bottomLevel || 0) * 10 + 9;
	if (this.config.style.zIndex !== undefined){
		this.zIndex = this.config.style.zIndex;
	}
    this.width = this.style.width || '600px';
    this.showSelectedNum = (this.style.showSelectedNum!=undefined) ? this.style.showSelectedNum : true;
    console.log('selectionPanel::ngOnInit');
  }

  ngOnDestroy() {
  }

  isOpen() {
    return this.selectedItems.length > 0;
  }

  itemClick(item, event, itemEle) {
    if (item.disableWhen && item.disableWhen() ){
      return;
    }
    if (item.multiple || this.selectedItems.length == 1)
      item.callback(event, item, itemEle);
  }

  closePanel() {
    this.selectedItems.splice(0, this.selectedItems.length);
    this.onClose.emit();
  }

  public getMarginLeft():SafeStyle {
    return this.sanitization.bypassSecurityTrustStyle('calc((100% - ' + this.width + ') / 2)');
  }

  get panelClass(){
    const panelClass:any = this.config.panelClass;
    if (panelClass){
      return typeof panelClass === 'function'?panelClass():panelClass;
    } else {
      return '';
    }
  }

}
