// import { Injectable } from '@angular/core';
/// import { ModalService } from './modal.service';


// @Injectable()

export class UndoManager {
	
	public actions: Action[] = [];
	public pool: any[] = []; //wait to pass to server to handle save
	public footprint: any[] = [];
	public pointer:number = -1;
	public  maxUndoCount:number = 50;
	constructor()
	{

	}

	reset() {
		this.actions = [];
	}

	public getRedoList(count:number = -1):any []
	{
		var start:number = this.pointer + 1 ;
		return this.actions.slice(start,count == -1 ? this.actions.length : start + count);
	}

	public getUndoList(count:number = -1):any[]
	{
		if(this.pointer < 0) return  [];
		var end:number = this.pointer + 1;
		return this.actions.slice(count == -1 ? 0 : Math.max(0, end - count), end);
	}

	hasUndo():boolean
	{
		return this.pointer >= 0;
		// return (this.actions.length > 0) && this.pointer >= 0;
	}

	hasRedo():boolean
	{
		var currentAction:Action
		if(this.actions.length)
		{
			if(this.pointer >= this.actions.length - 1)
			{
				currentAction = this.actions[this.actions.length - 1];
				return currentAction.redo ? true :false;
			} else {
				currentAction = this.actions[this.pointer + 1];
				return currentAction.execute ? true :false;
			}

		}
		return false;

	}

	redo():void
	{
		if(this.actions.length)
		{
			if(this.pointer + 1 >= this.actions.length)
			{
				// this is the last actions
				// run redo
				var lastAction:Action = this.actions[this.pointer];
				if(lastAction && lastAction.redo)
				{
					this.pointer ++;
					lastAction.redo(lastAction.element);
				}
			} else {
				// this is not the last action
				// execute
				var lastAction:Action = this.actions[this.pointer + 1];
				if(lastAction && lastAction.execute)
				{
					this.pointer ++;
					lastAction.execute(lastAction.element);

				}
			}

		}
	}

	undo() {
		if (this.actions.length == 0)
			return false;
		if(this.pointer >= 0 && this.pointer < this.actions.length)
		{
			this.footprint = [];
			let action:Action = this.actions[this.pointer];
			action.undo(action.element);
			action.undoUi(action.element);
			this.pointer --;
		}
	}

	add(action: any):Action{
		if(this.pointer < this.actions.length)
		{
			this.actions.splice(this.pointer + 1, this.actions.length);
		}
		var output:Action = new Action(action);
		this.actions.push(output);
		if(this.actions.length > this.maxUndoCount)
		{
			var removeCount:number = this.actions.length - this.maxUndoCount;
			this.actions.splice(0, removeCount);
		}
		this.pointer = this.actions.length - 1;
		return output;
	}

	lastTmp() {
		return this.actions[this.actions.length - 1].tmp;
	}

	public addPool(objs: any[]) {
		objs.forEach((obj) => {
			let found = this.pool.find(i => i.id == obj.id);
			if (found) {
				let foundIndex = this.pool.indexOf(found);
				this.pool[foundIndex] = obj;
			} else if (obj.id != null) {
				this.pool.push(obj);
			}
		});
	}

	public saveAll() {
		while (this.actions.length > 0) {
			let action = this.actions[this.actions.length - 1];
			if (action.save)
				action.save();
			this.actions.pop();
		}
	}

	public hasFootprint(obj: any) {
		return this.footprint.indexOf(obj) > -1;
	}

}

class Action {
	public title:string;
	public save;
	public undo;
	public undoUi;
	public redo; // redo the last action
	public execute:any; // execute after undo
	public tmp: any;
	public element:any;
	public time:Date;

	constructor(obj: any) {
		this.time = new Date();
		this.element = obj;
		let func = () => { };

		this.title = obj.title;
		this.save = this.wrapHandler(obj.save) || func;

		this.undo = this.wrapHandler(obj.undo) || func;
		this.undoUi = this.wrapHandler(obj.undoUi) || func;

		this.execute = this.wrapHandler(obj.execute) ;
		this.redo = this.wrapHandler(obj.redo);

		this.tmp = obj.tmp || {};
	}

	private wrapHandler(o:any):any
	{
		return o ? ()=>{
			o(this.element);
		} : null;
	}


}
