import { Component, HostListener, NgZone, ViewChild, OnInit, Input, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService } from 'src/app/service/common.service';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { BubbleBox2Component } from 'src/app/sharedModule/bubbleBox2Module/bubbleBox2.component';
import {OupService} from "../../../../service/oup.service";
declare var Chart;
declare var Object;

@Component({
	selector: 'p-question-chart',
	template: `
	<div class="card" *ngIf="chartData.allComponents.length > 0">
		<div class="panel" [ngClass]="theme.theme$.value + '-theme'">
			<ng-container *ngFor="let item of ['0-25%','25-50%','50-75%','75-100%',{tc: '已答(不計分)',sc:'已答(不计分)',en:'submit but no scored'}[datas.lang], {tc: '未答',sc:'未答',en:'not submit'}[datas.lang]];let i = index;">
				<div class="item" *ngIf="i != 4 || (isPdfPaper == false)" (click)="rangeChange(i)">
					<div class="item-box" [style.backgroundColor]="colors[i]">
						<fa-icon [icon]="faCheck" *ngIf="ranges[i]"></fa-icon>
					</div>
					<div class="item-label">
						<ng-container *ngIf="i < 4">{{ {tc:'答對', sc: '答对', en:'Correct '}[datas.lang] + item }}</ng-container>
						<ng-container *ngIf="i == 4 || i == 5">{{ item }}</ng-container>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="body">
			<div class="body-header">
				
				<div class="body-line">
					<div class="body-label">
						{{ 'result-panel.' + (chartData.bookType == 'pdf-paper'?'question-section':'chapter') | translate }}:
					</div>
					<okaPulldown2 [options]="filterOptions" bindLabel="label" bindValue="value" 
						[okaPulldownStyle]="{width: '120px'}" 
						[(ngModel)]="filter" (changing)="filterChange()"></okaPulldown2>
					<div class="reminder"></div>
					<div class="score-column">{{'result-panel.total-score'|translate}}</div>
					<div class="score-column">{{'result-panel.avg-score'|translate}}</div>
					<div class="score-column">{{'result-panel.highest'|translate}}</div>
					<div class="score-column">{{'result-panel.lowest'|translate}}</div>
				</div>
			</div>

			<div class="box-wrapper">
				<ng-container *ngFor="let ch of chapters;let i = index;">
					<div class="box" [ngClass]="theme.theme$.value + '-theme'" *ngIf="filter == 'all' || filter == i">
						<div class="box-header">
							<div class="box-header-title">
								<span *ngIf="!isPdfPaper">{{'result-panel.chapter'|translate}} {{ch.title}}</span>
								<span *ngIf="isPdfPaper">{{'result-panel.question-section'|translate}} {{ch.title}}</span>
							</div>
							<div class="max">{{ch.maxScore}}</div>
							<div class="avg">{{ch.avgScore}}</div>
							<div class="highest">{{ch.highest}}</div>
							<div class="lowest">{{ch.lowest}}</div>
						</div>
						<div class="comp" [class.non-first]="j > 0" *ngFor="let comp of ch.comps;let j = index;" [attr.cid]="comp.cid">
							<div class="comp-header" [ngClass]="theme.theme$.value + '-theme'">
								<div class="box-header-title">
									<span *ngIf="!isPdfPaper">{{j + 1}} .{{comp.title}}<ng-container *ngIf="comp.mcType">{{comp.mcType == 'single'?'':{tc:'(多選)',sc:' (多选)',en:'(multiple)'}[lang]}}</ng-container></span>
									<span *ngIf="isPdfPaper">{{comp.qsNumName}}. {{comp.title}}<ng-container *ngIf="comp.mcType">{{comp.mcType == 'single'?'':{tc:'(多選)',sc:' (多选)',en:'(multiple)'}[lang]}}</ng-container></span>
								</div>
								<div class="max">{{comp.maxScore !== null? comp.maxScore: '-'}}</div>
								<div class="avg">{{comp.avgScore !== null? comp.avgScore: '-'}}</div>
								<div class="highest">{{comp.highest !== null? comp.highest: '-'}}</div>
								<div class="lowest">{{comp.lowest !== null? comp.lowest: '-'}}</div>
							</div>
							<div class="comp-info" [ngClass]="theme.theme$.value + '-theme'">
								<div class="comp-info-line" *ngFor="let share of comp.shares">
									<div class="comp-info-title">
										<ng-container *ngIf="comp.mcType === undefined">
											<div class="comp-info-bar s1" [class.before-animate]="!barAnimated"
												*ngIf="share.b1 > 0 && share.s1 && ranges[0]"
												[style.width]="share.b1 + '%'">{{share.s1.length}}</div>
											<div class="comp-info-bar s2" [class.before-animate]="!barAnimated"
												*ngIf="share.b2 > 0 && share.s2 && ranges[1]" 
												[style.width]="share.b2 + '%'">{{share.s2.length}}</div>
											<div class="comp-info-bar s3" [class.before-animate]="!barAnimated"
												*ngIf="share.b3 > 0 && share.s3 && ranges[2]"
												[style.width]="share.b3 + '%'">{{share.s3.length}}</div>
											<div class="comp-info-bar s4" [class.before-animate]="!barAnimated"
												*ngIf="share.b4 > 0 && share.s4 && ranges[3]"
												[style.width]="share.b4 + '%'">{{share.s4.length}}</div>
										</ng-container>

										<ng-container *ngIf="comp.mcType === 'single'">
											<div class="comp-info-bar mcType-wrong s1" [class.before-animate]="!barAnimated"
												*ngIf="share.b1 > 0 && share.s1 && ranges[0]"
												(mouseenter)="barHover($event, 1, comp, share)" (mouseleave)="barLeave(comp)"
												[style.width]="share.b1 + '%'">
												<div class="comp-info-bar-mc" 
													[style.backgroundColor]="item.color" [style.width]="item.pt + '%'"
													*ngFor="let item of share.s1AnswerSelected">{{item.value}}</div>
											</div>

											<div class="comp-info-bar s4" [class.before-animate]="!barAnimated"
												*ngIf="share.b4 > 0 && share.s4 && ranges[3]"
												(mouseenter)="barHover($event, 4, comp, share)" (mouseleave)="barLeave(comp)"
												[style.width]="share.b4 + '%'">{{share.s4.length}}</div>
										</ng-container>

										<ng-container *ngIf="comp.mcType === 'multiple'">
											<div class="comp-info-bar s1" [class.before-animate]="!barAnimated"
												*ngIf="share.b1 > 0 && share.s1 && ranges[0]"
												(mouseenter)="barHover($event, 1, comp, share)" (mouseleave)="barLeave(comp)"
												[style.width]="share.b1 + '%'">{{share.s1.length}}</div>
											<div class="comp-info-bar s2" [class.before-animate]="!barAnimated"
												*ngIf="share.b2 > 0 && share.s2 && ranges[1]" 
												(mouseenter)="barHover($event, 2, comp, share)" (mouseleave)="barLeave(comp)"
												[style.width]="share.b2 + '%'">{{share.s2.length}}</div>
											<div class="comp-info-bar s3" [class.before-animate]="!barAnimated"
												*ngIf="share.b3 > 0 && share.s3 && ranges[2]"
												(mouseenter)="barHover($event, 3, comp, share)" (mouseleave)="barLeave(comp)"
												[style.width]="share.b3 + '%'">{{share.s3.length}}</div>
											<div class="comp-info-bar s4" [class.before-animate]="!barAnimated"
												*ngIf="share.b4 > 0 && share.s4 && ranges[3]"
												(mouseenter)="barHover($event, 4, comp, share)" (mouseleave)="barLeave(comp)"
												[style.width]="share.b4 + '%'">{{share.s4.length}}</div>
										</ng-container>

										<div class="comp-info-bar s5" [class.before-animate]="!barAnimated"
											*ngIf="isPdfPaper == false && share.b5 > 0 && share.s5 && ranges[4]"
											[style.width]="share.b5 + '%'">{{share.s5.length}}</div>
										<div class="comp-info-bar s6" [class.before-animate]="!barAnimated"
											*ngIf="share.b6 > 0 && share.s6 && ranges[5]"
											[style.width]="share.b6 + '%'">{{share.s6.length}}</div>
										<div class="comp-info-tag">{{ oup.isOupUser ? oup.get_class_name(share.title) || share.title : share.title }}</div>
									</div>
									<div class="max"></div>
									<div class="avg">{{share.avgScore !== null?share.avgScore: '-' }}</div>
									<div class="highest">{{share.highest !== null?share.highest: '-'}}</div>
									<div class="lowest">{{share.lowest !== null?share.lowest: '-'}}</div>
								</div>
							</div>
						</div>
						<div class="box-line-{{i}}" *ngFor="let i of [1,2,3,4]"></div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="no-data" *ngIf="chartData.allComponents.length == 0">
		<div class="no-data-image"></div>
		<div class="no-data-text">{{ {tc:'未有題目',sc:'未有题目',en:'No question'}[datas.lang] }}</div>
	</div>

	<bubbleBox2 #bubblebox class="quetion-chart-tooltip" [autoTailColor]="false"
		[backgroundColor]="'rgba(0,0,0,0.86)'" [options]="{noAnimate: true}"
		[padding]="10" [showBackdrop]="false">
		<div class="tooltip-wrapper" *ngIf="tooltip">
			<div class="tooltip-header">{{tooltip.header}}</div>
			<div class="tooltip-item" *ngFor="let item of tooltip.items">
				<div class="tooltip-box" *ngIf="item.color" [style.backgroundColor]="item.color"></div>
				<div class="tooltip-text">
					<span>{{item.text}}</span>
				</div>
			</div>
		</div>
	</bubbleBox2>
  `,
	styleUrls: ['./personal-question.chart.scss']
})
export class PersonalQuestionChart implements OnInit, AfterViewInit, OnDestroy {
	@Input() parent;
	@ViewChild('bubblebox',{static: false}) bubblebox: BubbleBox2Component;
	public chartData:any;
	public chapters:any = [];
	public onDestroy = new Subject();
	public filter = 'all';
	public filterOptions = [{label: 'common.all', value: 'all'}];
	public colors:string[] = ['#FD6585', '#FECC60', '#51C0BF', '#3DA3E8', '#e3f5fa','#f3f3f4'];
	public barAnimated = false;
	public ranges = [1, 1, 1, 1, 1, 1];
	public faCheck = faCheck;
	public isPdfPaper = false;
	public hasMC = false;
	public tooltip;
	public lang;

	constructor(public lds: LoadingService, public datas: DataService, public theme: ThemeService, public coms: CommonService, public trans: TranslateService, public eleRef: ElementRef, public oup: OupService) {
	}

	ngOnInit() {
		this.lang = this.datas.lang;
		this.chartData = this.parent.chartData;
		this.isPdfPaper = this.chartData.bookType == 'pdf-paper';
		this.hasMC = !!this.chartData.allComponents.find(e=> ['MC2','GraphicMC','SimpleMC'].indexOf(e.type) > -1);
		if (this.isPdfPaper || this.hasMC){
			this.coms.waitFor(()=> this.chartData.compXmls, 100, 100).then(()=>{
				this.loadData();
			});
		} else {
			this.loadData();
		}
	}

	ngAfterViewInit(): void {
		this.parent.parent.filterChange.pipe(takeUntil(this.onDestroy)).subscribe(event=>{
			this.loadData();
		});
	}
	
	ngOnDestroy() {
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	loadChapterData(filteredShares){
		let maxPeopleCountInOneShare = 0;
		filteredShares.forEach(share=>{
			maxPeopleCountInOneShare = Math.max(maxPeopleCountInOneShare, share.peoples.length);
		});
		let clonedChapters = JSON.parse(JSON.stringify(this.chartData.chapters));
		this.chapters = clonedChapters.map(ch => {
			let chapter:any = {title: ch.title, maxScore: ch.maxScore, comps: [], highest: null, lowest: null };
			if (ch.pages){
				ch.pages.forEach(page => {
					if (page.components){
						page.components.forEach(comp => {
							let component:any = {type: comp.type, name: comp.name, cid: comp.cid, maxScore: comp.maxScore, avgScore: null, highest: null, lowest: null, shares: [] };
							if (comp.mcType !== undefined){
								component.mcType = comp.mcType;
							}
							component.title = this.trans.instant('ro.components.name.' + comp.type);
							let compPeopleTotal = 0;
							let compPeopleCount = 0;
							let personalData:any = {title: this.chartData.studentName, avgScore: null, highest: null, lowest: null, s1: [], s2: [], s3: [], s4: [], s5: [], s6: []};
							filteredShares.forEach(share=>{
								let peopleCount = 0;
								let peopleTotal = 0;
								let shareData:any = {bsid: share.bsid, title: share.tag_fulltitle, avgScore: null, highest: null, lowest: null, s1: [], s2: [], s3:[], s4:[], s5: [], s6: [] };
								if (comp.hasScoring){
									ch.maxScore += comp.maxScore?comp.maxScore:0;
									share.peoples.forEach(people => {
										const found = comp.results.find(e=> e.uid == people.uid);
										if (found){
											const score = isNaN(found.score)?0:Math.round(parseFloat(found.score) * 10) / 10;
											const percent = Math.round(score * 10 * 100 / comp.maxScore) / 10;
											peopleCount++;
											compPeopleCount++;
											peopleTotal += score;
											compPeopleTotal += score;
											shareData.highest = shareData.highest === null?score: Math.max(shareData.highest, score);
											shareData.lowest = shareData.lowest === null?score: Math.min(shareData.lowest, score);
											if (percent > 75){
												shareData.s4.push(people);
											} else if (percent > 50){
												shareData.s3.push(people);
											} else if (percent > 25){
												shareData.s2.push(people);
											} else {
												if (comp.mcType == 'single'){
													if (shareData.s1AnswerSelected === undefined){
														shareData.s1AnswerSelected = [];
													}
													this.updateAnswerSelected(found, comp, shareData.s1AnswerSelected);
												}
												shareData.s1.push(people);
											}
											if (people.uid == this.chartData.uid){
												personalData.avgScore = score;
												personalData.highest = score;
												personalData.lowest = score;
												if (percent > 75){
													personalData.s4.push(people);
												} else if (percent > 50){
													personalData.s3.push(people);
												} else if (percent > 25){
													personalData.s2.push(people);
												} else {
													personalData.s1.push(people);
												}
											}
										} else {
											shareData.s6.push(people);
											if (people.uid == this.chartData.uid){
												personalData.s6.push(people);
											}
										}
									});
									if (shareData.s1AnswerSelected !== undefined){
										shareData.s1AnswerSelected = shareData.s1AnswerSelected.sort((a,b)=> a.answer - b.answer);
										let wrongColor = ['#fd6585', '#de4968', '#c53050','#a5243f','#961834','#fd6585', '#de4968', '#c53050','#a5243f','#961834'];
										shareData.s1AnswerSelected.forEach(item=>{
											item.pt = Math.round(item.value * 1000 / shareData.s1.length) / 10;
											item.color = wrongColor.length>0?wrongColor.shift():'#fd6585'; 
										})
									}
									if (peopleCount > 0){
										shareData.avgScore = Math.round(10 * peopleTotal / peopleCount) / 10;
										component.highest = component.highest === null?shareData.highest:Math.max(shareData.highest, component.highest); 
										component.highest = Math.round(component.highest * 10) / 10;
										component.lowest = component.lowest === null?shareData.lowest:Math.max(shareData.lowest, component.lowest);
										component.lowest = Math.round(component.lowest * 10) / 10;
									}
								} else {
									share.peoples.forEach(people => {
										const found = comp.results.find(e=> e.uid == people.uid);
										if (found){
											peopleCount++;
											compPeopleCount++;
											shareData.s5.push(people);
											if (people.uid == this.chartData.uid){
												personalData.s5.push(people);
											}
										} else {
											shareData.s6.push(people);
											if (people.uid == this.chartData.uid){
												personalData.s6.push(people);
											}
										}
									});
								}
								shareData.b1 = Math.round(shareData.s1.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								shareData.b2 = Math.round(shareData.s2.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								shareData.b3 = Math.round(shareData.s3.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								shareData.b4 = Math.round(shareData.s4.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								shareData.b5 = Math.round(shareData.s5.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								shareData.b6 = Math.round(shareData.s6.length * 100 * 100 / maxPeopleCountInOneShare) / 100;

								personalData.b1 = Math.round(personalData.s1.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								personalData.b2 = Math.round(personalData.s2.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								personalData.b3 = Math.round(personalData.s3.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								personalData.b4 = Math.round(personalData.s4.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								personalData.b5 = Math.round(personalData.s5.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
								personalData.b6 = Math.round(personalData.s6.length * 100 * 100 / maxPeopleCountInOneShare) / 100;

								component.shares.push(shareData);
								component.shares.push(personalData);
							});
							
							if (compPeopleCount > 0 && comp.hasScoring){
								component.avgScore = Math.round(compPeopleTotal * 10 / compPeopleCount) / 10;
							}
							
							chapter.comps.push(component);
						});
					}
				});
			}

			let chapterCount = 0;
			let chapterSum = 0; 
			filteredShares.forEach(share => {
				share.peoples.forEach(people => {
					let chapterPeopleSum = 0;
					let calculatedCids = [];
					let peopleSubmitted = false;
					const chapterPeopleResults = ch.results.filter(e=>e.uid == people.uid);
					chapterPeopleResults.forEach(e => {
						if (calculatedCids.indexOf(e.cid) === -1){
							peopleSubmitted = true;
							calculatedCids.push(e.cid);
							const score = e.score?Math.round(parseFloat(e.score) * 10) / 10:0;
							chapterPeopleSum += score;
						}
					});
					chapterPeopleSum = Math.round(chapterPeopleSum * 10) / 10;
					if (peopleSubmitted){
						chapterCount++;
						chapterSum += Math.round(chapterPeopleSum * 10) / 10;
						chapter.highest = chapter.highest === null?chapterPeopleSum: Math.max(chapterPeopleSum, chapter.highest);
						chapter.lowest = chapter.lowest === null?chapterPeopleSum: Math.min(chapterPeopleSum, chapter.lowest);
					}
				});
			});
			if (chapterCount > 0){
				chapter.avgScore = Math.round(chapterSum * 10 / chapterCount) / 10;
			}
			return chapter;
		});
	}

	loadSectionData(filteredShares){
		let maxPeopleCountInOneShare = 0;
		filteredShares.forEach(share=>{
			maxPeopleCountInOneShare = Math.max(maxPeopleCountInOneShare, share.peoples.length);
		});
		let questionSections:any = [
			{title: {tc: '無環節', sc: '无环节', en: 'no section'}[this.datas.lang], comps: [], maxScore: 0, highest: null, lowest: null }
		];
		this.chartData.compXmls.forEach(chx => {
			const foundQS = questionSections.find(e=> e.title == chx.questionSection);
			const foundComp = this.chartData.allComponents.find(e=> e.cid == chx.cid);
			let comp:any = {maxScore: 0, avgScore: null, highest: null, lowest: null, shares: [], cid: chx.cid, qsNumName: foundComp.qsNumName };
			comp.type = foundComp.type;
			if (foundComp.mcType !== undefined){
				comp.mcType = foundComp.mcType;
			}
			comp.title = this.trans.instant('ro.components.name.' + chx.key);
			if (foundQS){
				foundQS.comps.push(comp);
			} else {
				questionSections.push({title: chx.questionSection, comps: [comp], maxScore: 0, highest: null, lowest: null});
			}
		});

		questionSections = questionSections.filter(e=> e.comps.length > 0);
		questionSections.forEach(qs => {
			qs.comps.forEach(comp => {
				const sComp = this.chartData.scoredComponents.find(e=> e.cid == comp.cid);
				if (sComp){
					comp.maxScore = sComp.maxScore?sComp.maxScore:0;
					qs.maxScore += sComp.maxScore?sComp.maxScore:0;
					let compPeopleCount = 0;
					let compPeopleSum = 0;
					let personalData:any = {title: this.chartData.studentName, avgScore: null, highest: null, lowest: null, s1: [], s2: [], s3: [], s4: [], s5: [], s6: []};
					filteredShares.forEach(share => {
						let sharePeopleSum = 0;
						let sharePeopleCount = 0;
						let shareData:any = {bsid: share.bsid, title: share.tag_fulltitle, avgScore: null, highest: null, lowest: null, s1: [], s2: [], s3:[], s4:[], s5: [], s6: [], answerSelected: null };
						share.peoples.forEach(people => {
							const found = share.result.find(e=> e.uid == people.uid && e.cid == comp.cid);
							if (found){
								const score = isNaN(found.score)?0:Math.round(parseFloat(found.score) * 10) / 10;
								const percent = Math.round(score * 10 * 100 / comp.maxScore) / 10;
								sharePeopleSum += score;
								sharePeopleCount++;
								compPeopleSum += score;
								compPeopleCount++;
								shareData.highest = shareData.highest === null?score: Math.max(shareData.highest, score);
								shareData.lowest = shareData.lowest === null?score: Math.min(shareData.lowest, score);
								if (percent > 75){
									shareData.s4.push(people);
								} else if (percent > 50){
									shareData.s3.push(people);
								} else if (percent > 25){
									shareData.s2.push(people);
								} else {
									if (comp.mcType == 'single'){
										if (shareData.s1AnswerSelected === undefined){
											shareData.s1AnswerSelected = [];
										}
										this.updateAnswerSelected(found, comp, shareData.s1AnswerSelected);
									}
									shareData.s1.push(people);
								}
								if (people.uid == this.chartData.uid){
									personalData.avgScore = score;
									personalData.highest = score;
									personalData.lowest = score;
									if (percent > 75){
										personalData.s4.push(people);
									} else if (percent > 50){
										personalData.s3.push(people);
									} else if (percent > 25){
										personalData.s2.push(people);
									} else {
										personalData.s1.push(people);
									}
								}
							} else {
								shareData.s6.push(people);
								if (people.uid == this.chartData.uid){
									personalData.s6.push(people);
								}
							}
						});
						if (shareData.s1AnswerSelected !== undefined){
							shareData.s1AnswerSelected = shareData.s1AnswerSelected.sort((a,b)=> a.answer - b.answer);
							let wrongColor = ['#fd6585', '#de4968', '#c53050','#a5243f','#961834','#fd6585', '#de4968', '#c53050','#a5243f','#961834'];
							shareData.s1AnswerSelected.forEach(item=>{
								item.pt = Math.round(item.value * 1000 / shareData.s1.length) / 10;
								item.color = wrongColor.length > 0?wrongColor.shift():'#fd6585'; 
							})
						}
						if (sharePeopleCount > 0){
							shareData.avgScore = Math.round(10 * sharePeopleSum / sharePeopleCount) / 10;
							comp.highest = comp.highest === null?shareData.highest:Math.max(shareData.highest, comp.highest); 
							comp.lowest = comp.lowest === null?shareData.lowest:Math.max(shareData.lowest, comp.lowest);
							shareData.b1 = Math.round(shareData.s1.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
							shareData.b2 = Math.round(shareData.s2.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
							shareData.b3 = Math.round(shareData.s3.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
							shareData.b4 = Math.round(shareData.s4.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
							shareData.b5 = Math.round(shareData.s5.length * 100 * 100 / maxPeopleCountInOneShare) / 100;

							personalData.b1 = Math.round(personalData.s1.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
							personalData.b2 = Math.round(personalData.s2.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
							personalData.b3 = Math.round(personalData.s3.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
							personalData.b4 = Math.round(personalData.s4.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
							personalData.b5 = Math.round(personalData.s5.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
						}
						shareData.b6 = Math.round(shareData.s6.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
						personalData.b6 = Math.round(personalData.s6.length * 100 * 100 / maxPeopleCountInOneShare) / 100;
						comp.shares.push(shareData);
						comp.shares.push(personalData);
					});
					if (compPeopleCount > 0){
						comp.avgScore = Math.round(compPeopleSum * 10 / compPeopleCount) / 10;
					}
				} else { //not scored component

				}
			});

			let qsSum = 0;
			let qsCount = 0;
			filteredShares.forEach(share =>{
				share.peoples.forEach(people => {
					let qsPeopleSum = 0;
					let calculatedCids = [];
					let peopleSubmitted = false;
					const qsPeopleResults = share.result.filter(e=> e.uid == people.uid && qs.comps.find(f=> f.cid == e.cid));
					qsPeopleResults.forEach(e=> {
						if (calculatedCids.indexOf(e.cid) === -1){
							peopleSubmitted = true;
							calculatedCids.push(e.cid);
							const score = e.score?parseFloat(e.score):0;
							qsPeopleSum += score;
						}
					});
					qsPeopleSum = Math.round(qsPeopleSum * 10) / 10;
					if (peopleSubmitted){
						qsCount++;
						qsSum += Math.round(qsPeopleSum * 10) / 10;
						qs.highest = qs.highest === null?qsPeopleSum: Math.max(qsPeopleSum, qs.highest);
						qs.lowest = qs.lowest === null?qsPeopleSum: Math.min(qsPeopleSum, qs.lowest);
					}
				});
			});
			if (qsCount > 0){
				qs.avgScore = Math.round(qsSum * 10 / qsCount) / 10;
			}
		});
		this.chapters = questionSections;
	}

	loadData(){
		const filteredShares = this.chartData.shares;
		if (this.chartData.bookType == 'pdf-paper'){
			this.isPdfPaper = true;
			this.coms.waitFor( () => this.chartData.compXmls, 20, 300).then(()=>{
				this.loadSectionData(filteredShares);
				this.initFilter();
			});
		} else {
			this.loadChapterData(filteredShares);
			this.initFilter();
		}

	}

	initFilter(){
		this.filterOptions = [{label: 'common.all', value: 'all'}];
		this.chapters.forEach((ch, index) => {
			const chapterOption = {label: ch.title, value: index };
			this.filterOptions.push(chapterOption);
		});
		this.barAnimated = false;
		this.filterChange();
	}

	filterChange(){
		this.barAnimated = false;
		setTimeout(()=>{
			this.barAnimated = true;
		}, 500);
	}

	rangeChange(index){
		this.ranges[index] = this.ranges[index]?0:1;
		this.filterChange();
	}

	questionClick(item){
		
	}

	barHover($event, choice, comp, share){
		console.log('hover');
		const mcTypeComp = ['ScoreCom-mc', 'MC2','SimpleMC', 'GraphicMC'];
		if (mcTypeComp.indexOf(comp.type) == -1 || choice > 4 || (this.tooltip && this.tooltip.cid == comp.cid && this.bubblebox.opened) ){
			return;
		}
		if (this.bubblebox.opened){
			console.log('hover1');
			setTimeout(()=>{ this.barHover($event, choice, comp, share);}, 100);
			return;
		}
		console.log('hover2');
		let compResults = this.chartData.chapters[0].results.filter(e=> e.bsid == share.bsid);
		if (!this.isPdfPaper){
			const tmpComp = this.chartData.allComponents.find(e=> e.cid == comp.cid && e.bsid == share.id);
			compResults = tmpComp?tmpComp.results:[];
		}
		const selectedResults = compResults.filter(result=> {
			if (result.cid != comp.cid){
				return false;
			}
			if (choice == 4){ //全對
				return result.correct_type == '2';
			} else if (choice == 1){ //0% - 25%
				return result.score >= comp.maxScore * 0 &&  result.score <= comp.maxScore * 0.25 && result.correct_type != '2';
			} else if (choice == 2){ //25% - 50%
				return result.score > comp.maxScore * 0.25 &&  result.score <= comp.maxScore * 0.5 && result.correct_type != '2';
			} else if (choice == 3) { // 50% - 75%
				return result.score > comp.maxScore * 0.5 &&  result.score <= comp.maxScore * 0.75 && result.correct_type != '2';
			}
		});
		let answerSelected:any = this.initAnswerSelected(selectedResults, comp);
		answerSelected = Object.fromEntries(Object.entries(answerSelected).sort());
		let answerSelectedItems = [];
		let wrongColor = ['#fd6585', '#de4968', '#c53050','#a5243f','#961834','#fd6585', '#de4968', '#c53050','#a5243f','#961834'];
		for(let prop in answerSelected){
			if (comp.mcType == 'multiple'){
				answerSelectedItems.push({text: prop + ': ' + answerSelected[prop].value + (this.lang == 'en'?' times':'次')});
			} else {
				let color = answerSelected[prop].isCorrect?'#3DA3E8':(wrongColor.length > 0 ?wrongColor.shift(): '#fd6585');
				const pt = Math.round(answerSelected[prop].value * 1000 / compResults.length) / 10;
				const text = prop + ': ' + pt + '%';
				answerSelectedItems.push({color, text, answer: prop });
			}
		}
		const ele = $event.target;
		let header = {tc: '作答分析 (%)',sc: '作答分析 (%)',en: 'Ans. analysis (%)'}[this.lang];
		if (comp.mcType == 'multiple'){
			header = {tc: '作答分析 (次數)',sc: '作答分析 (次數)',en: 'Ans. analysis (freq.)'}[this.lang];
		}
		this.tooltip = {header: header, items: answerSelectedItems, cid: comp.cid};
		this.bubblebox.open(ele, this.eleRef.nativeElement);
	}

	barLeave(comp){
		if (this.tooltip && comp.cid == this.tooltip.cid && this.bubblebox){
			if (this.bubblebox.opened && this.bubblebox.closeFn){
				this.bubblebox.closeFn(null, false);
			}
			if (this.tooltip && this.tooltip.cid == comp.cid){
				this.tooltip = null;
			}
		}
	}

	initAnswerSelected(results = [], comp){
		let answerSelected:any = {};
		results.forEach(r=>{
			const obj = JSON.parse(r.result);
			const isCorrect = obj.correct == 2?1:0;
			if (comp.type == 'ScoreCom-mc'){
				obj.answer.split(',').forEach(answer=>{
					if (answerSelected[answer]){
						answerSelected[answer].value++;
					} else {
						answerSelected[answer] = { isCorrect: comp.mcType == 'multiple'?null: (isCorrect?1:0), value:1 };
					}
				});
			} else if (comp.type == 'MC2' || comp.type == 'GraphicMC'){
				if (r.data){
					for(let i=0;i < r.data.length;i++){
						const charCode = String.fromCharCode(65 + i);
						const selected = r.data[i] == '1'?1:0;
						if (selected){
							if (answerSelected[charCode]){
								answerSelected[charCode].value++;
							} else {
								answerSelected[charCode] = {isCorrect: isCorrect, value: 1};
							}
						}
					}
				}
			} else if (comp.type == 'SimpleMC'){
				if (r.data){
					const data = JSON.parse(r.data);
					for(let i=0;i < data.length;i++){
						const charCode = String.fromCharCode(65 + i);
						const selected = data[i] == '1'?1:0;
						if (selected){
							if (answerSelected[charCode]){
								answerSelected[charCode].value++;
							} else {
								answerSelected[charCode] = {isCorrect: isCorrect, value: 1};
							}
						}
					}
				}
			}
		});
		return answerSelected;
	}

	updateAnswerSelected(result, comp, answerSelected){
		const resultObj = JSON.parse(result.result);
		let answer = '';
		if (comp.type == 'ScoreCom-mc'){
			answer = resultObj.answer;
		} else if (comp.type == 'MC2' || comp.type == 'GraphicMC'){	
			if (result.data && result.data.indexOf('1') > -1){
				answer = String.fromCharCode(result.data.indexOf('1') + 65);
			}
		} else if (comp.type == 'SimpleMC'){
			if (result.data && result.data.indexOf('1') > -1){
				const array = JSON.parse(result.data);
				answer = String.fromCharCode(array.indexOf('1') + 65);
			}
		}
		if (answer != ''){
			const found = answerSelected.find(e=> e.answer == answer);
			if (found){
				found.value++;
			} else {
				const obj = {answer: answer, value: 1};
				answerSelected.push(obj);
			}
		}
		return answerSelected;
	}

}
