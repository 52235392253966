import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SwitchService {
    
    public observable = new Subject()
    public bookcover = new Subject()
    public bookcover_cancel = new Subject()
    public status_change = new Subject()
    constructor(){

    }
}