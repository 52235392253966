import { Component, ContentChild, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { faCaretDown, faCaretUp, faCaretSquareDown, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { ThemeService } from 'src/app/service/theme.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'okaPulldown2',
	template: `

    <ng-template #selectTemplate>
      <div
        #okaPulldown
        class="okaPulldown {{menuPosition}}"
		[class.open]="isShowMenu"
        [style.width]="okaPulldownStyle.width || menuStyle.width || '150px'"
        [ngStyle]="okaPulldownStyle"
        (click)="toggleMenu()"
      >
        <div class="content">
            <ng-container *ngIf="!searchable || !isShowMenu" >
                <span *ngIf="!selected" class="label placeholder">{{getText(placeholder)}}</span>
                <ng-container *ngIf="selected">
                    <fa-icon *ngIf="selected.icon" [icon]="selected.icon"></fa-icon>
					<svg-icon *ngIf="selected.svgIcon" [name]="selected.svgIcon"></svg-icon>
                    <span class="label selected-label {{selected.optionClass?selected.optionClass:''}}" [class.labelDisabled]="isDisabled" [ngStyle]="SelectedLabelStyle">{{getText(bindLabel ? selected[bindLabel] : selected)}}</span>
                </ng-container>
            </ng-container>
            <input
                *ngIf="searchable && isShowMenu && okaPulldown.parentElement.tagName!='OKAPULLDOWN2'"
                #searchInput
                class="label searchInput"
                [placeholder]="getText((bindLabel ? (selected && selected[bindLabel]) : (selected||placeholder))) || (placeholder|translate)"
                [(ngModel)]="searchText"            
                (keydown.arrowup)="searchInputArrow('up')"
                (keydown.arrowdown)="searchInputArrow('down')"
                (keydown.enter)="marked && setSelected(marked)"
            >
          <fa-icon class="triangle" [icon]="(isShowMenu ? faCaretUp : faCaretDown)"></fa-icon>
        </div>
      </div>
    </ng-template>

    <ng-content *ngTemplateOutlet="selectTemplate"></ng-content>

    <div
      #menu
      class="menu"
      [style.top.px]="menuTop"
      [style.left.px]="menuLeft"
      [style.width]="_menuWidth"
      [style.zIndex]="zIndex"
      [class.showMenu]="isShowMenu"
      [ngStyle]="menuStyle"
    >
      <ng-container *ngIf="menuPosition=='bottom'">
        <ng-content *ngTemplateOutlet="selectTemplate"></ng-content>
      </ng-container>

      <perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
        <cdk-virtual-scroll-viewport
			*ngIf="virtualScroll"
            class="optionsWrapper"
            [class.bottom]="menuPosition=='bottom'"
            [class.top]="menuPosition=='top'"
            itemSize="25"
            [style.height.px]="menuGetOptions().length * 30"
            style="max-height:300px;"
        >
            <div *ngIf="allowNull" class="option placeholder" (click)="setSelected(null)">{{getText(placeholder)}}</div>
            <div
                #menuOption
                *cdkVirtualFor="let option of menuGetOptions()"
                class="option {{option.optionClass?option.optionClass:''}}"
                [ngStyle]="optionStyle"
                [class.selected]="selected==option" [class.disabled]="isDisabled || (option.hasOwnProperty('active') && !option.active)"
                [class.marked]="marked==option"
                (click)="setSelected(option)"
            >
                <fa-icon *ngIf="option.icon" [icon]="option.icon"></fa-icon>
                <span class="label" [ngStyle]="labelStyle">
					{{getText(bindLabel ? option[bindLabel] : option)}}
					{{option.optionClass == 'group'?':':''}}
				</span>
            </div>

        </cdk-virtual-scroll-viewport>

		<div class="optionsWrapper" *ngIf="!virtualScroll"
			[class.bottom]="menuPosition=='bottom'" [class.top]="menuPosition=='top'">
			<div *ngIf="allowNull" class="option placeholder" (click)="setSelected(null)">{{getText(placeholder)}}</div>
			<div
				*ngFor="let option of options"
				class="option {{option.optionClass?option.optionClass:''}}"
				[title]="getText(bindLabel ? option[bindLabel] : option)"
				[class.selected]="selected==option"  [class.disabled]="isDisabled || (option.hasOwnProperty('active') && !option.active)"
				(click)="setSelected(option)">
				<fa-icon *ngIf="option.icon" [icon]="option.icon"></fa-icon>
				<svg-icon *ngIf="option.svgIcon" [name]="option.svgIcon"></svg-icon>
                <span class="label" [ngStyle]="labelStyle">
					{{getText(bindLabel ? option[bindLabel] : option)}}
					{{option.optionClass == 'group'?':':''}}
				</span>
			</div>
		</div>
          
        </perfect-scrollbar>

        <ng-container *ngIf="menuPosition=='top'">
          <ng-content *ngTemplateOutlet="selectTemplate"></ng-content>
        </ng-container>
    </div>
    
    `,
	styleUrls: ['./okaPulldown2.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OkaPulldown2Component),
			multi: true,
		},
	],
})

export class OkaPulldown2Component implements ControlValueAccessor, OnInit, OnChanges {
	public faCaretDown: IconDefinition = faCaretDown;
	public faCaretUp: IconDefinition = faCaretUp;
	public faCaretSquareDown: IconDefinition = faCaretSquareDown;
	@ViewChild('okaPulldown', { static: false }) public okaPulldown: ElementRef<HTMLDivElement>;
	@ViewChild('menu', { static: false }) public menu: ElementRef<HTMLDivElement>;
    @ViewChild('searchInput', {static:false}) public searchInput:ElementRef<HTMLInputElement>;
    @ViewChildren('menuOption') public menuOptions:QueryList<ElementRef<HTMLElement>>;

	@Input() public options: any[] = [];
	@Input() public bindLabel: string = 'label';
	@Input() public bindValue: string = 'value';
	@Input() public placeholder: string = 'okaColorPulldown.pleaseSelect';
	@Input() public allowNull: boolean = false;
    @Input() public searchable:boolean = false;
	@Input() public okaPulldownStyle: any = {};
	@Input() public menuStyle:any = {};
	@Input() public SelectedLabelStyle:any = {};
	@Input() public themeStyles:any = {};
	@Input() public optionStyle:any = {};
    @Input() public labelStyle:any = {};
	@Input() public virtualScroll = false;
	@Input() public isDisabled: boolean = false;
	@Input() public isModalStyle: boolean = false;
	@Input() public alwaysApplyTheme:string = null;
	@Output() public close:EventEmitter<any> = new EventEmitter();
	@Output() public changing:EventEmitter<any> = new EventEmitter();

	@Input() public preset:any;
	@Input() public selected: any = null;
	public isShowMenu: boolean = false;
	public zIndex: number = 999;
	public menuLeft: number = 0;
	public menuTop: number = 0;
	public menuPosition: string = 'bottom';
    public searchText:string = '';
    public marked:any = null;
    public _menuWidth:string = '150px';

	constructor(private coms: CommonService, public datas: DataService, public translate: TranslateService, public eleRef: ElementRef, private themeService: ThemeService) {

	}

	// list 不到數字，所以修改
	getText(key:string):string {
		try {
			return this.translate.instant(key);
		} catch(e) {
			return key ? key :"";
		}
	}

	ngOnInit(): void {
		this.themeService.getThemeJson("okaPulldownModule.json").then((styleObj: any) => {
			if (this.alwaysApplyTheme) styleObj = this.pickTheme(styleObj, this.alwaysApplyTheme);
			this.themeService.applyStyleObj(styleObj, this.eleRef.nativeElement);
			if (this.themeStyles){
				let themeStyles:any = this.alwaysApplyTheme ? this.pickTheme(this.themeStyles, this.alwaysApplyTheme) : this.themeStyles;
				this.themeService.applyStyleObj(themeStyles, this.eleRef.nativeElement);
			}
			if (this.isModalStyle && !this.okaPulldownStyle.backgroundColor) {
				this.okaPulldownStyle.backgroundColor = 'var(--pulldown-bgcolor-white, "#fffff")'
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		// options 改變時，更新一次 select object
		if(changes.options && this.selected && this.bindValue)
			this.writeValue(this.selected[this.bindValue]);
		if (changes.options && this.preset) {
			setTimeout(() => {
				this.setSelected(this.preset)
			}, 50);
			
		}
	}

	private pickTheme(orgStyleObj:any, themeName:string):any {
		let styleObj:any = JSON.parse(JSON.stringify(orgStyleObj));
		if (styleObj[themeName]) {
			if (!styleObj.default) styleObj.default = {};
			Object.assign(styleObj.default, styleObj[themeName]);
			return { default:styleObj.default };
		} else {
			return styleObj;
		}
	}
	//-implements ControlValueAccessor---------------------------------------------------------------
	private onChange = (v: any) => { };
	private onTouched = () => { };

	writeValue(value: any): void {
		let valueToSet: any = value;
		if (this.bindValue) {
			valueToSet = this.options ? this.options.find((option: any) => option[this.bindValue] == value && option.optionClass != 'group') : null;
		}
		this.selected = valueToSet;
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
	//-----------------------------------------------------------------------------------------------
	public toggleMenu(): void {
        this.searchText = '';
		this.isShowMenu ? this.hideMenu() : this.showMenu();
	}
	public open():void
	{
		if(this.isShowMenu == false) this.showMenu();
	}
	public showMenu(): void {
        this.calMenuWidth();
		//因為如果init後馬上打開menu，menu的位置會不對，所以加到setTimeout內
		setTimeout(() => {
			if (this.isDisabled || !this.okaPulldown || !this.okaPulldown.nativeElement) return;

			let rect: DOMRect = <DOMRect>this.okaPulldown.nativeElement.getBoundingClientRect();
			let menuEle: HTMLDivElement = this.menu.nativeElement;
			this.menuPosition = 'bottom'; //如果menu的位置過低超出畫面，需要改為在上面顯示
			if ((rect.top + menuEle.offsetHeight) > window.innerHeight) this.menuPosition = 'top';
			this.menuLeft = rect.left;
			this.menuTop = this.menuPosition == 'bottom' ? rect.top : rect.top + rect.height - menuEle.offsetHeight;
			if (this.menuStyle && this.menuStyle.top && this.menuStyle.top.px !== undefined){
				this.menuTop = this.menuStyle.top.px;
			}
			if (this.menuStyle && this.menuStyle.top && this.menuStyle.left.px !== undefined){
				this.menuLeft = this.menuStyle.left.px;
			}
			this.zIndex = this.coms.getZIndex();
            
			this.isShowMenu = true;

			let closeFn: any = (ev: PointerEvent) => {
				if (this.menu && this.menu.nativeElement && ev.target) {
					if (!this.isDescendant(this.menu.nativeElement, ev.target)) {
						this.hideMenu();
						window.removeEventListener('mousedown', closeFn);
						window.removeEventListener('scroll', closeFn, true);
					}
				}
			};

			setTimeout(() => {
				window.addEventListener('mousedown', closeFn);
				window.addEventListener('scroll', closeFn, true);
			});

            let setSearchInputFocus:Function = (retry:number=0):void => {
                if (this.searchInput && this.searchInput.nativeElement) this.searchInput.nativeElement.focus()
                else if (retry<100) setTimeout(()=>setSearchInputFocus(retry+1), 100);
            }
            setSearchInputFocus();
		});
	}

	public hideMenu(): void {
		this.isShowMenu = false;
		this.close.emit(null);
	}

	public setSelected(value: any): void {
		console.log(this.selected,value);
		
		if (this.selected != value) {
			var oldValue = this.selected;
			var event:any = {
				oldValue:oldValue,
				newValue:value,
				defaultPrevented:false,
				preventDefault:()=>{
					event.defaultPrevented = true;
				},
				confirm:()=>{
					this.selected = value;
					this.onValueChanged();
				}
			};
			this.changing.emit(event);
			this.isShowMenu = false;
			if(!event.defaultPrevented) event.confirm();
		}
		this.hideMenu();
	}

	private onValueChanged():void
	{
		this.onChange(this.bindValue ? this.selected[this.bindValue] : this.selected);
		this.onTouched();
	}

	private isDescendant(parent, child): boolean {
		let node = child.parentNode;
		while (node) {
			if (node === parent) return true;
			node = node.parentNode;
		}
		return false;
	}

    private getFilteredOptions():any[] {
        return this.options.filter((option:any)=>option[this.bindLabel] && option[this.bindLabel].indexOf(this.searchText) > -1);
    }

    public menuGetOptions():any[] {
        if (this.searchable && this.searchText) {
            let result:any[] = this.getFilteredOptions();
            if (result.indexOf(this.marked) == -1){
				this.marked = result[0] || null;
			}
			return result;
        } else {
			return this.options;
        }
    }

    public searchInputArrow(dir:string):void { //當在input內按上、下key時
        let filteredOptions:any[] = this.getFilteredOptions();
        //如果未有標記，就用第一個
        if (!this.marked) {
            if (filteredOptions[0]) this.marked = filteredOptions[0];
            return;
        }
        //取得在當前menu的index
        let curIndex:number = filteredOptions.indexOf(this.marked);
        if (curIndex<0) return;
        //計算將要移至的index
        let targetIndex:number = curIndex + {up:-1, down:1}[dir];
        if (filteredOptions[targetIndex]) {
            this.marked = filteredOptions[targetIndex];
            //scroll至該Div Ele
            let eleRef:ElementRef<HTMLElement> = this.menuOptions.toArray()[targetIndex];
            if (eleRef) eleRef.nativeElement.scrollIntoView();
        }
    }

    public calMenuWidth():void {
        setTimeout(()=>{
            let result:string = this.menuStyle.width || this.okaPulldownStyle.width;
            if (!result || result=='100%') {
                if (this.okaPulldown && this.okaPulldown.nativeElement) {
                    result = this.okaPulldown.nativeElement.offsetWidth + 'px';
                } else {
                    result = '150px';
                }
            }
            this._menuWidth = result;
        });
    }

}
