import { Injectable } from '@angular/core';
@Injectable()
export class CommonService {
  waitFor(arg0: () => any, arg1: number) {
	  throw new Error('Method not implemented.');
  }
  public loginPage: any;
  public mainPage: any;
  public lessonPage: any;
  public chatPage: any;
  public bookshelfPage: any;
  public loading: string[] = [];
  public debug: any[] = [];
  public inTouch = false;
  constructor() {

  }

  public addLoading(appName: string, time = 0): void {
    setTimeout(() => {
      this.loading.push(appName);
      console.log('LoadingService:', this.loading);
    }, 100);
    if (time){
      setTimeout(()=>{this.removeLoading(appName);}, time);
    }
  };

  public removeLoading(appName: string): void {
    setTimeout(() => {
      this.loading = this.loading.filter(item => item != appName);
    }, 100);
  }

  public clearAllLoading(): void {
    setTimeout(() => {
      this.loading = [];
    }, 100);
  }

  public log(obj:any){
    if (!obj.style){
      obj.style = {};
    }
    if (this.debug.length == 0 || !obj.singleOnly){
      this.debug.push(obj);
    }
    if (obj.timeout){
      setTimeout(()=>{
        const index = this.debug.indexOf(obj);
        this.debug.splice(index,1);
      },obj.timeout);
    }
  }


}

