import {
	Component,
	HostListener,
	NgZone,
	ViewChild,
	OnInit,
	Input,
	OnDestroy,
	AfterViewInit,
} from "@angular/core";
import { LoadingService } from "src/app/sharedModule/loadingModule/loading.service";
import { DataService } from "src/app/service/data.service";
import { ThemeService } from "src/app/service/theme.service";
import { Subject } from "rxjs";
import { takeUntil, throttleTime, delay } from "rxjs/operators";
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { CommonService } from "src/app/service/common.service";
import {OupService} from "../../../../service/oup.service";
declare var Chart;

@Component({
	selector: "p-distribution-chart",
	template: `
		<div class="card" *ngIf="chartData.scoredComponents.length > 0">
			<div class="panel" [ngClass]="theme.theme$.value + '-theme'">
				<ng-container *ngIf="chartDatasets">
					<div class="item" *ngFor="let ds of chartDatasets;let i=index">
						<div
							class="item-box item-{{i}}"
							[style.backgroundColor]="ds.backgroundColor"
						>
						</div>
						<div class="item-label">{{ ds.fullLabel }}</div>
					</div>
				</ng-container>
			</div>
			<div class="body">
				<div class="chart-wrapper">
					<canvas #canvas></canvas>
				</div>
			</div>
		</div>

		<div class="no-data" *ngIf="chartData.scoredComponents.length == 0">
			<div class="no-data-image"></div>
			<div class="no-data-text">{{ {tc:'未有題目',sc:'未有题目',en:'No question'}[datas.lang] }}</div>
		</div>
	`,
	styleUrls: ["./personal-distribution.chart.scss"],
})
export class PersonalDistributionChart implements OnInit, AfterViewInit, OnDestroy {
	@Input() parent;
	public chartData: any;
	public chartDatasets: any;
	public chartLabels: any;
	public chartObj: any;
	public chartOptions: any;
	public yMax = 100;
	public stepSize = 1;
	public onDestroy = new Subject();
	@ViewChild("canvas", { static: false }) canvas;
	public faCheck = faCheck;
	public lang;
	constructor(
		public lds: LoadingService,
		public datas: DataService,
		public theme: ThemeService,
		public coms: CommonService,
		public oup: OupService,
	) {}

	ngOnInit() {
		this.chartData = this.parent.chartData;
		this.lang = this.datas.lang;
	}

	ngAfterViewInit(): void {
		setTimeout(()=>{
			this.initChartData();
			this.initChartOptions();
			this.renderChart();
		});
		this.parent.parent.filterChange.pipe(takeUntil(this.onDestroy), throttleTime(100) ).subscribe(event=>{
			this.initChartData();
			this.initChartOptions();
			this.chartObj.data.datasets = this.chartDatasets;
			this.chartObj.update();
		});
	}

	ngOnDestroy() {
		this.onDestroy.next();
		this.onDestroy.complete();
		if (this.chartObj){
			this.chartObj.destroy();
		}
	}

	initChartOptions() {
		const axisXLabel = { tc: "分數%", sc: "分数%", en: "Score%" }[
			this.datas.lang
		];
		const axisYLabel = { tc: "人數", sc: "人数", en: "Number of students" }[
			this.datas.lang
		];
		this.chartOptions = {
			maintainAspectRatio: false,
			spanGaps: true,
			tension: 0.5,
			scales: {
				x: {
					grid: {
						display: false,
					},
					ticks: {
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 14,
							weight: "bold",
						},
						color: this.chartLabels.map((e) => e.color),
					},
					title: {
						display: true,
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 18,
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#666',
						text: axisXLabel,
					},
					stacked: false,
				},
				y: {
					grid: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					border: {
						color: this.theme.theme$.value == 'dark'?'#53536a' : '#ccc',
					},
					ticks: {
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 14,
							weight: "bold",
						},
						beginAtZero: true,
						stepSize: this.stepSize,
						max: this.yMax + 1,
					},
					title: {
						display: true,
						font: {
							family: "Noto Sans T Chinese Regular,Helvetica Neue",
							size: 18,
						},
						color: this.theme.theme$.value == 'dark'?'#fff':'#666',
						text: axisYLabel,
					},
					stacked: false,
				},
			},
			interaction: {
				mode: "nearest",
				axis: 'x',
				intersect: true,
			},
			animation: {
				duration: 300,
			},
			responsive: true,
			title: true,
			layout: { padding: { left: 20, right: 20, top: 20, bottom: 20 } },
			plugins: {
				legend: {
					display: false,
					labels: {
						display: false,
					},
				},
			},
		};
	}

	initChartData() {
		this.chartLabels = [{ label: "0-10%", min: 0, max: 10 }];
		for (let i = 11; i < 100; i += 10) {
			this.chartLabels.push({
				label: i + "-" + (i + 9) + "%",
				min: i,
				max: i + 9,
				color: this.theme.theme$.value == "light" ? "#333" : "#fff",
			});
		}
		let passCondition = this.chartData.passCondition;
		const passColor = "#7fc42d";
		const failColor = "#ee4865";
		passCondition = "50";
		if (passCondition == null || passCondition == "") {
			this.chartLabels.forEach((l) => {
				l.color = passColor;
			});
		} else if (isNaN(passCondition) == false) {
			this.chartLabels.forEach((l) => {
				l.color =
					l.min >= parseFloat(passCondition) ? passColor : failColor;
			});
		}
		let chartDatasets = [];
		this.yMax = 1;
		this.stepSize = 1;

		const personalInfo = {tag_title: this.chartData.studentName, tag_fulltitle: this.chartData.studentName, bsid: this.chartData.bsid, color: '#FD6585'};
		let personalDataset = this.initDataset(personalInfo);
		this.chartData.shares.forEach((share) => {
			share.color = '#7CCFFA';
			let dataset = this.initDataset(share);
			this.chartLabels.forEach((label) => {
				const peoples = share.peoples.filter(
					(e) => {
						const score = e.score === null?0:e.score;
						const percentOfBook = Math.round(score * 100 / this.chartData.structure.book.maxScore);
						return percentOfBook >= label.min && percentOfBook <= label.max;
					}
				);
				const peoplesLength = peoples.length;
				if (peoples.find(e=> e.uid == this.chartData.uid)){
					personalDataset.data.push(1);
				} else {
					personalDataset.data.push(null);
				}
				this.yMax = Math.max(this.yMax, peoplesLength + 1);
				dataset.data.push(peoplesLength);
			});

			chartDatasets.push(dataset);
		});

		chartDatasets.push(personalDataset);
			
		this.chartDatasets = chartDatasets;
		if (this.yMax > 20) {
			this.stepSize = Math.floor(this.yMax / 10);
		}
	}

	initDataset(share){
		let dataset: any = {
			data: [],
			_label: share.tag_title,
			label: share.tag_title,
			_fullLabel: share.tag_fulltitle,
			fullLabel: share.tag_fulltitle,
			backgroundColor: share.color,
		};
		if(this.oup.isOupUser) {
			Object.defineProperty(dataset, 'label', {
				get: () => {
					return this.oup.get_class_name(dataset._label) || dataset._label;
				},
				set: (value) => {
					dataset._label = value;
				}
			});

			Object.defineProperty(dataset, 'fullLabel', {
				get: () => {
					return this.oup.get_class_name(dataset._fullLabel) || dataset._fullLabel;
				},
				set: (value) => {
					dataset._fullLabel = value;
				}
			});

		}

		dataset.borderColor = share.color;
		dataset.pointBorderColor = "#fff";
		dataset.pointBackgroundColor = share.color;
		dataset.borderWidth = 2;
		dataset.pointBorderWidth = 2;
		dataset.pointRadius = 7;
		dataset.fill = false;
		dataset.bsid = share.bsid;
		return dataset;
	}

	renderChart() {
		if (this.chartData.scoredComponents.length == 0){
			return;
		}
		if (this.chartObj) {
			this.chartObj.destroy();
		}
		this.coms.waitFor(()=> this.canvas && this.canvas.nativeElement, 20, 200).then(()=>{
			const canvasEle = this.canvas.nativeElement;
			const ctx = canvasEle.getContext("2d");
			this.lds.add("renderChart", 5000);
			setTimeout(() => {
				this.chartObj = new Chart(ctx, {
					type: "line",
					data: {
						datasets: [ this.chartDatasets[1], this.chartDatasets[0] ],
						labels: this.chartLabels.map((e) => e.label),
					},
					options: this.chartOptions,
					plugins: [
						{
							afterRender: (chart, args, options)=> {
								this.lds.remove('renderChart');
							} 
						}
					]
				});
			}, 800);
		});
	}

}

