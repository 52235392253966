
export class DOMHelper {
	/*
	static getElementScale2(el: HTMLElement): any[] {
		var array:any [] = [];
		// var scale:number = 1;
		while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
			var elementScale:number = DOMHelper.getElementTransformScale(el);
			// scale *= elementScale;
			array.push(el.className, elementScale, "("+ el.style.transform +")");
			el = <HTMLElement> el.offsetParent;
		}
		return array;
	}

	*/

	static getElementScale(el: HTMLElement): any {
		var scale:number = 1;
		while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
			var elementScale:number = DOMHelper.getElementTransformScale(el);
			scale *= elementScale;
			el = <HTMLElement> el.offsetParent;
		}
		return scale;
	}

	static getElementTransformScale(el:HTMLElement)
	{
		if(el.style.transform)
		{
			var match:any = /scale\((.*?)\)/.exec(el.style.transform);
			if(match) return parseFloat(match[1]);
		}
		return 1;
	}

	static getElementTransformRotate(el:HTMLElement)
	{
		if(el.style.transform)
		{
			var match:any = /rotate\((.*?)\)/.exec(el.style.transform);
			if(match) return parseFloat(match[1]);
		}
		return 0;
	}

	
	static getElementTransform3D(el: HTMLElement):any{
		var scale:number = 1;
		var x:number = 1;
		var y:number = 1;
		var z:number = 1;
		let rotate:string = '0deg';
		if(el.style.transform)
		{
			var match:any;
			match = /scale\((.*?)\)/.exec(el.style.transform);
			if(match)
			{
				scale = parseFloat(match[1]);
			}
			match = /translate3d\((.*?)\,(.*?)\,(.*?)\)/.exec(el.style.transform);
			if(match)
			{
				x = parseFloat(match[1]);
				y = parseFloat(match[2]);
				z = parseFloat(match[3]);
			}
			match = /rotate\((.*?)\)/.exec(el.style.transform);
			if (match){
				rotate = match[1];
			}
		}
		return {
			x:x,
			y:y,
			z:z,
			scale:scale,
			rotate:rotate
		};
	}
	
	static getHTMLElement(item:any, defaultElement:HTMLElement):HTMLElement
	{
		if(item)
		{
			if(typeof item == "string")
			{
				var element:HTMLElement = document.querySelector(item);
				if(element) return element;
			} else if(item instanceof HTMLElement)
			{
				return item;
			}
		}
		return defaultElement;
	}
	static getLocalPoint(el, globalPoint)
	{
		var position:any = DOMHelper.getDOMGlobalPosition(el);
		
		var x = ( globalPoint.x - position.left)/ position.scale; //x position within the element.
		var y = ( globalPoint.y - position.top )/ position.scale;  //y position within the element.
		return {
			scale:position.scale,
			gp:globalPoint,
			x:x ,
			y:y
		};
	}
	static getDOMGlobalPosition( el):any{
		let accumZoom = 1;
		if (el.className.indexOf('zoomed') > -1){
			accumZoom = this.getAccumulatedZoom(el);
		}
		var rect = el.getBoundingClientRect();
		var _scale:number = 1;
		if (el.className.indexOf('notScaled') == -1){
			while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
				var scale:number = DOMHelper.getElementTransformScale(el);
				_scale *=  scale;
				el = el.offsetParent;
			}
		}
		_scale = _scale * accumZoom; 
		return { left:rect.x, top: rect.y, scale:_scale};
	}

	// rotation not support
	// convert global rectangle to target layer coordinates
	static convertGRect(gRect:DOMRect, targetLayer:HTMLElement):any {
		var pt1:any = DOMHelper.getLocalPoint(targetLayer, gRect);
		var pt2:any = DOMHelper.getLocalPoint(targetLayer, {x:gRect.x+gRect.width, y:gRect.y+gRect.height});
		return {gRect:gRect, x:pt1.x, y:pt1.y, width:pt2.x - pt1.x, height: pt2.y - pt1.y};
	}

	static removeChildren(container:HTMLElement):void
	{
		while(container.childNodes.length)
		{
			var child:ChildNode = container.childNodes[0];
			container.removeChild(child);
		};
	}
	static removeElement(element:HTMLElement):void
	{
		if(element && element.parentElement)
		{
			element.parentElement.removeChild(element);
		}
	}

	static findROComDOM(element:HTMLElement):HTMLElement
	{
		while(element && !element.classList.contains("ro-component"))
			element = element.parentElement;

		return element;
	}

	static getAccumulatedZoom(element) { //慎用: 因loop到root ele, 或會變慢
		// 基本情况：如果没有父元素，返回 1（无缩放）
		if (!element) {
			return 1;
		}
	
		// 获取当前元素的计算样式
		const style = window.getComputedStyle(element);
		
		// 获取当前元素的 zoom 值，默认为 1
		const zoom = parseFloat(style.zoom) || 1;
	
		// 递归查找父元素并计算总的 zoom
		return zoom * this.getAccumulatedZoom(element.parentElement);
	}
	
}