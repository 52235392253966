import {Component, Input, OnInit} from '@angular/core';
import {WebLessonService} from "../../../coreModule/lessonModule/WebLessonModule/services/WebLesson.service";
import {DataService} from "../../../service/data.service";
import {faThumbsUp} from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: 'student-like-panel',
	template: `
		<div class="student-like-panel" *ngIf="student && page">
			<img [src]="student.profile_image_url" class="student-profile-image"/>
			<div class="info">
				{{ web_lesson.get_student_display_name(student) }}, P.{{ page.pageNo }}
			</div>
			<div class="btn-like" [class.active]="liked" (click)="toggle_like()">
				<fa-icon [icon]="faThumbsUp"></fa-icon>
				{{ like_count }}
			</div>
		</div>
	`,
	styleUrls: ['./student-like-panel.component.scss']
})

export class StudentLikePanelComponent implements OnInit {
	private _student = null;
	private self_uid = null;
	private student_uid = null;
	private _page = null;
	private page_id = null;

	public liked = false;
	public like_count = 0;

	public faThumbsUp = faThumbsUp;
	@Input() index = 0;

	private subs = [];
	private is_refreshing = false;

	constructor(
		public web_lesson: WebLessonService,
		public datas: DataService,
	) {
		this.self_uid = this.datas.userInfo.uid;
		this.subs.push(this.web_lesson.on_message.subscribe(async (message) => {
			let action = message.content.action;
			if (!this.web_lesson.is_teacher) {
				if (action === 'broadcast') {
					if (message.content.type === 'student_like_updated') {
						this.refresh_student_like_status();
					}
				}
			}
		}))
	}

	ngOnInit() {
		this.refresh_student_like_status()
	}

	ngOnDestroy() {
		this.subs.forEach(sub => {
			sub.unsubscribe();
		})
		this.subs = [];
	}

	public get student() {
		let student_uid = this.web_lesson.lesson_state.scoring_mode.current_student_uid;
		if (this.student_uid !== student_uid) {
			this._student = this.web_lesson.student_list.find(s => `${s.uid}` === `${student_uid}`);
			this.student_uid = student_uid;
			this.refresh_student_like_status();
		}

		return this._student;
	}

	public get page() {
		let page_id = this.web_lesson.lesson_state.page_id;
		if (this.page_id !== page_id) {
			this._page = this.web_lesson.context.config.pages.find(p => p.attributes.douid === page_id);
			this.page_id = page_id;
			this.refresh_student_like_status();
		}

		return this._page;
	}

	public get can_click_like() {
		return this.student_uid != this.self_uid;
	}

	async toggle_like() {
		if (!this.can_click_like) {
			return;
		}

		let response = null;

		if (this.liked) {
			response = await this.datas.call(
				'ROBookShare.cancelStudentRating',
				this.web_lesson.share_id,
				this.page.chapter.id,
				this.page.attributes.douid,
				this.index,
				this.student_uid,
				1,
			);
		} else {
			response = await this.datas.call(
				'ROBookShare.addStudentRating',
				this.web_lesson.share_id,
				this.page.chapter.id,
				this.page.attributes.douid,
				this.index,
				this.student_uid,
				1,
				""
			);
		}
		this.web_lesson.broadcast_msg({
			type: 'student_like_updated',
		})

		this.update_like_status(response);

		console.log('toggle_like', response);

	}

	async refresh_student_like_status() {
		if (this.is_refreshing || !this.page || !this.student) {
			return;
		}
		this.is_refreshing = true;

		let response = await this.datas.call(
			'ROBookShare.getStudentRating',
			this.web_lesson.share_id,
			this.page.chapter.id,
			this.page.attributes.douid,
			this.index,
			this.student_uid
		);

		console.log("student like records", response);
		this.update_like_status(response);
		this.is_refreshing = false;
	}

	update_like_status(response) {

		if (response.code == 0) {
			this.like_count = response.data.length;
			this.liked = response.data.find(record => {
				return `${record.from_uid}` === `${this.self_uid}`
			})
		}

	}
}