import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {WebLessonService} from "../../services/WebLesson.service";
import {CommonService} from "../../../../../service/common.service";
import {ROContext} from "../../../../../sharedModule/roBookModule/ROContext";
import {ROPageSliderComopnent} from "../../../../../sharedModule/roBookModule/ROPageSliderComponent";
import {DOMHelper} from "../../../../../common/DOMHelper";
import {ROBookPreviewComponent} from "../../../../../sharedModule/roBookModule/ROBookPreviewComponent";

@Component({
	selector: 'web-lesson-pointer',
	template: `
		<div #pointer class="pointer" *ngIf="web_lesson.lesson_id && web_lesson.lesson_state.pointer.show" [class.is-using-epen]="pointer_data.is_using_epen">
			<svg width="71" height="73" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M34.2 19.35C34.2 23.7166 32.65 27.4333 29.55 30.5C26.4833 33.6 22.7666 35.15 18.4 35.15C14.0333 35.15 10.3166 33.6 7.24998 30.5C4.14998 27.4333 2.59998 23.7166 2.59998 19.35C2.59998 14.9833 4.14998 11.2666 7.24998 8.19998C10.3166 5.13331 14.0333 3.59998 18.4 3.59998C22.7666 3.59998 26.4833 5.13331 29.55 8.19998C32.65 11.2666 34.2 14.9833 34.2 19.35Z" fill="#FC3737"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0.900006 0.14997C8.33334 0.94997 15.8667 2.3333 23.5 4.29997L3.00001 23.65C1.33334 15.6166 0.366673 8.06664 0.100007 0.999971C0.0666735 0.399971 0.333339 0.116637 0.900006 0.14997Z" fill="#FC3737"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M26.95 19.35C26.95 21.6833 26.1167 23.6833 24.45 25.35C22.7834 27.0166 20.7834 27.85 18.45 27.85C16.0834 27.85 14.0667 27.0166 12.4 25.35C10.7334 23.6833 9.90002 21.6833 9.90002 19.35C9.90002 16.9833 10.7334 14.9833 12.4 13.35C14.0667 11.6833 16.0834 10.85 18.45 10.85C20.7834 10.85 22.7834 11.6833 24.45 13.35C26.1167 14.9833 26.95 16.9833 26.95 19.35Z" fill="#F8F3EB"/>
			</svg>
			<img *ngIf="web_lesson.lesson_teacher" [src]="web_lesson.lesson_teacher.profile_image_url"/>
		</div>
	`,
	styleUrls: ['./web-lesson-pointer.component.scss'],
})

export class WebLessonPointerComponent implements OnInit {

	before_destroy_list = [];
	pointer_data = {
		x: null,
		y: null,
		is_using_epen: false,
	}
	@Input() parent: ROBookPreviewComponent;
	@ViewChild('pointer', {static: false}) pointer_el: ElementRef;

	constructor(
		public eleRef: ElementRef,
		public web_lesson: WebLessonService,
		public coms: CommonService,
	) {
		document.body.classList.add('web-lesson-pointer-enabled');
		this.before_destroy_list.push(() => {
			document.body.classList.remove('web-lesson-pointer-enabled');
		});
		if (!this.web_lesson.is_teacher) {
			let sub = this.web_lesson.on_message.subscribe((message) => {
				if (message.content.action === 'broadcast') {
					if (message.content.type === 'update_pointer') {
						let [x, y, is_using_epen] = message.content.pointer;
						this.pointer_data = {x, y, is_using_epen};
					}
				}
			});

			this.before_destroy_list.push(() => {
				sub.unsubscribe();
			})
			this.auto_update_position();
		} else {
			this.auto_send_position();
		}
	}

	ngOnDestroy() {
		this.before_destroy_list.forEach(cb => {
			cb();
		})
	}


	timeout(ms) {
		return new Promise(res => setTimeout(res, ms));
	}

	async auto_send_position() {
		do {
			this.web_lesson.update_pointer(this.pointer_data);
			await this.timeout(1000 / 60);
		} while (this.web_lesson.lesson_state.pointer.show);
	}

	async auto_update_position() {
		do {
			try {
				let {x, y} = this.pointer_data;
				if (this.parent && this.parent.slider) {
					let el = this.parent.slider.centerPage.ref.instance.elementRef.nativeElement;
					if (el) {
						let info = DOMHelper.getLocalPoint(el, {x: 0, y: 0});
						let rect = el.getBoundingClientRect();

						x = x * info.scale + rect.x;
						y = y * info.scale + rect.y;
					}
				}
				this.update_position(x, y);
			} catch (e) {
				// console.trace(e);
			}
			await this.timeout(1000 / 90);
		} while (this.web_lesson.lesson_state.pointer.show)
	}

	ngOnInit() {

		let sub = this.parent.context.subject.subscribe(async (data) => {
			if (data.type === "notify") {
				if (data.notify.type === 'ePenNoteStart') {
					this.pointer_data.is_using_epen = true;
				} else if (data.notify.type === 'ePenNoteEnd') {
					this.pointer_data.is_using_epen = false;
				} else if (data.notify.type === 'ePenNoteMoveData') {
					if (data.notify.data.event instanceof PointerEvent) {
						this.update_position(data.notify.data.point.x, data.notify.data.point.y);
					}
				}
			}
		});
		this.before_destroy_list.push(() => {
			sub.unsubscribe();
		});
		if (this.web_lesson.is_teacher) {
			// follow mouse
			const mousemove = (e) => {
				this.update_position(e.clientX, e.clientY);
			}
			window.addEventListener('mousemove', mousemove,);

			// follow touch
			const touchmove = (e) => {
				this.update_position(e.touches[0].clientX, e.touches[0].clientY);
			}
			window.addEventListener('touchmove', touchmove,);

			this.before_destroy_list.push(() => {
				window.removeEventListener('mousemove', mousemove);
				window.removeEventListener('touchmove', touchmove);
			})
		}
	}

	update_position(x, y) {
		// console.log(this.web_lesson)
		if (this.web_lesson.is_teacher && this.parent && this.parent.slider) {
			let el = this.parent.slider.centerPage.ref.instance.elementRef.nativeElement;
			if (el) {
				let info = DOMHelper.getLocalPoint(el, {x: 0, y: 0});
				var scale = 1 / info.scale;
				var px = x * scale + info.x;
				var py = y * scale + info.y;
				this.pointer_data.x = px;
				this.pointer_data.y = py;
			}
		}
		if (this.pointer_el) {
			this.pointer_el.nativeElement.style.left = x + 'px';
			this.pointer_el.nativeElement.style.top = y + 'px';
		}
	}
}