import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {DataService} from "src/app/service/data.service";
import {OupService} from "src/app/service/oup.service";
import {faChevronDown, faChevronRight, faCircle} from "@fortawesome/pro-solid-svg-icons";
import {TranslateService} from "@ngx-translate/core";
import {ROBookConfig} from "../ROBookConfig";
import {WebLessonService} from "../../../coreModule/lessonModule/WebLessonModule/services/WebLesson.service";

@Component({
	selector: 'pending-correction-panel',
	template: `
		<div class="bounds" #myBounds *ngIf="mode">
			
			<div class="pending-correction-panel"
				 drag-to-move
				 [xAlign]="drag_to_move_data.xAlign"
				 [yAlign]="drag_to_move_data.yAlign"
				 [x]="drag_to_move_data.x" [y]="drag_to_move_data.y"
				 [handle]="move_handle" [bounds]="myBounds" [inBounds]="true"
				 (positionChange)="position_change($event)"
			>
				
				<div class="header" #move_handle>
					<div class="toggle" (click)="toggle_is_panel_open()">
						<fa-icon [icon]="is_panel_open ? faChevronDown : faChevronRight"></fa-icon>
					</div>
					<div class="title">{{ 'web-lesson.scoring_mode.correction' | translate }}</div>
				</div>
				<div class="content">
					<ng-container *ngIf="is_panel_open">
						<div class="pending-count">
							<fa-icon [icon]="faCircle"></fa-icon>
							{{ ('web-lesson.scoring_mode.' + mode + '_pending') | translate }}
							({{ pending_count }}/{{ total_question_count }})
						</div>
						<div class="chapter-list">
							<div *ngFor="let chapter of chapter_list; let i = index">
								<div class="chapter">
									{{ 'web-lesson.scoring_mode.chapter' | translate }}{{ i + 1 }} {{ chapter.title }}
								</div>
								<div class="page-list">
									<div *ngFor="let page of chapter.pages" class="page"
										 [class.selected]="page === config.pages[config.pageIndex]"
										 [class.need-correct]="need_correct_page_map.get(page)"
										 (click)="go_to_page.emit(page.attributes.douid)"
									>
										P.{{ page.pageNo }}
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	`,
	styleUrls: ['./pending-correction-panel.component.scss']
})

export class PendingCorrectionPanelComponent implements OnInit {

	public drag_to_move_data: any;
	public is_panel_open = true;
	public faChevronDown = faChevronDown;
	public faChevronRight = faChevronRight;
	public faCircle = faCircle;
	public pending_count = 0;
	public total_question_count = 0;
	public chapter_list = [];
	public need_correct_page_map = new Map();

	@Input() mode: any;
	@Input() student: any;
	@Input() answer_map: any;
	@Input() config: ROBookConfig;
	@Output() go_to_page = new EventEmitter();

	constructor(
		public datas: DataService,
		public oup: OupService,
		public trans: TranslateService,
		public web_lesson: WebLessonService,
	) {

	}

	ngOnChanges(changes: SimpleChanges): void {
		this.update_count();
	}

	update_count() {
		console.log("update_count", this.config);
		let pending_count = 0;
		let total_question_count = 0;
		let chapter_map = {};
		let chapter_list = [];
		let answer_map = this.config.dataSource.answerMap;
		let need_correct_page_map = new Map();
		this.config.pages.forEach((page) => {
			let components = page.pageNode.children;
			let page_has_question = false;

			let page_need_correct = false;
			for (let component of components) {
				let key = `${page.chapter.id}/${page.attributes.douid}/${component.attributes.douid}`;
				let is_answered = false;
				let need_correct = false;
				is_answered = !!answer_map[key];
				if (component.attributes.isQuestionComponent) {
					page_has_question = true;
					total_question_count += 1;
					if (is_answered) {
						need_correct = answer_map[key].result.maxScore !== answer_map[key].result.score;
					} else {
						need_correct = true;
					}
					if (need_correct) {
						pending_count += 1;
						page_need_correct = true;
					}
				}
			}
			if (page_has_question) {
				if (!chapter_map[page.chapter.id]) {
					chapter_map[page.chapter.id] = {
						title: page.chapter.title,
						pages: [],
					};
					chapter_list.push(chapter_map[page.chapter.id]);
				}
				chapter_map[page.chapter.id].pages.push(page);
				need_correct_page_map.set(page, page_need_correct);
			}
		});

		this.total_question_count = total_question_count;
		this.pending_count = pending_count;
		this.chapter_list = chapter_list;
		this.need_correct_page_map = need_correct_page_map;

		console.log({
			total_question_count,
			pending_count,
		})
	}

	ngOnInit() {
		this.drag_to_move_data = this.datas.getPersonalSetting("pendingCorrectionPanel");
		this.update_count();
	}

	toggle_is_panel_open() {
		this.is_panel_open = !this.is_panel_open;
	}

	public position_change(event) {
		this.drag_to_move_data.x = event.data.x;
		this.drag_to_move_data.y = event.data.y;
		if (event.event == "end") {
			this.datas.setPersonalSetting("pendingCorrectionPanel", this.drag_to_move_data);
		}
	}

}