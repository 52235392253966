import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

    public analyticsChartListView:any = {
        selectedMenuItem: "my-chart", //schoolChart or myChart
        selectedYear: null,
        sortType: "asc", //asc or desc;
    }

    public targetSelector;
    
    public options:any = (()=>{
		let result:any = {
			chartTypes: [
				//{titleKey:'analytics.analysis-all-chart', value: 'all-chart', title:'', useModule:'storedChart'},
				//{titleKey:'analytics.analysis-my-chart', value: 'my-chart', title:'', useModule:'storedChart'},
				{titleKey:'analytics.analysis-subject', value: 'subject', title:'', useModule:'subjectChart', teacherOnly: true},
				{titleKey:'analytics.analysis-objective', value: 'objective', title:'', useModule:'lineChart', teacherOnly: true},
				//{titleKey:'analytics.analysis-login', value: 'login', title:'', useModule:'lineChart'},
				//{titleKey:'analytics.analysis-activity', value: 'activity', title:'', useModule:'lineChart'},
				//{titleKey:'analytics.analysis-read', value: 'read', title:'', useModule:'lineChart'},
				//{titleKey:'analytics.analysis-submission', value: 'submission', title:'', useModule:'lineChart'},
				//{titleKey:'analytics.analysis-star', value: 'star', title:'', useModule:'lineChart'}
				{titleKey:'analytics.student-diversity-list', value: 'studentDiversityList', title:'', useModule:'studentDiversityList', teacherOnly: true},
				{titleKey:'analytics.cross-year-subject-chart', value: 'crossYearSubjectChart', teacherOnly: false }
			]
		}
		if (["localhost", "dev.openknowledge.hk", "uat.openknowledge.hk"].includes(location.hostname)) {
			result.chartTypes.push(
				{titleKey:'analytics.student-middle-pass-count', value: 'studentMiddlePassCount', teacherOnly: false }
			)
		}
		return result;
	})();
    
    public years:any[] = [];
    public selectedYear:BehaviorSubject<any> = new BehaviorSubject(null);

    public selectedChartType:BehaviorSubject<any>;
    public selectedObjectives:BehaviorSubject<any[]> = new BehaviorSubject([]);
    public selectedSubjects:BehaviorSubject<any[]> = new BehaviorSubject(['all']);
    public selectedTargets:BehaviorSubject<any[]> = new BehaviorSubject([]);
    public selectedDateRange:BehaviorSubject<any> = new BehaviorSubject({ startDate: moment().subtract(7, 'days').startOf('day'), endDate: moment().endOf('day') });

    public ctl:any = {};
    
    constructor(private coms:CommonService, private datas:DataService, private translate:TranslateService) {
        //設定表的種類
        /*if (this.storage.userInfo.branch){
            this.otherTabs = [{title: 'analysis-my-chart', value: 'my-chart'},{title: 'analysis-login', value: 'login'},{title: 'analysis-activity', value: 'activity'},{title: 'analysis-read', value: 'read'},{title: 'analysis-submission', value: 'submission'},{title: 'analysis-star', value: 'star'}];
        }*/
		if (this.datas.userInfo.user_role == 2){
			this.options.chartTypes = this.options.chartTypes.filter(e=> !e.teacherOnly);
 		}
		this.selectedChartType = new BehaviorSubject(this.options.chartTypes[0].value);
        const happyreading = {titleKey: 'analytics.analysis-happyreading', value: 'happyreading', title:'', useModule:'lineChart'}
        if(this.datas.appId == 'happyreading'){
            this.options.chartTypes = [happyreading];
        } else if (this.datas.userInfo.school_id == 272){
            //this.options.chartTypes.push(happyreading);
        }
        this.selectedChartType.next(this.options.chartTypes[0]);

        //套用Translate Lang
        this.coms.waitFor(()=>this.datas.lang, 30)
        .then(()=>this.translate.use(this.datas.lang))
        .then(()=>this.coms.waitFor(()=>this.translate.translations[translate.currentLang].analytics, 30))
        // .then(()=>this.coms.waitFor(()=>{debugger;console.log(this.translate.translations);return this.translate.translations[translate.currentLang].analytics;}, 30))
        .then(()=>this.translateOptionsTitle());

        //取得所有學年並設定現年
        this.coms.waitFor(()=>this.datas.userInfo.school_id, 30)
        .then(()=>this.getYearsApi(this.datas.userInfo.school_id));
    }
    //-------------------------------------------------------------------------------------------------
    private translateOptionsTitle():void {
        for (let key in this.options) {
            for (let option of this.options[key]) {
                if (option.titleKey) option.title = this.translate.instant(option.titleKey);
            }
        }
    }
    //-------------------------------------------------------------------------------------------------
    public getYearsApi(schoolId:number, selectedYearId:number=null):void {
		// dev
		if ([272,1559].indexOf(schoolId) > -1 && this.datas.userInfo.user_role != 2) {
			this.options.chartTypes.push(
				{titleKey:'analytics.analysis-scoreDataList', value: 'scoreDataList', title:'', useModule:'table',teacherOnly: true}
			)
		}
		
        this.datas.post2({
            data: { api:'ROUserAndGroup.getSchoolYear', json:[schoolId] }, loading:true
        }).then((res:any)=>{
            if (res && res.years) {
                this.years = res.years;

                let curUnixTime:number = Math.floor(Date.now() / 1000);
                this.years.map(year=>{
                    if (year.start_time && year.end_time && year.start_time < curUnixTime && year.end_time > curUnixTime) year.current = true;
                });

                let selectedYear:any = null;
                if (selectedYearId) selectedYear = this.years.find((year:any)=>year.id==selectedYearId);
                if (!selectedYear) selectedYear = this.years.find((year:any)=>year.current);
                if (!selectedYear) selectedYear = this.years[this.years.length-1];
                this.selectedYear.next(selectedYear);
            }
        });
    }
    //-------------------------------------------------------------------------------------------------
    public getSelectedObjectivesTitle():string {
        return this.selectedObjectives.getValue().map((obj:any)=>obj.name).join(',');
    }
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------

}
