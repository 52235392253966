import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Modal2Component } from "src/app/sharedModule/modal2Module/modal2.component";
import { faCheckCircle, IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { ThemeService } from "src/app/service/theme.service";
import { DataService } from "src/app/service/data.service";
import { environment } from "src/environments/environment";
import { AlertService } from "src/app/service/alert.service";
import { ProgressBoxComponent } from "src/app/sharedModule/progressBoxModule/progressBox.component";
import { RoService } from "src/app/service/ro.service";
import { FileIOService } from "src/app/service/FileIO.service";
import { UploadService } from "src/app/sharedModule/uploadModule/upload.service";
import { LoadingService } from "src/app/sharedModule/loadingModule/loading.service";
import { ZipUtils } from "src/app/common/ZipUtils";

@Component({
	selector: 'fileToImageConverter',
	template: `
		<modal2 #convertModal 
			[title]="modalTitle | translate" 
			[width]="'880px'"
			height="720px"
			(confirmClick)="onConfirm()"
			(cancelClick)="onCancel()"
		>			
			<ng-container *ngIf="!fileId">
				<div class="selectFileContainer">
					<span>{{ getTranslate("selectReminder") }}</span>
					<div class="selectBtn" (click)="selectFile()">{{ getTranslate("selectBtn") }}</div>
				</div>
			</ng-container>

			<ng-container *ngIf="fileId">
				<div class="optionsWrapper">
					<span class="step">{{ getTranslate("stepReminder") }}</span>
					<div class="options" *ngIf="isAllThumbLoadCompleted()">
						<div class="option">
							<span>{{ getTranslate("resolution") }}:</span>
							<okaPulldown2 
								[options]="curDensityLists" 
								bindLabel="label" bindValue="value"
								[(ngModel)]="curDensity" 
								[okaPulldownStyle]="{ width: '100px' }"
							>
							</okaPulldown2>
						</div>
						<div class="option">
							<span>{{ getTranslate("lockImages") }}:</span>
							<ui-switch class="useCssVar" [checked]="lockImage" (change)="lockImage = !lockImage">
							</ui-switch>
						</div>
						<div class="option selectAll" (click)="setAllSelection(true)">
							<span>{{ getTranslate("selectAll") }}</span>
						</div>

						<div class="option unselectAll" (click)="setAllSelection(false)">
							<span>{{ getTranslate("unselectAll") }}</span>
						</div>
					</div>
				</div>

				<div class="imageContainer">
					<perfect-scrollbar [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
					<div class="imagePage" *ngFor="let image of images">
						<div class="imageWrapper" (click)="onSelectImage(image)">
							<div
								class="image"
								[class.loading]="isLoadingShown(image)"
								[class.converting]="isPngConverting(image.page)"
							>
								<img *ngIf="image.Url" [src]="image.Url">
								<img *ngIf="image.path" [src]="pdfConverterHost + '/' + image.path">
								<fa-icon class="check" [class.selected]="image.selected" [icon]="faCheckCircle"></fa-icon>
							</div>
						</div>
						<span class="label">P.{{image.page + 1}}</span>
					</div>
					
					</perfect-scrollbar>
				</div>
			</ng-container>

			<progressBox #progressBox [backgroundStyle]="'var(--wpopup-bgcolor)'">
				<div class="processTemplateMsg">{{ processMsg }}</div>
				<div class="processTemplateCnlBtn" (click)="processCancelOnClick()">{{'commonService.cancel' | translate}}</div>
			</progressBox>
		</modal2>
	`,
	styleUrls: ['./fileToImageConverter.component.scss']
})

export class FileToImageConverter implements OnInit, OnDestroy, OnChanges {
	@ViewChild('convertModal', {static:false}) public convertModal: Modal2Component;
	@ViewChild('progressBox', {static:false}) progressBox: ProgressBoxComponent;

	@Output() submitEmit = new EventEmitter<any>();

	// params
	public faCheckCircle:IconDefinition = faCheckCircle;
	public modalTitle = ""
	public type: "pdf" | "pptx" = "pdf"
	public bookDetails = null
	public lockImage:boolean = false;
	public fileId: number = 0
	public fileName: string = ""
	public roHasPages:number = 0;
	public images:any[] = [];
	public pngs:any[] = [];

	public MAX_FILE_SIZE: number = 0;
	public maxPagesAllow:number = 100;
    public pdfConverterHost:string = (window.location.protocol == 'http:') ? environment.pdfConverterService.httpHost : environment.pdfConverterService.httpsHost;

	public progressPt = 0;
	public processMsg:string = "";
	public curDensity:number = 288;
	public curDensityOptions: any[] = [ 144, 216, 288, 432 ]
	public curDensityLists: any[] = []
    public curSharpen:number = 0;

	constructor(
		public translate: TranslateService, 
		private sanitization: DomSanitizer, 
		public themeService:ThemeService,
		private als: AlertService,
		public datas: DataService,
		private ros: RoService,
		public fileio: FileIOService,
		private uls: UploadService,
		private lds: LoadingService,
		private eleRef:ElementRef,
	) {}

	ngOnInit(): void {
		this.MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB
		this.curDensityLists = this.curDensityOptions.map(item => ({label: item.toString(), value: item}))
		this.themeService.getThemeJson("alert2Module.json").then((styleObj:any)=>this.themeService.applyStyleObj(styleObj, this.eleRef.nativeElement));
	}

	ngOnDestroy(): void {}

	ngOnChanges(changes: SimpleChanges): void {}

	public open(title = "", type: "pdf" | "pptx", bookDetails: any = null) {
		this.modalTitle = title
		this.type = type
		if (bookDetails) {
			this.roHasPages = bookDetails.roHasPages || 0
			this.bookDetails = bookDetails
		}
		this.convertModal.open()
	}

	public onConfirm() {
		let pages: number[] = this.images.filter(img => img.selected).map(img => img.page);
		if (pages.length < 1 || !this.isAllThumbLoadCompleted()) {
			this.als.alert2(this.getTranslate("selectAtLeast1PageToImport"), null, { btns: [['ok']] });
			return;
		} else if ((pages.length + this.roHasPages) > this.maxPagesAllow) {
			this.als.alert2("ro.fileConverter.noMoreThanNPagesAfterImport", {
                max: this.maxPagesAllow,
                roHasPages: this.roHasPages,
                diff: this.maxPagesAllow - this.roHasPages
            }, { btns: [['ok']] });
            return;
		}

		// upload the files
		if (this.type === 'pdf') {
			this.convertPdfToPng(pages)
		} else if (this.type === 'pptx') {
			this.uploadPptxPng(pages)
		}
	}

	public onCancel() {
		this.resetData()
		this.convertModal.close()
	}

	private resetData() {
		this.modalTitle = "";
		this.type = 'pdf';
		this.bookDetails = null;
		this.lockImage = false;
		this.fileId = 0;
		this.fileName = '';
		this.roHasPages = 0;
		this.images = [];
		this.pngs = [];
		this.roHasPages = 0;
		this.fileId = 0;
		this.processMsg = "";
		this.curDensity = 288
	}
   	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// Helper functions
	public getTranslate(text: string, params: any = null) {
		return this.translate.instant("ro.fileConverter." + text, params)
	}

	public setAllSelection(value: boolean): void {
		this.images.map(img => img.selected = value);
	}

	public onSelectImage(image: any): void {
		if (this.isAllThumbLoadCompleted()) {
			image.selected = !image.selected 
		}
	}

	public processCancelOnClick(): void {
		this.progressBox.close();
		this.pngs = [];
	}
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// handle files upload
	public selectFile() {
		if (this.type === 'pdf') {
			this.onHandleSelectPdf()
		} else if(this.type === 'pptx') {
			this.onHandleSelectPptx()
		}
	}

	/** for upload pptx file  */
	private selectPptx(): Promise<any> {
		return new Promise((resolve, reject) => {
			let fileInput: HTMLInputElement = document.createElement('input');
			fileInput.type = 'file';
			fileInput.accept = '.ppt,.pptx';
			fileInput.onchange = async (event:any)=>{
            	if (event && event.target && event.target.files && event.target.files[0]) {
                    let file:File = event.target.files[0];

					// check file size
					if (file.size > this.MAX_FILE_SIZE) {
						this.als.alert2("ro.fileConverter.selectLessThanNMBToImport", {value:50}, { btns: [['ok']] });
						return;
					}
					// check file page count
					const zip = await ZipUtils.loadURL(URL.createObjectURL(file))
					const slideFiles = Object.keys(zip.files).filter(filename => 
                        filename.startsWith('ppt/slides/slide') && filename.endsWith('.xml')
                    );
					const slideCount = slideFiles.length;

					if (slideCount > this.maxPagesAllow) {
						this.als.alert2("ro.fileConverter.selectLessThanNPageToImport", {value:this.maxPagesAllow}, { btns: [['ok']] });
						return;
					}

					this.lds.add('selectPptx', 10000);
					// upload the file to tmp server then convert it
					this.uls.uploadFileObject(file, false).then(data => {
						if (data && data.path && data.id) {
							const server = this.fileio.getResourceServer(data.path, true)
							const filePath = server + data.path
							const fileData = { Name: data.filename, Url: filePath }

							this.lds.remove('selectPptx');
							this.datas.post2({
								data: { api: "ROConverter.convertPPTXToPng", json: [fileData] },
								loading: true
							}).then((res)=>{
								if (res) {
									if (res.code === 200) {
										resolve(Object.assign(res.response, { fileName: file.name, fileId: data.id }));
									} else if (res.code === 400) {
										reject(400)
									}
								}		
                    		});
						}
					})
                }
            };
			fileInput.click();
		})
	}

	private onHandleSelectPptx() {
		/**
		 * Api return data
		 * ConversionCost: number
		 * Files: [
		 * 	{
		 * 		FileData: string, // optional, if the file is not stored in the API server
		 * 		FileId: string, // optional, if the file is stored in the API server
		 * 		Url: string, // optional, if the file is stored in the API server
		 * 		FileExt: string,
		 * 		FileName: string,
		 * 		FileSize: number,
		 * 	}
		 * ]
		 */
		this.selectPptx().then(data => {
			const convertResult = JSON.parse(data.body)
			if (data && convertResult.Files && convertResult.Files.length > 0 && data.fileName && data.fileId) {
				this.fileId = data.fileId
				this.fileName = data.fileName
				const images = convertResult.Files.map((file: any, index: number) => ({...file, status: 'q', page: index}))
				this.setImages(images)
			}
		}).catch(err => {
			this.als.alert2("commonService.processTimeoutMsg", null, { btns: [['ok']] });
		})
	}

	/** for import pdf type */
	private selectPdf(): Promise<any> {
		return new Promise((resolve, reject) => {
			let fileInput: HTMLInputElement = document.createElement('input');
			fileInput.type = 'file';
            fileInput.accept = 'application/pdf';
			fileInput.onchange = (event:any)=>{
            	if (event && event.target && event.target.files && event.target.files[0]) {
                    let file:File = event.target.files[0];
                    let formData:FormData = new FormData();
                    formData.append('file', file, file.name);
                    formData.append('filename', file.name);

					if (file.size > this.MAX_FILE_SIZE) {
						this.als.alert2("ro.fileConverter.selectLessThanNMBToImport", {value:50}, { btns: [['ok']] });
						return;
					}

                    this.datas.post2({
                        url: this.pdfConverterHost + '/api/uploadPdfFile',
                        data: formData,
                        loading: true
                    }).then((res)=>{
                        if (res && res.data) resolve(Object.assign(res.data, { fileName: file.name }));
                    });
                }
            };
			fileInput.click();
		})
	}
	
	private onHandleSelectPdf() {
		this.selectPdf().then((data: any) => {
			if (data && data.pdfFileId && data.size && data.totalPages && data.fileName) {
				if (data.totalPages > this.maxPagesAllow) {
					this.als.alert2("ro.fileConverter.selectLessThanNPageToImport", {value:this.maxPagesAllow}, { btns: [['ok']] });
				} else if (data.size >this.MAX_FILE_SIZE) {
                    this.als.alert2("ro.fileConverter.selectLessThanNMBToImport", {value:50}, { btns: [['ok']] });
                } else {
					this.fileId = data.pdfFileId;
					this.fileName = data.fileName
					this.getPdfThumbnail(this.fileId)
				}
			} else {
                if (!data.pdfFileId) this.als.alert2('fileId missed.', { btns: [['ok']] });
                if (!data.size) this.als.alert2('size missed.', { btns: [['ok']] });
                if (!data.totalPages) this.als.alert2('totalPages missed.', { btns: [['ok']] });
			}
		})
	}
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// handle file convert to image
	// pdf functions
	private getPdfThumbnail(fileId: number): void {
		if (fileId !== this.fileId) return;

		this.datas.post2({
			url: this.pdfConverterHost + '/api/pdfToPng',
			data: {
				pdfFileId: fileId,
				pages: [],
				resize: "160x244",
				quality: 60,
                density: 72
			},
			loading: false
		}).then(res => {
			if (res && res.data) {
				this.setImages(res.data);
				// retry
				if (res.data.find(img => img.status == 'q' || img.status == 'c')) {
					setTimeout(() => {
						this.getPdfThumbnail(fileId);
					}, 1000)
				}
			}
		})
	}

	private setImages(images: any[]): void {
		for (let image of images) {
			const field = this.type === 'pdf' ? "page" : "FileId"
			let extImg: any = this.images.find(extImg => extImg[field] == image[field]);
			if (extImg) {
				for (let key in image) extImg[key] = image[key];
			} else {
				this.images.push(image);
			}
			let curImage:any = extImg || image;
			if (curImage.path ||curImage.Url) curImage.selected = true;
			if (curImage.Url) {
				setTimeout(() => {
					curImage.status = 'f'
				}, 500)
			}
		}
	}

	/** check image convert status */
	public isPngConverting(page: number): boolean {
		let png: any = this.pngs.find(png => png.page == page);
		return (png && (png.status=='q' || png.status=='c'));
	}

	/** check is completed */
	public isAllThumbLoadCompleted():boolean {
        return !(this.images.length < 1 || this.images.find(image => image.status != 'f'));
    }

	public isLoadingShown(image) {
		if (this.type === 'pdf') {
			return !image.path
		} else if (this.type === 'pptx') {
			return image.status !== 'f' || !image.Url
		}
	}
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// Functions for insert into the E-book
	/** convert pdf to png */
	private convertPdfToPng(pages):void {
		 // add dummy result with status 'q' to trigger 'converting css class'
		 this.pngs = this.images.filter(image => image.selected).map(image => {
            return { page:image.page, status:'q' };
        });

		this.updateProcessMsg(pages)

		const getPngFn:Function = () => {
            let sharpen:string = "";
            if (this.curSharpen) sharpen = `0x${this.curSharpen}`;

			this.datas.post2({
                url: this.pdfConverterHost + '/api/pdfToPng',
                data: {
                    pdfFileId: this.fileId,
                    pages: pages,
                    resize: "1024x768",
                    quality: 80,
                    density: this.curDensity,
                    sharpen: sharpen
                },
                loading: false
            }).then((res)=>{
                if (res && res.data && this.progressBox.isOpened) {
                    this.pngs = res.data;
                    this.updateProcessMsg(pages)

					const stillProcessing = this.pngs.some(png => png.status == 'q' || png.status == 'c');
                    if (stillProcessing) {
                        setTimeout(()=>{ getPngFn()}, 1000);
                    } else {
						// handle the upload image process
						const pngs = this.pngs.map(png => ({...png, src: `${this.pdfConverterHost}/${png.path}`}));
						
						const processPngs = async () => {
							try {
								for (const png of pngs) {
									await new Promise(async (resolve, reject) => {
										try {
											await this.moveToResource(png, resolve);
										} catch (err) {
											reject(err)
											throw(err)
										}
									});
								}
								this.progressBox.close();
								this.als.alert2('ro.fileConverter.nPagesAreAdded', {value:this.pngs.length}, { btns: [['ok']] }, this.eleRef.nativeElement).then(() => {
									this.submitEmit.emit({ images: pngs, lockImage:this.lockImage, bookDetails: this.bookDetails })
									this.resetData()
									this.convertModal.close()
								})
							} catch (err) {
								console.log(err, "convertPDFToPng error")
								this.als.toastError("commonService.failToUpload")
							}
						};
						processPngs()
                    }
                }
            });
        }
		setTimeout(()=>{ getPngFn()}, 500);
		this.progressBox.open();
	}

	/** download asset and reupload to media resource */
	private async moveToResource(png, resolveOuter?: Function) {
		try {
			const blob = await this.fileio.downloadAsset(png.src).toPromise();
			const file = new File([blob], `${this.fileName}#${png.page + 1}`, { type: blob.type });
			const res = await this.uls.uploadFileObject(file, false);
			if (res.filename && res.path) {
				// create image object to get dimensions
				await new Promise((resolve) => {
					const img = new Image();
					img.src = png.src;
					img.onload = async () => {
						let width = img.width;
						let height = img.height;
		
						// Limit dimensions to a maximum of 1200x1200
						const maxDimension = 1200;
		
						if (width > maxDimension || height > maxDimension) {
							const aspectRatio = width / height;
		
							if (width > height) {
								width = maxDimension;
								height = maxDimension / aspectRatio;
							} else {
								height = maxDimension;
								width = maxDimension * aspectRatio;
							}
						}
		
						// Store the dimensions in png
						png.width = Math.round(width);
						png.height = Math.round(height);
		
						const groupRes = await this.datas.post("Resource.finailze_group_resource2", [1, res, null, 0]).toPromise();
						png.file = groupRes;
	
						resolve(true);
						if (resolveOuter) resolveOuter();
					};
				})
			}
		} catch (err) {
			console.error(err)
			if (resolveOuter) resolveOuter();
			throw err;
		}
	}
	/** testing function for changing the pptx resolution */
	private async movePPTXToResource(png, resolveOuter?: Function) {
		try {
			const img = new Image();
			img.crossOrigin = 'anonymous';
			img.src = png.src;
			await new Promise((resolve) => {
				img.onload = async () => {
					// Get original dimensions
					let { width, height } = img;
					
					// First apply max dimension limits to base dimensions
					const maxDimension = 1200;
					if (width > maxDimension || height > maxDimension) {
						const aspectRatio = width / height;
						
						if (width > height) {
							width = maxDimension;
							height = maxDimension / aspectRatio;
						} else {
							height = maxDimension;
							width = maxDimension * aspectRatio;
						}
					}
	
					// Store the final display dimensions
					png.width = Math.round(width);
					png.height = Math.round(height);
	
					// Now create a high-resolution canvas based on DPI
					const dpiScaleFactor = this.curDensity / 200;
					const canvas = document.createElement('canvas');
					canvas.width = Math.round(width * dpiScaleFactor);
					canvas.height = Math.round(height * dpiScaleFactor);
					
					// Draw image with high-quality settings
					const ctx = canvas.getContext('2d');
					ctx.imageSmoothingEnabled = true;
					ctx.imageSmoothingQuality = 'high';
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
	
					canvas.toBlob(async (newBlob) => {
						const newFile = new File([newBlob], `${this.fileName}#${png.page + 1}`, { type: 'image/png' });
						const newRes = await this.uls.uploadFileObject(newFile, false);
						const groupRes = await this.datas.post("Resource.finailze_group_resource2", [1, newRes, null, 0]).toPromise();
						png.file = groupRes;
						resolve(true);
						if (resolveOuter) resolveOuter();
					}, 'image/png', 1.0);
				}
			})
		} catch (err) {
			console.error(err)
			if (resolveOuter) resolveOuter();
		}
	}

	/** upload pptx's png to server */
	private async uploadPptxPng(pages) {
		 // add dummy result with status 'q' to trigger 'converting css class'
		 this.pngs = this.images.filter(image => image.selected).map(image => {
            return { page:image.page, status:'q', src: image.Url };
        });
		this.updateProcessMsg(pages);

		const processPngs = async () => {
			for (const png of this.pngs) {
                await new Promise(async (resolve) => {
                    await this.movePPTXToResource(png, resolve);
                });
				png.status = 'f'
				this.updateProcessMsg(pages)
			}
			this.progressBox.close();
			this.als.alert2('ro.fileConverter.nPagesAreAdded', {value:this.pngs.length}, { btns: [['ok']] }).then(() => {
				this.submitEmit.emit({ images: this.pngs, lockImage:this.lockImage, bookDetails: this.bookDetails })
				this.resetData()
				this.convertModal.close()
			})
		};
		setTimeout(()=>{ processPngs()}, 500);
		this.progressBox.open();
	}

	/** show the process msg when converting or uploading */
	private updateProcessMsg(pages): void {
		const pngCompleted:any = this.pngs.filter(png => (png.status=='f'));
		this.processMsg = this.translate.instant('ro.fileConverter.processingPages', {
			page: pngCompleted.length,
			total: pages.length
		});
		this.progressPt = (pngCompleted.length / pages.length) * 100 
		this.scrollToConvertingThumb()
	}

	/** scroll to the current converting thumb */
	private scrollToConvertingThumb(): void {
		let convertingThumb:any = document.querySelector('.image.converting');
		if (convertingThumb) convertingThumb.scrollIntoView();
	}
}