import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewContainerRef } from "@angular/core";
import { ROComponent, ROPageComponent } from "./ROComponent";
import { CorrectionStateType, ROQuestionScore } from "./ROQuestionScore";
import { XMLNode } from "./xml/XMLNode";
import { SelectMarkerIcon } from "./SelectMarkerIcon";
import { ROExamPaper } from "./ROExamPaper";
import { TranslateService } from "@ngx-translate/core";
import { XMLJSNode } from "./xml/XMLParser";
import { Subscription } from "rxjs";
import { AnswerSource, ROBookConfig } from "./ROBookConfig";
import { StyleUtils } from "./StyleUtils";
import { DragManager } from "./DragManager";
import { CoordinateExpression } from "./CoordinateExpression";
import { SectionColorCode } from "./SectionColorCode";

@Component({
	// 總分 {{userScore}} / {{totalScore}}

	template:`
		<div class="score-container" [class.passed]="passed" [class.failed]="!passed" [class.editing]="editing" (pointerdown)="moveCom($event)">
			<div class="text-container" >
				<span class="score-font user-score">{{userScore}}</span>
				<span class="score-font total-score" *ngIf="showTotalScore" >/{{totalScore}}</span>
			</div>
		</div>
		
	`,
	styles:[`
		.score-container:not(.editing) .user-score,
		.score-container .total-score
		{
			font-family:var(--score-font-family);
			transform:translate(0px, 0.1em);
		}

		.score-container{
			// border:solid 1px red;
			width: 100%;
			height: 100%;
			text-align: center;
			align-items: center;
			align-content: center;
			justify-content: center;
			display:flex;
			resize: both;
		}
		
		.user-score{
			font-family:var(--score-font-family);
			font-size:var(--user-score, 32px);
			color:var(--score-color);
			transform:var(--font-transform);
			display: inline-block;
		}
		.total-score{
			font-size:var(--book-score, 24px);
			transform:var(--font-transform);
			display: inline-block;
		}
		.passed{
			--score-color:#66AD11;
		}
		.failed{
			--score-color:#FF585A;
		}
	`]
})
export class ROTotalScoreCom extends ROComponent
{
	public passed:boolean = true;
	public userScore:any = 0;
	public totalScore:number = 0;
	public showTotalScore:boolean = true;
	private subscription:Subscription;
	private selectorOptions:any = {};
	public editing:boolean;
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	protected buildQuestionAndScoreObject():void
	{
		/*
		setTimeout(()=>{
			this.updateScore();
		}, 1000);
		*/
		
	}
	

	public getTagNames():string []
	{
		return ["TotalScoreCom"];
	}

	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"showTotalScore":true,
				"fontSize":32,
				"coordinateExpression": "UA UK KW KH X 0 Y 0 D T 0 L 0 H 60 W 60",
				"isQuestionComponent": false,
				"douid": this.createDouid()
			},
			tag:"TotalScoreCom"
		});
		this.node.createElement();
		
	}
	
/*	
	public activate(): void {
		
	}	
	public deactivate(): void {
		
	}
*/
	public watch(): void {
		this.updateScore();
		if(this.subscription)  return;
		this.subscription = this.context.subject.subscribe((response:any)=>{
			if(response && response.type == "notify" )
			{
				var type:string = response.notify.type;
				if(type == "pageDataChanged" ||  type == "scoreUpdated" || type == "bookInfoUpdate")
				{
					this.updateScore();
				}
			}
		});
	}
	public unwatch(): void {
		if(!this.subscription) return;
		this.subscription.unsubscribe();
		this.subscription = null;
	}
	
	protected buildContent():void
	{
		this.showTotalScore = this.node.getAttribute("showTotalScore", true);
		
		var fontFamily:string = this.node.getAttribute("fontFamily", "Noto Sans TC");
		// var fontFamily:string = this.node.getAttribute("fontFamily", "DartyZhedant");
		StyleUtils.applyStyleObj(
			this.elementRef.nativeElement, 
			{"score-font-family":fontFamily}
		);
		this.updateFontSize();
	}
	updateFontSize():void
	{
		var userFontSize;
		var totalScoreFontSize;
		var config:ROBookConfig = this.context.config;
		var largeFontSize = this.node.attributes.fontSize ? this.node.attributes.fontSize : 32;
		var size = largeFontSize * 1.8;
		if(config.viewMode == "edit")
		{
			userFontSize = largeFontSize ;
			totalScoreFontSize = largeFontSize * 0.65;
		} else {
			userFontSize = largeFontSize ;
			totalScoreFontSize = largeFontSize * 0.65;
		}
		
		// 75 / 32 - 2.34375
		// 32 24 212 75 - 2.8 - 6.625
		// 32 24 138 75 - 1.84
		// this.h = Math.floor(fontSize * 2.34375);
		// this.h = fontSize + 30 + 20;
		this.h = size ;
		this.w = Math.floor(this.h * (this.showTotalScore ? 2.8 : 1.84));
		this.assignWH();

		StyleUtils.setStyleVariable(this.elementRef.nativeElement, "user-score", `${userFontSize}px`);
		StyleUtils.setStyleVariable(this.elementRef.nativeElement, "book-score", `${totalScoreFontSize}px`);
		// StyleUtils.setStyleVariable(this.elementRef.nativeElement, "line-height", `${this.h}px`);
		
	}
	dataContentReady():boolean
	{
		this.updateScore();
		return true;
	}
	

	private updateScore():void
	{
	
		// temporarily usage -  should be moving this code to somewhere else
		var page:ROPageComponent = this.page as ROPageComponent;
		var source:AnswerSource = page.source;
		var config:ROBookConfig = this.context.config;
		
		config.document.updateBookInfo();
		var pages:any [] = config.document.getPages();
		var scoreSum:number = 0;
		var completedQ = 0;
		var totalQ = 0;

		if(config.viewMode == "edit")
		{
			this.editing = true;
			this.totalScore = config.document.bookScore;
			this.userScore = this.totalScore.toString(10).replace(/[0-9]/g, "X");
			this.passed = true;
			return;
		}
		
		if(!source) return;
		pages.forEach((page:any)=>{
			page.components.forEach((component:any)=>{
				var answer:any = source.getAnswer(page.chapter.id, page.id, component.cid);
				totalQ++;
				if(answer)
				{
					completedQ++;
					var result:any;
					if(typeof answer.result == "string")
					{
						var result = JSON.parse(answer.result);
					} else if(answer.result){
						result = answer.result;
					}
					if(result)
					{
						if(result.score)
						{
							scoreSum +=result.score;
						}
					}
				}
			})
		});
		var share:any = config.share;
		this.userScore = scoreSum;
		this.totalScore = config.document.bookScore;
		
		// var correctionState:number;
		var isPassed:boolean;
		

		if(share && (share.pass_condition == "all" || share.pass_condition == "all-question"))
		{
			
			// [class.passed]="passed" [class.failed]="!passed"
			isPassed = completedQ >= totalQ;
			this.passed = isPassed;
			/*
			// correctionState = isPassed ? CorrectionStateType.CORRECT : CorrectionStateType.INCORRECT;
			if(isPassed)
			{
				correctionState = CorrectionStateType.CORRECT;
			} else if(completedQ == 0)
			{
				correctionState = CorrectionStateType.INCORRECT;
			} else {
				correctionState = CorrectionStateType.PARTIAL_CORRECT;
			}
			*/
		} else {
			var passScore:number = this.getPassScore(share, this.totalScore);
			isPassed = scoreSum >= passScore;
			this.passed = isPassed;
			
			/*
			if(isPassed)
			{
				correctionState = CorrectionStateType.CORRECT;
			} else if(scoreSum == 0)
			{
				correctionState = CorrectionStateType.INCORRECT;
			} else {
				correctionState = CorrectionStateType.PARTIAL_CORRECT;
			}
			*/
		}
		
		// this.sObj.showText(correctionState, `${scoreSum}/${this.totalScore}`);
	}
	private getPassScore(share:any, totalScore:number):number
	{
		if(!share) return totalScore * 0.6;
		var percent:number = parseInt(share.pass_condition)/ 100;
		return totalScore * percent;
	}

	public getPropertiesThroughPanel(key: string) 
	{
		if(key == "fontSize")
		{
			return this.node.getAttribute("fontSize", 32);
		} else if(key == "showTotalScore")
		{
			return this.node.getAttribute(key, true);
		} else if(key == "scoreType")
		{
			if(this.showTotalScore)
			{
				return "student+total";
			} else {
				return "student";
			}
		}
		return null;
	}
	public setPropertiesThroughPanel(key: string, val: any): boolean {
		if(key == "fontSize")
		{
			this.node.setAttribute("fontSize", val);
			this.updateFontSize();
			return true;
		} else if(key == "showTotalScore")
		{
			this.node.setAttribute(key, val);
			this.showTotalScore = val;
			this.updateFontSize();
			return true;
		} else if(key == "scoreType")
		{
			if(val == "student+total"){
				this.showTotalScore = true;
			} else if(val == "student")
			{
				this.showTotalScore = false;
			}
			this.node.setAttribute("showTotalScore", this.showTotalScore);
			this.updateFontSize();
			return true;
		}
		return true;
	}

	 
	public getSectionSelection():any
	{
		var key:string = "score";
		if(!this.selectorOptions.hasOwnProperty(key))
		{
			var translateService:TranslateService = this.context.service.translateService;
			var selection:any = {
				enabled:true,
				key:"scoreType",
				// "score-type":"顯示",
				// "student-score":"只有學生得分",
				// "student-total-score":"學生得分及試卷總分"
				name:translateService.instant("ro.components.total-score.score-type"),
				type:"options",
				options:[
					{
						value:"student",
						label:translateService.instant("ro.components.total-score.student-score")
					},
					{
						value:"student+total",
						label:translateService.instant("ro.components.total-score.student-total-score")
					}
				]
			};
			this.selectorOptions["key"] = selection;
		}
		
		return this.selectorOptions["key"];
	}

	public getPopupSelector():any []
	{
		var selectionSelection:any = this.getSectionSelection();
		/*
		var selectionSelection:any = this.getSectionSelection();
		var modeSelection:any = this.getModeSelection();
		var mcSelection:any = this.getMCSelection();
		var multiSelectSelection:any = this.getMultiSelectSelection();
		var learningObjective:string = this.node.getAttribute("learningObjective", "[]");
		*/
		return [
			{
				type:'name',
				translate:false,
				name:this.getTitleName()
			},
			selectionSelection,
			/*
			selectionSelection,
			modeSelection,
			mcSelection,
			multiSelectSelection,
			{
				name:this.context.service.translateService.instant("ro.components.common.score"),
				key:"score",
				type:"number",
				target:"component",
				max:100,
				min:0.5,
				value:0,
				step:0.5,
				dp:1
			},
			{
				type:"action",
				key:"learningObjective",
				action:"learningObjective",
				target:"editor",
				text:this.context.service.translateService.instant("ro.components.common.set-learning-objective"),
				learningObjective:JSON.parse(learningObjective)
			},
			
			{
				name:this.context.service.translateService.instant("ro.components.total-score.score-type"),
				key:"showTotalScore",
				type:"boolean",
				target:"component"
			},
			*/
			{
				name:this.context.service.translateService.instant("commonService.fontSize"),
				key:"fontSize",
				type:"number",
				target:"component",
				max:200,
				min:24,

				value:0
			},
			{
				type:"action",
				action:"remove",
				target:"editor",
				icon:SelectMarkerIcon.DELETE_ICON
			}
		];
	}

	// =========================
	// data function
	// =========================
	protected dd:DragManager;
	public positionData:any;
	public set data(value:string) {
		try {
			this.positionData = JSON.parse(value);
		} catch(e) {
			
		}
		if(!this.positionData)
			this.positionData = {};
		if(!this.positionData.hasOwnProperty("x") || isNaN(this.positionData.x) || isNaN(this.positionData.y)) {
			var ceString:string = this.node.getAttribute("coordinateExpression");
			if(!ceString) {
				// 只用一般座標
				var options:any = this.node.attributes;
				this.positionData.x = parseInt(options.x);
				this.positionData.y = parseInt(options.y);
			} else {
				var ce:CoordinateExpression = this.parseCE(ceString);
				if(ce) {
					this.positionData.x = ce.getX();
					this.positionData.y = ce.getY();
				}
			}
		}
		this.setPosition(this.positionData.x, this.positionData.y);

		var viewMode:string = this.context.config.viewMode;
		var subViewMode:string = this.context.config.subViewMode;
		if(subViewMode == "marking1" || (viewMode=="scoring" && subViewMode == "correction")) {
			if(!this.dd)
				this.dd = new DragManager();
		}
	}

	public get data():string {
		return this.positionData ? JSON.stringify(this.positionData) : null;
	}

	public isDataComponent():boolean {
		return true;
	}

	public moveCom(event:any):void {
		var viewMode:string = this.context.config.viewMode;
		var subViewMode:string = this.context.config.subViewMode;
		if(!(subViewMode == "marking1" || (viewMode=="scoring" && subViewMode == "correction")) || viewMode=="view" || viewMode=="review")
			return;

		
		event.stopImmediatePropagation();
		var originalPosition:any = {x:this.positionData.x, y:this.positionData.y};
		
		this.dd.monitorMovement(this.getElementRef().nativeElement, event, (type, movement)=> {
			if(type == "cancel" || type == "timeout"){
				// restore original position
				this.positionData.x = originalPosition.x;
				this.positionData.y = originalPosition.y;
				this.setPosition(this.positionData.x, this.positionData.y);

			} else {
				this.positionData.x = Math.max(0, Math.min(this.page.w - 40, this.positionData.x+movement.x));
				this.positionData.y = Math.max(20, Math.min(this.page.h - 40, this.positionData.y +movement.y));
				this.setPosition(this.positionData.x, this.positionData.y);

				if(type == "end") {
					this.saveStudentResult({"maxScore":0,"correction":false}, this.data);
				}
			}
			
			
		});
	}

	public saveStudentResult(newResult:any, data:any = undefined):Promise<any>
	{
		var page:ROPageComponent = <ROPageComponent> this.page;
		var index:any = this.context.config.index;
		var shareID:any = this.context.config.share.id;
		var bookID:any = this.context.config.book.id;
		var student:any = this.context.config.student;
		var chapterID:any = page.chapter.id;
		var pageID:any = page.douid;
		var componentID:any = this.douid;
		var tag:string = this.node.tag;
		if(data === undefined) data = this.data;
		
		return this.context.service.roBookReviewService.save(
			shareID,  bookID, student.uid,  index,
			chapterID, pageID,  componentID, 
			data, newResult, tag
		).then(()=>{
			page.source.setAnswer(chapterID, pageID, componentID, data, newResult);
			return newResult;
		});
	}
}

@Component({
	template:``,
	styles:[``]
})
export class ROScoreCom extends ROComponent
{
	public selectorOptions:any = {};
	public correctionAns:any;
	public file:File;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.firstLineHeight = 40;
	}
	
	onTransformComplete() {
		this.sObj.updatePosition();
	}
	getBoundingDOM():HTMLElement
	{
		return this.sObj.getDOM();
	}

	getComponentRectangle()
	{
		// return {x:this.sObj.x,y:this.sObj.y,width:60,height:60};
		return {x:this.sObj.x + 10,y:this.sObj.y + 10,width:40,height:40};
	}

	public getPropertiesThroughPanel(key: string) 
	{
		if(key == "type")
		{
			if(this.ctx.type == "mc")
			{
				return "mc_a_"+String.fromCharCode(96 + this.ctx.mcCount);
			} else {
				return this.ctx.type;
			}
			return this.ctx.type;
		} else if(key == "mc-type")
		{
			return this.ctx.mcAnswer ? this.ctx.mcAnswer.split(",") : [];
		} else if(key == "tf-type")
		{
			return this.ctx.answer ? this.ctx.answer : "T";
		} else if(key == "multi-select")
		{
			return this.ctx.multiSelect ? true :false;
		} else if(key == "score")
		{
			return this.getFullScore();
		} else {
			return super.getPropertiesThroughPanel(key);
		}
	}
	public setPropertiesThroughPanel(key: string, val: any): boolean {
		if(key == "multi-select")
		{
			this.ctx.multiSelect = val;
			
			this.removeExtraAnswer();
			this.updateMCSelection();
			this.saveContext();
			this.showComponentScore();
			
			return true;
		} else if(key == "score")
		{
			this.node.setAttribute("fullScore", val);
			this.showComponentScore();
		} else if(key == "mc-type")
		{
			this.ctx.mcAnswer = this.ctx.multiSelect ? val.join(",") : val;
			this.removeExtraAnswer();
			this.updateMCSelection();
			this.saveContext();
			this.showComponentScore();
		} else if(key == "tf-type")
		{
			this.ctx.answer = val;
			this.removeExtraAnswer();
			this.updateTFSelection();
			this.saveContext();
			this.showComponentScore();
		} else if(key == "type")
		{
			var o:any = this.selectorOptions.mode.options.filter((option:any)=>{
				return option.value == val;
			});
			if(!o || o.length == 0) return false;
			var selection:any = o[0];
			this.ctx.type = selection.type;
			if(this.ctx.type == "mc")
			{
				this.ctx.mcCount = selection.mcCount;
			}
			this.removeExtraAnswer();
			this.getMultiSelectSelection();
			this.updateModeSelection();
			this.updateMCSelection();
			this.updateTFSelection();
			this.saveContext();
			this.showComponentScore();
		} else {
			return super.setPropertiesThroughPanel(key, val);
		}
		return true;
	}
	removeExtraAnswer() {
		if(this.ctx.type != "mc") return;
		if(!this.ctx.mcAnswer) return;
		var answers = this.ctx.mcAnswer.split(",");
		var a:string = "A";
		var aCode:number = a.charCodeAt(0);
		// B - A < 2 answers ; 1 < 2 => true
		// C - A < 2 answers ; 2 < 2 => false
		// filter out extra answers;
		if(this.ctx.multiSelect)
		{
			answers = answers.filter((ans:string)=>{
				if(ans.charCodeAt(0) - aCode < this.ctx.mcCount) return true;
			});
		} else {
			answers = answers.filter((ans:string, index:number)=>{
				return index <= 0;
			});
		}
		this.ctx.mcAnswer = answers.join(",");
	}

	public hasDefaultAnswer():boolean
	{
		var type:string = this.ctx.type;
		if(type == "full")
			return true;
		else if(type == "mc")
			return this.ctx.mcAnswer ? true : false;
		return false;
	}

	showComponentScore():void
	{
		var mcAnswr:string
		if(this.ctx.type == "mc")
		{
			mcAnswr = this.ctx.type == "mc" ? this.ctx.mcAnswer : "";
			if(mcAnswr) mcAnswr = mcAnswr.replace(/[\,]/g, ", ");
			this.sObj.type = this.ctx.type;
		} else if(this.ctx.type == "full")
		{
			mcAnswr = this.ctx.answer ? this.ctx.answer : "T";
			this.sObj.type = "mc"; 
		} else {
			mcAnswr = "";
			this.sObj.type = this.ctx.type;
		}
		this.sObj.mcAnswer = mcAnswr;
		super.showComponentScore();
	}

	private updateSelectionSelection():void
	{
		/*
		this.selectorOptions.section.options.forEach((element) => {
			// element.
		});
		*/
	}

	private updateModeSelection():void
	{
		/*
		this.selectorOptions.mode.options.forEach((o:any)=>{
			if(o.type == this.ctx.type) 
			{
				if(o.type == "mc")
				{
					o.selected = o.mcCount == this.ctx.mcCount;
				} else {
					o.selected = true;
				}
			} else {
				o.selected = false;
			}
		})
		*/
	}

	private updateMCSelection():void
	{
		var mc:any = this.selectorOptions.mc;
		mc.enabled = this.ctx.type == "mc";
		mc.multiSelect = mc.enabled && this.ctx.multiSelect ? true : false;
		mc.options = [];
		var answers:any[] = this.ctx.mcAnswer ? this.ctx.mcAnswer.split(",") : [];
		for(var i = 0;i < this.ctx.mcCount;i++)
		{	
			var label:string = String.fromCharCode(65 + i);
			mc.options.push({
				selected:answers.indexOf(label) != -1,
				label:label,
				value:label,
				index:i
			});
		}
	}

	private updateTFSelection():void
	{
		var mc:any = this.selectorOptions.tf;
		mc.enabled = this.ctx.type == "full";
		mc.multiSelect = mc.enabled && this.ctx.multiSelect ? true : false;
		mc.options = [];
		if(!this.ctx.answer)
			this.ctx.answer = "T";
		var answer:string = this.ctx.answer;
		var list = ["T", "F"];
		list.forEach((label, index)=>{
			mc.options.push({
				selected:answer == "label",
				label:label,
				value:label,
				index:index
			});
		});
	}

	private saveContext():void
	{
		this.node.setAttribute("context", JSON.stringify(this.ctx));
	}
	

	
	public getSectionSelection():any
	{
		if(!this.selectorOptions.hasOwnProperty("section"))
		{
			var translateService:TranslateService = this.context.service.translateService;
			var section:any = {
				enabled:true,
				key:"q.section",
				name:this.context.service.translateService.instant("ro.components.common.section"),
				type:"options",
				options:[
	
				]
			};
			var aString:string = "A";
			var a:number = aString.charCodeAt(0);
			section.options.push({
				value:null,
				valueLabel:translateService.instant("commonService.na"), // selected value display
				label:translateService.instant("commonService.no-value") // selection label
			});
			for(var i = 0;i < 26; i++)
			{
				var s:string = String.fromCharCode(a + i);
				var color:number = SectionColorCode.color[s];
				section.options.push({
					/*
					labelStyle:{
						color:color,
					},
					*/
					value:s,
					label:s
				});
			}
			this.selectorOptions.section = section;
		}
		this.updateSelectionSelection();
		return this.selectorOptions.section;
	}
	public getModeSelection():any
	{
		if(!this.selectorOptions.hasOwnProperty("mode"))
		{
			this.selectorOptions.mode =  {
				enabled:true,
				key:"type",
				// name:"type",
				name:this.context.service.translateService.instant("ro.components.score-com.type"),
				type:"options",
				options:[
					{key:"type-tf", type:"full", value:"full"},
					{key:"type-free", type:"free", value:"free"},
					{key:"type-mc-2", type:"mc", value:"mc_a_b", mcCount:2},
					{key:"type-mc-3", type:"mc", value:"mc_a_c", mcCount:3},
					{key:"type-mc-4", type:"mc", value:"mc_a_d", mcCount:4},
					{key:"type-mc-5", type:"mc", value:"mc_a_e", mcCount:5},
					{key:"type-mc-6", type:"mc", value:"mc_a_f", mcCount:6}
				]
			};
			this.selectorOptions.mode.options.forEach((o:any)=>{
				var key:string = "ro.components.score-com."+o.key;
				o.label = this.context.service.translateService.instant(key);
			})
		}
		this.updateModeSelection();
		return this.selectorOptions.mode;
		
	}
	
	public getTFSelection():any
	{
		if(!this.selectorOptions.hasOwnProperty("tf"))
		{
			this.selectorOptions.tf = {
				key:"tf-type",
				// name:"MC",
				name:this.context.service.translateService.instant("ro.components.score-com.answer"),
				type:"options",
				options:null
			};
		}
		this.updateTFSelection();
		return this.selectorOptions.tf;
	}
	public getMCSelection():any
	{
		if(!this.selectorOptions.hasOwnProperty("mc"))
		{
			this.selectorOptions.mc = {
				key:"mc-type",
				// name:"MC",
				name:this.context.service.translateService.instant("ro.components.score-com.answer"),
				type:"options",
				options:null
			};
		}
		
		this.updateMCSelection();
		return this.selectorOptions.mc;
	}
	
	public getMultiSelectSelection():any
	{
		var multiSelect:any;
		if(!this.selectorOptions.hasOwnProperty("multiSelect"))
		{
			multiSelect  = {
				key:"multi-select",
				multiSelect:true,
				name:this.context.service.translateService.instant("ro.components.score-com.multi-select"),
				type:"boolean"
			};
			this.selectorOptions.multiSelect = multiSelect;
		}
		multiSelect = this.selectorOptions.multiSelect;
		multiSelect.enabled = this.ctx.type == "mc";
		multiSelect.value = this.ctx.multiSelect ? true: false;
		return multiSelect;
	}
	public getPopupSelector():any []
	{
		var selectionSelection:any = this.getSectionSelection();
		var modeSelection:any = this.getModeSelection();
		var mcSelection:any = this.getMCSelection();
		var tfSelection:any = this.getTFSelection();
		var multiSelectSelection:any = this.getMultiSelectSelection();
		var learningObjective:string = this.node.getAttribute("learningObjective", "[]");
		return [
			{
				type:'name',
				translate:false,
				name:this.getTitleName()
			},
			selectionSelection,
			modeSelection,
			mcSelection,
			tfSelection,
			multiSelectSelection,
			{
				name:this.context.service.translateService.instant("ro.components.common.score"),
				key:"score",
				type:"number",
				target:"component",
				max:100,
				min:0.5,
				value:0,
				step:0.5,
				dp:1
			},
			{
				type:"action",
				key:"learningObjective",
				action:"learningObjective",
				target:"editor",
				text:this.context.service.translateService.instant("ro.components.common.set-learning-objective"),
				learningObjective:JSON.parse(learningObjective)
			},
			{
				type:"action",
				action:"duplicate",
				target:"editor",
				icon:SelectMarkerIcon.DUPLICATE_ICON
			},
			{
				type:"action",
				action:"remove",
				target:"editor",
				icon:SelectMarkerIcon.DELETE_ICON
			}
		];
	}
	
	public getTagNames():string []
	{
		return ["ScoreCom"];
	}

	protected buildQuestionAndScoreObject():void
	{
		if(this.node.attributes.hasOwnProperty("q") || this.node.attributes.hasOwnProperty("s")) {
			super.buildQuestionAndScoreObject();
			return;
		}
		// default node 加左，應行不到這裡
		/*
		let xmlNode:XMLNode = new XMLJSNode().assign(
			{
				attributes: {
					"optional":false,"x":0,"y":0,"offset":{"y":0,"x":0},"enable":true,"reference":"rt","freezed":1
				},
				tag:"QuestionScore"
			}
		);

		this.sObj = <ROQuestionScore> this.context.createForEF(this.elementRef, xmlNode, this.page, this);
		*/
	}
	
	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"coordinateExpression": "UA UK KW KH X 0 Y 0 D T 0 L 0 H 60 W 60",
				"hasScoring": true,
				"scoringType": 1,
				"scoreN": 1,
				"unitScore": 1,
				"fullScore": 1,
				"q": "{\"x\":-60,\"color\":\"noColor\",\"show\":true,\"level\":0,\"y\":0,\"type\":\"mini\",\"ballSize\":36,\"freezed\":1}",
				"s": "{\"optional\":false,\"x\":0,\"y\":0,\"offset\":{\"y\":0,\"x\":0},\"enable\":true,\"reference\":\"rt\",\"freezed\":1}",
				"isQuestionComponent": true,
				"locked": false,
				"questionIndex": 0,
				//"qInfo": "{"rootIndex":3,"pid":0,"level":0,"id":10,"order":5,"index":3,"root":5}",
				"context": "{\"mcCount\":4,\"type\":\"free\"}",
				"douid": this.createDouid()
			},
			tag:"ScoreCom"
		});
		this.node.createElement();
	}
	
	public mcAnswer:any = "";
	public score:any = "";
	public ctx:any; // "context": "{"mcCount":4,"type":"mc"}",
	public size:number;
	protected buildContent():void
	{
		var options:any = this.node.attributes; // this.context.context;
		this.ctx = options.context ? JSON.parse(options.context) : {
			type:"free", // 可設定"模式 / Type"，可選"對錯 / Correct Wrong"或"自由分數 / Free"兩種模式 (屬性欄中只有此設定) 若目前是電
			mcCount:2
		};
		this.size = 40;
		this.w = this.size;
		this.h = this.size;
		this.assignWH();
		
		// this.sObj.type = this.ctx.type;

		// this.type = ctx.type;

		/*
		{
			"coordinateExpression": "UA UK KW KH X 762 Y 490 D T 490 L 762 H 40 W 40",
			"hasScoring": true,
			"scoringType": 1,
			"scoreN": 1,
			"unitScore": 3,
			"fullScore": 3,
			"q": "{"color":"noColor","show":true,"level":0,"freezed":1,"y":-10,"x":-60}",
			"s": "{"enable":1,"freezed":1,"y":-10,"optional":true,"x":-10}",
			"isQuestionComponent": true,
			"locked": false,
			"questionIndex": 3,
			"qInfo": "{"rootIndex":3,"pid":0,"level":0,"id":10,"order":5,"index":3,"root":5}",
			"context": "{"mcCount":4,"type":"mc"}",
			"douid": "37FF31B2-06D9-6615-7A29-773C4CB3FED6"
		}
		*/
		// this.showComponentScore();
		if(this.sObj) this.sObj.type = this.ctx.type;
	}
	

	// =========================
	// data function
	// =========================
	public reset():void {
		
	}
	public tmpData:string;

	public set data(value:string) {
		this.tmpData = value;
		try {
			this.correctionAns = JSON.parse(this.tmpData);
		} catch(e) {
			
		}
		if(!this.correctionAns)
			this.correctionAns = {};
		if(!this.correctionAns.hasOwnProperty("x") || isNaN(this.correctionAns.x) || isNaN(this.correctionAns.y)) {
			var ceString:string = this.node.getAttribute("coordinateExpression");
			if(!ceString) {
				// 只用一般座標
				var options:any = this.node.attributes;
				this.correctionAns.x = parseInt(options.x);
				this.correctionAns.y = parseInt(options.y);
			} else {
				var ce:CoordinateExpression = this.parseCE(ceString);
				if(ce) {
					this.correctionAns.x = ce.getX();
					this.correctionAns.y = ce.getY();
				}
			}
		}
		this.setPosition(this.correctionAns.x, this.correctionAns.y);

		if(this.context.config.viewMode == "correction" && this.context.config.subViewMode == "correction") {
			// 隱藏分數組件
			this.elementRef.nativeElement.style.display = "none";

		} else {
			var coms:any[] = (this.page as ROPageComponent).dataComponents;
			for(let i:number=0; i<coms.length; i++) {
				var c:any = coms[i];
				if(c instanceof ROExamPaper) {
					// 如果無 exam pape 資料，隱藏分數組件
					this.elementRef.nativeElement.style.display = c.data!=null ? "block" : "none";
					break;
				}
			}

		}

		if(this.context.config.viewMode != "edit") {
			this.elementRef.nativeElement.style.zIndex = 1;
		}
	}
	
	protected onAnswerSet() {
		this.sObj.updateScoreCanMoveState();

		if(this.tmpData)
		{
			if(["scoring","correction","finished"].indexOf(this.context.config.viewMode)>=0 && (this.resultObject.correction || this.correctionAns.hasOwnProperty("epenData"))) {
				// 改正/完成模式時要更新 xy
				if(this.correctionAns.hasOwnProperty("x"))
					this.setPosition(this.correctionAns.x, this.correctionAns.y);
			}
			this.sObj.showResult(this.resultObject);
			/*
			if(this.resultObject)
			{
				this.sObj.showScoring(true, "view", this.resultObject);
			} else {
				this.sObj.showScoring(true, "view", this.resultObject);
			}
			*/
		} else {
			// mcAnswer
			this.sObj.showScore(-1, -1);
			this.sObj.mcAnswer = "";
		}
		// 
			/*
		
		if(this.tmpData)
		{
			if(this.resultObject)
			{
				this.showScoring(this.resultObject.score)
				// this.sObj.showScoring(true, "view", this.resultObject);
			} else {
				this.showScoring(null);
				// this.showScoring(this.resultObject.correct, this.resultObject.score)
				// this.sObj.showScoring(true, "view", this.resultObject);
			}
		} else {
			this.showScoring({score:0, type:-1});
			// this.sObj.showScore(-1, -1);
			
		}
		*/
	}
	/*
	private showScoring(obj:any):void
	{
		this.sObj.showScoring(true, "view", this.resultObject);
		if(obj)
		{
			this.score = obj.hasOwnProperty("score") ? obj.score ? "?";
		} else {
			this.score = "?";
		}
		
	}
	*/
	public get data():string
	{
//		if(this.answerChanged)
//			this.sObj.saveCorrection();
		this.tmpData = JSON.stringify(this.correctionAns);
		return this.tmpData;
	}

	public verify(_showScoring:boolean):any {
		// reset verify
		if(_showScoring)
			this.sObj.showResult(this.resultObject);
		else 
			this.sObj.hideScore();
		return this.resultObject;
	}

	// =========================
	// component type
	// =========================
	// 題目組件：有資料、計分/不計分、有/無對錯、出成績表
	public isQuestionComponent():boolean
	{
		return true;
	}
	public showDefaultAnswer(): void {
		super.showDefaultAnswer();
		this.elementRef.nativeElement.style.display = 'none';
	}
	public hideDefaultAnswer(): void {
		super.hideDefaultAnswer();
		this.elementRef.nativeElement.style.display = null;
	}

	public dataChanging():void {
		this.context.subject.next({
			type:"componentDataChanging",
			target:this
		});
	}

	public dataChanged():void {
		this.answerChanged = true;
		this.context.subject.next({
			type:"componentDataChanged",
			target:this
		});
	}
}