import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ColorPickerComponent } from "../colorPickerModule/color-picker.component";
import { PropertyPanelIcon } from "./propertyPanelIcon";
import { interval } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fromEvent } from "rxjs";
import { delay } from "rxjs/operators";
import { InlineSvgService } from 'src/app/sharedModule/inlineSvgModule/inlineSvg.service';


@Component({
	selector: `propertyPanel`,
	templateUrl: './propertyPanel.component.html',
	styleUrls: ['./propertyPanel.component.scss']
})

export class PropertyPanelComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChildren('itemEle') itemEles: QueryList<ElementRef>;
	@ViewChild('backgroundPicker', {static:false}) backgroundPicker: ColorPickerComponent;

	// icons
	public textIcon = PropertyPanelIcon.TEXT_ICON;
	public minusIcon = PropertyPanelIcon.MINUS_ICON;
	public plusIcon = PropertyPanelIcon.PLUS_ICON;
	public boldIcon = PropertyPanelIcon.BOLD_ICON;
	public italicIcon = PropertyPanelIcon.ITALIC_ICON;
	public underlineIcon = PropertyPanelIcon.UNDERLINE_ICON;
	public strikethroughIcon = PropertyPanelIcon.STRIKETHROUGH_ICON;
	public alignLeftIcon = PropertyPanelIcon.ALIGN_LEFT_ICON;
	public alignCenterIcon = PropertyPanelIcon.ALIGN_CENTER_ICON;
	public alignRightIcon = PropertyPanelIcon.ALIGN_RIGHT_ICON;
	public alignJustifyIcon = PropertyPanelIcon.ALIGN_JUSTIFY_ICON;
	public paletteIcon = PropertyPanelIcon.PALETTE_ICON;
	public volumeIcon = PropertyPanelIcon.VOLUME_ICON;
	public lockOpenIcon = PropertyPanelIcon.LOCK_OPEN_ICON;
	public lockCloseIcon = PropertyPanelIcon.LOCK_CLOSE_ICON;

	// input
	@Input() headerStyle: any = {}
	@Input() headerTitle: string = ""
	@Input() contentStyle: any = {}
	@Input() width:string = "220px"
	@Input() items: any[] = []
	@Input() selectedItems: any[] = []
	@Input() isHide: boolean = false
	@Input() tabs: any[] = []

	// params
	public backgroundColor: string = ""
	public borderTopColor: string = ""
	public tabsColor: string = ""
	public selectedTab: string = ""
	public isFirstInit = true
	public isSvgLoaded = false

	constructor(public translate: TranslateService, private sanitization: DomSanitizer, public svgReg:InlineSvgService) {

	}

	ngOnInit(): void {
		this.svgReg.regSvgFiles([
			{url: 'fileicon/property_btn_coordinate.svg', name: 'property_btn_coordinate'},
			{url: 'fileicon/property_btn_coordinate_darkgreen.svg', name: 'property_btn_coordinate_darkgreen'}
		]).then (() => {
			this.isSvgLoaded = true;
		})
		
		this.initPanel();
		this.isFirstInit = false;
	}

	ngOnDestroy(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['contentStyle'] && !this.isFirstInit) {
			this.initPanel()
		}
	}

	public initPanel() {
		this.backgroundColor = this.contentStyle.backgroundColor || ""
		this.borderTopColor = this.headerStyle.backgroundColor || ""
		this.tabsColor = this.headerStyle.backgroundColor || ""
		if (this.tabs && this.tabs.length > 0 && !this.tabs.some(tab => tab.tab === this.selectedTab)) {
			this.selectedTab = this.tabs[0].tab
			// this.canShowSome(this.selectedTab)
		}
		this.canShowSome(this.selectedTab)
	}
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// UI functions
	public removeHashFromContent(item: any) {
		if (item.content) {
			if (item.content.startsWith("#")) {
				return item.content.replace(/#/g, '').toUpperCase();
			} else {
				return this.translate.instant(item.content)
			}
		}
		return ""
	} 

	public openColorPicker() {
		if (this.backgroundPicker) {
			this.backgroundPicker.onClick()
		}
	}

	public buttonDown(item:any, parameter:any)
	{
		const source = interval(100);
		const timer$ = fromEvent(document.body, "pointerup");
		this.updateNumber(item, parameter);
		source.pipe(delay(500)).pipe(takeUntil(timer$)).subscribe((o:any)=>{
			this.updateNumber(item, parameter);
		})
	}

	public updateNumber(item:any, parameter:any):void {
		var tmpValue:number = item.value;
		var stepSize = item.step ? item.step :1;
		if(parameter == "-") tmpValue -= stepSize;
		else if(parameter == "+") tmpValue += stepSize;
		else if(parameter == "update") tmpValue = item.value;
		if(tmpValue === null || tmpValue === undefined) return;
		tmpValue = parseFloat(tmpValue.toFixed(item.dp));
		if(tmpValue > item.max) tmpValue = item.max;
		else if(tmpValue < item.min) tmpValue = item.min;
		item.value = tmpValue;
		item.callback(tmpValue);
	}

	public updateDoubleNumber(item:any, numberLabel:any):void {
		var tmpValue:number = item[`value${numberLabel}`];
		if(tmpValue === null || tmpValue === undefined) return;
		tmpValue = parseFloat(tmpValue.toFixed(item.dp));
		if(tmpValue > item.max) tmpValue = item.max;
		else if(tmpValue < item.min) tmpValue = item.min;
		item[`value${numberLabel}`] = tmpValue;
		item.callback(tmpValue, numberLabel);
	}

	public isStringIcon(icon: any) {
		return typeof icon === 'string'
	}

	public checkSelectedSvgIcon(icon: any, isSelected: boolean) {
		return isSelected ? `${icon}_darkgreen` : icon
	}
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	/** trigger the call back function */
	public onItemClick(item: any, event: Event, itemEle: ElementRef) {
		if (item.disableWhen && item.disableWhen()) return 
		if (["pulldown", "switch", "colorPicker"].includes(item.type)) return
		
		if (item.multiple || this.selectedItems.length == 1) {
			item.callback(event, item, itemEle)
		}
	}
	
	/** check if all items can show */
	public canShowSome(tab: string = ''): boolean {
		const showSome = this.items.length > 0 && this.items.some(item => (!item.showWhen || item.showWhen(tab)));
		this.isHide = !showSome
		return showSome
	}
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// color picker
	public onCloseColorPicker() {
		if (this.backgroundPicker) {
			this.backgroundPicker.onClose()
		}
	}
}