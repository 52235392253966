import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from "@angular/core";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { PinYinUtils } from "./PinYinUtils";
import {  ROComponent, ROTLFTextComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { TextFlowUtils } from "./TextFlowUtils";
import { XMLNode } from "./xml/XMLNode";

@Component({
	// template:`Ball{{node.attributes|json}}`
	template:
		`
		<div class="textflow">
			<div class="p" *ngFor="let line of lines">
				<div 
					*ngFor="let a of line.match" 
					class="pinyin-element"
					[style.width.px]="a.width"
					>
					<div class="pinyin pinyin-old"><div [class]="pinyinFontFamily">{{a.pinyin}}</div></div>
					<div class="text"  ><div [class]="textFontFamily">{{a.text}}</div></div>
				</div>
			</div>
		</div>
				
			
		`,
		styles:[
			`
			.pinyin{
				color:var(--pinyin-color);
				font-size:var(--pinyin-size);
			}
			.text{
				font-size:var(--text-size);
				color:var(--text-color);
			}
			`
		]
	/**
	 
	{
		"width":129,"height":78,
		"coordinateExpression":"UA UK X 559 Y 427 D T 427 L 559 H 78.35 W 95.85",
		"scrollEnabled":false,"writing":true,"transparency":100,"memo":false,
		"pinyinFontSize":23,"stripSpace":true,"tabStop":1,"tabSpacing":30,
		"textFontSize":40,
		"text":"腳(jiɑ̌o)","baselineShift":-7,
		"locked":false,"version":2,"leading":100,
		"letterSpacing":0,"wordShift":55,"rScale":0.6,
		"toneColor":42321,"pinyinFont":"Arial PinYin","pinyinColor":29115,
		"wordFontSize":40,"wordFont":"DFHKStdKai-B5","wordColor":0,"pinyin":"null",
		"enableTone":false,"isArticle":false,"secondLineIndent":0,"isFullArticle":false,
		"douid":"C24B4222-D389-4D36-DE07-8944E1597EFA","isQuestionComponent":true,"w":95.85,"h":78.35}
	 */
	
})
export class ROPinYinOld extends ROComponent
{

	public pinyinColor:string;
	public textColor:string;
	public textFontFamily:string;
	public pinyinFontFamily:string;
	// public text:string;
	// public match:any [];
	public lines:any [];
	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}
	
	public getTagNames():string []
	{
		return ["PinYin"];
	}
	private convertPinYin(pinyin:string):string
	{
		// ɑ̌=>ǎ xiɑ̌o
		if(!pinyin) return "";
			
		pinyin = PinYinUtils.convertAreverse(pinyin);
		pinyin = PinYinUtils.convertEIreverse(pinyin);
		return pinyin;
	}
	protected initVariables():void
	{
		
		/*
		{
			"width":550,"height":207,
			"coordinateExpression":"UA UK X 310.85 Y 232.85 D T 232.85 L 310.85 H 385.55 W 498.35",
			"scrollEnabled":false,"writing":true,
			"transparency":100,"memo":false,"pinyinFontSize":23,
			"stripSpace":true,"tabStop":30,"tabSpacing":30,
			"textFontSize":38,
			text":"一(Yì)棵(kē)小(xiɑ̌o)樹(shù)五(wǔ)個(gè)杈(chɑ̀)，&#xD;沒(Méi)有(yǒu)葉(yè)子(zi)沒(méi)有(yǒu)花(huɑ̄)，&#xD;兄(Xiōng)弟(di)合(hé)作(zuò)真(zhēn)靈(líng)巧(qiɑ̌o)，&#xD;寫(Xiě)字(zì)幹(gɑ̀n)活(huó)全(quɑ́n)靠(kɑ̀o)它(tɑ̄)。",
			"baselineShift":-7,"locked":false,"version":2,
			"leading":100,"letterSpacing":0,"wordShift":55,
			"rScale":0.6,"toneColor":65280,
			"pinyinFont":"Arial PinYin",
			"pinyinColor":29115,"wordFontSize":40,
			"wordFont":"DFHKStdKai-B5","wordColor":0,
			"pinyin":"null","enableTone":false,
			"isArticle":false,"secondLineIndent":0,"isFullArticle":false,
			"douid":"E128A8AB-9DBA-6C20-7C10-4DBA65B9D004",
			isQuestionComponent":true,"w":498.35,"h":385.55}-->

			100 -> 150
			200 ->  350

		*/
		
		super.initVariables();
		
		var options:any = this.node.attributes;
		
		
		
		var dom:HTMLElement = this.elementRef.nativeElement;

		
		this.textFontFamily  = options.wordFont;
		this.pinyinFontFamily  = options.pinyinFont;
	
		var fontSize:number = options.wordFontSize;
		
		
		this.pinyinColor = StyleUtils.colorCodeToHex(options.pinyinColor);
		this.textColor = StyleUtils.colorCodeToHex(options.wordColor);
		if(this.pinyinColor) StyleUtils.setStyleVariable(dom, "pinyin-color", this.pinyinColor);
		if(this.textColor) StyleUtils.setStyleVariable(dom, "text-color", this.textColor);
		
		// StyleUtils.setStyleVariable(dom, "pinyin-letter-spacing",  (options.tabSpacing + options.letterSpacing) +"px")
		StyleUtils.setStyleVariable(dom, "pinyin-letter-spacing",  options.tabStop +"px")
		StyleUtils.setStyleVariable(dom, "text-size", options.wordFontSize +"px")
		StyleUtils.setStyleVariable(dom, "pinyin-size", options.pinyinFontSize +"px")
		StyleUtils.setStyleVariable(dom, "text-block-height", options.textFontSize +"px")
		
		var text = this.node.getAttribute("text");
		this.lines = text.split("&#xD;").map((text:string)=>{
			var reg = /(.*?)\((.*?)\)|(.*)/g;
			return { 
				match:ArrayUtils.exec(reg, text).map((m:any)=>{
					var size:number;
					if(m[3])
					{

						return {
							width:this.getTextLength(m[3]) * options.wordFontSize, 
							text:m[3],
							pinyin:""
						};
					} else {
						
						return {
							width:this.getTextLength(m[1]) * options.wordFontSize + 20, 
							text:m[1],
							pinyin:this.convertPinYin(m[2])
						};
					}
				})
			};
		})
	}
	private getTextLength(text:string):number
	{
		if(text)
		{
			return text.length;
		}
		return 1;
	}

}