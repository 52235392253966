import { ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewContainerRef } from "@angular/core";
import { ROComponent, ROPageComponent } from "./ROComponent";

import { SafeStyle } from "@angular/platform-browser";
import { XMLNode } from "./xml/XMLNode";
import { XMLJSNode } from "./xml/XMLParser";
import { PromiseUtils } from "src/app/common/PromiseUtils";
import { ROBookConfig } from "./ROBookConfig";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, first } from "rxjs/operators";
import { faRotateRight } from "@fortawesome/pro-regular-svg-icons";

@Component({
	template:`
	<div class="innerContent" [style.backgroundImage]="imageURLStyle"></div>
	<div *ngIf="absent!=null" class="absent">{{absent}}</div>
	<!-- <fa-icon class="reload" [icon]="faRotateRight" (tap)="reload()"></fa-icon> -->
	<!-- <div class="info">{{pdfAssetURL}}</div> -->
	`,
	styles:[
		`
		.info{
			position: absolute;
			left:0px;
			top:0px;
			z-index:9999;
		}
		:host{
			border:1px solid #cccccc;
			position: relative;
		}
		.innerContent{
			width:100%;
			height:100%;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		}
		.ans{
			position:absolute;
			top:0;
		}
		.hide{display:none;}
		.absent{
			position:absolute;
			width:96px;
			height:36px;
			border-radius:5px;
			background-color:#df3354;
			font-size:20px;
			text-align:center;
			font-weight:bold;
			color:#fff;
			top:10px;
			left:10px;
			line-height:36px;
		}
		.reload{
			position:absolute;
			top:0;
			right:20px;
			width:40px;
			height:40px;
			border: solid 1px #000;
			border-radius:5px;
			background-color:#fff;
			display:flex;
			color:#000;
			z-index:1;
		}
		::ng-deep svg{margin:auto}
		`
	]
})

export class ROExamPaper extends ROComponent
{
	public faRotateRight:any = faRotateRight;
	public ref:any;
	public studentPDF:HTMLElement;
	public bgPDF:HTMLElement;
	private definition:any;

	public absent:string;

	protected answerPageAsset:any;
	protected blankPageAsset:any;
	protected currentBGAsset:any;
	public loadingSubject:Subject<any>;
	private ready:boolean = false;
	public onReadyObserable:Observable<any>;
	private subscription:Subscription;

	public studentAnsURLStyle:SafeStyle;
	protected ansURLStyle:SafeStyle;
	public bgURLStyle:SafeStyle;
	public imageURLStyle:SafeStyle;
	public rawAssetURL:string;
	public imageAssetURL:string;
	public pdfAssetURL:string;
	protected useImageAsset:boolean = false;

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
		this.loadingSubject = new Subject();
	
		this.onReadyObserable = this.loadingSubject.pipe(debounceTime(50));
		this.onReadyObserable.subscribe(()=>{
			this.ready = true;
		});
		
	}

	loadImage(url:string):Promise<any>
	{
		return new Promise((resolve, reject)=>{
			var img = document.createElement("img");

			img.onload = function() { // trigger if the image was loaded
				// console.log($(this).attr('src') + ' - done!');
				resolve(null);
			}
			
			img.onerror = function() { // trigger if the image wasn't loaded
				// console.log($(this).attr('src') + ' - error!');
				reject(null);
			}
			img.src = url;
			/*
			img.onAbort = function() { // trigger if the image load was abort
				console.log($(this).attr('src') + ' - abort!');
			}
			*/
		})
		
	}
	private initSubscription():void
	{
		if(this.subscription)
		{
			this.subscription.unsubscribe();
		}
		this.subscription = new Subscription();
	}
	
	
	public isLocked():boolean
	{
		return true;
	}
	

	public getTagNames():string []
	{
		return ["ExamPaper"];
	}

	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"ver": "1.0",
				"hasScoring": false,
				"douid": this.createDouid(),
				"coordinateExpression": "UA UK KR X 0 Y 0 D T 0 L 0 W 383 H 268",
//				"q": "{\"x\":-60,\"color\":43163,\"show\":false,\"level\":1,\"y\":0,\"type\":\"auto\",\"ballSize\":36,\"freezed\":1}",
//				"s": "{\"y\":0,\"optional\":false,\"x\":383,\"offset\":{\"y\":0,\"x\":0},\"enable\":false,\"reference\":\"rt\",\"freezed\":1}",
//				"isQuestionComponent": true,
				"isQuestionComponent": false,
				"locked": false,
				"questionIndex": 0
				//"qInfo": "{\"index\":0,\"rootIndex\":0,\"pid\":5,\"level\":1,\"name\":\"a\",\"root\":0,\"order\":0,\"id\":3}"
			},
			tag:"ExamPaper"
		});
		this.node.createElement();
	}

	protected buildContent(): void {
		if(this.qObj)
			this.qObj.hide();
		
//		var options:any = this.node.attributes;
//		var dom:HTMLElement = this.elementRef.nativeElement;
//		var style:CSSStyleDeclaration = dom.style;
//		var src:string= this.node.getAttribute("src");
		
		var pdfURL:any = this.node.getAttribute("pdfSrc");
		if(pdfURL) {
			this.answerPageAsset = {
				type:"pdf",
				page:this.node.getAttribute("pdfPage"),
				url:pdfURL
			};
		}
		pdfURL = this.node.getAttribute("pdf2Src");
		if(pdfURL) {
			this.blankPageAsset = {
				type:"pdf",
				page:this.node.getAttribute("pdf2Page"),
				url:pdfURL
			};
		}


		var mode:string = this.context.config.viewMode;
		var asset;
		if(this.answerPageAsset) {
			asset = this.answerPageAsset;
			if(mode != "scoring" && mode != "submisssion_review" && mode != "edit" && (!this.viewerIsStudent() && mode != "view" && mode != "preview")) {
				// 學生做改正時右邊是顯示空白卷
				asset = this.blankPageAsset; // 無空白卷就什麼都不顯示 / 無答案的卷
			}
//		} else if(src)
//		{
//			asset = {
//				type:"image",
//				url:src
//			};
		}
		
		/**
		 	pdf2Page :  1
			pdf2Src :  "OKG://id/44683446/checksum/AB2D78F0/len/2569304/token/02B1BDBC/type/1/server/1/title/3.permissions.pdf"
			pdfPage :  1
			pdfSrc :  "OKG://id/44683447/checksum/AB2D78F0/len/2569304/token/A9E04BAB/type/1/server/1/title/3.permissions.pdf"
		 */
		//style.backgroundSize = "contain";
		//if(options.alpha != 1)
		//	style.opacity = options.alpha;
		
		if(!asset) return;
		
		this.definition = {};

		if(!this.bgPDF) this.bgPDF = document.createElement("div");

		if(asset.type = "pdf" && (mode == "edit" || (!this.viewerIsStudent() && (mode == "view" || mode == "preview"))))
		{
			// this.renderPDF(asset, this.bgPDF, this.context.config.viewMode == "scoring");// scoring 時，default 是不顯示
			this.renderPDF(asset, this.bgPDF, false);// scoring 時，default 是不顯示
		}
	}

	renderImage(asset:any):void{
		this.lookupAsset(asset.url).then((url)=>{
			return this.bypassSecurityTrustStyle(url)
		}).then((trustedURL:string)=>{
			this.bgURLStyle = trustedURL;
		});
	}
	/*
	private readURL(url:string):Promise<any>
	{
		return new Promise((resolve, reject)=>{
			var request = new XMLHttpRequest();
			// request.setRequestHeader("Cache-Control", "no-cache");
			request.open('GET', url, true);
			// request.setRequestHeader("Cache-Control", "max-age=0");
			request.responseType = 'blob';
			request.onload = function() {
				var reader = new FileReader();
				reader.readAsArrayBuffer(request.response);
				reader.onload =  function(e){
					resolve(e.target.result);
					// console.log('DataURL:', e.target.result);
				};
			};
			request.send();
		});
		
	}
	*/
	retry = null;
	renderPDF(asset:any, pdfPageContainer:HTMLElement, hide:boolean):Promise<any>
	{
		
		this.initSubscription();
		if(!asset)
			return Promise.resolve(null);
		if(this.bgPDF == pdfPageContainer) {
			this.currentBGAsset = asset;
		}

		if(pdfPageContainer)pdfPageContainer.innerHTML = "";
		// pdfPageContainer.replaceChildren();
		this.hideTarget(pdfPageContainer, hide);

		// 老師只顯示教師答案, 
		// 學生改正時，左邊 show 學生答案，右邊 show 沒作答頁 (當需要改正時)
		// return this.context.service.pdfService.load().then((definition:any)=>{
		// 	this.definition = definition;

		var p:Promise<any> = this.lookupAsset(asset.url).then((url:string)=>{
//			console.log(">>pdf assets path get", this.douid);
			this.rawAssetURL = url;
			this.pdfAssetURL = url;
			return this.context.service.pdfService.getDocument(url);
		}).then((pdfDocument)=>{
//			console.log(">>pdf doc get", this.douid);
			this.definition = this.context.service.pdfService.definition;
			var pageNo:number = asset.page;
			if (pdfDocument && pdfDocument._pdfInfo && pageNo > pdfDocument._pdfInfo.numPages){
				pageNo = 1; //取代文件時pdf只有1頁，但pageNo不是1，未明原因。暫這样解決
			}
			return pdfDocument.getPage(pageNo).then((page) => {
//				console.log(">>pdf page get", this.douid);
				// you can now use *page* here
				
				var ret = this.renderPDFPageAsHTML(pdfPageContainer, page, pageNo);
//				console.log(ret, this.douid);
				return ret;
			});
		}).catch((reason)=>{
			// this.context.service.alertService.alert("Error");
			/*
			this.context.service.alertService.alert("Err");
			console.error("pdf error", reason);
			if(reason && reason.message)
			{
				console.error("error message", reason.message);
			}
			if(reason && reason.stack)
			{
				console.error("error stack", reason.stack);
			}
			*/
			this.context.service.pdfService.promiseQueue.add(()=>{
				return this.delay(1000).then(()=>{
					if(this.retry){
						this.retry();
						this.retry = null;
					}
				})
			});
		})
		var pageComponent:ROPageComponent = (<ROPageComponent> this.page);
		if(!pageComponent.printing)
		{
			var key:string = "exam-paper-"+Math.random();
			var remove:Function = this.context.service.loadingService.add(key);
			p.finally(()=>{
				remove();
			});
		}
		return p;
	}
	private delay(time:number):Promise<any>
	{
		return new Promise((resolve)=>{
			setTimeout(()=>{
				resolve(null);
			}, time)
		})
	}

	private renderPDFPageAsHTML(container:HTMLElement, pdfPage, pageNo):Promise<any>
	{
		return this.context.service.pdfService.promiseQueue.add(()=>{
			return this.renderPDFPageAsHTML2(container, pdfPage, pageNo);
		});
	}
	private renderPDFPageAsHTML2(container:HTMLElement, pdfPage, pageNo):Promise<any>
	{
		var eventBus = new this.definition.pdfjsViewer.EventBus();
		var div:HTMLElement = container;
		div.style.position = "absolute";
		div.style.left = "0px";
		div.style.top = "0px";
		div.style.transformOrigin = "top left";
		var pdfPageContainer:HTMLElement = document.createElement("div");
//		var dom = this.elementRef.nativeElement;
//		dom.appendChild(div);
		// div.appendChild(pdfPageContainer);
		div.prepend(pdfPageContainer);
		var viewport = pdfPage.getViewport({ scale: 1});
		var scaleX = this.page.w / viewport.width;
		var scaleY = this.page.h / viewport.height;
		var scale = Math.min(scaleX, scaleY);
		// var scale = 1;
		var paperScale = 1.5;
		var paperScaleFraction = 1 / paperScale;
		div.style.pointerEvents  = `none`;
		div.style.transform  = `scale(${paperScaleFraction})`;
		var config = {
			container:pdfPageContainer,
			id: pageNo,
			scale:1,
			defaultViewport: viewport,
			eventBus
		};
		
		// const pdfPageView = new this.definition.pdfjsViewer.PDFPageView(config);
		const pdfPageView = new this.definition.pdfjsViewer.PDFPageView(config);
		  // Associate the actual page with the view, and draw it.
		// pdfPageView.scale = 0.5;
		pdfPageView.setPdfPage(pdfPage);
		// this.elementRef.nativeElement.appendChild(div);
		pdfPageView.update({scale:0.75 * paperScale});
		this.subscription.add(()=>{
			// pdfPageView.destroy();
			if(pdfPageView.renderTask) pdfPageView.renderTask.cancel();
			pdfPage.cleanup(false);
			// console.log("PDF.distroy()");
		});
		// console.log("PDF.draw()");
		return pdfPageView.draw();
		// this.pageView = pdfPageView;
	}
	
/*	private renderPDFPageAsCanvas(canvas:HTMLCanvasElement, pdfDocument, page):Promise<any>
	{
		if(!canvas) canvas = document.createElement("canvas");
		this.elementRef.nativeElement.appendChild(canvas);
		return this.context.service.pdfService.renderPageAsCanvas(canvas, pdfDocument, page);
	}*/
	
	private lookupAsset(url:string):Promise<any>{
		if(url && url !== "undefined")
		{
			return this.context.service.resourceSource.lookupResource(url);
		} else {
			return Promise.reject("no url");
		}
	}
	private bypassSecurityTrustStyle(url:any):any
	{
		return this.context.service.fileIO.trustURLStyle(url);
	}
	
	// ======================================================
	// data function
	// ======================================================
//	public isQuestionComponent():boolean {
	public isDataComponent():boolean {
		return true;
	}
	public isQuestionComponent():boolean
	{
		return false;
	}

	public verify(_showScoring:boolean):any {
		// reset verify
		// reset score display
//		this.sObj.hideScore();

		if(this.ref) {
			if(!this.resultObject)
				this.resultObject = {correct:-1};
		} else
			this.resultObject = null;

		// 顯示分數 / 已提交
//		this.sObj.showScoring(_showScoring, "view", this.resultObject);
		return this.resultObject;
	}

	public get data():string {
		return this.ref ? this.objectToString(this.ref) : null;
	}
	
	dataContentReady():boolean
	{
		return this.ready;
	}

	protected inputData:string;
	public reload():void {
		if(this.studentPDF) {
			this.studentPDF.remove();
			this.studentPDF = null;
		}
		this.ref = null;
//		this.useImageAsset = true;
		this.imageURLStyle = null;
		this.data = this.inputData;
	}
	
	public set data(input:string) {
		// console.log("ExampPaper.data", input);
		this.inputData =  input;
		// clear verify and answer
		this.answerChanged = false;
		this.studentAnsURLStyle = null;

		var config:ROBookConfig = this.context.config;
		
		if(input) {
			let jsonObj = this.stringToObject(input);
			if(jsonObj.hasOwnProperty("key") && jsonObj.hasOwnProperty("reference") && jsonObj.hasOwnProperty("page")) {
				this.ready = false;
				if(!this.ref || !jsonObj.hasOwnProperty("type") || 
					!(jsonObj.type == "pdf" && jsonObj.key == this.ref.key && jsonObj.reference == this.ref.reference && jsonObj.page == this.ref.page)) {
					this.ref = jsonObj;

					if(jsonObj.hasOwnProperty("image") && this.context.service.dataService.getPersonalSetting("paperSrc")=="image") {
						this.ref = jsonObj.image;
						this.loadFile(this.ref).finally(()=>{
							this.loadingSubject.next(null);
						});

					} else {
						this.loadFile(this.ref).finally(()=>{
							// this.ready = true;
							this.loadingSubject.next(null);

						});

					}

/*					setTimeout(()=>{
						this.loadFile(this.ref).finally(()=>{
							// this.ready = true;
							this.loadingSubject.next(null);
	
						});
					}, 1000);*/
				} else {
					// 已載入學生 pdf 時的處理
					var hideStudentAnswer:boolean = this.needHideStudentAnswer();
					this.hideTarget(this.studentPDF, hideStudentAnswer);
					this.updateBGPDF(hideStudentAnswer).finally(()=>{
						// this.ready = false;
						this.loadingSubject.next(null);
					})

					//if(this.useImageAsset)
						this.showWhichImage();
				}
			} else {
				this.loadingSubject.next(null);
				// this.ready = true;
				this.ref = null;
			}
				

		} else{
			this.loadingSubject.next(null);
			// this.ready = true;
			this.ref = null;
		}

		if(!this.ref) {
			if(this.studentPDF) {
				this.studentPDF.remove();
				this.studentPDF = null;
			}
			this.imageURLStyle = this.studentAnsURLStyle = null;
		}


		this.absent = null;
		this.context.subject.next({
			type:"isAbsent",
			callback:(isAbsent:boolean) => {
				this.absent = isAbsent ? this.context.service.translateService.instant('bookviewer.absent') : null;
			}
		});
	}

	updateBGPDF(hideStudentAnswer:boolean):Promise<any>{
//		console.log(">>hideStudentAnswer", hideStudentAnswer);
		if(hideStudentAnswer) {
			if(this.context.config.subViewMode=="correction") {
				if(this.currentBGAsset!=this.blankPageAsset) {
					return this.renderPDF(this.blankPageAsset, this.bgPDF, false);
					
				}
			} else if(this.currentBGAsset!=this.answerPageAsset) {
				return this.renderPDF(this.answerPageAsset, this.bgPDF, false);
			}
		}

//		console.log("bgPDF>",hideStudentAnswer);
		this.hideTarget(this.bgPDF, !hideStudentAnswer); // 答案頁, 無答案頁
		return Promise.resolve(null);
	}

	protected needHideStudentAnswer():boolean {
		var config:ROBookConfig = this.context.config;
//		if(["view","review","finished"].indexOf(config.viewMode)>=0)
//			return false;
		return (config.viewMode=="scoring" && config.subViewMode=="answer") ||
					(config.subViewMode=="correction");
	}

	loadFile(obj: any):Promise<any>
	{
		this.retry = ()=>{
			this.loadFile(obj);
		}
		if(obj.hasOwnProperty("type") && obj.type == "pdf") {
			var pageComponent:ROPageComponent = (<ROPageComponent> this.page);
			var showLoading:boolean = pageComponent.printing ? false : true;
			return this.context.getBookAnswerAsset(pageComponent.chapter.id,obj, showLoading).then((url:string)=>{
				if(!this.studentPDF)
					this.studentPDF = document.createElement("div");
				var hideStudentAnswer:boolean = this.needHideStudentAnswer();
				// const regex = /-\d+-/;
				// if (obj.reference && regex.test(obj.reference) ){
				// 	const arr = obj.reference.split('-');
				// 	obj.page = +arr[1];
				// }
				return Promise.all([
					this.renderPDF({url:url,page:obj.page}, this.studentPDF, hideStudentAnswer),
					this.updateBGPDF(hideStudentAnswer)
				]);
			});

		} else {
			// 舊 code ，學生圖片答案
			if(this.context.config.viewMode == "scoring" || this.context.config.viewMode == "submission_review")
			{
				var page:any = this.page;
				var dataSource:any = page.source;
				return this.context.loadStudentFile(
					<ROPageComponent> this.page,
					this, 
					// this.context.config.student.uid,
					dataSource.student.uid,
					obj, 0
				).then((url:string)=>{
					this.studentAnsURLStyle = this.context.service.fileIO.trustURLStyle(url);
					this.showWhichImage();
					this.rawAssetURL = url;
					this.imageAssetURL = url;
					return this.loadImage(url).then((a:any)=>{
					}).catch((reason:any)=>{
						// not handle
					})
				});

			}

			return this.context.loadFile(<ROPageComponent> this.page, this, obj).then((url:string)=>{
				this.studentAnsURLStyle = this.context.service.fileIO.trustURLStyle(url);
				this.showWhichImage();
			});

		}
	}

	protected showWhichImage():void {
		if(this.defaultAnswer) {
			this.showDefaultAnswer();
		} else {
			this.hideDefaultAnswer();
		}
	}

/*	public getAnswerAssets():any [] {
		if(this.answerChanged) return [{
			key:this.ref.key,
			reference:this.ref.reference,
			index:0,
			file:this.file
		}];
		return null;
	}*/

	public showDefaultAnswer(): void {
		this.defaultAnswer = true;
		if(this.context.config.viewMode == "scoring" || this.context.config.viewMode == "submission_review") {
//			if(!this.useImageAsset) {
				this.hideTarget(this.studentPDF, true);
				if(this.currentBGAsset!=this.answerPageAsset)
					this.renderPDF(this.answerPageAsset, this.bgPDF, false);
				else
					this.hideTarget(this.bgPDF, false);

/*			} else {
				if(!this.ansURLStyle) {
					var asset:any = {type:"image",url:"https://oka.blob.core.windows.net/test/ans.png"};
					if(asset)
						this.lookupAsset(asset.url).then((url)=>{
							return this.bypassSecurityTrustStyle(url)
						}).then((trustedURL:string)=>{
							this.imageURLStyle = this.ansURLStyle = trustedURL;
						});
				} else {
					this.imageURLStyle = this.ansURLStyle;
				}
			}*/
		}
	}
	public hideDefaultAnswer(): void {
		var mode:string = this.context.config.viewMode;
		if(!this.viewerIsStudent() && (mode == "edit" || mode == "view" || mode == "preview")) {
			this.showDefaultAnswer();
			return;
		}

		this.imageURLStyle = null;

		this.defaultAnswer = false;
//		if(this.context.config.viewMode == "scoring" || this.context.config.viewMode == "submission_review") {
//			if(!this.useImageAsset) {
				if(this.context.config.subViewMode=="correction") {
					this.hideTarget(this.studentPDF, true);
					if(this.currentBGAsset!=this.blankPageAsset)
						this.renderPDF(this.blankPageAsset, this.bgPDF, false);
					else
						this.hideTarget(this.bgPDF, false);
				} else {
					if(this.studentAnsURLStyle)
						this.imageURLStyle = this.studentAnsURLStyle;
					else
						this.hideTarget(this.studentPDF, false);
					this.hideTarget(this.bgPDF, true);
				}

/*			} else {
				if(this.context.config.subViewMode=="correction") {
					// blank page
					if(!this.bgURLStyle) {
						var asset:any = {type:"image",url:"https://oka.blob.core.windows.net/test/blank.png"};
						if(asset)
							this.lookupAsset(asset.url).then((url)=>{
								return this.bypassSecurityTrustStyle(url)
							}).then((trustedURL:string)=>{
								this.imageURLStyle = this.bgURLStyle = trustedURL;
							});
					} else {
						this.imageURLStyle = this.bgURLStyle;
					}
					
				} else {
					this.imageURLStyle = this.studentAnsURLStyle;
				}
			}*/
//		}
	}

	public hideTarget(target:HTMLElement, hide:boolean):void {
		if(target) {
			if(hide)
				target.remove();
			else
				this.elementRef.nativeElement.appendChild(target);
		}
	}

	ngOnDestroy(): void {
		if(this.subscription)
		{
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}


}