import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // ngModel 需要
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SelectMarkerComponent } from './SelectMarker.component';
import { ComponentMenuBarModule } from '../componentMenuBarModule/componentMenuBar.module';
import { PropertyPanelModule } from '../propertyPanelModule/propertyPanel.module';
@NgModule({
    imports: [

		FontAwesomeModule,CommonModule,TranslateModule
		,FormsModule,
		ComponentMenuBarModule,
		PropertyPanelModule
    ],
    declarations: [
        SelectMarkerComponent
    ],
    exports: [
        SelectMarkerComponent
    ],
    providers: [],
    bootstrap: []
})
export class SelectMarkerModule { }