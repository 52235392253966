import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { InlineSvgService } from "src/app/sharedModule/inlineSvgModule/inlineSvg.service";
import { ROContextService } from "../../ROContext";
import { BubbleBox2Component } from "src/app/sharedModule/bubbleBox2Module/bubbleBox2.component";
import { ThemeService } from "src/app/service/theme.service";

@Component({
	selector: `roComponentTool`,
	template: `
		<div 
			[ngClass]="['roComponentTool']"
			[ngStyle]="style"
			[style.zIndex]="zIndex"
			[style.marginLeft]="getMarginLeft()"
			[@openClose]="(isOpen() ? open : close)"
		>
			<div class="roComponentTool__tagWrapper">
				<div class="roComponentTool__tagWrapper__tag">
					{{"ro.components.name.Component" | translate}} {{getSelectedLabel()}}
				</div>
				<div class="roComponentTool__tagWrapper__arrow-right">
				</div>
			</div>

			<ng-container *ngFor="let item of items; let idx = index">
				<ng-container *ngIf="!item.showWhen || item.showWhen()">
					<div #itemEle 
						[ngClass]="{
							'roComponentTool__itemWrapper': true,
							'disabled': (selectedItems.length > 1 && !item.multiple) || (item.disableWhen && item.disableWhen())
						}"
						(click)="onItemClick(item, $event, itemEle)"
					>
						<div *ngIf="item.isDivider" class="roComponentTool__itemWrapper__divider"></div>
						<div *ngIf="item.svg && isSvgLoaded" class="roComponentTool__itemWrapper__svg">
							<div class="background" [class.selected]="isDisplayBtnBackground(item)">
								<svg-icon [name]="item.svg"></svg-icon>
							</div>
						</div>
						<div *ngIf="item.img" class="roComponentTool__itemWrapper__image" [style.backgroundImage]="'url(assets/img/' + item.img + ')'"></div>
						<div *ngIf="item.icon" class="roComponentTool__itemWrapper__icon">
							<fa-icon [icon]="item.icon" [style.fontSize]="item.iconSize || '17px'"></fa-icon>
						</div>
						<div *ngIf="item.name" class="roComponentTool__itemWrapper__name">{{'toolBarPanel.' + item.name | translate}}</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
		<bubbleBox2
			#subMenu
			padding="0"
			elClass="ctb"
			[targetMargin]="4"
			[autoTailColor]="false"
			[backgroundColor]="subMenuBgColor" 
			[autoClose]="false" 
			[hCenter]="'try'"
			[boxShadow]="themeService.getStyleValue('wpopup-shadow') || '0px 5px 30px rgba(0,0,0,0.3)'"
		>
			<div class="content">
				<ng-container *ngFor="let itm of subMenuItems; let last = last;">
					<ng-container *ngIf="!itm.showWhen || itm.showWhen()">
						<div #subItemEle 
							[ngClass]="{
								'roComponentTool__itemWrapper': true,
								'disabled': (selectedItems.length > 1 && !itm.multiple) || (itm.disableWhen && itm.disableWhen())
							}"
							(click)="onItemClick(itm, $event, subItemEle)"
						>
							<div *ngIf="itm.isDivider" class="roComponentTool__itemWrapper__divider"></div>
							<svg-icon *ngIf="itm.svg && isSvgLoaded" class="roComponentTool__itemWrapper__svg" [name]="itm.svg"></svg-icon>
							<div *ngIf="itm.img" class="roComponentTool__itemWrapper__image" [style.backgroundImage]="'url(assets/img/' + itm.img + ')'"></div>
							<div *ngIf="itm.icon" class="roComponentTool__itemWrapper__icon">
								<fa-icon [icon]="itm.icon" [style.fontSize]="itm.iconSize || '17px'"></fa-icon>
							</div>
							<div *ngIf="itm.name" class="roComponentTool__itemWrapper__name">{{'ro.components.name.' + itm.name | translate}}</div>
							<div *ngIf="itm.btn" class="roComponentTool__itemWrapper__btn">
								<span>{{'ro.components.name.' + itm.btn | translate}}:</span>
								<ui-switch 
									class="useCssVar" 
									[checked]="itm.boolean" 
									[disabled]="(selectedItems.length > 1 && !itm.multiple)"
									(change)="itm.callback($event)"
								>
								</ui-switch>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</bubbleBox2>
	`,
	styleUrls: ['./roComponentTool.component.scss'],
	animations: [
		trigger('openClose', [
			state('open-0', style({ bottom: '10px', opacity: '1' })), state('close-0', style({ bottom: '-80px', opacity: '0' })),
			state('open-1', style({ bottom: '30px' })), state('close-1', style({ bottom: '-180px', display: 'none' })),
			transition('open-0 => close-0', [animate('0.1s')]), transition('close-0 => open-0', [animate('0.1s')]),
			transition('open-1 => close-1', [animate('0.1s')]), transition('close-1 => open-1', [animate('0.1s')]),
		])
	]
})

export class ROComponentToolComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChildren('itemEle') itemEles: QueryList<ElementRef>;
	@ViewChildren('subItemEle') subItemEles: QueryList<ElementRef>;
	@ViewChild('subMenu', {static:false}) public subMenu: BubbleBox2Component;

	// input
	@Input() selectedItems: any[] = []
	@Input() style: any = {};
	@Input() isEditMode: boolean = false;
	@Output() toolAction: EventEmitter<string> = new EventEmitter();
	
	// params
	public items: {
		name?: string;          
		img?: string;           
		icon?: string;           
		disableWhen?: () => boolean;
		showWhen?: () => boolean;
		isSelected?: boolean;
		svg?: string;           
		multiple?: boolean;
		isDivider?: boolean;
		callback?: (event?: Event, item?: any, itemEle?: ElementRef) => void;
	} [] = [];
	public subMenuItems: {
		name?: string;          
		img?: string;           
		icon?: string;           
		disableWhen?: () => boolean;
		showWhen?: () => boolean;
		svg?: string;           
		btn?: string;           
		boolean?: boolean;
		multiple?: boolean;
		isDivider?: boolean;
		callback?: (event?: Event, item?: any, itemEle?: ElementRef) => void;
	} [] = [];
	public open = 'open-0';
	public close = 'close-0';
	public zIndex = 9;
	public isSvgLoaded = false;
	public subMenuBgColor = "#000000CC"

	private subMenuType: "more" | "align" | "" = ""

	constructor(
		public translate: TranslateService, 
		private sanitization: DomSanitizer, 
		public svgReg: InlineSvgService,
		private roContextService: ROContextService,
		public themeService: ThemeService
	) {}

	ngOnInit(): void {
		this.svgReg.regSvgFiles([
			{url: 'tool_bar_option/tool_bar_align.svg', name: 'tool_bar_align'},
			{url: 'tool_bar_option/tool_bar_more.svg', name: 'tool_bar_more'},
			{url: 'tool_bar_option/tool_bar_layer_bottom.svg', name: 'tool_bar_layer_bottom'},
			{url: 'tool_bar_option/tool_bar_layer_top.svg', name: 'tool_bar_layer_top'},
			{url: 'tool_bar_option/tool_bar_layer_down.svg', name: 'tool_bar_layer_down'},
			{url: 'tool_bar_option/tool_bar_layer_up.svg', name: 'tool_bar_layer_up'},
			{url: 'tool_bar_option/tool_bar_select.svg', name: 'tool_bar_select'},
			{url: 'tool_bar_option/tool_bar_close.svg', name: 'tool_bar_close'},
			{url: 'tool_bar_option/tool_bar_lock.svg', name: 'tool_bar_lock'},
			{url: 'tool_bar_option/tool_bar_unlock.svg', name: 'tool_bar_unlock'},
			{url: 'tool_bar_option/tool_bar_flip_horizontal.svg', name: 'tool_bar_flip_horizontal'},
			{url: 'tool_bar_option/tool_bar_flip_vertical.svg', name: 'tool_bar_flip_vertical'},
			{url: 'tool_bar_option/tool_bar_zoomin.svg', name: 'tool_bar_zoomin'},
			{url: 'tool_bar_option/tool_bar_zoomout.svg', name: 'tool_bar_zoomout'},
			{url: 'tool_bar_option/tool_bar_anticlockrise_10.svg', name: 'tool_bar_anticlockrise_10'},
			{url: 'tool_bar_option/tool_bar_clockrise_10.svg', name: 'tool_bar_clockrise_10'},
			{url: 'tool_bar_option/tool_bar_resize.svg', name: 'tool_bar_resize'},
			{url: 'tool_bar_option/tool_bar_full_size.svg', name: 'tool_bar_full_size'},
			{url: 'tool_bar_option/tool_bar_align_left.svg', name: 'tool_bar_align_left'},
			{url: 'tool_bar_option/tool_bar_align_center.svg', name: 'tool_bar_align_center'},
			{url: 'tool_bar_option/tool_bar_align_right.svg', name: 'tool_bar_align_right'},
			{url: 'tool_bar_option/tool_bar_align_top.svg', name: 'tool_bar_align_top'},
			{url: 'tool_bar_option/tool_bar_align_vertical_center.svg', name: 'tool_bar_align_vertical_center'},
			{url: 'tool_bar_option/tool_bar_align_bottom.svg', name: 'tool_bar_align_bottom'},
			{url: 'tool_bar_option/tool_bar_align_auto_horizontal.svg', name: 'tool_bar_align_auto_horizontal'},
			{url: 'tool_bar_option/tool_bar_align_auto_vertical.svg', name: 'tool_bar_align_auto_vertical'},
		]).then (() => {
			this.isSvgLoaded = true;
		}).catch(err => console.log("error on reg svg:", err))
		this.initPanel()
		this.initItems()
	}

	ngOnDestroy(): void {
		if (this.subMenu) {
			this.subMenu.closeNow()
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['selectedItems']) {
            this.initPanel();
			this.initItems();
        }
	}

	public initPanel() {
		this.open = 'open-' + (this.style.bottomLevel || '0');
		this.close = 'close-' + (this.style.bottomLevel || '0');
		this.zIndex = (this.style.bottomLevel || 0) * 10 + 9;
	}

	public initItems() {		
		this.items = [
			{ svg: 'tool_bar_align', multiple: true, callback: (event, item, ele) => { this.openSubMenu(ele, "align", item) } },
			{ svg: 'tool_bar_more', multiple: true, callback: (event, item, ele) => { this.openSubMenu(ele, "more", item) } },
			{ isDivider: true },
			{ name: 'replace', multiple: true, disableWhen: () => (!this.roContextService.roClipboardService.hasReferenceComponents()), callback: () => { this.toolAction.emit("replace") } },
			{ name: 'paste', multiple: true, disableWhen: () => (!this.roContextService.roClipboardService.hasReferenceComponents()), callback: () => { this.toolAction.emit("paste") } },
			{ name: 'cut', multiple: true, callback: () => { this.toolAction.emit("cut"); } },
			{ name: 'copy', multiple: true, callback: () => { this.toolAction.emit("copy"); } },
			{ svg: 'tool_bar_layer_bottom', multiple: true, callback: () => { this.toolAction.emit("bottom"); } },
			{ svg: 'tool_bar_layer_top', multiple: true, callback: () => { this.toolAction.emit("top"); } },
			{ svg: 'tool_bar_layer_down', multiple: true, callback: () => { this.toolAction.emit("down"); } },
			{ svg: 'tool_bar_layer_up', multiple: true, callback: () => { this.toolAction.emit("up"); } },
			{ svg: 'tool_bar_select', multiple: true, callback: () => { console.log("click") } },
		]
	}
   	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
   	public getSelectedLabel() {
		return this.selectedItems.length <= 1 ? ":" : `x ${this.selectedItems.length}`
	}
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	public isOpen() {
		return this.selectedItems.length > 0
	}

	/** align the panel to the center */
	public getMarginLeft(): SafeStyle {
		return this.sanitization.bypassSecurityTrustStyle('calc((100% - 475px) / 2)');
	}

	/** trigger the call back function */
	public onItemClick(item: any, event: Event, itemEle: ElementRef) {
		if (item.disableWhen && item.disableWhen()) return 

		if ((item.multiple || this.selectedItems.length == 1) && item.callback) {
			item.callback(event, item, itemEle)
		}
	}
	//----------------------------------------------------------------------------------------------------------------------------------------------------------------
	// bubble box2
	public openSubMenu(targetEle: any, type: "more" | "align", item: any) {
		item.isSelected = true
		if (this.subMenuType !== type) {
			if (type === 'more') {
				this.setMoreItems()
			} else {
				this.setAlignItems()
			}
			const found = this.items.find(itm => {
				if (itm.svg && ["tool_bar_more", "tool_bar_align"].includes(itm.svg)) {
					if (this.subMenuType === 'more') {	
						return itm.svg === 'tool_bar_more'
					} else if (this.subMenuType === 'align') {
						return itm.svg === 'tool_bar_align'
					}
				}
				return false
			})
			if (found) {
				found.isSelected = false
			}
			this.subMenu.openOrMove(targetEle).catch((e) => {});
		} else {
			if (!this.subMenu.opened) {
				this.subMenu.open(targetEle).catch((e) => {});
			} else {
				item.isSelected = false
				this.subMenu.closeNow()
			}
		}
		this.subMenuType = type
	}

	private setMoreItems() {
		this.subMenuItems = [
			{ svg: 'tool_bar_close', multiple: true, callback: () => { this.subMenu.closeNow() } },
			{ isDivider: true },
			{ svg: 'tool_bar_full_size', multiple: true, disableWhen: () => !this.isForImgComponent(), callback: () => { console.log("click") } },
			{ svg: 'tool_bar_resize', multiple: true,disableWhen: () => !this.isForImgComponent(), callback: () => { console.log("click") } },
			{ svg: 'tool_bar_clockrise_10', multiple: true, disableWhen: () => this.selectedItems.length > 1, callback: () => { this.toolAction.emit("rotate"); } },
			{ svg: 'tool_bar_anticlockrise_10', multiple: true, disableWhen: () => this.selectedItems.length > 1, callback: () => { this.toolAction.emit("antiRotate") } },
			{ svg: 'tool_bar_flip_vertical', multiple: true, disableWhen: () => !this.isForImgComponent(), callback: () => { console.log("click") } },
			{ svg: 'tool_bar_flip_horizontal', multiple: true, disableWhen: () => !this.isForImgComponent(), callback: () => { console.log("click") } },
			{ name: 'Mask', multiple: true, disableWhen: () => !this.isForImgComponent(), callback: () => { console.log("click") } },
		]
	}

	private setAlignItems() {
		this.subMenuItems = [
			{ svg: 'tool_bar_close', multiple: true, callback: () => { this.subMenu.closeNow() } },
			{ isDivider: true },
			{ btn: 'Align', boolean: true, multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
			{ svg: 'tool_bar_align_auto_vertical', multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
			{ svg: 'tool_bar_align_auto_horizontal', multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
			{ svg: 'tool_bar_align_bottom', multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
			{ svg: 'tool_bar_align_vertical_center', multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
			{ svg: 'tool_bar_align_top', multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
			{ svg: 'tool_bar_align_right', multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
			{ svg: 'tool_bar_align_center', multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
			{ svg: 'tool_bar_align_left', multiple: true, disableWhen: () => this.selectedItems.length === 1, callback: () => { console.log("click") } },
		]
	}

	public isDisplayBtnBackground(item: any) {
		if (item.svg && ["tool_bar_more", 'tool_bar_align', 'tool_bar_select'].includes(item.svg)) {
			return !!(item.isSelected)
		}
		return false
	}

	public isForImgComponent(): boolean {
		return this.selectedItems.length === 1 && this.selectedItems[0].obj.getTagNames().includes('Image')
	}
}