import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { CommonService } from './common.service';
import { LoadingService } from '../sharedModule/loadingModule/loading.service';
import { AlertService } from './alert.service';
import { tap } from 'rxjs-compat/operators/tap';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class DataService {
	private _jwt: string = null;
	public jwt$:Subject<string> = new Subject();
	public jwtObj: any = null;

    public user_role:any 
	public appId: string = 'rainbowone';

	private _userInfo:any = null;
	public userInfo$:Subject<any> = new Subject();
	public get userInfo():any {
		return this._userInfo;
	}
	public set userInfo(value:any) {
		if (this._userInfo == value) return;
		this._userInfo = value;
		this.userInfo$.next(value);
	}

	public lang: string;// = this.getLang();
	public dev;

	public orgs: any[] = [];

	public apiUrl: string;
	public devApiUrl: string;
	public isProd:boolean = false;
	public isInWeb:boolean = true;
	public set jwt(value: string){
		this._jwt = value;
		var newJWTObject = this.jwt ? this.decodeJWT(this.jwt) : null;
		/*
		if(newJWTObject && this.jwtObj)
		{
			console.log(
				"old jwt", this.jwtObj.time , "\r\n",
				"new jwt", newJWTObject.time, "\r\n"
			);
		}
		*/
		this.jwtObj = newJWTObject;
		this.jwt$.next(value);
	}

	public get jwt():string
	{
		return this._jwt;
	}

	public oupEulaVer:number = 1733657435;
	public oupEulaRouteTemp:string = "";

	decodeJWT(jwt:string)
	{
		if(jwt)
		{
			let jwts: string[] = jwt.split('.');
			return JSON.parse(atob(jwts[1]));
		} else {
			return null;
		}
	}
	public isCalling(): boolean {
		return (window['httpCalling'] && window['httpCalling'].length > 0);
	}
	public calling(): Function {
		//console.log("calling()");
		// console.log(new Error().stack);
		if (!window['httpCalling']) window['httpCalling'] = [];
		let _calling = window['httpCalling'];
		let random: number = +new Date() * 1000 + Math.floor(Math.random() * 1000);
		_calling.push(random);
		let clsFn: Function = (id: number): void => {
			//console.log("calling.response()");
			for (let i = 0; i < _calling.length; i++) {
				if (_calling[i] == random) _calling.splice(i, 1);
			}
			//console.log("calling.length = ", _calling.length);
		}
		setTimeout(() => {
			//console.log("calling.timeout");
			clsFn()
		}, 30000);
		return clsFn;
	}

	constructor(
		private coms: CommonService,
		public device: DeviceDetectorService,
		private http: HttpClient,
		private lds: LoadingService,
		private als: AlertService,
		private router: Router,
		private translate:TranslateService
	) {
		/*let proUrl: string = "//api.openknowledge.hk/RainbowOne/index.php/PHPGateway/proxy/2.8/";
		let testUrl: string = "//ro2.azurewebsites.net/RainbowOne/index.php/PHPGateway2/proxy/2.8/";
		if (location.hostname == 'dev.openknowledge.hk' || location.port) {
			testUrl = '//dev.openknowledge.hk/RainbowOne/index.php/PHPGateway2/proxy/2.8/';
			// testUrl = '//localhost:8000/index.php/PHPGateway2/proxy/2.8/';
			proUrl = testUrl;
		} else {
			// proUrl = '//' + location.hostname + '/RainbowOne/index.php/PHPGateway2/proxy/2.8/';
			// testUrl = proUrl;
		}*/
		if (environment.production) {
			this.coms.log('In Production mode.');
			//this.apiUrl = proUrl;
			this.apiUrl = '//' + location.hostname + '/RainbowOne/index.php/PHPGateway2/proxy/2.8/';
			this.devApiUrl = this.apiUrl.replace(location.hostname, 'dev.openknowledge.hk');
			this.isProd = true;
		} else {
			this.coms.log('In Development mode.');
			//this.apiUrl = testUrl;
			this.apiUrl = environment.apiUrl;
			this.devApiUrl = environment.apiUrl;
		}
		this.coms.log('Use URL: ' + this.apiUrl);

		if (document.cookie && (document.cookie.indexOf('login_session_token=') > 0)) {
			this.coms.log('Session: ' + document.cookie.indexOf('login_session_token='));
		} else {
			this.coms.log('No Session.');
		}

		//this.whoAmIApi();

		this.dev = {
			//E.g.:
			//device.getDeviceInfo().os = Windows/Mac/Android
			//device.getDeviceInfo().device = Android/iPad

			info: device.getDeviceInfo(),
			isMobile: device.isMobile() || device.isTablet(),
			isWindows: device.getDeviceInfo().os.toLowerCase() == 'windows',
			//isMac: device.getDeviceInfo().os.toLowerCase()=='mac' && device.getDeviceInfo().device.toLowerCase()!='ipad',
			isMac: device.getDeviceInfo().os.toLowerCase() == 'mac' && device.isDesktop(),
			isAndroid: device.getDeviceInfo().os.toLowerCase() == 'android',
			//isIpad: device.getDeviceInfo().device.toLowerCase()=='ipad'
			isIpad: device.getDeviceInfo().os.toLowerCase() == 'mac' && device.isTablet()
		};
		this.coms.log(this.dev);

		this.fixIosScroll();
	}
	//---------------------------------------------------------------------------------------------
	public whoAmIApi(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.userInfo && this.userInfo.rainbow_one_school) {
				resolve(this.userInfo);
			} else {
				resolve(
					this.post2({
						data: {
							api: 'ROMediaLibrary.whoAmI',
							json: []
						}
					}).then((res: any) => {
						if (res && res.data) this.userInfo = res.data;
						if (this.userInfo) {
							this.userInfo.planType = () => {
								let planType: string = (res.data.rainbow_one_school && res.data.rainbow_one_school.plan_type && res.data.rainbow_one_school.plan_type.trim().toLowerCase()) || '';
								let extraTrial: string = (res.data.rainbow_one_school && res.data.rainbow_one_school.extra_trial && res.data.rainbow_one_school.extra_trial.trim().toLowerCase()) || '';
								if (planType == 'pro' || extraTrial == 'extra pro') return 'pro';
								if (planType == 'basic' || extraTrial == 'extra basic') return 'basic';
								if (planType == 'free') return 'free';
								return 'none';
							}
						}
						return this.userInfo;
					})
				)
			}
		});
	}

	public getPersonalSetting(key:string, useDefault:boolean = false):any {
		if(!useDefault && this.userInfo && this.userInfo.personalSettings && this.userInfo.personalSettings.hasOwnProperty(key))
			return this.userInfo.personalSettings[key];
		
		// use default settings
		var def:any ={
			evaluation_comment:1, // 上課批改學生可否比like
			evaluation_sync:1, // 上課批改學生同步
			mainMenuIconDisplayMode:"grid",
			workspace_fav_subjects:[],

			onoff_split_view:false, // 分頁
			onoff_student_list:true, // 學生批改列表
			onoff_marking_layer:true, // 顯示批改
			onoff_teacher_sticker_list:true, // 顯示學校設定了可用的 sticker
			panAction:parseInt(this.userInfo.school_id)==1675 ? "student" : "page", // 天虹批改左滑動設定，預設是轉學生
			paperSrc:"image", // exam paper component 顯示的資料來源
			correctionToolsLayout:"bottom", // 批改時的 UI layout

			schoolStickerPanel:{ver:1,x:10,y:50,xAlign:"right",yAlign:"top",width:240,height:200,perRow:3,dockHeight:178},
			studentListPanel:{ver:1,x:10,y:50,xAlign:"left",yAlign:"top"},
			correctionEpenSwitchSetting:{ver:1,x:0,y:152,xAlign:"left",yAlign:"bottom"},
			pendingCorrectionPanel:{ver:1,x:10,y:50,xAlign:"left",yAlign:"top"},
		};
		return def[key];
	}

	public setPersonalSetting(key:string, value:any):Promise<void> {
		const that:any = this;
		return new Promise((resolve, reject)=>{;
			if (!this.userInfo) {
				console.log('no userInfo!');
				setTimeout(()=>resolve(this.setPersonalSetting(key, value)), 500);
			} else {
				if (!that.userInfo.personalSettings) that.userInfo.personalSettings = {};
				that.userInfo.personalSettings[key] = value;

				//因在login前會先用localStorage，在saveDB前都需更新localStorage
				try {
					let dataString:string = localStorage.getItem('homeScreen_data');
					let dataJson = JSON.parse(dataString);
					dataJson.account.personalSettings = that.userInfo.personalSettings;
					dataString = JSON.stringify(dataJson);
					localStorage.setItem('homeScreen_data', dataString);
				} catch(e) {}

				that.post2({data:{
					api:'Account.savePersonalSetting', loading: false,
					json:[that.userInfo.personalSettings]
				}}).then((res:any)=>{
					resolve();
				});
			};
		});
	}
	//---------------------------------------------------------------------------------------------
	public get(url: string, option: any = {}): Observable<any> {
		console.log("get:", url);
		let callEnd: Function = this.calling();
		return this.http.get(url, option).pipe(tap(() => callEnd()),
			tap((res: any) => {
				if (typeof res === 'object') {
					if (res.code == 12 && res.msg == 'LOGIN_REQUIRED') {
						this.router.navigate(['login']);
					}
				}
			}),
			catchError((err) => {
				callEnd();
				return throwError(err);
			}));
	}
	//---------------------------------------------------------------------------------------------
	public getWithJwt(url: string, option: any = {}): Observable<any> {
		if (!option.headers) option.headers = new HttpHeaders();
		if (!this.jwt && localStorage.getItem('common_jwt')){
			this.jwt = localStorage.getItem('common_jwt');
		}
		if (this.jwt) option.headers = option.headers.append("Authorization", 'Bearer ' + this.jwt);
		// if (this.appId && window.location.href.indexOf('localhost') == -1 && window.location.href.indexOf('//192.168') == -1) option.headers.append("app-id", this.appId);
		option.headers.append("app-id", this.appId);
		option.headers.append("accept-language", this.lang);
		return this.get(url, option);
	}
	//-------------------------------------------------------------------------------------------------
	public get2(config: any): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {

			let callEnd: Function = this.calling();
			let apiConfig: any = {
				url: this.apiUrl,
				data: {},
				option: { headers: new HttpHeaders() },
				loading: config && config.loading !== undefined?config.loading:true,
				jwt: true,
				retryDialog: true
			}
			for (let key in apiConfig) if (config.hasOwnProperty(key)) apiConfig[key] = config[key];
			//Set Token
			if (apiConfig.jwt && this.jwt) apiConfig.option.headers = apiConfig.option.headers.append("Authorization", 'Bearer ' + this.jwt);
			apiConfig.option.headers = apiConfig.option.headers.append("app-id", this.appId);
			apiConfig.option.headers = apiConfig.option.headers.append("accept-language", this.lang);
			let urlWithPara: string = apiConfig.url;
			for (let key in apiConfig.data) {
				urlWithPara += (urlWithPara.indexOf("?") > -1 ? "&" : "?") + key + "=" + encodeURIComponent(apiConfig.data[key]);
			}

			let apiCall: Function = () => {
				let cls: Function = apiConfig.loading ? this.lds.add() : null
				let subscription: Subscription;
				let trial: number = 0;
				let retryFn: Function = () => {
					subscription = this.http.get(urlWithPara, apiConfig.option).subscribe((res: any) => {
                        callEnd();
                        if (typeof res == 'object' && Array.isArray(res) == false && res != null) {
                            if (res.code == 12 && res.msg == 'LOGIN_REQUIRED') {
                                this.router.navigate(['login']);
                            }
                        }
                        resolve(res);
                        if (cls) cls();
                    }, (err: Error) => {
                        callEnd();
                        this.coms.log('Error on apiCall [' + apiConfig.data.api + ']: ' + JSON.stringify(err));
                        if (trial < 4) {
                            trial++;
                            if (subscription) subscription.unsubscribe();
                            subscription = null;
                            let waitMs:number = [1000, 3000, 7000, 15000][trial-1];
							this.coms.log('Retry apiCall [' + apiConfig.data.api + '] after ' + (waitMs / 1000) + 'sec.');
							setTimeout(() => retryFn(), waitMs);
                        } else {
                            if (cls) cls();
                            if (subscription) subscription.unsubscribe();
                            subscription = null;
                            if (apiConfig.retryDialog) {
                                this.als.alert2('commonService.processTimeoutMsg', null, { btns:[ [{key:'retry',labelKey:"commonService.processTimeoutRetry", ngStyle:{backgroundColor:'#8ABF52'}}, 'cancel'] ] }).then((key:string)=>{
                                    if (key=='retry') {
                                        this.coms.log('Retry apiCall [' + apiConfig.data.api + ']');
                                        apiCall();
                                    } else {
                                        this.coms.log('Cancel apiCall [' + apiConfig.data.api + ']');
                                        reject('cancel');
                                    }
                                });
                            } else {
                                reject('Fail to call api [' + apiConfig.data.api + ']')
                            }
                        }
                    });
				}
				retryFn();
			}
			apiCall();

		});
	}
	//---------------------------------------------------------------------------------------------
	public post(api: string, json: any[] = [{}], url: string = this.apiUrl, data: any = {}, option: any = {},timeout:any =null): Observable<Object> {
		console.log("post", api);
		let callEnd: Function = this.calling();
		//let url = environment.apiUrl;
		if (!data.api) data.api = api;
		if (!data.json) data.json = JSON.stringify(json);

		if (!option.headers) option.headers = new HttpHeaders();
		//Set Token
		if (!this.jwt && localStorage.getItem('common_jwt')){
			this.jwt = localStorage.getItem('common_jwt');
		}
		if (this.jwt) option.headers = option.headers.append("Authorization", 'Bearer ' + this.jwt);
		if (timeout) {
			option.headers.append("timeout" ,`${timeout}`)
		}
		
		// if (this.appId && window.location.href.indexOf('localhost') == -1 && window.location.href.indexOf('//192.168') == -1) option.headers = option.headers.append("app-id", this.appId);
		option.headers = option.headers.append("app-id", this.appId ? this.appId : 'unset');
		option.headers = option.headers.append("accept-language", this.lang ? this.lang : 'unset');
		url = url +'?' + api;
		return this.http.post(url, data, option).pipe(tap(() => callEnd()), tap((res: any) => {
			if (typeof res === 'object' && Array.isArray(res) == false && res != null) {
				if (res.code == 12 && res.msg == 'LOGIN_REQUIRED') {
					this.router.navigate(['login']);
				}
			}
		}),
			catchError((err) => {
				callEnd();
				this.lds.removeAll();
				return throwError(err);
			}));
	}
	//-------------------------------------------------------------------------------------------------
	public call(api: string, ...parameters: any[]): Promise<any> {
		this.coms.info(api);
		var p = this.post2(
			{
				data: {
					api: api,
					json: parameters
				}
			}
		);
		p.then(() => {
			this.coms.info(api + ":success");
		}, () => {
			this.coms.error(api + ":failed");
		})
		return p;
	}

	public post2(config: any): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			//console.log("post2", config);
			let callEnd: Function = this.calling();
			let apiConfig: any = {
				url: this.apiUrl,
				data: {
					api: '',
					json: [{}]
				},
				option: {
					headers: new HttpHeaders()
				},
				loading: config && config.loading !== undefined?config.loading:true,
				jwt: true,
				retryDialog: true,
				roNode: false
			}
			for (let key in apiConfig) if (config.hasOwnProperty(key)) apiConfig[key] = config[key];
			if (!apiConfig.data.hasOwnProperty('json')) apiConfig.data.json = [{}];
			if (typeof apiConfig.data.json != 'string') apiConfig.data.json = JSON.stringify(apiConfig.data.json);
			//Set Token
			if (apiConfig.jwt && this.jwt) apiConfig.option.headers = apiConfig.option.headers.append("Authorization", 'Bearer ' + this.jwt);
			apiConfig.option.headers = apiConfig.option.headers.append("app-id", this.appId);
			apiConfig.option.headers = apiConfig.option.headers.append("accept-language", this.lang ? this.lang : 'unset');
			if (apiConfig.roNode) apiConfig.url = ((window.location.protocol == 'http:') ? environment.roNodeServer.httpHost : environment.roNodeServer.httpsHost) + '/api/' + apiConfig.url;
			let apiCall: Function = () => {
				let cls: Function = apiConfig.loading ? this.lds.add() : null
				let subscription: Subscription;
				let trial: number = 0;
				let retryFn: Function = () => {
					subscription = this.http.post(apiConfig.url, apiConfig.data, apiConfig.option).subscribe((res: any) => {
						callEnd();
						if (typeof res == 'object' && Array.isArray(res) == false && res != null) {
							if (res.code == 12 && res.msg == 'LOGIN_REQUIRED') {
								this.router.navigate(['login']);
							}
						}
						resolve(res);
						if (cls) cls();
					}, (err: any) => {
						callEnd();
						this.coms.log('Error on apiCall [' + apiConfig.data.api + ']: ' + JSON.stringify(err));
						if (err.status === 500) {
							if (cls) cls();
							if (subscription) subscription.unsubscribe();
							subscription = null;
							reject('Fail to call api [' + apiConfig.data.api + ']');
						} else {
							if (trial < 4) {
								trial++;
								if (subscription) subscription.unsubscribe();
								subscription = null;
								let waitMs: number = [1000, 3000, 7000, 15000][trial - 1];
								this.coms.log('Retry apiCall [' + apiConfig.data.api + '] after ' + (waitMs / 1000) + 'sec.');
								setTimeout(() => retryFn(), waitMs);
							} else {
								if (cls) cls();
								if (subscription) subscription.unsubscribe();
								subscription = null;
								if (apiConfig.retryDialog) {
									this.als.alert2('commonService.processTimeoutMsg', null, { btns: [[{ key: 'retry', labelKey: "commonService.processTimeoutRetry", ngStyle: { backgroundColor: '#8ABF52' } }, 'cancel']] }).then((key: string) => {
										if (key == 'retry') {
											this.coms.log('Retry apiCall [' + apiConfig.data.api + ']');
											apiCall();
										} else {
											this.coms.log('Cancel apiCall [' + apiConfig.data.api + ']');
											reject('cancel');
										}
									});
								} else {
									reject('Fail to call api [' + apiConfig.data.api + ']');
								}
							}
						}
					});
				}
				retryFn();
			}
			apiCall();
		});
	}
	//---------------------------------------------------------------------------------------------

	public postFormData(api: string, formData: FormData = new FormData(), url: string = this.apiUrl, option:any = {}): Observable<Object> {
		//let url = environment.apiUrl;
		if (!option.headers) option.headers = new HttpHeaders();
		if (!this.jwt && localStorage.getItem('common_jwt')){
			this.jwt = localStorage.getItem('common_jwt');
		}
		if (this.jwt) {
			option.headers = option.headers.append("Authorization", 'Bearer ' + this.jwt);
			formData.append('jwt', this.jwt);
		}
		option.headers = option.headers.append("app-id", this.appId ? this.appId : 'unset');
		option.headers = option.headers.append("accept-language", this.lang ? this.lang : 'unset');
		formData.append("api", api);
		return this.http.post(url, formData);
	}

	//---------------------------------------------------------------------------------------------

	fixIosScroll() {
		if (this.dev.isMobile) {
			document.ontouchstart = function (e) {
				document.body.scrollTop = 0;
			};
			document.ontouchend = function (e) {
				document.body.scrollTop = 0;
			};
		}
	}
	//---------------------------------------------------------------------------------------------
	public isAppId(appId: string): boolean {
		return (this.appId && this.appId.toLowerCase() == appId.toLowerCase());
	}
	//---------------------------------------------------------------------------------------------
	public get appId2(){
		let appId = this.appId?this.appId:'';
		appId = appId.toLowerCase();
		appId = appId.replace(/\#.*/,'').replace(/%23.*/,'');
		appId = appId.replace(/\?.*/g,'');
		return appId;
	}
	//---------------------------------------------------------------------------------------------
	public async getResourceFile(src, outputType = 'file', file_type=''){
		if (src == null || src == ''){
			return Promise.reject('no file');
		}
		return this.post('Resource.get_blob', [src, file_type],this.apiUrl,{},{responseType: 'blob'}).pipe(map((res:any)=>{
			const arr = src.split('/');
			const filename = arr[arr.length - 1];
			if (outputType == 'blob'){
				return res;
			} else {
				return new File([res], filename);
			}
		})).toPromise();
	}
	//---------------------------------------------------------------------------------------------
	public setLang(langCode:string = null):void {
		if(!langCode)
		{
			langCode = this.getLang();
				
		}
		this.lang = langCode;
		localStorage.setItem('lang', langCode);
		this.translate.use(langCode);
	}
	//---------------------------------------------------------------------------------------------
	public getLang(defaultLang:string = null):string {
		if (this.lang) return this.lang;
		this.lang = localStorage.getItem('lang');
		if(!this.lang)
			this.lang = defaultLang ? defaultLang : 'tc';
		localStorage.setItem('lang', this.lang);
		return this.lang;
	}
	//---------------------------------------------------------------------------------------------
	public getUserDisplayName():string {
		try {
			if (this.lang == 'en') {
				return this.userInfo.first_name + ' ' + this.userInfo.last_name;
			} else {
				return this.userInfo.displayName = this.userInfo.c_last_name + this.userInfo.c_first_name;
			}
		} catch (e) {
			return "";
		}
	}
	//---------------------------------------------------------------------------------------------
	//---------------------------------------------------------------------------------------------
	//---------------------------------------------------------------------------------------------

	public logout() {
		this.post('Account.logout').subscribe((res: any) => {
			setTimeout(() => { //avoid error
				this.userInfo = null;
				this.jwt = '';
				this.jwtObj = null;
				if (localStorage) {
					localStorage.removeItem('common_jwt');
					localStorage.removeItem('common_uid');
					localStorage.removeItem('common_schoolId');
					localStorage.removeItem('common_schoolLogo');
					localStorage.removeItem('common_photo');
					sessionStorage.removeItem('common_photo_done');
					sessionStorage.removeItem('common_schoolLogo_done');
					localStorage.removeItem('homeScreen_data');
				}
				// let url = location.origin + '/login/' + this.datas.lang + '/' + this.datas.appId2;
				let url = location.origin + '/login';
				if (location.port == "") {
					const href = window.location.href;
					url = href.replace(/roWeb.*/, 'roWeb/login');
				}
				location.href = url;
			}, 10);
		});
	}
}
