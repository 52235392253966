import {Component, EventEmitter, OnInit, TemplateRef, Output, Input} from '@angular/core';

@Component({
	selector: 'tab-view',
	template: `
		<div class="tabs">
			<ng-container *ngFor="let tab of tabs; let i = index">
				<div
					(click)="change_tab(tab)"
					class="tab"
					[ngClass]="{active: tab.key === current_key}"
				>
					{{ tab.title || tab.key }}
				</div>
			</ng-container>
		</div>
	`,
	styles: [`
        :host {
            --active-color: #ffffff;
            --active-bg-color: #388074;
        }

        .tabs {
            display: flex;
            justify-content: center;
            /*border-width: 1px;*/
            /*border-style: solid;*/
            /*border-color: var(--active-bg-color);*/
            /*border-radius: 10px;*/
            overflow: hidden;
        }

        .tab {
            padding: 5px 10px;
            cursor: pointer;
            flex: 1;
            width: 120px;
            height: 50px;
			font-size: var(--popuptoggle-textsize, 15px);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999999;
			font-weight: 700;

            /*&:not(:last-child) {*/
            /*    border-right: 1px solid var(--active-bg-color);*/
            /*}*/
        }
		
        .tab.active {
            color: var(--active-bg-color);
			text-decoration: underline solid var(--active-bg-color);
			text-decoration-thickness: 2px;
			text-underline-offset: 4px;
        }
	`]
})
export class TabViewComponent implements OnInit {

	@Input() tabs: TabViewComponent_Tab[] = [];
	@Input() current_key = null;
	@Input() can_change = true;

	@Output() tab_changed = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		if (!this.current_key) {
			if (this.tabs && this.tabs.length) {
				this.current_key = this.tabs[0].key;
			} else {
				this.current_key = null;
			}
		}
	}

	change_tab(tab) {
		if (this.can_change) {
			this.current_key = tab.key;
		}
		this.tab_changed.emit(tab);
	}
}

interface TabViewComponent_Tab {
	key: string;
	title?: string | any;
}